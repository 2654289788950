import React, { FC } from 'react';
import Picture from '../../../@shared/picture';
import { TSplitModuleMediaPicture } from '../../types/splitModule.types';

const MediaPicture: FC<TSplitModuleMediaPicture> = ({ mobileImage, desktopImage }) => (
  <div className="split-module__column split-module__image">
    <Picture
      editorialImageProps={{
        mobileImage: mobileImage,
        desktopImage: desktopImage,
        mobileTransformation: 'Size500',
        desktopTransformation: 'Size1000',
        disableLazyLoad: true,
        cssClassImageEl: 'split-module__imagemobile',
      }}
    />
  </div>
);
export default MediaPicture;
