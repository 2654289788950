import { TContentHubButton, TStringField } from '../../../types/sitecore.types';
import { appendRootToInternalLink } from '../../../utils/appendRootToInternalLink.utils';
import { appendRootToRichTextInternalLinks } from '../../../utils/appendRootToRichTextInternalLinks.utils';
import { TComponentDetails, TSeriesDetailsData } from '../../@shared/innerComponentsPopup/types/innerComponentsPopup.types';
import { TContentHubSeriesOverviewProps } from '../types/contentHubSeriesOverview.type';

export class OverviewTextModel {
  private readonly fields?;
  public readonly data?: TSeriesDetailsData;
  constructor(props: TContentHubSeriesOverviewProps) {
    this.fields = props?.fields || null;
    this.data = props?.fields?.apiData || null;
  }

  get eyebrow(): TStringField {
    return { value: this.fields?.apiData?.eyebrow };
  }

  get title(): TStringField {
    return { value: this.fields?.apiData?.title };
  }
  get layoutType(): string {
    return this.fields?.apiData?.layoutType;
  }

  get description(): TStringField {
    return {
      value: appendRootToRichTextInternalLinks(this.fields?.apiData?.description),
    };
  }

  get primaryLink(): TContentHubButton {
    return this.fields?.apiData?.button1;
  }

  get primaryLinkUrl(): string | undefined {
    return appendRootToInternalLink(this.primaryLink?.url);
  }

  get secondaryLink(): TContentHubButton {
    return this.fields?.apiData?.button2;
  }

  get secondaryLinkUrl(): string | undefined {
    return appendRootToInternalLink(this.secondaryLink?.url);
  }

  get innerComponents(): TComponentDetails[] {
    return this.data?.innerComponents || [];
  }
}
