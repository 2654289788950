import React, { useState } from 'react';
import { isEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isEmptyResponse } from '../../utils/checkEmptyResponse';
import useExperienceEditorPlaceholder from '../../hooks/useExperienceEditorPlaceholder';
import ExperienceEditorComponentPlaceholder from '../@shared/experienceEditorComponentPlaceholder';
import EmptyApiDataMessage from '../@shared/emptyApiDataMessage';
import { TContentHubModelDetailSpecificationsTableProps, TMRPRow, TSpecGroup } from './types/index.types';
import { ContentHubModelDetailSpecificationsTableModel } from './models/index.model';
import SpecificationTable from './components/specificationTable/index';
import { textContains } from '../../utils/regex.utils';
import UnreleasedComponent from '../@shared/unreleasedComponent';
import AnalyticsVisibleEvent from '../@shared/analyticsVisibleEvent';

const ProductModelSpecifications = (props: TContentHubModelDetailSpecificationsTableProps) => {
  const model = new ContentHubModelDetailSpecificationsTableModel(props);
  const [searchTerm, setSearchTerm] = useState('');
  const apiResponse = isEmptyResponse(props);
  const isPlaceholderShown = useExperienceEditorPlaceholder(props);

  // Component is unreleased
  if (model.isUnreleased) {
    return <UnreleasedComponent />;
  }

  // star  node opened directly in EE
  if (isPlaceholderShown) {
    return <ExperienceEditorComponentPlaceholder image={model.thumbnailEE} componentName={model.componentName} />;
  }

  // not opened from star node
  // data missing when navigating via from products node
  if (!apiResponse && isEditorActive()) {
    return <EmptyApiDataMessage componentName={model.componentName} />;
  }

  // No data and not in EE
  if (!apiResponse && !isPlaceholderShown && !isEditorActive()) {
    return null;
  }

  const getCategoryRows = (): Array<TSpecGroup> => {
    if (searchTerm) {
      const newCategoryRows: Array<TSpecGroup> = [];

      model.categoryRows.forEach((category) => {
        const newCategory: TSpecGroup = {
          group: '',
          items: [],
        };
        if (textContains(category.group, searchTerm)) {
          newCategory.group = category.group;
        }
        const items = category.items.filter((item) => textContains(item.key, searchTerm));
        if (items.length) {
          newCategory.group = category.group;
          newCategory.items = items;
        }

        if (newCategory.group) {
          newCategoryRows.push(newCategory);
        }
      });
      return newCategoryRows;
    }
    return model.categoryRows;
  };

  const getMrpRows = (): TMRPRow => {
    if (searchTerm && !textContains(model.mrpRow?.label || '', searchTerm)) {
      return { ...model.mrpRow, hideRow: true };
    }
    return model.mrpRow;
  };

  const passProps = {
    colHeadings: model.colHeadings,
    originalMrpRow: model.mrpRow,
    originalCategoryRows: model.categoryRows,
    mrpRow: getMrpRows(),
    eyebrow: model.eyebrow,
    title: model.title,
    button1: model.button1,
    contextItemUrl: model.contextItemUrl,
    categoryRows: getCategoryRows(),
    searchTerm: searchTerm,
    setSearchTerm: setSearchTerm,
    convertToExcelLink: model.convertToExcelLink,
    notes: model.notes,
    iconsList: model.iconsList,
  };

  if (model.colHeadings?.length > 0 && model.haveAtLeastOneModelsWithSpecs) {
    return (
      <AnalyticsVisibleEvent event="series_specs_visible" className="model-details-specification-analytics-wrapper">
        <SpecificationTable {...passProps} />
      </AnalyticsVisibleEvent>
    );
  } else {
    return null;
  }
};

export default withSitecoreContext()(ProductModelSpecifications);
