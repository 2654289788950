import React, { FC, useEffect } from 'react';
import { isEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import BrochureCard from './components/brochureCard';
import ExperienceEditorComponentPlaceholder from '../@shared/experienceEditorComponentPlaceholder';
import EmptyApiDataMessage from '../@shared/emptyApiDataMessage';
import Heading from '../@shared/heading';
import UnreleasedComponent from '../@shared/unreleasedComponent';
import { isEmptyResponse } from '../../utils/checkEmptyResponse';
import { documentService, windowService } from '../../utils/window.utils';
import useExperienceEditorPlaceholder from '../../hooks/useExperienceEditorPlaceholder';
import { TCHSpecialOffersDownloadBrochuresProps } from './types/productSpecialOffersDownloadBrochures.types';
import { CHSpecialOffersDownloadBrochureModel } from './models/productSpecialOffersDetailDownloadBrochures.model';
import './styles.scss';

const ProductSpecialOffersDetailDownloadBrochures: FC<TCHSpecialOffersDownloadBrochuresProps> = (props) => {
  const model = new CHSpecialOffersDownloadBrochureModel(props);
  const apiResponse = isEmptyResponse(props);
  const isPlaceholderShown = useExperienceEditorPlaceholder(props);

  useEffect(() => {
    setTimeout(() => {
      const shareScript = 'https://static.addtoany.com/menu/page.js';
      const addthisScript = documentService()?.createElement?.('script');
      addthisScript.setAttribute('src', shareScript);
      const shareContentScript = documentService()?.querySelector?.(`[src="${shareScript}"]`);
      if (documentService()?.body && !shareContentScript) documentService()?.body?.appendChild?.(addthisScript);
      else windowService()?.a2a?.init_all?.();
    });
  }, [model.contextItemUrl]);

  // Component is unreleased
  if (model.isUnreleased) {
    return <UnreleasedComponent />;
  }

  //  star node opened directly in EE
  if (isPlaceholderShown) {
    return <ExperienceEditorComponentPlaceholder image={model.thumbnailEE} componentName={model.componentName} />;
  }

  if ((!apiResponse || !model.canShowComponent) && isEditorActive()) {
    return <EmptyApiDataMessage componentName={model?.componentName} />;
  }

  if ((!apiResponse && !isPlaceholderShown && !isEditorActive()) || !model.canShowComponent) {
    return null;
  }

  return (
    <section>
      <div className="special-offer-download-brochure-container special-offer-download-brochure-container__min-width-none">
        <div className="special-offer-download-brochure-container__label-container">
          <Heading useDictionaryFallback titleDictionaryKey='cnhi-specialOfferBrochureTitle' />
        </div>
        <div className="special-offer-download-brochure-container__card-container">
          {model?.downloadBrochureCard.map((card) => (
            <BrochureCard {...card} key={card.full} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default withSitecoreContext()(ProductSpecialOffersDetailDownloadBrochures);
