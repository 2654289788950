import React, { FC } from 'react';
import { CarouselNavPositionEnum } from '../../../../../enums/carousel.enums';
import CarouselTransparentNavSection from '../../../carouselTransparentNavSection';
import { TCarouselArrowAndNavSection } from '../../types/overlayCarouselPopup.types';
import OverlayCarouselArrow from '../carouselArrow';

const CarouselArrowAndNavSection: FC<TCarouselArrowAndNavSection> = (props) => {
  const { slides, moveLeft, videoPlaying, videoPaused, currentCardIndex, isWide, moveRight } = props;
  if (slides?.length > 1) {
    return (
      <>
        {!isWide && currentCardIndex === 0 ? null : (
          <OverlayCarouselArrow
            direction="previous"
            onClick={moveLeft}
            customClass={`
                overlay-carousel-popup__animate-icons
                ${videoPlaying && 'overlay-carousel-popup__animate-icons--animate-fade'}
                ${videoPaused && 'overlay-carousel-popup__animate-icons--show'}
                ${currentCardIndex === 0 && 'overlay-carousel-popup__animate-icons--hide'}
              `}
          />
        )}
        <CarouselTransparentNavSection position={CarouselNavPositionEnum.LEFT} handleTouchStart={moveLeft} />
        {!isWide && currentCardIndex + 1 === slides.length ? null : (
          <OverlayCarouselArrow
            direction="next"
            onClick={moveRight}
            customClass={`
                overlay-carousel-popup__animate-icons
                ${videoPlaying && 'overlay-carousel-popup__animate-icons--animate-fade'}
                ${videoPaused && 'overlay-carousel-popup__animate-icons--show'}
                ${currentCardIndex + 1 === slides.length && 'overlay-carousel-popup__animate-icons--hide'}
              `}
          />
        )}
        <CarouselTransparentNavSection position={CarouselNavPositionEnum.RIGHT} handleTouchStart={moveRight} />
      </>
    );
  }
  return null;
};

export default CarouselArrowAndNavSection;
