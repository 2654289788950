import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { TSeoTagsProps } from '../types/seoTags.types';

const distPathFavicon = '/dist/nhag/favicon.ico'; // favicon path fall back

const GeneralHeadOrMetaTags: FC<TSeoTagsProps> = ({ model }) => (
  <Helmet>
    <html lang={model.language || 'en'} />
    <title>{model.title}</title>

    {model.keywords && <meta name="keywords" content={model.keywords} />}
    {model.description && <meta name="description" content={model.description} />}
    {model.robots && <meta name="robots" content={model.robots} />}

    {model.gscVerificationCodes?.map((value) => (
      <meta name="google-site-verification" content={value} />
    ))}

    <link rel="shortcut icon" href={model.favIconUrl || distPathFavicon} />
    <link rel="canonical" href={model.canonicalUrl} />

    {model.alternateLinks.map((item) => (
      <link key={`href-lang-${item?.langCode}`} rel="alternate" hrefLang={item?.langCode} href={item?.langUrl}></link>
    ))}

    {model.language && <meta httpEquiv="content-language" content={model.language} />}
  </Helmet>
);

export default GeneralHeadOrMetaTags;
