import React, { FC } from 'react';
import { CustomHtmlComponentModel } from './models/CustomHtmlComponentModel.model';
import { TCustomHtmlComponentProps } from './types/CustomHtmlComponent.types';
import DangerouslySetHtmlAndJS from '../@shared/dangerouslySetHtmlAndJS';

const CustomHtml: FC<TCustomHtmlComponentProps> = (props) => {
  const model = new CustomHtmlComponentModel(props);
  const html = model.getInnerHtml?.value || '';

  return <DangerouslySetHtmlAndJS html={html} />;
};
export default CustomHtml;
