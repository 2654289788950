import { ComponentRendering } from '@sitecore-jss/sitecore-jss-react';
import {
  TCHSpecialOffersDownloadBrochuresDataAssets,
  TCHSpecialOffersDownloadBrochuresFields,
  TCHSpecialOffersDownloadBrochuresProps,
} from '../types/productSpecialOffersDownloadBrochures.types';

export class CHSpecialOffersDownloadBrochureModel {
  private readonly fields: TCHSpecialOffersDownloadBrochuresFields;
  private readonly rendering?: ComponentRendering;

  constructor(props: TCHSpecialOffersDownloadBrochuresProps) {
    this.fields = props?.fields || null;
    this.rendering = props?.rendering;
  }

  get thumbnailEE(): string {
    return this.fields?.itemData?.thumbnail || '';
  }

  get componentName(): string {
    return this.rendering?.componentName || '';
  }

  get downloadBrochureCard(): TCHSpecialOffersDownloadBrochuresDataAssets[] {
    return (this.fields?.apiData?.items || []).map((item) => {
      return {
        ...item,
        icon: this.fields?.itemData?.iconsList || [],
      };
    });
  }

  get canShowComponent(): boolean {
    return this.fields?.apiData?.items?.length > 0;
  }

  get isUnreleased(): boolean {
    return !!this.fields?.isComponentUnreleased;
  }

  get contextItemUrl(): string {
    return this.fields?.itemData?.contextItemUrl || '';
  }
}
