import './styles.scss';
import React, { FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { TQuickLinksProps } from './types/quickLinks.types';
import { QuickLinksModel } from './models/quickLinks.model';
import QuickLinksLink from './components/quickLinksLink';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import breakpoints from '../../constants/breakpoints.constants';
import { windowService } from '../../utils/window.utils';
import OtherQuickLinksMobile from './components/otherQuickLinksMobile';
import UnreleasedComponent from '../@shared/unreleasedComponent';
import useStickyWrapper from '../../hooks/useStickyWrapper';
import useEEActive from '../../hooks/useEEActive';
import useMediaQuery from '../../hooks/useMediaQuery';

const QuickLinks: FC<TQuickLinksProps> = (props: TQuickLinksProps) => {
  const model = new QuickLinksModel(props);

  const isWide = useMediaQuery(`(min-width: ${breakpoints['tbl-p']}px)` );
  const arr = isWide
    ? model.desktopLinks?.filter((el, index) => index < 7)
    : model.mobileLinks?.filter((el) => el.fields?.isOnStickyBar?.value);
  const isEEactive = props.sitecoreContext?.pageEditing;
  const [canShowQuickLinks, setCanShowQuickLinks] = useState(false);
  const stickyWrapperEle = useStickyWrapper();
  const isEE = useEEActive();

  const otherLinks = isWide ? [] : model.mobileLinks?.filter((el) => !el.fields?.isOnStickyBar?.value);

  const generateLinks = arr?.map((item, i) => {
    item.fields = item.fields || {};
    item.fields.first = false;
    item.fields.last = false;
    if (i === 0) {
      item.fields.first = true;
    }
    if (i === arr.length - 1) {
      item.fields.last = true;
    }
    if (arr.length === 1) {
      item.fields.first = true;
      item.fields.last = true;
    }

    if (isWide) {
      return <QuickLinksLink {...item} key={item?.id} />;
    } else {
      return i < 4 ? <QuickLinksLink {...item} key={item?.id} /> : null;
    }
  });

  const wrapperClassNameQuickLinks = (): string => {
    return `quick-links-container ${isEEactive ? 'quick-links-container--editor' : ''} ${
      canShowQuickLinks ? 'quick-links-container--show' : ''
    }`;
  };

  const classNameQuickLinks = (): string => {
    return `quick-links ${isEEactive ? 'quick-links--editor' : ''} ${canShowQuickLinks ? 'quick-links--load' : 'quick-links--unload'}`;
  };

  useEffect(() => {
    if (!isEEactive) {
      const checkAfterInitialView = (): void => {
        setCanShowQuickLinks(windowService().pageYOffset > windowService().innerHeight / 2);
      };
      windowService().addEventListener('scroll', checkAfterInitialView);

      return (): void => {
        windowService().removeEventListener('scroll', checkAfterInitialView);
      };
    }
  }, [isEEactive, stickyWrapperEle]);

  // Component is unreleased
  if (model.isUnreleased) {
    return <UnreleasedComponent />;
  }

  if (!arr || arr.length === 0) return null;

  const quickLinks = (
    <>
      <section className={wrapperClassNameQuickLinks()}>
        <div className={classNameQuickLinks()}>
          <ul className="quick-links__wrapper">{generateLinks}</ul>
        </div>
      </section>
      {otherLinks && otherLinks.length > 0 ? <OtherQuickLinksMobile quickLinks={otherLinks} label={model.othersLinkLabel} /> : <></>}
    </>
  );

  if (isEE)
    return (
      <>
        <div className={classNameQuickLinks()}>
          <ul className="quick-links__wrapper">{generateLinks}</ul>
        </div>
        {otherLinks && otherLinks.length > 0 ? <OtherQuickLinksMobile quickLinks={otherLinks} label={model.othersLinkLabel} /> : <></>}
      </>
    );

  if (!stickyWrapperEle) return null;

  return createPortal(quickLinks, stickyWrapperEle);
};

export default withSitecoreContext()(QuickLinks);
