import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isInternalUrl } from '../../../utils/link.utils';

const RoutableNotEditableLink = ({ href, ...otherProps }): JSX.Element => {
  if (href && isInternalUrl(href)) {
    return <Link to={href} {...otherProps} />;
  }
  // eslint-disable-next-line
  return <a href={href} rel="nofollow" {...otherProps} />;
};

RoutableNotEditableLink.propTypes = {
  href: PropTypes.string.isRequired,
};

export default RoutableNotEditableLink;
