import { windowService } from './window.utils';

function googleDataLayerPush(dataLayerData: any): void {
  windowService().dataLayer = windowService()?.dataLayer || [];

  if (!windowService().dataLayer) {
    return;
  }

  windowService().dataLayer.push(dataLayerData);
}

function generateGoogleLinkUrl(url: string | undefined): string {
  if ((url || '').startsWith('/')) {
    return windowService()?.location?.origin + url;
  }
  return url || '';
}

function googleNavClickEvent(type: string, link_url: string | undefined, link_text: string | undefined): void {
  const dataLayerData = {
    event: 'navigation_click',
    type,
    link_url: generateGoogleLinkUrl(link_url),
    link_text,
  };

  googleDataLayerPush(dataLayerData);
}

function goolgeMarketLangClickEvent(type: string, current_market_value: string | undefined, current_lang_value: string | undefined) {
  const dataLayerData = {
    event: 'market_lang_click',
    type,
    current_market_value,
    current_lang_value,
  };
  googleDataLayerPush(dataLayerData);
}

function googleButtonClickEvent(
  event: string,
  title: string | undefined,
  product_line: string | undefined,
  product_series: string | undefined
): void {
  const dataLayerData = {
    event,
    title,
    product_line,
    product_series,
  };

  googleDataLayerPush(dataLayerData);
}

function googleSocialProfileClickEvent(platform_link: string | undefined, platform_name: string | undefined) {
  const dataLayerData = {
    event: 'social_profile_click',
    platform_link: generateGoogleLinkUrl(platform_link),
    platform_name,
  };

  googleDataLayerPush(dataLayerData);
}

function googleSocialShareClickEvent(shared_link: string | undefined, sharing_platform: string | undefined) {
  const dataLayerData = {
    event: 'social_share_click',
    _clear: true,
    'data-tag-meta': {
      shared_link: generateGoogleLinkUrl(shared_link),
      sharing_platform,
    },
  };

  googleDataLayerPush(dataLayerData);
}

function googleCtaClickEvent(type: string, link_url: string | undefined, link_text: string | undefined, popup_content: string | undefined) {
  const dataLayerData = {
    event: 'cta_click',
    type,
    link_url: generateGoogleLinkUrl(link_url),
    link_text,
    popup_content,
  };
  googleDataLayerPush(dataLayerData);
}

function googleCTAClickEvent(
  event: string,
  link_url: string | undefined,
  link_text: string | undefined,
  product_line: string | undefined,
  product_series: string | undefined
): void {
  const dataLayerData = {
    event,
    link_url: generateGoogleLinkUrl(link_url),
    link_text,
    product_line,
    product_series,
  };

  googleDataLayerPush(dataLayerData);
}

function googleExportEvent(
  event: string,
  export_type: string | undefined,
  product_line: string | undefined,
  product_series: string | undefined
): void {
  const dataLayerData = {
    event,
    export_type,
    product_line,
    product_series,
  };

  googleDataLayerPush(dataLayerData);
}

function googleUpdatesClickEvent(
  event: string,
  title: string | undefined,
  link_url: string | undefined,
  link_text: string | undefined
): void {
  const dataLayerData = {
    event,
    title,
    link_url: generateGoogleLinkUrl(link_url),
    link_text,
  };

  googleDataLayerPush(dataLayerData);
}

function googleDownloadClickEvent(
  event: string,
  title: string | undefined,
  product_line: string | undefined,
  product_series: string | undefined,
  link_url: string | undefined
): void {
  const dataLayerData = {
    event,
    title,
    product_line,
    product_series,
    link_url: generateGoogleLinkUrl(link_url),
  };

  googleDataLayerPush(dataLayerData);
}

function googleFileDownloadClickEvent(
  file_name: string | undefined,
  content_type: string | undefined,
  link_text: string | undefined,
  link_url: string | undefined
): void {
  const dataLayerData = {
    event: 'file_download',
    file_name,
    content_type,
    link_text,
    link_url: generateGoogleLinkUrl(link_url),
  };

  googleDataLayerPush(dataLayerData);
}

function goolgeInteractiveImageClickEvent(title: string | undefined, content: string | undefined) {
  const dataLayerData = {
    event: 'interactive_module_action',
    type: 'Interactive Image',
    interaction: 'hotspot',
    title,
    content,
  };
  googleDataLayerPush(dataLayerData);
}

function googleClickDealerLocatorResults(
  event: string | undefined,
  dealerLocatorCountry: string | undefined,
  dealerLocatorSearch: string | undefined,
  dealerLocatorEquipment: string | undefined,
  dealerLocatorServices: string | undefined,
  dealerLocatorState: string | undefined
): void {
  const dataLayerData = {
    event,
    dealerLocatorCountry,
    dealerLocatorSearch,
    dealerLocatorEquipment,
    dealerLocatorServices,
    dealerLocatorState,
  };
  googleDataLayerPush(dataLayerData);
}

function googleClickDealerLocatorGetDetails(
  event: string,
  dealerLocatorCountry: string | undefined,
  dealerLocatorSearch: string | undefined,
  dealerName: string | undefined,
  certifiedDealer: string | undefined
): void {
  const dataLayerData = {
    event,
    dealerLocatorCountry,
    dealerLocatorSearch,
    dealerName,
    certifiedDealer,
  };
  googleDataLayerPush(dataLayerData);
}

function googleDealerLocatorContactClick(event: string, type: string | undefined, dealerName: string | undefined): void {
  const dataLayerData = {
    event,
    type,
    dealerName,
  };
  googleDataLayerPush(dataLayerData);
}

function googleFormProgress(form_name, form_step: string, form_step_name: string | undefined) {
  const dataLayerData = {
    event: 'form_progress',
    form_name,
    form_step,
    form_step_name,
  };
  googleDataLayerPush(dataLayerData);
}

export {
  googleDataLayerPush,
  googleNavClickEvent,
  goolgeMarketLangClickEvent,
  googleSocialProfileClickEvent,
  googleCtaClickEvent,
  googleButtonClickEvent,
  googleCTAClickEvent,
  googleExportEvent,
  googleUpdatesClickEvent,
  googleDownloadClickEvent,
  googleFileDownloadClickEvent,
  googleSocialShareClickEvent,
  goolgeInteractiveImageClickEvent,
  googleClickDealerLocatorResults,
  googleClickDealerLocatorGetDetails,
  googleDealerLocatorContactClick,
  googleFormProgress,
};
