import React, { useRef, useState, FC } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { APIPrefix, 
          DealerLocatorUrlSegment, 
          DealerLocatorGetDealerByGeoCodeSegment, 
          DealerLocatorGetDealerByDealerNumberSegment, 
          DealerLocatorGetDealerByDealerNameSegment, 
          DealerLocatorGetDealerByGeographicFilterSegment, 
          DealerLocatorGetDealerByAddressSegment} from '../../constants/api.constants';
import { DealerLocatorProps } from './types/dealerLocator.types';
import { DealerLocatorModel } from './models/dealerLocator.model';
import UnreleasedComponent from '../@shared/unreleasedComponent';
import './styles.scss';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const formatCoordinate = (coordinate): number => {
  return coordinate.length !== 0 ? Number.parseFloat(coordinate): 0.0;
}

const DealerLocator: FC<DealerLocatorProps> = (props) => {
  const model = new DealerLocatorModel(props);

  const [fetchedDealers, setFetchedDealers] = useState(JSON.parse('{}'));

  const [noDealersFetched, setNoDealersFetched] = useState(false);
  const [autoGeoLocationOnClick, setAutoGeoLocationOnClick] = useState(false);
  const [latitudeValue, setLatitudeValue] = useState(model.initialLatitude);
  const latitudeRef = useRef<any>(null);
  const [longitudeValue, setLongitudeValue] = useState(model.initialLongitude);
  const longitudeRef = useRef<any>(null);
  const [dealerNumberValue, setDealerNumberValue] = useState('911-0N3222A');
  const dealerNumberRef = useRef<any>(null);
  const [dealerNameValue, setDealerNameValue] = useState('Ronson Equipment');
  const dealerNameRef = useRef<any>(null);
  const [stateValue, setStateValue] = useState('Madagascar');
  const stateRef = useRef<any>(null);
  const [addressValue, setAddressValue] = useState('700 State St, Racine, WI 53404');
  const addressRef = useRef<any>(null);

  const containerStyle = {
    width: '600px',
    height: '400px'
  };

  const center = {
    lat: latitudeValue,
    lng: longitudeValue
  };

  const fetchDealers = (event, apiFunction, params): void => {
    event && event.preventDefault();

    setFetchedDealers(JSON.parse('{}'));

    const dealerApiEndpoint = `/${APIPrefix}/${DealerLocatorUrlSegment}/${apiFunction}?brand=${model.brand}&pageId=${model.pageId}&language=${model.siteLanguage}&${params}`;

    fetch(dealerApiEndpoint)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          setNoDealersFetched(true);
          throw Error(`${res.status} : Failed to fetch dealers`);
        }
      })
      .then((result) => {
        setFetchedDealers(result);   
        setNoDealersFetched(false);
      })
      .catch(() => {
        setFetchedDealers(JSON.parse('{}'));
        setNoDealersFetched(true);
      });
  }

  const onClickHandler = (coordinates): void => {   
    if (autoGeoLocationOnClick) 
      {
        setLatitudeValue(coordinates.latLng.lat());
        setLongitudeValue(coordinates.latLng.lng());
        fetchDealers(null, DealerLocatorGetDealerByGeoCodeSegment, `latitude=${coordinates.latLng.lat()}&longitude=${coordinates.latLng.lng()}`);
      }
  }

  // Component is unreleased
  if (model.isUnreleased) {
  return (
      <div className="dealerLocator">
        <p>Dealer Locator Component - still WIP / Unreleased - Do not use it!</p>
        <hr />
        <div className="dealerLocator__row">
          <div className="dealerLocator__column">
            <LoadScript googleMapsApiKey={model.dealerLocatorGoogleMapsAPIKey}>
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={model.initialZoom}
                onClick={onClickHandler}
              >
                {fetchedDealers?.dealershipResults?.map(dealer => 
                  <>
                  <Marker
                    position= {{
                      lat: formatCoordinate(dealer?.dealership?.latitude), 
                      lng: formatCoordinate(dealer?.dealership?.longitude)
                    }}
                    title={dealer.dealership?.dealerName}
                    icon={'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png'}
                  />
                  </>
                )}                    
              </GoogleMap>
            </LoadScript>
          </div>
          <div className="dealerLocator__double-column">
            <form onSubmit={event => fetchDealers(event, DealerLocatorGetDealerByGeoCodeSegment, `latitude=${latitudeValue}&longitude=${longitudeValue}`)}>
              <fieldset>
                <legend>Search by Geo location:</legend>
                <label htmlFor='latitude'>Latitude:</label>
                <input
                  id="latitude"
                  name="latitude"
                  type="text"
                  ref={latitudeRef}
                  className="dealerLocator__input"
                  value={latitudeValue}
                  onChange={(e): void => setLatitudeValue(Number.parseFloat(e.target.value))}
                  autoComplete="off"
                />
                <label className="dealerLocator__label" htmlFor='longitude'>Longitude:</label>
                <input
                  id="longitude"
                  name="longitude"
                  type="text"
                  ref={longitudeRef}
                  className="dealerLocator__input"
                  value={longitudeValue}
                  onChange={(e): void => setLongitudeValue(Number.parseFloat(e.target.value))}
                  autoComplete="off"
                />
                <button className="dealerLocator__button" type="submit" disabled={!latitudeValue || !longitudeValue}>
                  Search by Geo location
                </button>

                <a className={`dealerLocator__switch-button-${autoGeoLocationOnClick ? 'on' : 'off'}`} onClick={() => setAutoGeoLocationOnClick(!autoGeoLocationOnClick)}>
                  {autoGeoLocationOnClick ? 'Turn off ' : 'Turn on'} automatic geo location by click
                </a>
              </fieldset>
            </form>
            <br />
            <form onSubmit={event => fetchDealers(event, DealerLocatorGetDealerByDealerNumberSegment, `dealerNumber=${dealerNumberValue}`)}>
              <fieldset>
                <legend>Search by Dealer Number:</legend>
                <label htmlFor='dealerNumber'>Dealer Number:</label>
                <input
                  id="dealerNumber"
                  name="dealerNumber"
                  type="text"
                  ref={dealerNumberRef}
                  className="dealerLocator__input"
                  value={dealerNumberValue}
                  onChange={(e): void => setDealerNumberValue(e.target.value)}
                  autoComplete="off"
                />
                <button className="dealerLocator__button" type="submit" disabled={!dealerNumberValue}>
                  Search by Dealer Number
                </button>                
              </fieldset>
            </form>
            <br />
            <form onSubmit={event => fetchDealers(event, DealerLocatorGetDealerByDealerNameSegment, `dealerName=${encodeURIComponent(dealerNameValue)}`)}>
              <fieldset>
                <legend>Search by Dealer Name:</legend>
                <label htmlFor='dealerName'>Dealer Name:</label>
                <input
                  id="dealerName"
                  name="dealerName"
                  type="text"
                  ref={dealerNameRef}
                  className="dealerLocator__input"
                  value={dealerNameValue}
                  onChange={(e): void => setDealerNameValue(e.target.value)}
                  autoComplete="off"
                />
                <button className="dealerLocator__button" type="submit" disabled={!dealerNameValue}>
                  Search by Dealer Name
                </button>
              </fieldset>
            </form>
            <br />
            <form onSubmit={event => fetchDealers(event, DealerLocatorGetDealerByGeographicFilterSegment, `state=${encodeURIComponent(stateValue)}`)}>
              <fieldset>
                <legend>Search by Geographic Filter:</legend>
                <label htmlFor='state'>State:</label>
                <input
                  id="state"
                  name="state"
                  type="text"
                  ref={stateRef}
                  className="dealerLocator__input"
                  value={stateValue}
                  onChange={(e): void => setStateValue(e.target.value)}
                  autoComplete="off"
                />
                <button className="dealerLocator__button" type="submit" disabled={!stateValue}>
                  Search by Geographic Filter
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp; Do not forget to set country code to MG to make this work!
              </fieldset>
            </form>
            <br />
            <form onSubmit={event => fetchDealers(event, DealerLocatorGetDealerByAddressSegment, `address=${encodeURIComponent(addressValue)}`)}>
              <fieldset>
                <legend>Search by Address:</legend>
                <label htmlFor='address'>Address:</label>
                <input
                  id="state"
                  name="state"
                  type="text"
                  ref={addressRef}
                  className="dealerLocator__input"
                  value={addressValue}
                  onChange={(e): void => setAddressValue(e.target.value)}
                  autoComplete="off"
                />
                <button className="dealerLocator__button" type="submit" disabled={!addressValue}>
                  Search by Address
                </button>
             
              </fieldset>
            </form>
          </div>
        </div>        
        {noDealersFetched && <strong>No dealers found! Please try different values or location!</strong>}
        {!noDealersFetched && Object.keys(fetchedDealers).length !== 0 
          ? <>
              <br /><br />
              <hr />
              <strong>Dealers found:</strong>
              {fetchedDealers?.dealershipResults?.map((dealer, index) => 
                <p key={dealer?.dealership?.dealerName}>
                  <strong>${modelListing}` } }}
                extraClassName="component-details__button"
              >
                {t('cnhi-viewPLMProductPage')}
              </CTA>
            )}
          </div>
        </div>
      </div>
    </article>
  );
};

export default withTranslation()(ContentHubSeriesDetails);
