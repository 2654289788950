import React, { FC } from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Helmet from 'react-helmet';
import Icon from '../icon';
import NavigationBarItem from '../navigationBarItem';
import { NavigationBarModel } from './models/breadcrumbBar.model';
import { TNavigationBarProps } from './types/breadcrumbBar.types';
import RoutableNotEditableLink from '../routableNotEditableLink';
import UnreleasedComponent from '../unreleasedComponent';
import { iconSize } from '../../../constants/icon.constants';
import { useHeroBannerAsFirstComponent, useHeroBannerBoxedTextAsFirstComponent } from '../../../hooks/useHeroBannerAsFirstComponent';
import DictionaryTerm from '../dictionaryTerm';
import './style.scss';
import { TNavigationBarBreadcrumbItem } from '../navigationBarItem/types/navigationBarItem.types';

const SharedBreadcrumbBar: FC<TNavigationBarProps> = (props: TNavigationBarProps) => {
  const model = new NavigationBarModel(props);
  const breadcrumbItems = model?.breadcrumbItems || [];

  const exceptComponentNames = props?.rendering?.componentName ? [props?.rendering?.componentName] : [];
  const isShownAsOverlay = useHeroBannerBoxedTextAsFirstComponent(exceptComponentNames);
  const isHeroBannerCarousel = useHeroBannerAsFirstComponent(exceptComponentNames);
  const isEditOrPreview = props?.sitecoreContext?.pageState === 'edit' || props?.sitecoreContext?.pageState === 'preview';
  const cssClass = `nav-bar nav-bar__${isShownAsOverlay || isHeroBannerCarousel ? 'hero-banner' : 'no-hero-banner'} ${
    isEditOrPreview ? ' EE-active' : ''
  } ${isHeroBannerCarousel ? 'nav-bar__hero-banner--carousel' : ''}`;

  const getItemWithModifiedUrl = (item: TNavigationBarBreadcrumbItem): TNavigationBarBreadcrumbItem => {
    if (props?.appendQueryParams) {
      return {
        ...item,
        url: props.appendQueryParams(item.url),
      };
    }

    return item;
  };

  const getModifiedBackButtonUrl = (url: string) => {
    if (props?.appendQueryParams) {
      return props.appendQueryParams(url);
    }

    return url;
  }

  // Component is unreleased
  if (model.isUnreleased) {
    return <UnreleasedComponent />;
  }

  return (
    <>
      <Helmet script={[model.breadcrumbJSONLD]} />
      <nav className={cssClass}>
        <div className="nav-bar__wrapper">
          <ul className="nav-bar__breadcrumb">
            {breadcrumbItems.map((item, i, self) => (
              <NavigationBarItem item={getItemWithModifiedUrl(item)} isLast={i === self.length - 1} key={`navigation-bar-item-${i}`} />
            ))}
          </ul>
          <RoutableNotEditableLink href={getModifiedBackButtonUrl(model.backButtonUrl ?? '')} className="link">
            <Icon iconName="arrow-chevron-left" size={iconSize.dsk} extraClassName="nav-bar__back-icon" />
            <DictionaryTerm keyword={'cnhi-backButton'} />
          </RoutableNotEditableLink>
        </div>
      </nav>
    </>
  );
};

export default withSitecoreContext()(SharedBreadcrumbBar);
