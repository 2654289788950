import { TStringField } from '../../../types/sitecore.types';

import { TCustomHtmlComponentFields, TCustomHtmlComponentProps } from '../types/CustomHtmlComponent.types';

export class CustomHtmlComponentModel {
  private readonly fields: TCustomHtmlComponentFields;
  constructor(props: TCustomHtmlComponentProps) {
    this.fields = props?.fields || null;
  }
  get getInnerHtml(): TStringField {
    return this.fields && this.fields?.customHtml;
  }
}
