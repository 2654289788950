import React, { FC, useContext } from 'react';
import { iconSize } from '../../../../constants/icon.constants';
import { ALL_SP_CATEGORIES, ALL_SP_LINES, ALL_SP_SERIES } from '../../../../constants/specialOffersFilters.constants';
import ctaTypes from '../../../../enums/CTA.enums';
import DictionaryTerm from '../../../@shared/dictionaryTerm';
import Dropdown from '../../../@shared/filters/components/dropdown';
import Icon from '../../../@shared/icon';
import { FilterContext } from '../../fiilterContext';
import {
  TProductSpecialOffersListingCategoryFilter,
  TProductSpecialOffersListingLineFilter,
  TProductSpecialOffersListingSeriesFilter,
} from '../../types/productSpecialOffersListing.types';
import SortCTA from '../sortCTA';
import './styles.scss';

const DesktopFilter: FC<{}> = () => {
  const { model, lineSelectedInd, seriesSelectedInd, categorySelectedInd, updateFilters, resetFilters } = useContext(FilterContext);

  const categoryOptions: TProductSpecialOffersListingCategoryFilter[] = [
    { id: ALL_SP_CATEGORIES, title: <DictionaryTerm keyword="cnhi-special-offers-listing-all-categories" /> },
    ...model.categories,
  ];

  const lineOptions: TProductSpecialOffersListingLineFilter[] = [
    { id: ALL_SP_LINES, title: <DictionaryTerm keyword="cnhi-special-offers-listing-all-lines" /> },
    ...model.lines,
  ];

  const seriesOptions: TProductSpecialOffersListingSeriesFilter[] =
    lineSelectedInd === ALL_SP_LINES
      ? []
      : [
          { id: ALL_SP_SERIES, title: <DictionaryTerm keyword="cnhi-special-offers-listing-all-series" /> },
          ...(model.lines?.[lineSelectedInd]?.series || []),
        ];

  const handleCategoryOptionClick = (newCategorySelectedInd) => {
    updateFilters({
      categorySelectedInd: newCategorySelectedInd === 0 ? ALL_SP_CATEGORIES : newCategorySelectedInd - 1,
      lineSelectedInd: ALL_SP_LINES,
      seriesSelectedInd: ALL_SP_SERIES,
    });
  };

  const handleLineOptionClick = (newLineSelectedInd) => {
    updateFilters({
      lineSelectedInd: newLineSelectedInd === 0 ? ALL_SP_LINES : newLineSelectedInd - 1,
      seriesSelectedInd: ALL_SP_SERIES,
      categorySelectedInd: ALL_SP_CATEGORIES,
    });
  };

  const handleSeriesOptionClick = (newSeriesSelectedInd) => {
    updateFilters({
      seriesSelectedInd: newSeriesSelectedInd === 0 ? ALL_SP_SERIES : newSeriesSelectedInd - 1,
      categorySelectedInd: ALL_SP_CATEGORIES,
    });
  };

  return (
    <div className="special-offers-listing-filter--dsk">
      <div className="special-offers-listing-filter__heading">
        <p className="special-offers-listing-filter__title">
          <DictionaryTerm keyword="cnhi-special-offers-listing-filter-heading" />
        </p>
        {(lineSelectedInd !== ALL_SP_LINES || categorySelectedInd !== ALL_SP_CATEGORIES) && (
          <span
            className={`${ctaTypes['baseButton']} ${ctaTypes['secondaryBlue']} special-offers-listing-filter__reset`}
            onClick={resetFilters}
          >
            <DictionaryTerm keyword="cnhi-special-offers-listing-desktop-reset-filters" />
            <Icon iconName="action-refresh" size={iconSize['dsk']} color="primary" />
          </span>
        )}
      </div>
      <div className={'offers-filter'}>
        <div className="offers-filter__dropdown-wrapper">
          <Dropdown
            className="offers-filter__dropdown"
            dropdownOptions={categoryOptions}
            id={'special-offer-category-dropdown'}
            handleOptionClick={handleCategoryOptionClick}
            selectedOptionInd={categorySelectedInd === ALL_SP_CATEGORIES ? 0 : categorySelectedInd + 1}
          />
          <Dropdown
            className="offers-filter__dropdown"
            dropdownOptions={lineOptions}
            id={'special-offer-line-dropdown'}
            handleOptionClick={handleLineOptionClick}
            selectedOptionInd={lineSelectedInd === ALL_SP_LINES ? 0 : lineSelectedInd + 1}
          />
          {String(seriesSelectedInd) && lineSelectedInd !== ALL_SP_LINES && (
            <Dropdown
              className="offers-filter__dropdown"
              dropdownOptions={seriesOptions}
              id={'special-offer-series-dropdown'}
              handleOptionClick={handleSeriesOptionClick}
              selectedOptionInd={seriesSelectedInd === ALL_SP_SERIES ? 0 : seriesSelectedInd + 1}
            />
          )}
        </div>
        <div className="offers-filter__sort-cta">
          <SortCTA />
        </div>
      </div>
    </div>
  );
};

export default DesktopFilter;
