import { ComponentRendering } from '@sitecore-jss/sitecore-jss-react';
import { appendRootToRichTextInternalLinks } from '../../../utils/appendRootToRichTextInternalLinks.utils';
import { getThumbnailProps } from '../../../utils/youtubeUtils';
import { TGalleryItem as TOverlayCarouselPopupGalleryItem } from '../../@shared/overlayCarouselPopup/types/overlayCarouselPopup.types';
import {
  ProductSeriesDetailMediaGalleryFields,
  ProductSeriesDetailMediaGalleryProps,
  TGalleryItem,
} from '../types/productSeriesDetailMediaGallery.types';

export class ProductSeriesDetailMediaGalleryModel {
  private readonly fields?: ProductSeriesDetailMediaGalleryFields;
  private readonly rendering?: ComponentRendering;

  constructor(props: ProductSeriesDetailMediaGalleryProps) {
    this.fields = props?.fields || undefined;
    this.rendering = props?.rendering;
  }

  get thumbnailEE(): string {
    return this.fields?.itemData?.thumbnail || '';
  }

  get componentName(): string {
    return this.rendering?.componentName || '';
  }

  get slides(): Array<TOverlayCarouselPopupGalleryItem> {
    if (this.fields?.apiData?.assets && this.fields?.apiData?.assets?.length > 0) {
      return this.fields?.apiData?.assets?.map((item: TGalleryItem) => {
        return {
          title: {
            value: item.title,
          },
          description: {
            value: item.description,
          },
          productImageProps: {
            desktopRendition: item?.rendition_desktop_size1100,
            mobileRendition: item?.rendition_mobile_size500,
            ...(item.role === 'extvideo' && { desktopRendition: { url: getThumbnailProps(item.full || '')?.thumbnailImgSrc || '' } }),
          },
          isVideo: item.role !== 'main',
          videoUrl: item?.full,
        } as TOverlayCarouselPopupGalleryItem;
      });
    }
    return [];
  }

  get eyebrow(): string | undefined {
    return this.fields?.apiData?.eyebrow;
  }

  get title(): string | undefined {
    return this.fields?.apiData?.title;
  }

  get description(): string | undefined {
    return appendRootToRichTextInternalLinks(this.fields?.apiData?.description);
  }

  get isUnreleased(): boolean {
    return !!this.fields?.isComponentUnreleased;
  }
}
