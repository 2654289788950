import React, { FC } from 'react';
import useEEActive from '../../../hooks/useEEActive';
import './styles.scss';

const UnreleasedComponent: FC = () => {
  const isEEActive = useEEActive();

  if (isEEActive) {
    return (
      <section className="unreleased-component">
        <p>This component was unreleased so will be not visible in the page until will be enabled by admin/developer
          team.</p>
      </section>
    );
  }

  return null

};

export default UnreleasedComponent;
