import { TProductSeoTagsFields, TProductSeoTagsProps, TRouteData } from '../types/productSeoTags.types';

import { ComponentRendering } from '@sitecore-jss/sitecore-jss-react';
import { ISeoModel } from '../../@shared/seoTags/types/seoTags.types';
import { THrefLangItem, TSitecoreContext } from '../../../types/sitecore.types';
import { windowService } from '../../../utils/window.utils.js';

export class ProductSeoTagsModel implements ISeoModel {
  private readonly fields?: TProductSeoTagsFields;
  private readonly context?: TSitecoreContext;
  private readonly route?: TRouteData;
  private readonly rendering?: ComponentRendering;

  constructor(props: TProductSeoTagsProps) {
    this.fields = props?.fields;
    this.context = props?.sitecoreContext || null;
    this.route = props?.sitecoreContext?.route as any || null;
    this.rendering = props?.rendering;
  }

  get componentName(): string {
    return this.rendering?.componentName || '';
  }

  get title(): string {
    const pageTitle = this.fields?.apiData?.seo?.title ? this.fields?.apiData?.seo?.title : this.route?.fields?.metaTitle?.value;
    const brandName = this.context?.metaData?.brandName;
    const pageTitleSuffix = this.context?.metaData?.browserTitleSuffix;
    const brandNameWithSuffix = brandName && pageTitleSuffix ? `${brandName} ${pageTitleSuffix}` : brandName;

    if (pageTitle && brandName) {
      return `${pageTitle} | ${brandNameWithSuffix}`;
    }

    return pageTitle || brandNameWithSuffix || '';
  }

  get description(): string {
    return this.fields?.apiData?.seo?.description || this.route?.fields?.metaDescription?.value || '';
  }

  get keywords(): string {
    return this.fields?.apiData?.seo?.keywords || this.route?.fields?.MetaKeywords?.value || '';
  }

  get robots(): string {
    return this.fields?.apiData?.seo?.metaRobots || this.route?.fields?.RobotsContent?.value || '';
  }

  get canonicalUrl(): string {
    const canonicalurl =
      this.fields?.apiData?.seo?.canonicalUrl ||
      this.route?.fields?.canonicalLink?.value?.href ||
      this.route?.fields?.metaUrl?.value ||
      this.context?.metaData?.fullCanonicalLink;

    if (canonicalurl && !canonicalurl.includes('/layout/render')) {
      return canonicalurl;
    }
    return windowService()?.location?.href;
  }

  get alternateLinks(): Array<THrefLangItem> {
    return this.context?.ContextExtensions?.hrefLanguageList || [];
  }

  get language(): string {
    return this.context?.language || '';
  }

  get ogTitle(): string {
    return this.title;
  }

  get ogSiteName(): string {
    return this.title;
  }

  get currentPageUrl(): string {
    return this.context?.currentPageUrl as string ?? '';
  }

  get ogUrl(): string {
    return this.fields?.apiData?.seo?.ogUrl || this.route?.fields?.metaUrl?.value || this.currentPageUrl;
  }

  get ogImage(): string {
    let image = this.fields?.apiData?.seo?.ogImage;
    if (!image && this.fields?.apiData?.assets) {
      image = this.fields?.apiData?.assets[0]?.Size1200;
    }
    return image || this.route?.fields?.metaImage?.value?.src || '';
  }

  get ogType(): string {
    return this.fields?.apiData?.seo?.ogType || this.route?.fields?.metaType?.value || 'website';
  }

  get ogDescription(): string {
    return this.fields?.apiData?.seo?.ogDescription || this.description;
  }

  get ogLocale(): string {
    return this.context?.language || '';
  }

  get ogLocaleAlternate(): string[] {
    return (
      this.context?.ContextExtensions?.hrefLanguageList
        ?.filter((langItem) => langItem?.langCode !== this.context?.language)
        .map((langItem) => langItem.langCode || '') || []
    );
  }

  get priceAmountFromApi(): string {
    let price = '';
    if (this.fields?.apiData?.msrp?.length) {
      this.fields.apiData.msrp.split('').forEach((char) => {
        if (/\d|[.]/.test(char)) {
          price += char;
        }
      });
    }
    return price;
  }

  get ogPriceAmount(): string {
    return this.priceAmountFromApi || (this.route?.fields?.priceAmount?.value as string) || '';
  }

  get ogPriceCurrency(): string {
    return (this.route?.fields?.priceCurrency?.value as string) || '';
  }

  get twitterCard(): string {
    return this.fields?.apiData?.seo?.seoTwitterCard || 'summary';
  }

  get twitterSite(): string {
    return this.context?.metaData?.twitterMetaSite || this.fields?.apiData?.seo?.seoTwitterSite || this.route?.fields.metaSite?.value || '';
  }

  get twitterTitle(): string {
    return this.fields?.apiData?.seo?.title || this.route?.fields.metaTitle?.value || '';
  }

  get twitterDescription(): string {
    return this.description;
  }

  get twitterImage(): string {
    return this.fields?.apiData?.seo?.ogImage || this.route?.fields?.metaImage?.value?.src || '';
  }

  get gscVerificationCodes(): Array<string> {
    let values: Array<string> = [];
    const extMetaGSVC: Array<string> =
      typeof this.context?.metaData?.googleSiteVerificationCode === 'object'
        ? Object.values(this.context?.metaData?.googleSiteVerificationCode)
        : [];
    const regularGSVC: Array<string> = this.route?.fields?.googleSiteVerificationCode?.value?.map((link) => link.Value || '') || [];
    if (extMetaGSVC) {
      values = values.concat(extMetaGSVC);
    }
    if (regularGSVC) {
      values = values.concat(regularGSVC);
    }
    return values;
  }

  get favIconUrl(): string {
    return this.route?.fields?.Favicon?.value?.src || this.context?.metaData?.favicon?.src || '';
  }

  get isUnreleased(): boolean {
    return !!this.fields?.isComponentUnreleased;
  }
}
