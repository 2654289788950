import React from 'react';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { isEmptyResponse } from '../../utils/checkEmptyResponse';
import useExperienceEditorPlaceholder from '../../hooks/useExperienceEditorPlaceholder';
import ExperienceEditorComponentPlaceholder from '../@shared/experienceEditorComponentPlaceholder';
import EmptyApiDataMessage from '../@shared/emptyApiDataMessage';
import { ContentHubSeriesView360Props } from './types/productSeriesView360.types';
import { ProductSeriesView360Model } from './models/productSeriesView360.model';
import View360HotSpot from './components/view360HotSpot';

import './styles.scss';
import UnreleasedComponent from '../@shared/unreleasedComponent';

const ProductSeriesView360 = (props: ContentHubSeriesView360Props): JSX.Element | null => {
  const model = new ProductSeriesView360Model(props);
  const apiResponse = isEmptyResponse(props);
  const isEEActive = isEditorActive();
  const isPlaceholderShown = useExperienceEditorPlaceholder(props);

  // Component is unreleased
  if (model.isUnreleased) {
    return <UnreleasedComponent />;
  }

  // star node opened directly in EE
  if (isPlaceholderShown) {
    return <ExperienceEditorComponentPlaceholder image={model.thumbnailEE} componentName={model.componentName} />;
  }

  // not opened from star node
  // data missing when navigating via from products node
  if (!apiResponse && isEEActive) {
    return <EmptyApiDataMessage componentName={model.componentName} />;
  }

  // No data and not in EE
  if (!apiResponse && !isPlaceholderShown && !isEEActive) {
    return null;
  }

  return <View360HotSpot title={model?.title} hotspotConfigPath={model?.hotspotConfigPath} hotInitConfig={model?.hotInitConfig} />;
};

export default ProductSeriesView360;
