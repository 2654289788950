import React, { FC } from 'react';

import BaseTextField from '../baseTextField';
import { ValueFieldProps } from '@sitecore-jss/sitecore-jss-react-forms/types';
import { NumberInputViewModel } from '@sitecore-jss/sitecore-jss-forms';

const NumberField: FC<ValueFieldProps<NumberInputViewModel>> = (props) => {
  const numberValidation = {
    step: props?.field?.model?.step,
  };

  return <BaseTextField type="number" inputAttributes={numberValidation} {...props} />;
};

export default NumberField;
