import React, { FC } from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { TModalComponentsItemProps } from '../types/innerComponentsPopup.types';
import CTA from '../../CTA';
import ctaTypes from '../../../../enums/CTA.enums';
import Picture from '../../picture';
import { targetLinkdsk } from '../../../../utils/targetDskLink';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { BreakpointsInPx } from '../../../../constants/breakpoints.constants';
import { appendRootToInternalLink } from '../../../../utils/appendRootToInternalLink.utils';
import { appendRootToRichTextInternalLinks } from '../../../../utils/appendRootToRichTextInternalLinks.utils';

const InnerCard: FC<TModalComponentsItemProps> = (props) => {
  const model = props.component;
  const asset = model?.assets?.length ? model.assets?.[0] : null;
  const cardsCount = props?.cardsCount || 0;
  const isDesktop = useMediaQuery(`(min-width: ${BreakpointsInPx['tbl-p']})`);
  const link1 = { value: { href: model?.button1?.url, target: model?.button1?.target } };
  const link2 = { value: { href: model?.button2?.url, target: model?.button2?.target } };

  return (
    <section className="inner-cards-module">
      {asset && (
        <Picture
          productImageProps={{
            desktopRendition: cardsCount > 2 ? asset?.rendition_desktop_size500 : asset?.rendition_desktop_size1000,
            mobileRendition: asset?.rendition_mobile_size500,
            alt: asset?.alt,
            pictureClassName: 'inner-cards-module__picture--fixed-aspect-ratio',
          }}
        />
      )}
      {model?.title && <h2 className="inner-cards-module__title">{model?.title}</h2>}
      {model?.description && (
        <RichText
          editable={false}
          field={{ value: appendRootToRichTextInternalLinks(model?.description) }}
          tag="div"
          className="inner-cards-module__description rich-text"
        />
      )}
      <div className="inner-cards-module__ctas">
        {(model?.button1?.url || model?.button1?.label) && (
          <CTA
            type={ctaTypes.primaryYellow}
            field={{ value: { href: appendRootToInternalLink(model?.button1?.url), target: model?.button1?.target } }}
            extraClassName="component-details__button"
            target={targetLinkdsk(link1, isDesktop)}
          >
            {model?.button1?.label}
          </CTA>
        )}

        {(model?.button2?.url || model?.button2?.label) && (
          <CTA
            type={ctaTypes.secondaryBlue}
            field={{ value: { href: appendRootToInternalLink(model?.button2?.url), target: model?.button2?.target } }}
            extraClassName="inner-cards-module__ctas--secondary"
            target={targetLinkdsk(link2, isDesktop)}
          >
            {model?.button2?.label}
          </CTA>
        )}
      </div>
    </section>
  );
};

export default InnerCard;
