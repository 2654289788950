import { ComponentRendering } from '@sitecore-jss/sitecore-jss-react';
import { APIPrefix } from '../../../constants/api.constants';
import { TContentHubButton, TIconFromRenderingParams, TStringField } from '../../../types/sitecore.types';
import { appendRootToInternalLink } from '../../../utils/appendRootToInternalLink.utils';
import { appendRootToRichTextInternalLinks } from '../../../utils/appendRootToRichTextInternalLinks.utils';
import {
  TContentHubModel,
  TContentHubModelDetailSpecificationsTableFields,
  TContentHubModelDetailSpecificationsTableProps,
  TExportData,
  TMRPRow,
  TSpecGroup,
  TTableData,
} from '../types/index.types';

const createSpecsObj = (models?: TContentHubModel[]) => {
  const specs: TTableData = {};
  models?.forEach((model) => {
    model.specs.forEach((spec) => {
      if (spec.group && spec.items) {
        if (!specs[spec.group]) {
          specs[spec.group] = {
            group: '',
            tempItems: {},
            items: [],
          };
        }
        specs[spec.group].group = spec.group;
        if (!specs[spec.group].tempItems) {
          specs[spec.group].tempItems = {};
        }
        spec.items.forEach((item) => {
          if (specs[spec.group].tempItems && !specs[spec.group].tempItems?.[item.id]) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            specs[spec.group].tempItems![item.id] = {
              id: item.id,
              key: item.key,
              specs: [],
            };
          }
          specs[spec.group].tempItems?.[item.id].specs.push(item);
        });
      }
    });
  });
  return specs;
};

export class ContentHubModelDetailSpecificationsTableModel {
  private readonly fields?: TContentHubModelDetailSpecificationsTableFields;
  private readonly rendering?: ComponentRendering;

  constructor(props: TContentHubModelDetailSpecificationsTableProps) {
    this.fields = props?.fields || null;
    this.rendering = props?.rendering;
  }

  get contextItemUrl(): string {
    return this.fields?.itemData?.contextItemUrl || '';
  }

  get thumbnailEE(): string {
    return this.fields?.itemData?.thumbnail || '';
  }

  get componentName(): string {
    return this.rendering?.componentName || '';
  }

  get title(): TStringField {
    return { value: this.fields?.apiData?.title || '' };
  }

  get eyebrow(): TStringField {
    return { value: this.fields?.apiData?.eyebrow || '' };
  }

  get description(): TStringField {
    return { value: this.fields?.apiData?.description || '' };
  }

  get button1(): TContentHubButton | undefined {
    if (typeof this.fields?.apiData?.button1 === 'object' && this.fields?.apiData?.button1?.url) {
      const button1 = { ...this.fields?.apiData?.button1 };
      button1.url = appendRootToInternalLink(this?.fields?.apiData?.button1?.url) || '';
      return button1;
    }

    return this.fields?.apiData?.button1;
  }

  get colHeadings(): Array<string> {
    return this.fields?.apiData?.models?.map((model) => model.title || '') || [];
  }

  get mrpTitle(): TStringField {
    return { value: this.fields?.apiData?.msrpTitle || '' };
  }

  get mrpDescription(): TStringField {
    return { value: this.fields?.apiData?.msrpDescription || '' };
  }

  get showTooltip(): boolean {
    return Boolean(this.fields?.apiData?.msrpTitle || this.fields?.apiData?.msrpDescription);
  }

  get mrpRow(): TMRPRow {
    return {
      label: this.fields?.apiData?.msrpLabel || '',
      showTooltip: this.showTooltip,
      title: this.mrpTitle,
      description: this.mrpDescription,
      prices: this.fields?.apiData?.models?.map((model) => `${model?.optionalLabel || ''} ${model?.msrp || ''}`) || [],
      hideRow: true,
    };
  }

  get categoryRows(): Array<TSpecGroup> {
    const specs: TTableData = createSpecsObj(this.fields?.apiData?.models);
    if (this.fields?.apiData?.models?.length) {
      const newValues: Array<TSpecGroup> = [];
      const firstModelSpecs = this.fields?.apiData?.models[0].specs.map((spec) => {
        const items = spec.items.map((item) => ({ id: item.id }));
        return { group: spec.group, items };
      });

      firstModelSpecs.forEach(({ group, items }) => {
        const insertGroup = specs[group];
        insertGroup.items = [];
        items.forEach(({ id }) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          insertGroup.items.push(insertGroup.tempItems![id]);
        });
        newValues.push(insertGroup);
      });
      return newValues;
    }
    return [];
  }

  get exportData(): TExportData | undefined {
    return this.fields?.exportData;
  }

  get notes(): TStringField {
    return { value: appendRootToRichTextInternalLinks(this.fields?.apiData?.notes) || '' };
  }

  get haveAtLeastOneModelsWithSpecs(): boolean {
    return !!this.fields?.apiData?.models?.find((model: TContentHubModel) => model?.specs?.length > 0);
  }

  get iconsList(): Array<TIconFromRenderingParams> {
    return this.fields?.itemData?.iconsList || [];
  }

  get isUnreleased(): boolean {
    return !!this.fields?.isComponentUnreleased;
  }

  get convertToExcelLink(): string {
    const specificationUrlParameters = [
      this.exportData?.id,
      this.exportData?.language,
      this.exportData?.lineId,
      this.exportData?.serieId,
    ].join('/');

    return `/${APIPrefix}/download/serie-specifications/${specificationUrlParameters}`;
  }
}
