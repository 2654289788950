export const TGridCSSVariables = {
  gridColumns: '--grid-columns',
  gridScrollBy: '--grid-scroll-by',
  gridLeftCellsMoveBy: '--grid-left-cells-move-by',
  gridFixedColHeadsMoveBy: '--grid-fixed-col-heads-move-by',
  gridFirstColumnWidth: '--grid-first-column-width',
  gridColumnWidth: '--grid-column-width',
  gridOutsideSpaceHalved: '--grid-outside-space-halved',
};

export const DesktopRowHeaderCellWidth = 424;
export const MobileRowHeaderCellWidth = 180;
export const DesktopSpecCellWidth = 272;
export const MobileSpecCellWidth = 120;
export const TableMaxWidth = 1680;
