import { isEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { BreakpointsInPx } from '../../constants/breakpoints.constants';
import {
  descriptionKeyLineSpecialOffers,
  descriptionKeySeriesSpecialOffers,
  eyebrowKeyLineSpecialOffers,
  eyebrowKeySeriesSpecialOffers,
  titleKeyLineSpecialOffers,
  titleKeySeriesSpecialOffers,
} from '../../constants/dictionary.constants';
import { CarouselDirectionEnum } from '../../enums/carousel.enums';
import useExperienceEditorPlaceholder from '../../hooks/useExperienceEditorPlaceholder';
import useMediaQuery from '../../hooks/useMediaQuery';
import { isEmptyResponse } from '../../utils/checkEmptyResponse';
import { documentService, windowService } from '../../utils/window.utils';
import CarouselArrow from '../@shared/carouselArrow';
import CustomCarouselDots from '../@shared/customCarouselDots';
import EmptyApiDataMessage from '../@shared/emptyApiDataMessage';
import ExperienceEditorComponentPlaceholder from '../@shared/experienceEditorComponentPlaceholder';
import Heading from '../@shared/heading';
import SpecialOffersCard from './components/specialOfferCard';
import { ProductSpecialOffersModel, ProductSpecialOffersSlideModel } from './models/productSpecialOffers.model';
import { TProductSpecialOffersProps } from './types/productSpecialOffers.types';
import { specialOffers } from '../../constants/productspecialoffers.constants';
import useSitecoreContext from '../../hooks/useSitecoreContext';
import './styles.scss';

const CarouselDotComponent = ({ items, activeCard }) => {
  return <CustomCarouselDots cardsCount={items.length} activeCard={activeCard} />;
};

const ProductSpecialOffers = (props: TProductSpecialOffersProps) => {
  const isEEActive = props?.sitecoreContext?.pageEditing;
  const {sitecoreContext} = useSitecoreContext();
  const specialOfferMainPageLink: string = sitecoreContext?.specialOfferPageUrl || '';
  const lineFromPageContext: string = sitecoreContext?.analytics?.productLine || '';
  const lineFromParams = props.params?.lineId || '';
  const lineId = props.params?.enableProductParameters === '1' ? lineFromParams : lineFromPageContext;
  const { t } = useTranslation();

  const model = new ProductSpecialOffersModel(props, t, specialOfferMainPageLink);
  const apiResponse = isEmptyResponse(props) && props?.fields?.apiData?.items?.length > 0;
  const isPlaceholderShown = useExperienceEditorPlaceholder(props);
  const isDesktop = useMediaQuery(`(min-width: ${BreakpointsInPx['tbl-p']})`);
  const [screenWidth, setScreenWidth] = useState(1680);
  const [activeCard, updateActiveCardNumber] = useState(1);

  useEffect(() => {
    setScreenWidth(Math.min(windowService()?.innerWidth, 1680));
    if (!isDesktop) {
      if (!isDesktop) {
        const width = 343 / (2 * model?.items?.length);
        documentService().querySelector('body')?.style.setProperty('--slick-width', `${width}px`);
        documentService()
          .querySelector('body')
          ?.style.setProperty('--slick-width-active', `${width + 10}px`);
      }
    }
  }, [model]); // eslint-disable-line

  // star  node opened directly in EE
  if (isPlaceholderShown) {
    return (
      <ExperienceEditorComponentPlaceholder image={props?.fields?.itemData?.thumbnail} componentName={props?.rendering?.componentName} />
    );
  }

  // not opened from star node
  // data missing when navigating via from products node
  if ((!apiResponse || model.items.length === 0) && isEditorActive()) {
    return <EmptyApiDataMessage componentName={props?.rendering?.componentName} />;
  }

  // No data and not in EE
  if (!apiResponse && !isPlaceholderShown && !isEditorActive()) {
    return null;
  }

  const containerPaddingLeft = 32;
  const largeImageWidth = isDesktop ? 664 : 300;
  const smallImageWidth = isDesktop ? 392 : 300;

  const totalElementsWidth =
    model.items.reduce((sumWidths: number, curr: ProductSpecialOffersSlideModel) => {
      if (curr.cardStyles.value === 'Wide') {
        sumWidths += largeImageWidth;
      } else {
        sumWidths += smallImageWidth;
      }
      return sumWidths;
    }, 0) + containerPaddingLeft;

  const shouldInstanceCarousel = totalElementsWidth > screenWidth;

  const getDictionaryKey = (lineKey, seriesKey) => {
    if (props?.isProductSeries) {
      return seriesKey;
    }
    return lineKey;
  };

  const settings = {
    infinite: shouldInstanceCarousel,
    dots: shouldInstanceCarousel,
    arrows: !isEEActive && shouldInstanceCarousel,
    variableWidth: true,
    speed: 500,
    slidesToScroll: 1,
    nextArrow: <CarouselArrow direction={CarouselDirectionEnum.NEXT} cssClass={'special-offers__arrow'} />,
    prevArrow: <CarouselArrow direction={CarouselDirectionEnum.PREVIOUS} cssClass={'special-offers__arrow'} />,
    dotsClass: 'special-offers__dots',
    beforeChange: (_, newIndex: number) => {
      updateActiveCardNumber(newIndex + 1);
    },
    appendDots: () => CarouselDotComponent({ items: model.items || [], activeCard }),
  };

  return (
    <>
      {model.items?.length > 0 ? (
        <div className="special-offers-wrapper" id={specialOffers}>
          <div className="special-offers">
            <Heading
              eyebrow={model.eyebrow}
              title={model.title}
              subtitle={model.description}
              link={model.getLink(lineId)}
              useDictionaryFallback
              titleDictionaryKey={getDictionaryKey(titleKeyLineSpecialOffers, titleKeySeriesSpecialOffers)}
              eyebrowDictionaryKey={getDictionaryKey(eyebrowKeyLineSpecialOffers, eyebrowKeySeriesSpecialOffers)}
              descriptionDictionaryKey={getDictionaryKey(descriptionKeyLineSpecialOffers, descriptionKeySeriesSpecialOffers)}
            />
            <Slider {...settings}>
              {model.items?.map((item: ProductSpecialOffersSlideModel) => (
                <SpecialOffersCard key={item.title?.value} isEEActive={!!isEEActive} item={item} />
              ))}
            </Slider>
            <div className="special-offers__footer"></div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default withSitecoreContext()(ProductSpecialOffers);
