import React, { useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import { TGridCSSVariables } from '../../../../constants/table.constants';
import { FixedColumnRefType, TMainTable } from '../../types/index.types';
import ColumnHeadingElement from './columnHeading/columnHeadings';
import FixedColHeading from './columnHeading/fixedColHeading';
import RowItems from './row';

const MainTable = (props: TMainTable, ref) => {
  const GridRef = useRef(null);
  const TableWrapperRef = useRef<HTMLDivElement | null>(null);
  const fixedColumnRef = useRef<FixedColumnRefType>(null);

  useImperativeHandle(ref, () => ({
    get GridRef() {
      return GridRef;
    },
    get TableWrapperRef() {
      return TableWrapperRef;
    },
  }));

  const {
    fixedColumnHeading,
    searchTerm,
    mrpRow,
    categoryRows,
    gridColumns,
    gridColumnWidth,
    gridOutsideSpaceHalved,
    gridLeftCellsMoveBy,
    colHeadings,
    setDropdownAtColumnIndex,
    setColOrdInIndex,
    dropdownAtColumnIndex,
    colOrdInIndex,
    expandedRows,
    toggleRow,
    productLine,
    productSeries,
    showHiddenRightCells,
    setShowHiddenRightCells,
  } = props;

  useEffect(() => {
    if (GridRef.current && fixedColumnHeading) {
      const tableWrapperEl = TableWrapperRef.current as HTMLElement;
      const tableEl = tableWrapperEl.getElementsByTagName('table')?.[0];
      const fixedColHeadingEl = fixedColumnRef?.current?.FixedColHeadingRef?.current as HTMLDivElement | null;
      if (!tableEl || !fixedColHeadingEl) return;

      const leftValue = tableEl.getBoundingClientRect().left;
      if (!leftValue) return;

      fixedColHeadingEl.style.setProperty(TGridCSSVariables.gridFixedColHeadsMoveBy, `${leftValue || 0}px`);
    }
  }, [fixedColumnHeading, TableWrapperRef]);

  const handleScroll = (event) => {
    const leftValue = event?.target?.scrollLeft;

    const fixedColHeadingEl = fixedColumnRef?.current?.FixedColHeadingRef?.current as HTMLDivElement | null;
    if (fixedColHeadingEl && leftValue) {
      fixedColHeadingEl.style.setProperty(TGridCSSVariables.gridFixedColHeadsMoveBy, `-${leftValue}px`);
    }
    if (!showHiddenRightCells) setShowHiddenRightCells(true);
  };

  return (
    <div className="model-detail-specification-table" ref={TableWrapperRef} onScroll={handleScroll}>
      <table className="model-detail-specification-table__table-tag" cellPadding="0" cellSpacing="0" ref={GridRef}>
        <FixedColHeading
          ref={fixedColumnRef}
          showFixedColumnHeading={
            !!(fixedColumnHeading && (searchTerm ? !!mrpRow.hideRow || (categoryRows && categoryRows.length > 0) : true))
          }
          gridColumns={gridColumns}
          gridFixedColHeadsMoveBy={gridLeftCellsMoveBy}
          gridColumnWidth={gridColumnWidth}
          gridOutsideSpaceHalved={gridOutsideSpaceHalved}
          gridLeftCellsMoveBy={gridLeftCellsMoveBy}
        >
          <ColumnHeadingElement
            colHeadings={colHeadings}
            setDropdownAtColumnIndex={setDropdownAtColumnIndex}
            setColOrdInIndex={setColOrdInIndex}
            dropdownAtColumnIndex={dropdownAtColumnIndex}
            colOrdInIndex={colOrdInIndex}
            fixedColumn={true}
            showHiddenRightCells={showHiddenRightCells}
          />
        </FixedColHeading>
        <tbody>
          <tr>
            <th className="model-detail-specification-table__left-most-cell">
              <div className="model-detail-specification-table__left-most-empty-cell" />
            </th>
            {(searchTerm ? !mrpRow.hideRow || (categoryRows && categoryRows.length > 0) : true) && (
              <ColumnHeadingElement
                colHeadings={colHeadings}
                setDropdownAtColumnIndex={setDropdownAtColumnIndex}
                setColOrdInIndex={setColOrdInIndex}
                dropdownAtColumnIndex={dropdownAtColumnIndex}
                colOrdInIndex={colOrdInIndex}
                fixedColumn={false}
                showHiddenRightCells={showHiddenRightCells}
              />
            )}
          </tr>
          {categoryRows?.map((obj, index) => (
            <RowItems
              key={obj.group}
              obj={obj}
              colHeadings={colHeadings}
              expandedRows={expandedRows}
              index={index}
              toggleRow={toggleRow}
              searchTerm={searchTerm}
              colOrdInIndex={colOrdInIndex}
              productLine={productLine}
              productSeries={productSeries}
              showHiddenRightCells={showHiddenRightCells}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default React.memo(forwardRef(MainTable));
