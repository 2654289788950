import React, { FC } from 'react';
import { iconSize } from '../../../../../constants/icon.constants';
import Icon from '../../../../@shared/icon';
import { TRowItemProps } from '../../../types/index.types';
import { specsExpandEvent } from '../../../../../constants/analytics.constants';
import { googleButtonClickEvent } from '../../../../../utils/analytics';
import formatText from './formatText';
import SubValueRow from './subValueRow';

const RowItems: FC<TRowItemProps> = (props) => {
  const {
    obj,
    colHeadings,
    expandedRows,
    index,
    toggleRow,
    searchTerm,
    colOrdInIndex,
    productLine,
    productSeries,
    showHiddenRightCells,
  } = props;

  return (
    <>
      <tr
        key={`model-detail-specification-table__spec-${obj.group}`}
        className="model-detail-specification-table__spec-category-row"
        onClick={() => {
          toggleRow(index);
          googleButtonClickEvent(specsExpandEvent, obj.group, productLine, productSeries);
        }}
      >
        <td
          className="model-detail-specification-table__left-most-cell model-detail-specification-table__spec-category-row-cell"
          colSpan={colHeadings.length + 1}
        >
          <h3
            className={`
              model-detail-specification-table__spec-category-row__inner-box
              ${!expandedRows.includes(index) ? 'model-detail-specification-table__spec-category-row__inner-box--border-visible' : ''}
            `}
          >
            <Icon
              iconName={expandedRows.includes(index) ? 'action-minus' : 'action-plus'}
              size={iconSize.mbl}
              extraClassName={expandedRows.includes(index) ? 'table-minus-icon' : 'table-plus-icon'}
            />
            {formatText(obj.group, searchTerm)}
          </h3>
        </td>
      </tr>

      {obj.items &&
        obj.items.map((subObj, accIndex, subArr) => (
          <SubValueRow
            key={subObj.id}
            subObj={subObj}
            subArr={subArr}
            accIndex={accIndex}
            colOrdInIndex={colOrdInIndex}
            hide={!expandedRows.includes(index)}
            showHiddenRightCells={showHiddenRightCells}
            searchTerm={searchTerm}
          />
        ))}
    </>
  );
};

export default RowItems;
