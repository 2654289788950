import React from 'react';
import { seperateSplitsAndMatched } from '../../../../../utils/regex.utils';

const highlightText = (splits: Array<string>, matched: Array<string>): JSX.Element[] => {
  return splits.map((split, idx) => (
    <>
      <span className="model-detail-specification-table__text">{split}</span>
      <span className="model-detail-specification-table__text model-detail-specification-table__text--highlight">{matched[idx]}</span>
    </>
  ));
};

const formatText = (str: string, searchTerm: string, addWrapperDiv = false): JSX.Element => {
  if (searchTerm) {
    const { splits, matched } = seperateSplitsAndMatched(str, searchTerm);
    const result = splits.length === matched.length + 1 ? highlightText(splits, matched) : str;
    if (addWrapperDiv) return <div>{result}</div>;
    else return <>{result}</>;
  }
  return <>{str}</>;
};

export default formatText;
