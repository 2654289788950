import React, { useState, useEffect, FC } from 'react';

import { TModalComponentsListProps } from '../types/innerComponentsPopup.types';
import InnerCard from './innerCard';
import './styles.scss';

const InnerCards: FC<TModalComponentsListProps> = (props) => {
  const [list, setList] = useState(props?.components?.length ? [...props.components] : []);
  const classNameCards = list.length && list.length < 4 ? `inner-cards-modules-${list.length}-card` : '';

  useEffect(() => {
    if (props?.components?.length) {
      const newList = [...props.components];
      setList(newList);
    } else {
      setList([]);
    }
  }, [props.components]); // eslint-disable-line

  return (
    <div className={`inner-cards-modules ${classNameCards}`}>
      {list?.map((component) => (
        <InnerCard component={component} key={`${component?.id}`} cardsCount={list?.length} />
      ))}
    </div>
  );
};

export default InnerCards;
