import { TSitecoreContext } from '../../../types/sitecore.types';
import { DealerLocatorFields, DealerLocatorProps } from '../types/dealerLocator.types'

export class DealerLocatorModel {

  private readonly fields?: DealerLocatorFields;
  private readonly context?: TSitecoreContext;

  constructor(props:  DealerLocatorProps) {
    this.fields = props.fields || undefined;
    this.context = props?.sitecoreContext;
  }

  get pageId(): string | undefined {
    return this.fields?.pageId;
  }

  get dealerLocatorGoogleMapsAPIKey(): string {
    return (this.context?.settings as any)?.dealerLocatorGoogleMapsAPIKey || '';
  }

  get siteLanguage(): string {
    return this.context?.language || '';
  }

  get brand(): string {
    return this.context?.brand as string || '';
  }

  get initialLatitude(): number {
    return this.fields?.settings?.initialLatitude || 0.0;
  }

  get initialLongitude(): number {
    return this.fields?.settings?.initialLongitude || 0.0;
  }

  get initialZoom(): number {
    return this.fields?.settings?.initialZoom || 10;
  }

  get isUnreleased(): boolean {
    return !!this.fields?.isComponentUnreleased;
  }
}
