import React, { useCallback, useEffect, useRef, useState, FC } from 'react';
import { TStoriesProps } from './types/blogNews.types';
import { StoriesModel } from './model/blogNews.model';
import './index.scss';
import Heading from '../@shared/heading';
import StoriesCard from '../@shared/storiesCard';
import useMediaQuery from '../../hooks/useMediaQuery';
import { TCarouselSettings } from '../@shared/customCarousel/types/customCarousel.types';
import CustomCarousel from '../@shared/customCarousel';
import { BreakpointsInPx } from '../../constants/breakpoints.constants';
import UnreleasedComponent from '../@shared/unreleasedComponent';

const storiesMapper = (model: StoriesModel): JSX.Element[] => {
  return model.cards.map((item) => (
    <div key={item?.id} className="stories__card-container">
      <StoriesCard {...item} />
    </div>
  ));
};

const BlogNews: FC<TStoriesProps> = (props: TStoriesProps) => {
  const isDesktop = useMediaQuery(`(min-width: ${BreakpointsInPx['tbl-p']})`, true);
  const model = new StoriesModel(props);
  const cardWidthRef = useRef<HTMLDivElement | null>(null);

  const findSlidesToShow = useCallback((): number => {
    const _DESKTOP_CARD_WIDTH = 200;
    const _DESKTOP_CARD_MARGIN = 32;
    const _MOBILE_CARD_WIDTH = 163;
    const _MOBILE_CARD_MARGIN = 8;
    const _cardContainerWidth = cardWidthRef?.current?.clientWidth || 1680;
    const _cardWidth = isDesktop ? _DESKTOP_CARD_WIDTH : _MOBILE_CARD_WIDTH;
    const _cardMargin = isDesktop ? _DESKTOP_CARD_MARGIN : _MOBILE_CARD_MARGIN;
    const _slidesCanBeShown = Math.floor(_cardContainerWidth / (_cardWidth + _cardMargin));
    return model.cards.length > (_slidesCanBeShown || 4.5) ? _slidesCanBeShown : model.cards.length;
  }, [isDesktop, model.cards.length]);

  const [slidesToShow, setSlidesToShow] = useState(4.5);
  useEffect(() => setSlidesToShow(findSlidesToShow()), [cardWidthRef, findSlidesToShow]);

  const settings: TCarouselSettings = {
    arrows: true,
    infinite: false,
    variableWidth: true,
    slidesToScroll: 1,
    slidesToShow: slidesToShow,
    dots: false,
  };

  // Component is unreleased
  if (model.isUnreleased) {
    return <UnreleasedComponent />;
  }

  return (
    <div className="stories-wrapper">
      <div className="stories" ref={cardWidthRef}>
              <Heading eyebrow={model.eyebrow} title={model.title} subtitle={model.content} link={model.link} editable={true} customTextTitle tag={model.headingTag} />
        <CustomCarousel settings={settings} id="stories">
          {storiesMapper(model)}
        </CustomCarousel>
      </div>
    </div>
  );
};

export default BlogNews;
