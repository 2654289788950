import { TPromoBannerFields, TPromoBannerProps } from '../types/promoBanner.types';
import { TComponentParamsGeneral, THeadingTag, TImageField, TLinkField, TStringField } from '../../../types/sitecore.types';

export class PromoBannerModel {
    private readonly fields?: TPromoBannerFields;
    private readonly params?: TComponentParamsGeneral;

    constructor(props: TPromoBannerProps) {
        this.fields = props?.fields;
        this.params = props?.params;
    }

    get heading(): TStringField {
        return this.fields?.heading as TStringField;
    }

    get image(): TImageField {
        return this.fields?.image as TImageField;
    }

    get bodyText(): TStringField {
        return this.fields?.bodyText as TStringField;
    }

    get link(): TLinkField {
        return this.fields?.link as TLinkField;
    }

    get isUnreleased(): boolean {
        return !!this.fields?.isComponentUnreleased;
    }

    get headingTag(): THeadingTag {
        return this.params?.headingTag?.toLowerCase() as THeadingTag || 'h2'
    }
}
