import React, { FC, memo } from 'react';
import { TModalComponentsListProps } from '../types/innerComponentsPopup.types';
import InnerSplitModule from './innerSplitModule';
import './styles.scss';

const InnerSplitModules: FC<TModalComponentsListProps> = (props) => {
  const model = props.components;

  return (
    <section className="inner-split-modules">
      {model?.map((component) => (
        <InnerSplitModule component={component} key={`${component?.id}`} />
      ))}
    </section>
  );
};

export default memo(InnerSplitModules);
