import React, { useEffect, FC, useState } from 'react';
import Slider from 'react-slick';
import CustomArrow from '../carouselArrow';
import { TCustomCarouselProps, TDOMElements } from './types/customCarousel.types';
import { CarouselDirectionEnum } from '../../../enums/carousel.enums';
import { documentService } from '../../../utils/window.utils';
import './styles.scss';
import AppendDots from './components/appendDots';

const CustomCarousel: FC<TCustomCarouselProps> = ({ settings: customSettings, children, id, fade = false }) => {
  const [elements, setElements] = useState<TDOMElements | null>(null);
  const [activeCard, updateActiveCard] = useState(1);
  const visibleClass = 'visible';
  const slides = Array.isArray(children) ? children : [];
  const cssVisibility = { visible: 'visible', hidden: 'hidden' };
  const { beforeChange: CustomBeforeChange, afterChange: CustomAfterChange, ...rest } = customSettings;
  const defaultSettings = {
    arrows: true,
    dots: true,
    infinite: false,
    slidesToScroll: 1,
    nextArrow: <CustomArrow direction={CarouselDirectionEnum.NEXT} />,
    prevArrow: <CustomArrow direction={CarouselDirectionEnum.PREVIOUS} />,
    appendDots: <AppendDots cardsCount={slides.length} activeCard={activeCard} />,
  };

  const settings = { ...defaultSettings, ...rest };

  useEffect(() => {
    const leftArrow: HTMLButtonElement = documentService()?.querySelector?.(`.custom-carousel#${id} .custom-arrow--left`);
    // hiding left arrow on load
    if (leftArrow?.style) {
      leftArrow.style.visibility = 'hidden';
    }
  }, []);

  useEffect(() => {
    const _elements: TDOMElements = {
      carouselSelector: documentService()?.querySelector?.(`.custom-carousel#${id}`),
      rightArrow: documentService()?.querySelector?.(`.custom-carousel#${id} .custom-arrow--right`),
      leftArrow: documentService()?.querySelector?.(`.custom-carousel#${id} .custom-arrow--left`),
      rightFade: documentService()?.querySelector?.(`.custom-carousel#${id} .custom-arrow--right .fade`),
      leftFade: documentService()?.querySelector?.(`.custom-carousel#${id} .custom-arrow--left .fade`),
    };
    setElements(_elements);
    if (fade) _elements?.rightFade?.classList.add(visibleClass);
  }, [fade, id, activeCard]);

  const toggleFade = (currentSlide): void => {
    if (currentSlide.toFixed() > 0) {
      elements?.leftFade?.classList.add(visibleClass);
    } else elements?.leftFade?.classList.remove(visibleClass);

    if (currentSlide.toFixed() < slides.length - (settings?.slidesToShow || 1)) {
      elements?.rightFade?.classList.add(visibleClass);
    } else {
      elements?.rightFade?.classList.remove(visibleClass);
    }
  };

  const toggleArrowsVisibility = (currentSlide): void => {
    if (!settings.infinite) {
      if (elements?.leftArrow)
        (elements.leftArrow as HTMLElement).style.visibility = currentSlide === 0 ? cssVisibility.hidden : cssVisibility.visible;
      if (elements?.rightArrow)
        (elements.rightArrow as HTMLElement).style.visibility =
          currentSlide === slides.length - (settings?.slidesToShow || 1) ? cssVisibility.hidden : cssVisibility.visible;
    }
  };

  const afterChange = (currentSlide): void => {
    updateActiveCard(currentSlide + 1);
    if (fade) toggleFade(currentSlide);
    toggleArrowsVisibility(currentSlide);
    if (typeof CustomAfterChange === 'function') {
      CustomAfterChange(currentSlide);
    }
  };

  const beforeChange = (currentSlide): void => {
    if (typeof CustomBeforeChange === 'function') {
      CustomBeforeChange(currentSlide);
    }
  };

  return (
    <div className="custom-carousel" id={id}>
      <Slider {...settings} beforeChange={beforeChange} afterChange={afterChange}>
        {slides}
      </Slider>
    </div>
  );
};

export default CustomCarousel;
