import React, { useEffect, useRef, useState, FC } from 'react';
import Helmet from 'react-helmet';

import { BreakpointsInPx } from '../../../../../constants/breakpoints.constants';
import { handleThumbnailImage } from '../../../../../utils/youtubeUtils';
import { TContentHubImageProps } from '../../types/contentHubImage.types';

const ContentHubImage: FC<TContentHubImageProps> = ({
  desktopRendition,
  desktopSDRendition,
  tabletLandscapeRendition,
  tabletRendition,
  mobileRendition,
  alt,
  imageClassName,
  pictureClassName,
  imageStyle,
  pictureStyle,
  disableLazyLoad,
  prioritize,
  onClick,
  onTouchStart,
  onTouchEnd,
  onLoad,
  isVideoThumbnail,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const imageRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    if (imageLoaded || !imageRef.current || !isVideoThumbnail) return;
    handleThumbnailImage(null, isVideoThumbnail, imageRef.current);
    setImageLoaded(() => true);
  }, [imageLoaded, imageRef, isVideoThumbnail]);

  const getDefaultRenditionsAndBreakpoint = () => {
    let defaultRenditions = '';
    let defaultBreakpoint = '';

    if (desktopRendition?.url) {
      defaultRenditions = desktopRendition.url;
      defaultBreakpoint = BreakpointsInPx['dsk-hd'];
    } else if (desktopSDRendition?.url) {
      defaultRenditions = desktopSDRendition.url;
      defaultBreakpoint = BreakpointsInPx.dsk;
    } else if (tabletRendition?.url) {
      defaultRenditions = tabletRendition.url;
      defaultBreakpoint = BreakpointsInPx['tbl-l'];
    } else if (tabletLandscapeRendition?.url) {
      defaultRenditions = tabletLandscapeRendition.url;
      defaultBreakpoint = BreakpointsInPx['tbl-p'];
    } else if (mobileRendition?.url) {
      defaultRenditions = mobileRendition.url;
      defaultBreakpoint = BreakpointsInPx.mbl;
    }
    return [defaultRenditions, defaultBreakpoint];
  };

  const handleOnLoad = (event) => {
    onLoad?.(event);
    handleThumbnailImage(event, isVideoThumbnail);
    setImageLoaded(() => true);
  };

  const [defaultRenditions, defaultBreakpoint] = getDefaultRenditionsAndBreakpoint();

  if (!defaultRenditions) {
    return null;
  }

  return (
    <>
      {prioritize && (
        <Helmet>
          {mobileRendition?.url && (
            <link
              rel="preload"
              href={mobileRendition?.url}
              as="image"
              media={defaultBreakpoint !== BreakpointsInPx.mbl ? `(max-width: ${BreakpointsInPx.mbl})` : '(max-width: none)'}
            />
          )}
          {tabletRendition?.url && (
            <link
              rel="preload"
              href={tabletRendition?.url}
              as="image"
              media={defaultBreakpoint !== BreakpointsInPx['tbl-p'] ? `(max-width: ${BreakpointsInPx['tbl-p']})` : '(max-width: none)'}
            />
          )}
          {tabletLandscapeRendition?.url && (
            <link
              rel="preload"
              href={tabletLandscapeRendition?.url}
              as="image"
              media={defaultBreakpoint !== BreakpointsInPx['tbl-l'] ? `(max-width: ${BreakpointsInPx['tbl-l']})` : '(max-width: none)'}
            />
          )}
          {desktopSDRendition?.url && (
            <link
              rel="preload"
              href={desktopSDRendition?.url}
              as="image"
              media={defaultBreakpoint !== BreakpointsInPx.dsk ? `(max-width: ${BreakpointsInPx.dsk})` : '(max-width: none)'}
            />
          )}
          {desktopRendition?.url && <link rel="preload" href={desktopRendition?.url} as="image" media="(max-width: none)" />}
        </Helmet>
      )}
      <picture className={pictureClassName} onClick={onClick} onTouchStart={onTouchStart} onTouchEnd={onTouchEnd} style={pictureStyle}>
        {mobileRendition?.url && (
          <source
            srcSet={mobileRendition.url}
            media={defaultBreakpoint !== BreakpointsInPx.mbl ? `(max-width: ${BreakpointsInPx.mbl})` : '(max-width: none)'}
            width={mobileRendition?.width}
            height={mobileRendition?.height}
          />
        )}
        {tabletRendition?.url && (
          <source
            srcSet={tabletRendition.url}
            media={defaultBreakpoint !== BreakpointsInPx['tbl-p'] ? `(max-width: ${BreakpointsInPx['tbl-p']})` : '(max-width: none)'}
            width={tabletRendition?.width}
            height={tabletRendition?.height}
          />
        )}
        {tabletLandscapeRendition?.url && (
          <source
            srcSet={tabletLandscapeRendition.url}
            media={defaultBreakpoint !== BreakpointsInPx['tbl-l'] ? `(max-width: ${BreakpointsInPx['tbl-l']})` : '(max-width: none)'}
            width={tabletLandscapeRendition?.width}
            height={tabletLandscapeRendition?.height}
          />
        )}
        {desktopSDRendition?.url && (
          <source
            srcSet={desktopSDRendition.url}
            media={defaultBreakpoint !== BreakpointsInPx.dsk ? `(max-width: ${BreakpointsInPx.dsk})` : '(max-width: none)'}
            width={desktopSDRendition?.width}
            height={desktopSDRendition?.height}
          />
        )}
        {desktopRendition?.url && (
          <source
            srcSet={desktopRendition.url}
            media="(max-width: none)"
            width={desktopRendition?.width}
            height={desktopRendition?.height}
          />
        )}
        <img
          loading={prioritize || disableLazyLoad ? 'eager' : 'lazy'}
          src={defaultRenditions}
          alt={alt || ''}
          className={imageClassName}
          style={imageStyle}
          onLoad={handleOnLoad}
          ref={imageRef}
        />
      </picture>
    </>
  );
};

export default ContentHubImage;
