import React, { FC } from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import yellowImg from '../../../assets/4-columnLayout-dots.png';
import { appendToUrlPath } from '../../../utils/link.utils';
import { TRendition } from '../../../types/sitecore.types';
import Picture from '../../@shared/picture';
import RoutableNotEditableLink from '../../@shared/routableNotEditableLink';
import { TCardProps } from '../types/cards.types';

import './styles.scss';
import { appendRootToRichTextInternalLinks } from '../../../utils/appendRootToRichTextInternalLinks.utils';

const DashboardCard: FC<TCardProps> = (props: TCardProps) => {
  const asset = props?.fields?.assets && props?.fields.assets[0];

  return (
    <RoutableNotEditableLink href={appendToUrlPath(props?.baseUrl || '', props?.fields?.titleUrl || '')} className="dashboard-card">
      <div className="dashboard-card__media">
        {(asset?.rendition_mobile_size500 || asset?.rendition_desktop_size500) && (
          <img alt={props?.fields?.title} src={yellowImg} className="dashboard-card__bgimage mobile" loading="lazy" />
        )}
        <div className="dashboard-card__image-wrapper">
          <Picture
            productImageProps={{
              mobileRendition: asset?.rendition_mobile_size500 as TRendition,
              desktopRendition: asset?.rendition_desktop_size500 as TRendition,
              alt: asset?.alt,
              pictureClassName: 'dashboard-card__image-wrapper__image',
            }}
          />
        </div>
      </div>
      <div className="dashboard-card__content">
        <h3 className="dashboard-card__title">{props?.fields?.title}</h3>
        <RichText
          field={{ value: appendRootToRichTextInternalLinks(props?.fields?.description) }}
          className="dashboard-card__description rich-text"
          tag="div"
          editable={false}
        />
      </div>
    </RoutableNotEditableLink>
  );
};

export default DashboardCard;
