import React, { useState } from 'react';
import { DateField, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import RoutableSitecoreLink from '../../../@shared/routableSitecoreLink';
import { TSpecialOffersCardProps } from '../../types/productSpecialOffers.types';
import ctaTypes from '../../../../enums/CTA.enums';
import CTA from '../../../@shared/CTA';
import Icon from '../../../@shared/icon';
import { iconSize } from '../../../../constants/icon.constants';
import ModalDialog from '../../../@shared/modalDialog';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { targetLinkdsk } from '../../../../utils/targetDskLink';
import { BreakpointsInPx } from '../../../../constants/breakpoints.constants';
import { WithTranslation, withTranslation } from 'react-i18next';
import Picture from '../../../@shared/picture';
import useFormatDate from '../../../../hooks/useFormatDate';
import './styles.scss';

const SpecialOffersCard = ({ isEEActive, item, t }: WithTranslation & TSpecialOffersCardProps) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const isDesktop = useMediaQuery(`(min-width: ${BreakpointsInPx['tbl-p']})`);
  const { buttonPrimary, buttonSecondary } = item || {};
  const formattedDate = useFormatDate(item?.expiration?.value);

  return (
    <>
      {item.disclaimer && (
        <ModalDialog isOpen={modalIsOpen} close={closeModal} className="special-offers-disclaimer-modal">
          {item.disclaimer && <RichText tag="div" field={{ value: item.disclaimer }} className="rich-text" />}
        </ModalDialog>
      )}
      <div
        key={item.title.value}
        className={`special-offers__card special-offers-card special-offers-card--${item.cardStyles.value === 'Wide' ? 'large' : 'small'}`}
      >
        {item.badge ? (
          <div className="special-offers-card__badge">
            <Text field={{ value: item.badge }} tag="p" />
          </div>
        ) : null}
        <Picture
          productImageProps={{
            mobileRendition: item.mobileImage,
            desktopRendition: item.image,
            imageClassName: 'special-offers-card__image',
          }}
        />
        <div className={'special-offers-card__body'}>
          {item.title && <RichText tag="span" field={item.title} className="special-offers-card__title rich-text" />}
          {item.disclaimer && (
            <RichText
              onClick={openModal}
              tag="div"
              field={{ value: t('cnhi-disclaimer') }}
              className="special-offers-card__disclaimer rich-text"
            />
          )}
          <div className="special-offers-card__date-off">
            <RichText tag="span" field={item.expirationLabel} className="rich-text" />
            :&nbsp;
            <DateField field={item.expiration} tag="span" render={() => formattedDate} />
          </div>
          <div
            className={`special-offers-card__cta-container special-offers-card__cta-container--${
              item.cardStyles.value === 'Wide' ? 'row' : ''
            }`}
          >
            {(isEEActive || (buttonPrimary?.value.href && buttonPrimary?.value.title)) && (
              <CTA
                type={ctaTypes.primaryYellow}
                field={buttonPrimary}
                target={targetLinkdsk(item?.buttonPrimary, isDesktop)}
                extraClassName="special-offers-card__cta-primary"
              >
                {buttonPrimary.value.title}
              </CTA>
            )}
            {(isEEActive || (buttonSecondary?.value.href && buttonSecondary?.value.title)) && item.cardStyles.value === 'Wide' && (
              <button
                className={`special-offers-card__cta-secondary ${
                  (isEEActive || buttonPrimary?.value.href || buttonPrimary?.value.title) && 'special-offers-card__cta-secondary--margin'
                } ${
                  (isEEActive || buttonPrimary?.value.href || buttonPrimary?.value.title) &&
                  item.cardStyles.value === 'Wide' &&
                  'special-offers-card__cta-secondary--margin-row'
                }`}
              >
                <RoutableSitecoreLink field={buttonSecondary} target={targetLinkdsk(buttonSecondary, isDesktop)}>
                  {buttonSecondary.value.title}
                  <Icon iconName="arrow-chevron-right" size={iconSize.mbl} extraClassName="custom-arrow__icon" />
                </RoutableSitecoreLink>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(SpecialOffersCard);
