import './index.scss';

import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { BreakpointsInPx } from '../../../constants/breakpoints.constants';
import CarouselNavDeckCard from './components/carouselNavDeck';
import HeroCard from './components/carouselCard';
import OverlayCarouselArrow from './components/carouselArrow';
import { TOverlayCarouselPopup } from './types/overlayCarouselPopup.types';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { windowService } from '../../../utils/window.utils';
import CarouselArrowAndNavSection from './components/carouselArrowAndNavSection';
import TextFieldGuard from './components/textFieldGuard';

const OverlayCarouselPopup: FC<TOverlayCarouselPopup> = ({
  slides,
  showNavDeck = false,
  showOverlayTitleAndDescription = false,
  isOverlay = true,
  isEE = false,
  customTextTitle = false,
  customTextDescription = false,
}) => {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const SlideSectionRef = useRef(null);
  const isWide = useMediaQuery(`(min-width: ${BreakpointsInPx['tbl-p']})`);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [videoPaused, setVideoPaused] = useState(false);

  useEffect(() => {
    function resizeHandler(): void {
      if (SlideSectionRef && SlideSectionRef.current) {
        const slideSectionEle = SlideSectionRef.current as HTMLElement | null;
        if (!slideSectionEle) return;
        const slideSectionDim = slideSectionEle.getBoundingClientRect();
        const deckCardWidth = isWide ? 176 : 92;
        slideSectionEle.style.justifyContent = slideSectionDim.width - slides.length * deckCardWidth > 0 ? 'center' : 'start';
      }
    }

    resizeHandler();
    windowService().addEventListener('resize', resizeHandler);
    return (): void => windowService().removeEventListener('resize', resizeHandler);
  }, [SlideSectionRef, slides]); // eslint-disable-line

  useEffect(() => {
    const slideSectionEle = SlideSectionRef?.current;
    if (!slideSectionEle) return;
    if (isWide) {
      const slideSectionDim = (slideSectionEle as HTMLElement).getBoundingClientRect();
      if (slideSectionDim.width - slides.length * 176 > 0) {
        return;
      }
      const maxNoofCards = Math.floor(slideSectionDim.width / 176);
      let moveBy = (currentCardIndex + 1 - maxNoofCards) * 176;

      if (currentCardIndex + 1 === slides.length) {
        const totalWidth = 176 * slides.length - 8;
        moveBy = totalWidth - slideSectionDim.width;
      }

      (slideSectionEle as HTMLElement).style.setProperty('--expanded-overlay-slide-move-by', `-${moveBy > 0 ? moveBy : 0}px`);
    }
    if (isOverlay) {
      // for bringing the navigation thumbnail into view on mobile layout
      if (!isWide) {
        const currentSelectedEle = (slideSectionEle as HTMLElement).querySelector(
          `#overlay-carousel-popup__slide-image-wrapper-${currentCardIndex}`
        );
        if (!currentSelectedEle) return;
        const eleDim = currentSelectedEle.getBoundingClientRect();
        currentSelectedEle?.parentElement?.scroll({
          left: eleDim.left - 16, // 16 is padding left of parent element
          behavior: 'smooth',
        });
      }
    }
  }, [currentCardIndex, SlideSectionRef]); // eslint-disable-line

  const playCallback = useCallback(() => {
    setVideoPlaying(true);
    setVideoPaused(false);
  }, [setVideoPlaying, setVideoPaused]);

  const pauseCallback = useCallback(() => {
    setVideoPlaying(false);
    setVideoPaused(true);
  }, [setVideoPlaying, setVideoPaused]);

  const endCallback = useCallback(() => {
    setVideoPlaying(false);
    setVideoPaused(false);
  }, [setVideoPlaying, setVideoPaused]);

  const moveLeft = (): void => {
    endCallback();
    const newIndex = currentCardIndex > 0 ? currentCardIndex - 1 : currentCardIndex;
    setCurrentCardIndex(newIndex);
  };

  const moveRight = (): void => {
    endCallback();
    const newIndex = currentCardIndex + 1 < slides.length ? currentCardIndex + 1 : currentCardIndex;
    setCurrentCardIndex(newIndex);
  };

  const goToCard = (index: number): void => {
    endCallback();
    setCurrentCardIndex(index);
  };

  return (
    <div className="overlay-carousel-popup__white-box overlay-carousel-popup__carousel-box">
      {isWide && <div className="overlay-carousel-popup__heading-wrapper" />}
      <div className={isWide ? 'overlay-carousel-popup__main-section' : ''}>
        <div
          className={`
                overlay-carousel-popup__carousel-btn-wrapper
                ${!isWide || currentCardIndex === 0 ? 'overlay-carousel-popup__carousel-btn-wrapper--hide' : ''}
            `}
        >
          {isWide && slides?.length > 1 && <OverlayCarouselArrow direction="previous" onClick={moveLeft} />}
        </div>
        <div className="overlay-carousel-popup__focus-image-wrapper">
          {slides?.map((slide, index, arr) => {
            return (
              <div
                key={`${slide?.id}`}
                className={`
                  overlay-carousel-popup__focus-image
                  ${index < currentCardIndex ? 'overlay-carousel-popup__focus-image--move-left' : ''}
                `}
                style={{ zIndex: arr.length - index }}
              >
                <HeroCard
                  {...slide}
                  activeCard={index === currentCardIndex}
                  key={slide?.id}
                  index={index}
                  showOverlayTitleAndDescription={showOverlayTitleAndDescription}
                  moveLeft={moveLeft}
                  moveRight={moveRight}
                  playCallback={playCallback}
                  pauseCallback={pauseCallback}
                  endCallback={endCallback}
                  customTextTitle={customTextTitle}
                  customTextDescription={customTextDescription}
                />
              </div>
            );
          })}
          <CarouselArrowAndNavSection
            slides={slides}
            moveLeft={moveLeft}
            videoPlaying={videoPlaying}
            videoPaused={videoPaused}
            currentCardIndex={currentCardIndex}
            isWide={isWide}
            moveRight={moveRight}
          />
        </div>
        <div
          className={`overlay-carousel-popup__carousel-btn-wrapper ${
            !isWide || currentCardIndex + 1 === slides.length ? 'overlay-carousel-popup__carousel-btn-wrapper--hide' : ''
          }`}
        >
          {isWide && slides?.length > 1 && <OverlayCarouselArrow direction="next" onClick={moveRight} />}
        </div>
      </div>
      {(showNavDeck || showOverlayTitleAndDescription) && (
        <div className={'overlay-carousel-popup__slide-section-wrapper ' + (!isWide && 'overlay-carousel-popup__modal-gray-wrapper')}>
          <div className={slides?.length > 1 ? 'overlay-carousel-popup__slide-section' : ''} ref={SlideSectionRef}>
            {showNavDeck &&
              slides?.length > 1 &&
              slides?.map((slide, index) => (
                <CarouselNavDeckCard slide={slide} index={index} currentCardIndex={currentCardIndex} goToCard={goToCard} isEE={isEE} />
              ))}
          </div>
          {showOverlayTitleAndDescription && !isWide && (
            <>
              <div className="overlay-carousel-popup__modal-title">
                <TextFieldGuard field={slides[currentCardIndex]?.title} />
              </div>
              <div className="overlay-carousel-popup__modal-description">
                <TextFieldGuard field={slides[currentCardIndex]?.description} />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default OverlayCarouselPopup;
