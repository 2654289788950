import { FieldTypes } from '@sitecore-jss/sitecore-jss-react-forms';

export const customFieldTypes = {
  firstNameFieldTypeItemId: '{75A14E0D-B1FD-467C-9CD7-9C9509AAAB66}' as FieldTypes,
  lastNameFieldTypeItemId: '{C6F06C22-B216-46FC-961A-87059BCE3A6F}' as FieldTypes,
  companyNameFieldTypeItemId: '{F0D1AB3D-8BFD-4E96-B5E9-44989227AF7F}' as FieldTypes,
  emailFieldTypeItemId: '{818E6205-B366-4D89-929A-2DAEA9EA97D8}' as FieldTypes,
  zipCodeFieldTypeItemId: '{DDD9EF10-0F4C-44B9-ABEF-07A95151EB54}' as FieldTypes,
  addressFieldTypeItemId: '{8ADCCA7E-054B-4E4B-9095-1A341A9279D0}' as FieldTypes,
  cityFieldTypeItemId: '{8B7DBBAF-D6EC-4B4A-B685-45C598658CA7}' as FieldTypes,
  phoneNumberFieldTypeItemId: '{91777568-5C07-44BE-98CF-5A6C66A4CED6}' as FieldTypes,
  mobileNumberFieldTypeItemId: '{4FF02169-0658-4275-B4F7-D8CAFD321F59}' as FieldTypes,
  gdprConsentFieldTypeItemId: '{4C3C280A-EEC9-49B1-A788-8DE5FDB67816}' as FieldTypes,
  productSelectorFieldTypeItemId: '{86A0843F-2D69-435F-A865-75B723538DAB}' as FieldTypes,
  preferredCommunicationFieldTypeItemId: '{586BFC0B-8C64-4DA3-9DAE-E9C10700AD32}' as FieldTypes,
  countryStateDropdownFieldTypeItemId: '{F3E14317-2A3F-4490-B6A4-91D399C24430}' as FieldTypes,
  pageTitleFieldTypeItemId: '{97EF31FF-02DD-47F2-96BC-FD1918303808}' as FieldTypes,
  pageUrlFieldTypeItemId: '{C0200425-2688-4964-B1A6-507D33515859}' as FieldTypes,
  decisionRoleFieldTypeItemId: '{D5308E4D-9BB7-49CA-B0DC-9747DA189693}' as FieldTypes,
  purchaseTimingFieldTypeItemId: '{0146A0BF-2866-42FA-B423-62C5E353C455}' as FieldTypes,
  recaptchaFieldTypeItemId: '{64D1EA12-D725-43B1-AF08-8BCA520975E6}' as FieldTypes,
  purchaseIntentionFieldTypeItemId: '{020CAE16-D056-4218-94A9-6643A4E0A96D}' as FieldTypes,
  contactReasonFieldTypeItemId: '{BEAC4B57-610A-4CB4-975E-36116FF6BF6E}' as FieldTypes,
  notes1FieldTypeItemId: '{AC2CA90A-B694-44F3-A12D-E445DD37E68E}' as FieldTypes,
  codePromotionFieldTypeItemId: '{19C23A4E-D0AB-46B3-9498-16B074BD9FEF}' as FieldTypes,
  initiativeSourceReportFieldTypeItemId: '{4A27B7AC-BCD0-4625-AED6-A7949ED14EBE}' as FieldTypes,
  initiativeSourceReportDetailFieldTypeItemId: '{FC886FBB-FCA9-4485-82F4-DF01D4E76D10}' as FieldTypes,
  productOfInterestFieldTypeItemId: '{8DAEFFE1-C993-4C3E-BAEE-A2DA32675E8E}' as FieldTypes,
  customProductOfInterestFieldTypeItemId: '{703CEF0F-CD95-4B85-994E-670B6AF979D0}' as FieldTypes,
  dealerNotesFieldTypeItemId: '{3F6DE8EA-7948-4E94-B18B-61D63912052B}' as FieldTypes,
  address2FieldTypeItemId: '{47144AC0-7373-430C-ABA9-AF79CF7E1F16}' as FieldTypes,
  jobTitleFieldTypeItemId: '{E4D1659A-E91F-429F-AD7B-B450AABCE37E}' as FieldTypes,
  leadManagementCampaignFieldTypeItemId: '{5ADCD535-A6FB-467F-858E-D30E5B6CF6E9}' as FieldTypes,
  provinceFieldTypeItemId: '{46FC0640-3807-4634-8830-0A370C46BD4B}' as FieldTypes,
  delaerLocatorFieldTypeItemId: '{B8D5DE42-2EC0-485B-A4ED-B506CA45C6AB}' as FieldTypes,
  customNumberFieldTypeItemId: '{76B7D120-B053-4551-B317-5E749B67BA72}' as FieldTypes,
  formPageIdentifierFieldTypeItemId: '{2F8E0B1E-4CC7-4C28-A3C1-EF0165274BE5}' as FieldTypes,
  businessSegmentFieldTypeItemId: `{0AE6072C-5346-45D6-9E8E-226704D041C0}` as FieldTypes,
  marketingConsentTypeItemId: '{360E6123-3717-46FA-BCC3-23BC3483799B}' as FieldTypes,
  dealerNotesDropdownListFieldTypeItemId: '{3F888D60-8ABB-4537-93CA-FE86E657CB3B}' as FieldTypes,
  dealerNotesCheckboxListFieldTypeItemId: '{7F443BA0-E3B7-4E2F-9D7F-5C84920D5161}' as FieldTypes,
  dealerNotesRadioGroupFieldTypeItemId: '{9A3D9FBD-CC94-4DAC-B17C-AC8B08E78E91}' as FieldTypes,
};

export default customFieldTypes;
