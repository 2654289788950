const fields = {
  icon: '{FDF6F1E5-6930-4212-A5DE-C165A9A586D2}',
  isMobile: '{9D5649C1-CC99-473E-AF6F-12BCAC560493}',
  isOnStickyBar: '{22C87616-1D23-4268-976A-AA1C92549DC7}',
  link: '{690FBF99-1846-437A-8CDA-664979A8AE53}'
};

const socialMediaBarFields = {
  socialMedia: '{954B5A22-1F67-447F-8B6E-2A773520DFB3}',
  postText: '{F3DC7E48-B908-4EA4-A91A-8D3AD3A207DA}',
};

const imagefields = {
  mobileImage: '{FDCA82BE-9277-47E0-8137-FC3D0DD365DA}',
  image: '{4E9D2DE7-3ABB-4AB0-B276-8EA3D8E15AD2}',
};

const EE_EMPTY_IMAGE_FALLBACK_CLASS_NAME = 'scEmptyImage';

export { fields, socialMediaBarFields, imagefields, EE_EMPTY_IMAGE_FALLBACK_CLASS_NAME };
