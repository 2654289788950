import { ComponentRendering } from '@sitecore-jss/sitecore-jss-react';
import { HeroCarouselProps, TCarouselSlide, TFullscreenCarouselFields } from '../types/herocarousel.types';

export class HeroCarouselModel {
  private readonly fields?: TFullscreenCarouselFields | null;
  private readonly rendering?: ComponentRendering;

  constructor(props: HeroCarouselProps) {
    this.fields = props?.fields || null;
    this.rendering = props?.rendering;
  }

  get contextItemUrl(): string {
    return this.fields?.itemData?.contextItemUrl || '';
  }

  get thumbnailEE(): string {
    return this.fields?.itemData?.thumbnail || '';
  }

  get componentName(): string {
    return this.rendering?.componentName || '';
  }

  get carouselSlide(): Array<TCarouselSlide> {
    const carouselSlides = this.fields?.apiData?.assets ? [...this.fields.apiData.assets] : [];
    return [...carouselSlides];
  }

  get isUnreleased(): boolean {
    return !!this.fields?.isComponentUnreleased;
  }
}
