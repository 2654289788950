import { SitecoreContextReactContext, Text } from '@sitecore-jss/sitecore-jss-react';
import React, { FC, useContext } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

export type TDictionaryTermProps = WithTranslation & {
  t: WithTranslation['t'];
  keyword: string;
  isCustomText?: boolean;
  customTextClass?: string;
  customTextTag?: string;
};

const DictionaryTerm: FC<TDictionaryTermProps> = ({ t, keyword, isCustomText = false, customTextClass = '', customTextTag = 'div' }) => {
  const sitecoreContextFactory = useContext(SitecoreContextReactContext);
  const context = sitecoreContextFactory?.context;
  const pageEditing = context?.pageState === 'edit';
  if (!keyword) return null;
  if (!t) return <>{keyword}</>;

  if (isCustomText) {
    const value = t(keyword, { defaultValue: '' })?.trim();
    if (!pageEditing) {
      return value && value !== keyword ? (
        <Text tag={customTextTag} className={customTextClass} field={{ value }} editable={false} />
      ) : null;
    }
    return <Text tag={customTextTag} className={customTextClass} field={{ value: t(keyword) }} editable={false} />;
  }

  return <>{t(keyword)}</>;
};

export default withTranslation()(DictionaryTerm);
