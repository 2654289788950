import { isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import React, { FC } from 'react';
import CustomExternalAnchor from '../../../customExternalAnchor';
import RoutableSitecoreLink from '../../../routableSitecoreLink';
import { TLinkWithFieldProps } from '../../types/CTA.types';
import ValidHrefAndNotEditing from './validHrefNotEditing';
import LinkChildrenAndIcon from './linkChildrenAndIcon';

const LinkWithField: FC<TLinkWithFieldProps> = (props) => {
  const { field, children, hasCTAIcon, ctaClassName, target, onClick } = props;
  const hasValidHref = field.value && field.value.href;
  const isEditing = field.editable;

  // only want to apply the routing link if not editing (if editing, need to render editable link value)
  if (hasValidHref && !isEditing) {
    return (
      <ValidHrefAndNotEditing field={field} onClick={onClick} ctaClassName={ctaClassName} hasCTAIcon={hasCTAIcon} target={target}>
        {children}
      </ValidHrefAndNotEditing>
    );
  }

  if (children || hasCTAIcon) {
    // if it has children or the arrow, the sitecore link component gets duplicated on EE
    return (
      <LinkChildrenAndIcon isEditing={isEditing} ctaClassName={ctaClassName} field={field} hasCTAIcon={hasCTAIcon}>
        {children}
      </LinkChildrenAndIcon>
    );
  }

  if (!hasCTAIcon) {
    // Primary CTA with no children
    return (
      <span className={ctaClassName}>
        <CustomExternalAnchor field={field} />
      </span>
    );
  }

  return <RoutableSitecoreLink field={field} className={ctaClassName} editable={isEditorActive()} />;
};

export default LinkWithField;
