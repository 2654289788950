import React from 'react';
import LazyLoad from 'react-lazyload';
import { withSitecoreContext  } from '@sitecore-jss/sitecore-jss-react';

const LazySection = ({ height = 300, offset = -100, children, sitecoreContext, className = undefined as string | undefined}) => {
  return (
    <section style={{padding: '0px'}} className={className}>
      {
      sitecoreContext?.pageEditing
        ? children
        : <LazyLoad once={true} offset={offset} height={height}>{children}</LazyLoad>
      }
    </section>
  );
};
export default withSitecoreContext()(LazySection);