import {
  TCheckboxField,
  TComponentParamsGeneral,
  TDropdownSelect,
  TField,
  TImageField,
  TLinkField,
  TStringField,
  TVideoSitecoreField,
  WithSitecoreContextProps,
} from '../../../types/sitecore.types';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-react';
import ctaTypes from '../../../enums/CTA.enums';
import { SplitModuleProps } from '../../splitModule/types/splitModule.types';
import { WithTranslation } from 'react-i18next';

export enum ColumnLayoutEnum {
  TwoColumnLayout = '2ColumnLayout',
  ThreeColumnLayout = '3ColumnLayout',
  FourColumnLayout = '4ColumnLayout',
}

export enum ColumnLayoutCardColCountEnum {
  '2ColumnLayout' = 2,
  '3ColumnLayout' = 3,
  '4ColumnLayout' = 4,
}

export type TColumnLayout = {
  name: string;
  fields: {
    Key: {
      value: string | ColumnLayoutEnum;
    };
  };
};
export type TcolumnProperty = {
  displayName: string;
  fields: {
    Key: {
      value: string | ctaTypes;
    };
  };
};
export type TCardProps = {
  card: TCard;
  layout: string | ColumnLayoutEnum;
  textcolor: string | undefined;
  primaryStyle: string | ctaTypes;
  secondaryStyle: string | ctaTypes;
  bgColor: string | undefined;
  isTouch: boolean;
};

export type TCardAnimProps = {
  shouldAnimate?: boolean;
  animationDeleyInMs?: number;
};

export type TCard = {
  cardCtaTextLink?: TLinkField;
  cardSubheading?: TStringField;
  cardHeading?: TStringField;
  cardCtaButton?: TLinkField;
  image?: TImageField;
  mobileImage?: TImageField;
  appStoreImage?: TImageField;
  googlePlayImage?: TImageField;
  useImageCta?: TCheckboxField;
  video?: TVideoSitecoreField;
  useVideo?: TField<boolean>;
  items: Array<TSplitModulePopup>;
};

export type TSplitModulePopup = {
  fields: {
    popUpLabel: {
      value: string;
    };
    items: Array<SplitModuleProps>;
  };
};

export type TCardFields = {
  id?: string;
  fields: TCard;
};

export type TCardColumnLayoutFields = {
  sectionEyebrow?: TStringField;
  sectionHeading?: TStringField;
  sectionSubheading?: TStringField;
  sectionCtaTextLink?: TLinkField;
  columnLayout?: TColumnLayout;
  items?: TCardFields[];
  componentBgColor: TDropdownSelect;
  textElementColor: TDropdownSelect;
  primaryCtaStyle: TcolumnProperty;
  secondaryCtaStyle: TcolumnProperty;
  totalCardsCount?: number;
  rowsPerPage?: TStringField;
  hideLabelIndicator?: TField<boolean>;
  hideUiLineIndicator?: TField<boolean>;
  dataSource?: string;
  isComponentUnreleased?: boolean;
};

export type TCardColumnLayoutProps = WithSitecoreContextProps &
  WithTranslation & {
    fields?: TCardColumnLayoutFields;
    rendering?: ComponentRendering;
    params?: TComponentParamsGeneral;
  };

export type TCardImage = {
  imageOrThumbnail?: TImageField;
  useVideo: boolean;
  layout: string;
  imageIsPresent: boolean;
  yellowDotsImage: string;
  mobileImageOrThumbnail?: TImageField;
  showOverlayVideo: () => void;
};

export type TCardText = {
  title?: TStringField;
  subheading?: TStringField;
  textcolor?: string;
};

export type TCardButtons = {
  showPopupWithSplitModule: boolean;
  cta1?: TLinkField;
  cta2?: TLinkField;
  splitModuleLabel;
  useImageCTA?: TCheckboxField;
  title?: TStringField;
  primaryStyle: string;
  secondaryStyle: string;
  appStoreImage?: TImageField;
  googlePlayImage?: TImageField;
  handleSplitModuleModal: (state: boolean) => void;
};
