import { useContext } from 'react';
import { SitecoreContextReactContext } from '@sitecore-jss/sitecore-jss-react';

// returns true in Experience editor edit mode
// more reliable than isExperienceEditor function
export default function useEEActive(previewIncluded = false): boolean {
  const sitecoreContextFactory = useContext(SitecoreContextReactContext);
  const sitecoreContext = sitecoreContextFactory.context;
  let isEEActive = sitecoreContext?.pageEditing;
  if (previewIncluded) {
    isEEActive = isEEActive || sitecoreContext?.pageState === 'preview';
  }

  return !!isEEActive;
}
