import { ComponentRendering, Image, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import React, { FC } from 'react';
import { iconSize } from '../../../constants/icon.constants';
import { findCtaType } from '../../../utils/popUpCtaType.utils';
import { targetLinkdsk } from '../../../utils/targetDskLink';
import Icon from '../../@shared/icon';
import RoutableSitecoreLink from '../../@shared/routableSitecoreLink';
import { TSplitModuleButtons } from '../types/splitModule.types';

const Buttons: FC<TSplitModuleButtons> = (props) => {
  const { isEE, link1, link2, isDesktop, appStoreImage, googlePlayImage, rendering } = props;
  const hasSecondartCta = link2?.value?.title || link2?.value?.href;
  const popUpCtaType = findCtaType(rendering);
  const primaryCTAclassName = !hasSecondartCta && popUpCtaType === 'primary-cta' ? 'split-module__right-margin' : '';
  const secondaryCTAclassName = popUpCtaType === 'primary-cta' ? 'split-module__right-margin' : '';

  return (
    <div className={'split-module__buttons'}>
      {(isEE || link1?.value?.title || link1?.value?.href) && (
        <div
          className={`split-module__primary-link ${appStoreImage ? 'split-module__buttons--media' : ''}
                         ${primaryCTAclassName}`}
        >
          <RoutableSitecoreLink
            field={link1}
            target={targetLinkdsk(link1, isDesktop)}
            className={`${appStoreImage?.value?.src ? 'split-module__cta-image' : 'split-module__cta-icon'}`}
          >
            {appStoreImage?.value?.src && <Image loading="lazy" field={appStoreImage} />}
          </RoutableSitecoreLink>
        </div>
      )}
      {(isEE || link2?.value?.title || link2?.value?.href) && (
        <div
          className={`split-module__secondary-link
                    ${googlePlayImage?.value?.src ? 'split-module__buttons--media' : ''} ${secondaryCTAclassName}`}
        >
          <RoutableSitecoreLink
            field={link2}
            target={targetLinkdsk(link2, isDesktop)}
            className={`${googlePlayImage?.value?.src ? 'split-module__cta-image' : 'split-module__cta-icon'}`}
          >
            {googlePlayImage?.value?.src ? (
              <Image loading="lazy" field={googlePlayImage} />
            ) : (
              <>
                <span>{link2?.value?.text}</span>
                <Icon iconName="arrow-chevron-right" size={iconSize.dsk} extraClassName="split-module__arrow" />
              </>
            )}
          </RoutableSitecoreLink>
        </div>
      )}
      <div className={`split-module__pop-up ${isEE ? 'split-module__pop-up--ee' : ''}`}>
        <Placeholder name={'jss-generic-popup'} rendering={props.rendering as ComponentRendering} />
      </div>
    </div>
  );
};
export default Buttons;
