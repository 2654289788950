import React, { FC, memo } from 'react';
import { Link as SitecoreLink } from '@sitecore-jss/sitecore-jss-react';
import { TRoutableSiteCoreLinkField, TBaseObj } from '../../../types/sitecore.types';
import useEEActive from '../../../hooks/useEEActive';

const buildLinkAttributes = (props: TRoutableSiteCoreLinkField): TBaseObj => {
  const { field } = props;
  if (!field?.value) return { className: props?.className || '' };

  let linkAttributes = {};
  const value = field?.value;
  linkAttributes = {
    href: value?.href,
    className: props?.className ?? value?.class,
    title: value?.title,
    target: props?.target ?? value?.target,
  };

  if (!value?.removenofollow) {
    linkAttributes['rel'] = 'nofollow';
  }

  return linkAttributes;
};

const CustomExternalAnchor: FC<TRoutableSiteCoreLinkField> = (props) => {
  const isEE = useEEActive();

  if (isEE) {
    return <SitecoreLink {...props} />;
  }

  const { children, field } = props;

  const linkAttributes = buildLinkAttributes(props);

  return (
    <a onClick={() => props?.onclick && props?.onclick()} {...linkAttributes}>
      {children || field?.value?.text || field?.value?.href}
    </a>
  );
};

export default memo(CustomExternalAnchor);
