import React, { FC } from 'react';
import CTA from '../../CTA';
import ctaTypes from '../../../../enums/CTA.enums';
import { targetLinkdsk } from '../../../../utils/targetDskLink';
import { TInnerSplitModuleButtons } from '../types/innerComponentsPopup.types';
import { appendRootToInternalLink } from '../../../../utils/appendRootToInternalLink.utils';

const Buttons: FC<TInnerSplitModuleButtons> = (props) => {
  const { button1, button2, isDesktop } = props;
  const link1 = { value: { href: button1?.url, target: button1?.target } };
  const link2 = { value: { href: button2?.url, target: button2?.target } };
  return (
    <div className={'inner-split-module__buttons'}>
      {button1?.label && button1?.url && (
        <CTA
          type={ctaTypes.primaryGhost}
          field={{ value: { href: appendRootToInternalLink(button1?.url), target: button1?.target } }}
          target={targetLinkdsk(link1, isDesktop)}
        >
          {button1?.label}
        </CTA>
      )}
      {(button2?.url || button2?.label) && (
        <CTA
          type={ctaTypes.secondaryWhite}
          field={{ value: { href: appendRootToInternalLink(button2?.url), target: button2?.target } }}
          target={targetLinkdsk(link2, isDesktop)}
          extraClassName="inner-split-module__buttons--secondary"
        >
          {button2?.label}
        </CTA>
      )}
    </div>
  );
};

export default Buttons;
