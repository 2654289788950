import React, { FC } from 'react';
import Picture from '../../../@shared/picture';
import { TProductImageProps } from '../../../@shared/picture/types/picture.types';
import { TProductSpecialOffersListingMedia } from '../../types/productSpecialOffersListing.types';

const Media: FC<TProductSpecialOffersListingMedia> = (props) => {
  const { desktopImage, mobileImage, defaultImage, imageAlt } = props;

  const shouldShowDefaultImage = !desktopImage?.url && !mobileImage?.url;
  const prodcutImageProps: TProductImageProps = {
    mobileRendition: mobileImage,
    desktopRendition: desktopImage,
    pictureClassName: 'special-offers-listing-card__image',
    alt: imageAlt,
  };

  if (shouldShowDefaultImage && defaultImage) {
    prodcutImageProps['mobileRendition'] = { url: defaultImage.defaultMobileImage };
    prodcutImageProps['desktopRendition'] = { url: defaultImage.defaultDesktopImage };
  }

  return (
    <div className="special-offers-listing-card__media-wrapper">
      <Picture productImageProps={prodcutImageProps} />
    </div>
  );
};

export default Media;
