import { ComponentRendering } from '@sitecore-jss/sitecore-jss-react';
import {
  TCompatibleProductParams,
  TCompatibleProducts,
  TCompatibleProductsItemData,
  TProductCard,
} from '../types/compatibleProducts.types';
import { TStringField } from '../../../types/sitecore.types';

export class CompatibleProductsModel {
    private readonly data?: TCompatibleProducts;
    private readonly params?: TCompatibleProductParams;
    private readonly itemData?: TCompatibleProductsItemData;
    private readonly rendering?: ComponentRendering;
    private readonly isComponentUnreleased?: boolean;

    constructor(props: any) {
        this.data = props?.fields?.apiData || null;
        this.params = props?.params;
        this.itemData = props?.fields?.itemData || null;
        this.rendering = props?.rendering;
        this.isComponentUnreleased = props?.fields?.isComponentUnreleased;
    }

    get thumbnailEE(): string {
        return this.itemData?.thumbnail || '';
    }

    get componentName(): string {
        return this.rendering?.componentName || '';
    }

    get items(): TProductCard[] | undefined {
        return this.data?.items;
    }

    get total(): number | undefined {
        return this.data?.total;
    }

    get eyebrow(): TStringField {
        return {value: this.data?.eyebrow};
    }

    get title(): TStringField {
        return {value: this.data?.title};
    }

    get CTA(): TCompatibleProductParams | undefined {
        return this.params;
    }

    get baseUrl(): string | undefined {
        return this.itemData?.fields?.productsItemUrl;
    }

    get isUnreleased(): boolean {
        return !!this.isComponentUnreleased;
    }
}
