import React, { FC, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { ComponentRendering, Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { APIPrefix } from '../../constants/api.constants';
import ctaTypes from '../../enums/CTA.enums';
import { findCtaType } from '../../utils/popUpCtaType.utils';
import { isMobileDevice } from '../../utils/isMobile';
import CTA from '../@shared/CTA';
import Heading from '../@shared/heading';
import UnreleasedComponent from '../@shared/unreleasedComponent';
import Card from './components/card';
import LoadingCard from './components/loadingCard';
import { CardColumnLayoutModel } from './models/cardColumnLayout.model';
import { ColumnLayoutCardColCountEnum, TCardColumnLayoutProps, TCardFields } from './types/cardColumnLayout.types';
import '../../styles/@shared/card-layout.scss';
import './styles.scss';

const CardColumnLayout: FC<TCardColumnLayoutProps> = (props) => {
  const model = new CardColumnLayoutModel(props);
  const { t } = props;
  const [allCards, setCards] = useState<TCardFields[]>([]);
  const [fetchedCards, setFetchedCards] = useState<TCardFields[]>(model?.cards);
  const [isLoaderCardsShown, setIsLoaderCardsShown] = useState(false);
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const popUpCtaType = findCtaType(props.rendering);
  const secondaryCTAClassName = popUpCtaType === 'primary-cta' ? 'card-column-layout__left-margin' : '';
  const totalfetchedCards = Number(allCards?.length) + Number(fetchedCards.length);
  const noOfRowstoBeFetched = model.rowsPerPage;
  const noOFCardToBeFetched = (ColumnLayoutCardColCountEnum[model.layout] || 1) * noOfRowstoBeFetched;
  const totalCardsCount = model.totalCardsCount || model.cards.length;
  const secondaryCta = {
    secondaryWhite: ctaTypes.secondaryWhite,
    secondaryBluewithArrowicon: ctaTypes.secondaryBlue,
  };
  const siteLanguage = props.sitecoreContext?.language;

  const checkIdTouchDevice = () => {
    setIsTouchDevice(isMobileDevice());
  };

  useEffect(() => {
    setCards([]);
    setFetchedCards(model?.cards);
    setIsLoaderCardsShown(false);
    setIsTouchDevice(isMobileDevice());
  }, [props]);

  useEffect(() => {
    checkIdTouchDevice();
  }, [checkIdTouchDevice]);

  const fetchNewCards = (): void => {
    setIsLoaderCardsShown(true);
    setCards([...allCards, ...fetchedCards]);
    setFetchedCards([]);
    const startCardIndex = totalfetchedCards;
    const endCardIndex = noOFCardToBeFetched;
    const getCardsURL = `/${APIPrefix}/cardcolumn/card-items/${model.dataSource}/${siteLanguage}/${startCardIndex}/${endCardIndex}`;
    fetch(getCardsURL)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(`${res.status} : Failed to fetch cards`);
        }
      })
      .then((result) => {
        setFetchedCards(result?.map((_) => ({ fields: { ..._ } })) as TCardFields[]);
        setIsLoaderCardsShown(false);
      })
      .catch(() => {
        setIsLoaderCardsShown(false);
      });
  };

  // Component is unreleased
  if (model.isUnreleased) {
    return <UnreleasedComponent />;
  }

  return (
    <div className={`card-column-layout card-layout--${model.backgroundColor}`}>
      <div className={'card-column-layout__header'}>
        <Heading
          editable={true}
          eyebrow={model.eyebrow}
          title={model.title}
          subtitle={model.subtitle}
          secondaryTypeLink={secondaryCta[model.secondaryCTAStyle]}
          extraClassNameLink={secondaryCTAClassName}
          link={model.link}
          tag={model.headingTag}
          isGenericPopup={!!props.rendering?.placeholders?.['jss-generic-popup']?.length}
        >
          <div className={'card-column-layout__pop-up'}>
            <Placeholder name={'jss-generic-popup'} rendering={props.rendering as ComponentRendering} />
          </div>
        </Heading>
      </div>
      <div className={`card-column-layout__cards card-column-layout__cards--${model.layout}`}>
        {allCards.map((card: TCardFields) => (
          <Card
            key={card?.id}
            card={card?.fields}
            layout={model.layout}
            textcolor={model.textcolor}
            primaryStyle={model.primaryCTAStyle}
            secondaryStyle={model.secondaryCTAStyle}
            bgColor={model.backgroundColor}
            isTouch={isTouchDevice}
          />
        ))}
        {fetchedCards.map((card: TCardFields, index: number) => (
          <Card
            key={card?.id}
            card={card?.fields}
            layout={model.layout}
            textcolor={model.textcolor}
            primaryStyle={model.primaryCTAStyle}
            secondaryStyle={model.secondaryCTAStyle}
            bgColor={model.backgroundColor}
            shouldAnimate={true}
            animationDeleyInMs={Number(index) * 150}
            isTouch={isTouchDevice}
          />
        ))}
        {isLoaderCardsShown &&
          new Array(ColumnLayoutCardColCountEnum[model.layout]).fill(0).map((_, i) => <LoadingCard key={i} layout={model.layout} />)}
      </div>
      <div className="card-column-layout__footer">
        {model.isLabelIndicatorShown && (
          <div className="card-column-layout__items-count">
            {totalfetchedCards} {t('cnhi-of')} {totalCardsCount} {t('cnhi-itemsLoaded')}
          </div>
        )}
        {model.isUiLineIndicatorShown && (
          <span className="card-column-layout__progress">
            <span className="card-column-layout__progress--total" />
            <span
              className="card-column-layout__progress--completed"
              style={{ width: `${(totalfetchedCards / totalCardsCount) * 100}%` }}
            />
          </span>
        )}
        {totalfetchedCards < totalCardsCount && (
          <CTA
            type={ctaTypes['secondaryBlue']}
            iconName="arrow-chevron-down"
            onClick={fetchNewCards}
            extraClassName="card-column-layout__load-more"
          >
            {t('cnhi-loadMore')}
          </CTA>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(withSitecoreContext()(CardColumnLayout));
