import React, { FC } from 'react';
import { iconSize } from '../../../../constants/icon.constants';
import Icon from '../../../@shared/icon';
import { TSearchFilter } from '../../types/filter.types';
import Filter from '.';

const SearchFilter: FC<TSearchFilter> = (props) => {
  const { isDesktop, isCollapsed, filters, activeFilters, onFiltersChange, toggleCollapsed, t } = props;
  const collapsedIcon = isCollapsed ? 'arrow-chevron-down' : 'arrow-chevron-up';
  if (!isDesktop) {
    return (
      <div className="search__results__accordian">
        <button onClick={toggleCollapsed}>
          <h3 className="search__results__accordian--title">{t('cnhi-filters')}</h3>
          <Icon iconName={collapsedIcon} size={iconSize.dsk} />
        </button>
        {!isCollapsed && <Filter filters={filters} activeFilters={activeFilters} onFiltersChange={onFiltersChange} />}
      </div>
    );
  } else {
    return <Filter filters={filters} activeFilters={activeFilters} onFiltersChange={onFiltersChange} />;
  }
};

export default SearchFilter;
