import { ComponentRendering } from '@sitecore-jss/sitecore-jss-react';
import { SP_QUERY_STRINGS } from '../../../constants/special-offers-page.constants';
import { TDateField, TLinkField, TStringField } from '../../../types/sitecore.types';
import { appendRootToRichTextInternalLinks2 } from '../../../utils/appendRootToRichTextInternalLinks.utils';
import {
  TProductSpecialOfferCategoryWithLink,
  TProductSpecialOfferDetailFields,
  TProductSpecialOfferDetailProps,
} from '../types/productSpecialOfferDetail.types';

export class ProductSpecialOfferDetailModel {
  private readonly fields?: TProductSpecialOfferDetailFields;
  private readonly rendering?: ComponentRendering;
  private readonly specialOfferPageUrl?: string;

  constructor(props: TProductSpecialOfferDetailProps, specialOfferPageUrl?: string) {
    this.fields = props.fields || undefined;
    this.rendering = props?.rendering || undefined;
    this.specialOfferPageUrl = specialOfferPageUrl || '';
  }

  get title(): TStringField {
    return { value: this.fields?.apiData?.formattedTitle };
  }

  get categories(): TProductSpecialOfferCategoryWithLink[] {
    const mainCategory = this.fields?.apiData?.mainCategory;
    const otherCategories = this.fields?.apiData?.otherCategories || [];
    const allCategories = mainCategory ? [mainCategory, ...otherCategories] : otherCategories;
    return allCategories.map((eachCategory) => {
      const redirectUrl = encodeURI(this.specialOfferPageUrl + '?' + SP_QUERY_STRINGS.CATEGORY + '=' + eachCategory.titleUrl);
      return { ...eachCategory, redirectUrl };
    });
  }

  get description(): TStringField {
    return { value: appendRootToRichTextInternalLinks2(this.fields?.apiData?.description) };
  }

  get disclaimer(): TStringField {
    return { value: appendRootToRichTextInternalLinks2(this.fields?.apiData?.disclaimer) };
  }

  get expiration(): TDateField {
    return { value: this.fields?.apiData?.expiration };
  }

  get primaryLink(): TLinkField | undefined {
    const button = this.fields?.apiData?.buttonDetails1;
    if (!button?.url || !button?.label) return undefined;
    return ({
      value: {
        href: button.url,
        title: button.label,
        target: button.target
      }
    })
  }

  get secondaryLink(): TLinkField | undefined {
    const button = this.fields?.apiData?.buttonDetails2;
    if (!button?.url || !button?.label) return undefined;
    return ({
      value: {
        href: button.url,
        title: button.label,
        target: button.target
      }
    })
  }

  get thumbnailEE(): string {
    return this.fields?.itemData?.thumbnail || '';
  }

  get componentName(): string {
    return this.rendering?.componentName || '';
  }

  get isUnreleased(): boolean {
    return !!this.fields?.isComponentUnreleased;
  }
}
