import { EE_EMPTY_IMAGE_FALLBACK_CLASS_NAME } from '../constants/fields.const';
import useEEActive from '../hooks/useEEActive';
import { TImageField } from '../types/sitecore.types';

function useGetImageField(imageField: TImageField | undefined, thumbnailImgSrc?: string, useVideo?: boolean): TImageField | undefined {
  const isEEActive = useEEActive();
  if (useVideo) {
    // Displaying thumbnail in EE instead of sitecore fallbcack image
    // Note: Sitecore provides default image if no image is set in EE mode
    if (isEEActive && imageField?.value?.class === EE_EMPTY_IMAGE_FALLBACK_CLASS_NAME && thumbnailImgSrc) {
      return { ...imageField, value: { src: thumbnailImgSrc } };
    }

    return { value: { src: thumbnailImgSrc }, ...(imageField?.value?.src ? imageField : {}) };
  }
  return imageField;
}

export { useGetImageField as getImageField };
