import React, { FC } from 'react';

import { ValueFieldProps } from '@sitecore-jss/sitecore-jss-react-forms/types';
import { StringInputViewModel } from '@sitecore-jss/sitecore-jss-forms';
import BaseTextField from '../baseTextField';

const EmailField: FC<ValueFieldProps<StringInputViewModel>> = (props) => (
  <BaseTextField type="email" {...props} />
);

export default EmailField;
