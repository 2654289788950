import React, { useState } from 'react';
import { isEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isEmptyResponse } from '../../utils/checkEmptyResponse';
import useExperienceEditorPlaceholder from '../../hooks/useExperienceEditorPlaceholder';
import ExperienceEditorComponentPlaceholder from '../@shared/experienceEditorComponentPlaceholder';
import EmptyApiDataMessage from '../@shared/emptyApiDataMessage';
import { TCompatibleProductsProps, TProductCard } from './types/compatibleProducts.types';
import { CompatibleProductsModel } from './model/compatibleProducts.model';
import './styles.scss';
import CompatibleProduct from './components/compatibleProduct';
import Icon from '../@shared/icon';
import { iconSize } from '../../constants/icon.constants';
import Heading from '../@shared/heading';
import { WithTranslation, withTranslation } from 'react-i18next';
import UnreleasedComponent from '../@shared/unreleasedComponent';
import AnalyticsVisibleEvent from '../@shared/analyticsVisibleEvent';
import { compatibleClickEvent } from '../../constants/analytics.constants';
import { googleCTAClickEvent } from '../../utils/analytics';
import {
  descriptionKeyCompatibleProducts,
  eyebrowKeyCompatibleProducts,
  titleKeyCompatibleProducts,
} from '../../constants/dictionary.constants';
import useSitecoreContext from '../../hooks/useSitecoreContext';

const ProductSeriesDetailCompatibleProducts = (props: WithTranslation & TCompatibleProductsProps) => {
  const model = new CompatibleProductsModel(props);
  const { t } = props;
  const [showMore, setShowMore] = useState(false);
  const apiDataLength = props?.fields?.apiData?.items && props?.fields?.apiData?.items?.length > 0;
  const apiResponse = isEmptyResponse(props) && apiDataLength;
  const isPlaceholderShown = useExperienceEditorPlaceholder(props);

  const {sitecoreContext} = useSitecoreContext();
  const productLine = sitecoreContext?.analytics?.['productLine'];
  const productSeries = sitecoreContext?.analytics?.['productSeries'];

  // Component is unreleased
  if (model.isUnreleased) {
    return <UnreleasedComponent />;
  }

  // star  node opened directly in EE
  if (isPlaceholderShown) {
    return <ExperienceEditorComponentPlaceholder image={model.thumbnailEE} componentName={model.componentName} />;
  }

  // not opened from star node
  // data missing when navigating via from products node
  if (!apiResponse && isEditorActive()) {
    return <EmptyApiDataMessage componentName={model.componentName} />;
  }

  // No data and not in EE
  if (!apiResponse && !isPlaceholderShown && !isEditorActive()) {
    return null;
  }

  return (
    <AnalyticsVisibleEvent event="series_compatible_visible">
      <div className="compatible-product-wrapper">
        <div className="compatible-product__header">
          <Heading
            eyebrow={model.eyebrow}
            title={model.title}
            link={{
              value: {
                href: model.CTA?.bestSolutionCtaUrl,
                text: model.CTA?.bestSolutionCtaText,
                target: model?.CTA?.bestSolutionCtaTarget || '',
              },
            }}
            editable={false}
            useAnalytics
            event={compatibleClickEvent}
            useDictionaryFallback
            titleDictionaryKey={titleKeyCompatibleProducts}
            eyebrowDictionaryKey={eyebrowKeyCompatibleProducts}
            descriptionDictionaryKey={descriptionKeyCompatibleProducts}
          />
        </div>
        {model?.items?.length && (
          <div className={'compatible-product__cards'}>
            {showMore &&
              model.items.map((item: TProductCard) => (
                <CompatibleProduct
                  key={item?.id}
                  {...item}
                  baseUrl={model?.baseUrl}
                  productLine={productLine}
                  productSeries={productSeries}
                />
              ))}
            {!showMore &&
              model.items
                .filter((item: TProductCard, index: number) => index < 4)
                .map((item: TProductCard) => <CompatibleProduct key={item?.id} {...item} baseUrl={model?.baseUrl} />)}
          </div>
        )}
        {model.items && model.items.length > 4 && (
          <div className={'compatible-product__show-more'}>
            <button
              className={'compatible-product__show-more-cta'}
              onClick={() => {
                setShowMore(!showMore);
                googleCTAClickEvent(
                  compatibleClickEvent,
                  '#',
                  t(showMore ? 'cnhi-seeLessCompatibleProducts' : 'cnhi-seeMoreCompatibleProducts'),
                  productLine,
                  productSeries
                );
              }}
            >
              {t(showMore ? 'cnhi-seeLessCompatibleProducts' : 'cnhi-seeMoreCompatibleProducts')}
              <Icon iconName={showMore ? 'arrow-chevron-up' : 'arrow-chevron-down'} size={iconSize.dsk} />
            </button>
          </div>
        )}
      </div>
    </AnalyticsVisibleEvent>
  );
};

export default withSitecoreContext()(withTranslation()(ProductSeriesDetailCompatibleProducts));
