import {
  ProductPlmSeriesFeatureProps,
  PlmGroupProps,
  PlmSeriesFeatureData
} from '../types/productLinePlmSeriesSection.types';

type ModelType = {
  data: PlmGroupProps[] | PlmGroupProps;
  baseUrl: string;
}


export const PlmSeriesFeatureModel = (props: ProductPlmSeriesFeatureProps): ModelType => {

  const data = props?.fields?.apiData || null;
  const baseUrl = props?.fields?.itemData?.contextItemUrl || '';

  if (Array.isArray((data as PlmSeriesFeatureData)?.groups)) {
    const groups = (data as PlmSeriesFeatureData)?.groups || [];
    return {
      data: groups,
      baseUrl
    };
  }
  return {
    data: data as PlmGroupProps,
    baseUrl
  };
}
