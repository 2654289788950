import { TFunction } from 'i18next';
import { WithTranslation } from 'react-i18next';
import { SP_QUERY_STRINGS } from '../../../constants/special-offers-page.constants';
import { ALL_SP_CATEGORIES, ALL_SP_LINES, ALL_SP_SERIES } from '../../../constants/specialOffersFilters.constants';
import { updateSearchResults } from '../../../hooks/useQueryParams.effect';
import { TLinkField, TRendition, TSitecoreContext } from '../../../types/sitecore.types';
import { appendRootToInternalLink } from '../../../utils/appendRootToInternalLink.utils';
import { TFilterContextData } from '../fiilterContext';
import {
  TProductSpecialOffersListingCard,
  TProductSpecialOffersListingCardID,
  TProductSpecialOffersListingCategoryFilter,
  TProductSpecialOffersListingFields,
  TProductSpecialOffersListingFilters,
  TProductSpecialOffersListingLineFilter,
  TProductSpecialOffersListingProps,
  TSpecialOfferDefaultImage,
} from '../types/productSpecialOffersListing.types';

export class ProductSpecialOffersListingItemModel {
  private readonly specialOfferCard: TProductSpecialOffersListingCard;
  private readonly t?: TFunction;
  private readonly specialOfferMainPageLink: string;

  constructor(props: TProductSpecialOffersListingCard & WithTranslation, specialOfferMainPageLink?: string) {
    this.specialOfferCard = props;
    this.t = props.t;
    this.specialOfferMainPageLink = specialOfferMainPageLink || '';
  }

  get desktopImage(): TRendition | undefined {
    const asset = this.specialOfferCard?.assets?.[0];
    return asset?.rendition_desktop_size1000;
  }

  get mobileImage(): TRendition | undefined {
    const asset = this.specialOfferCard?.assets?.[0];
    return asset?.rendition_mobile_size300;
  }

  get imageAlt(): string {
    const asset = this.specialOfferCard?.assets?.[0];
    return asset?.alt ?? '';
  }

  get tag(): string | undefined {
    return this.specialOfferCard?.badge;
  }

  get expirationDate(): string | undefined {
    return this.specialOfferCard?.expiration;
  }

  get titleUrl(): string {
    return this.specialOfferCard?.titleUrl || '';
  }

  get title(): string | undefined {
    return this.specialOfferCard?.formattedTitle;
  }

  get shortDescription(): string | undefined {
    return this.specialOfferCard?.shortDescription;
  }

  get disclaimer(): string | undefined {
    return this.specialOfferCard?.disclaimer;
  }

  get button1(): TLinkField | undefined {
    if (!this.titleUrl || !this.specialOfferMainPageLink || this.specialOfferCard.hideSpecialOfferButtonFromCard) return undefined;
    const title = this.t?.('cnhi-special-offer-listing-details');
    return {
      value: {
        href: `${this.specialOfferMainPageLink}/${this.titleUrl}`,
        title,
        text: title,
      },
    };
  }

  get button2(): TLinkField | undefined {
    const button2 = this.specialOfferCard?.button2;
    if (button2?.url && button2?.label) {
      return {
        value: {
          href: appendRootToInternalLink(this.specialOfferCard?.button2?.url),
          title: this.specialOfferCard?.button2?.label,
          target: this.specialOfferCard?.button2?.target,
        },
      };
    }
    return undefined;
  }
}

export class ProductSpecialOffersListingModel {
  private readonly fields?: TProductSpecialOffersListingFields;
  readonly cardsObj: { [id: string]: TProductSpecialOffersListingCard };
  private readonly context?: TSitecoreContext;

  constructor(props: TProductSpecialOffersListingProps, sitecoreContext?: TSitecoreContext) {
    this.fields = props.fields;
    this.cardsObj = {};
    props.fields?.apiData?.cards?.forEach((eachCard) => {
      eachCard['id'] && (this.cardsObj[eachCard['id']] = eachCard);
    });
    this.context = sitecoreContext;
  }

  get isUnreleased(): boolean | undefined {
    return !!this.fields?.isComponentUnreleased;
  }

  get filters(): TProductSpecialOffersListingFilters {
    return this.fields?.apiData?.filters || {};
  }

  get lines(): TProductSpecialOffersListingLineFilter[] {
    return this.fields?.apiData?.filters?.lines || [];
  }

  get categories(): TProductSpecialOffersListingCategoryFilter[] {
    return this.fields?.apiData?.filters?.categories || [];
  }

  get specialOfferCards(): TProductSpecialOffersListingCard[] {
    return this.fields?.apiData?.cards || [];
  }

  get defaultCardDesktopImage(): string | undefined {
    return this.context?.defaultBackgroundImageParameters?.desktopImageData?.specialOfferListingCardImage;
  }

  get defaultCardMobileImage(): string | undefined {
    return this.context?.defaultBackgroundImageParameters?.mobileImageData?.specialOfferListingCardImage;
  }

  get defaultCardImage(): TSpecialOfferDefaultImage | undefined {
    if (this.defaultCardDesktopImage || this.defaultCardMobileImage) {
      return { defaultDesktopImage: this.defaultCardDesktopImage, defaultMobileImage: this.defaultCardMobileImage };
    }
    return undefined;
  }

  get cards(): TProductSpecialOffersListingCard[] {
    return this.fields?.apiData?.cards || [];
  }

  getCategoryTitleUrl(categorySelectedInd?: TFilterContextData['categorySelectedInd']): string {
    if (categorySelectedInd === undefined || categorySelectedInd === ALL_SP_CATEGORIES) return '';
    return this.categories[categorySelectedInd]?.titleUrl || '';
  }

  getLineTitleUrl(lineSelectedInd?: TFilterContextData['lineSelectedInd']): string {
    if (lineSelectedInd === undefined || lineSelectedInd === ALL_SP_LINES) return '';
    return this.lines[lineSelectedInd]?.titleUrl || '';
  }

  getSeriesTitleUrl(
    lineSelectedInd?: TFilterContextData['lineSelectedInd'],
    seriesSelectedInd?: TFilterContextData['seriesSelectedInd']
  ): string {
    if (
      lineSelectedInd === undefined ||
      lineSelectedInd === ALL_SP_LINES ||
      seriesSelectedInd === undefined ||
      seriesSelectedInd === ALL_SP_SERIES
    )
      return '';
    return this.lines?.[lineSelectedInd]?.series?.[seriesSelectedInd]?.titleUrl || '';
  }

  updateQueryParams(navigate, filters: Partial<TFilterContextData> = {}): void {
    const { categorySelectedInd, lineSelectedInd, seriesSelectedInd } = filters;
    const { CATEGORY, LINE, SERIES } = SP_QUERY_STRINGS;
    const queryObj = {};
    queryObj[CATEGORY] = this.getCategoryTitleUrl(categorySelectedInd);
    queryObj[LINE] = this.getLineTitleUrl(lineSelectedInd);
    queryObj[SERIES] = this.getSeriesTitleUrl(lineSelectedInd, seriesSelectedInd);
    updateSearchResults(navigate, queryObj);
  }

  updatePageQueryParams(navigate, pageNo): void {
    updateSearchResults(navigate, { [SP_QUERY_STRINGS.PAGE]: pageNo.toString() });
  }

  resetAllQueryParams(navigate) {
    const { CATEGORY, LINE, SERIES, PAGE } = SP_QUERY_STRINGS;
    updateSearchResults(navigate, {
      [CATEGORY]: '',
      [LINE]: '',
      [SERIES]: '',
      [PAGE]: '',
    });
  }

  getDefaultContextData(
    search = ''
  ): {
    categorySelectedInd: TFilterContextData['categorySelectedInd'];
    lineSelectedInd: TFilterContextData['lineSelectedInd'];
    seriesSelectedInd: TFilterContextData['seriesSelectedInd'];
  } {
    const searchParam = new URLSearchParams(search);
    const { CATEGORY, LINE, SERIES } = SP_QUERY_STRINGS;
    const categoryFromQuery = searchParam.get(CATEGORY) || '';
    const lineFromQuery = searchParam.get(LINE) || '';
    const seriesFromQuery = searchParam.get(SERIES) || '';
    const defaultCategoryInd = this.categories.findIndex((eachCategory) => eachCategory.titleUrl === categoryFromQuery);
    const deafultLineInd = defaultCategoryInd === -1 ? this.lines.findIndex((eachLine) => eachLine.titleUrl === lineFromQuery) : -1;
    const deafultSeriesInd =
      deafultLineInd !== -1
        ? this.lines[deafultLineInd]?.series?.findIndex((eachSeries) => eachSeries.titleUrl === seriesFromQuery) ?? -1
        : -1;

    return {
      categorySelectedInd: defaultCategoryInd === -1 ? ALL_SP_CATEGORIES : defaultCategoryInd,
      lineSelectedInd: deafultLineInd === -1 ? ALL_SP_LINES : deafultLineInd,
      seriesSelectedInd: deafultSeriesInd === -1 ? ALL_SP_SERIES : deafultSeriesInd,
    };
  }

  getSortedCards(
    cardsToSort: TProductSpecialOffersListingCard[],
    sortBy: TFilterContextData['sortBy']
  ): TProductSpecialOffersListingCard[] {
    if (sortBy === 'desc')
      return cardsToSort.sort(
        ({ expiration: expirationA = '' }, { expiration: expirationB = '' }) =>
          new Date(expirationB).getTime() - new Date(expirationA).getTime()
      );
    return cardsToSort.sort(
      ({ expiration: expirationA = '' }, { expiration: expirationB = '' }) =>
        new Date(expirationA).getTime() - new Date(expirationB).getTime()
    );
  }

  filterCards = (selectedFilters: TFilterContextData): TProductSpecialOffersListingCard[] => {
    const { lineSelectedInd, seriesSelectedInd, categorySelectedInd, sortBy } = selectedFilters;
    let filteredCardIds: TProductSpecialOffersListingCardID[] = [];
    const allCards = this.fields?.apiData?.cards || [];

    if (lineSelectedInd === ALL_SP_LINES && categorySelectedInd === ALL_SP_CATEGORIES) return this.getSortedCards(allCards, sortBy);

    if (categorySelectedInd !== ALL_SP_CATEGORIES) filteredCardIds = this.categories[categorySelectedInd]?.cards || [];
    else {
      if (lineSelectedInd !== ALL_SP_LINES && seriesSelectedInd === ALL_SP_SERIES)
        filteredCardIds = this.lines[lineSelectedInd]?.cards || [];
      if (lineSelectedInd !== ALL_SP_LINES && seriesSelectedInd !== ALL_SP_SERIES)
        filteredCardIds = this.lines[lineSelectedInd]?.series?.[seriesSelectedInd]?.cards || [];
    }

    const filteredCards = filteredCardIds?.map((eachCardID) => this.cardsObj[eachCardID])?.filter((_) => _);
    return this.getSortedCards(filteredCards, sortBy);
  };
}
