import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import { documentService, windowService } from './window.utils';

export const adjustModalPositionBasedOnContent = (): void => {
  if (isServer()) return;

  const element = documentService()?.querySelector('.ReactModal__Content.ReactModal__Content--after-open.my-modal-dialog');
  if (!element) return;

  const windowHeight = windowService()?.innerHeight;
  const scrollHeight = element.scrollHeight;
  const diffInGap = windowHeight - scrollHeight;
  const noGap = diffInGap > 0 && diffInGap < 160;
  const thresholdHeight = windowHeight * 0.8;

  if (scrollHeight > thresholdHeight || noGap) {
    element.classList.add('top-aligned-modal');
    element.classList.remove('center-aligned-modal');
  } else {
    element.classList.add('center-aligned-modal');
    element.classList.remove('top-aligned-modal');
  }
};
