import React, { FC, useEffect, useRef } from 'react';
import { documentService } from '../../../utils/window.utils.js';
import { TDangerouslySetHtmlAndJSProps } from './types/dangerouslySetHtmlAndJS.props.js';

const DangerouslySetHtmlAndJS: FC<TDangerouslySetHtmlAndJSProps> = ({ html, ...rest }) => {
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!html || !divRef.current) {
      return;
    }

    const htmlSlot = documentService().createRange().createContextualFragment(html);
    divRef.current.innerHTML = '';
    divRef.current.appendChild(htmlSlot);
  }, [html, divRef]);

  // eslint-disable-next-line react/react-in-jsx-scope
  return <section {...rest} ref={divRef} />;
};

export default DangerouslySetHtmlAndJS;
