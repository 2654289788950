import React, { useState } from 'react';
import { Placeholder, ComponentRendering } from '@sitecore-jss/sitecore-jss-react';
import { PlmSeriesSectionComponentProps } from '../types/productLinePlmSeriesSection.types';
import ComponentDetails from './componentDetails';
import Icon from '../../@shared/icon';

const MobilePlmSeriesSection = (props: PlmSeriesSectionComponentProps) => {
  const model = props?.model;
  const isEEActive = props?.isEEActive;
  const [collapsedTabs, setCollapsedTabs] = useState(model?.items?.map(({ id }) => id) || []);

  const toggleTab = (tabId: string) => {
    if (collapsedTabs.includes(tabId)) {
      setCollapsedTabs(collapsedTabs.filter((tab) => tab !== tabId));
    } else {
      setCollapsedTabs([...collapsedTabs, tabId]);
    }
  };

  return (
    <section className="plm-series-details">
      <div className="plm-series-details__container">
        <div className="plm-series-details__header">
          {model?.title && <h2 className="plm-series-details__title">{model.title}</h2>}
          <div className={`plm-series-details__popup ${isEEActive ? 'generic-popupEE-width' : ''}`}>
            <Placeholder name={'jss-generic-popup'} rendering={props?.rendering as ComponentRendering} />
          </div>
        </div>
        <ul className="plm-series-details__tabs">
          {model?.items?.map((component, index) => {
            const isCollapsed = collapsedTabs.includes(component.id);
            return (
              <li className={`plm-series-details__tab ${!isCollapsed ? 'plm-series-details__tab--selected' : ''}`} key={component?.id}>
                <button
                  className="plm-series-details__tab-button"
                  onClick={() => {
                    toggleTab(component.id);
                  }}
                >
                  <h3 className="plm-series-details__tab-title">{component.eyebrow}</h3>
                  <Icon size={24} iconName={isCollapsed ? 'arrow-chevron-down' : 'arrow-chevron-up'} />
                </button>
                <ComponentDetails
                  baseUrl={props.baseUrl}
                  component={model?.items?.[index]}
                  className={`${isCollapsed ? 'plm-series-details__component--hidden' : ''}`}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export default MobilePlmSeriesSection;
