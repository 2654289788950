import './styles.scss';

import React, { FC } from 'react';
import { isEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ExperienceEditorComponentPlaceholder from '../@shared/experienceEditorComponentPlaceholder';
import Gallery from '../@shared/overlayCarouselPopup';
import EmptyApiDataMessage from '../@shared/emptyApiDataMessage';
import { ProductSeriesDetailMediaGalleryModel } from './models/productSeriesDetailMediaGallery.model';
import { ProductSeriesDetailMediaGalleryProps } from './types/productSeriesDetailMediaGallery.types';
import { isEmptyResponse } from '../../utils/checkEmptyResponse';
import useExperienceEditorPlaceholder from '../../hooks/useExperienceEditorPlaceholder';
import Heading from '../@shared/heading';
import UnreleasedComponent from '../@shared/unreleasedComponent';
import {
  descriptionKeySeriesMediaGallery,
  eyebrowKeySeriesMediaGallery,
  titleKeySeriesMediaGallery,
} from '../../constants/dictionary.constants';
import useShowProductComponentTitle from '../../hooks/useShowProductComponentTitle';

const ProductSeriesDetailMediaGallery: FC<ProductSeriesDetailMediaGalleryProps> = (props) => {
  const isPlaceholderShown = useExperienceEditorPlaceholder(props);
  const isFieldsEmpty = props?.fields && Object.keys(props?.fields).length === 0;
  const apiResponse = !isFieldsEmpty && isEmptyResponse(props);
  const model = new ProductSeriesDetailMediaGalleryModel(props);
  const hasSlides = (model.slides || []).length > 0;
  const isEE = !!props.sitecoreContext?.pageEditing;
  const hasHeaderValue = !!(model?.eyebrow || model?.title || model?.description);
  const dictionaryTerms = [titleKeySeriesMediaGallery, eyebrowKeySeriesMediaGallery, descriptionKeySeriesMediaGallery];
  const showHeader = useShowProductComponentTitle(hasHeaderValue, dictionaryTerms);

  // Component is unreleased
  if (model.isUnreleased) {
    return <UnreleasedComponent />;
  }

  // star  node opened directly in EE
  if (isPlaceholderShown) {
    return <ExperienceEditorComponentPlaceholder image={model.thumbnailEE} componentName={model.componentName} />;
  }

  // not opened from star node
  // data missing when navigating via from products node
  if ((!apiResponse || !hasSlides) && isEditorActive()) {
    return <EmptyApiDataMessage componentName={model.componentName} />;
  }

  // No data and not in EE
  if (!apiResponse && !isPlaceholderShown && !isEditorActive()) {
    return null;
  }

  if (isFieldsEmpty || !hasSlides) {
    return null;
  }

  return (
    <section className="media-gallery">
      <div className="media-gallery__wrapper">
        {showHeader && (
          <div className="media-gallery__content">
            <Heading
              eyebrow={model.eyebrow as string}
              title={model.title as string}
              subtitle={model.description as string}
              useDictionaryFallback
              titleDictionaryKey={titleKeySeriesMediaGallery}
              eyebrowDictionaryKey={eyebrowKeySeriesMediaGallery}
              descriptionDictionaryKey={descriptionKeySeriesMediaGallery}
            />
          </div>
        )}
        {model.slides && (
          <Gallery slides={model.slides} showNavDeck={true} showOverlayTitleAndDescription={true} isOverlay={false} isEE={isEE} />
        )}
      </div>
    </section>
  );
};

export default withSitecoreContext()(ProductSeriesDetailMediaGallery);
