import { TExternalComponentFields, TExternalComponentProps } from '../types/externalComponent.types';

export class ExternalComponentModel {
  private readonly fields: null | TExternalComponentFields;

  constructor(props: TExternalComponentProps) {
    this.fields = props?.fields || null;
  }

  get url(): string {
    return this.fields?.link?.value?.url as string;
  }

  get height(): number {
    const _height = Number.parseInt(this.fields?.height?.value || '');
    
    return Number.isNaN(_height) ? 1000  : _height;
  }

  get visibilityDelay(): number {
    const _visibilityDelay = Number.parseInt(this.fields?.visibilityDelay?.value || '');
    
    return Number.isNaN(_visibilityDelay) ? 0  : _visibilityDelay;
  }
}
