import { ComponentRendering } from '@sitecore-jss/sitecore-jss-react';
import {
  TComponentParamsGeneral,
  THeadingTag,
  TLinkField,
  TRendition,
  TSitecoreContext,
  TStringField,
} from '../../../types/sitecore.types';
import { isEmptyObject } from '../../../utils/checkEmptyObject';
import { TSocialMediaBarProps } from '../../@shared/socialMediaBar/types/socialMediaBar.types';
import {
  ProductSpecialOffersDetailHeroBannerFields,
  ProductSpecialOffersDetailHeroBannerProps,
} from '../types/ProductSpecialOffersDetailHeroBanner.types';
import { TProductImageProps } from '../../@shared/picture/types/picture.types';

export class ProductSpecialOffersDetailHeroBannerModel {
  private readonly fields?: ProductSpecialOffersDetailHeroBannerFields;
  private readonly params?: TComponentParamsGeneral;
  private readonly rendering?: ComponentRendering;
  private readonly _id?: string;
  private readonly context?: TSitecoreContext;

  constructor(props: ProductSpecialOffersDetailHeroBannerProps, sitecoreContext: TSitecoreContext) {
    this.fields = props?.fields;
    this.params = props?.params;
    this.rendering = props?.rendering || undefined;
    this._id = props.id;
    this.context = sitecoreContext;
  }

  get id(): string {
    return this._id ?? (this.dataSource as string);
  }

  get description(): string | undefined {
    return this.fields?.apiData?.shortDescription;
  }

  get eyebrow(): string | undefined {
    return this.fields?.apiData?.eyebrow;
  }

  get desktopImage(): TRendition | undefined {
    const asset = this.fields?.apiData?.assets?.[0];
    return asset?.rendition_desktop_size1900;
  }

  get mobileImage(): TRendition | undefined {
    const asset = this.fields?.apiData?.assets?.[0];
    return asset?.rendition_mobile_size1000;
  }

  get thumbnailEE(): string {
    return this.fields?.itemData?.thumbnail || '';
  }

  get componentName(): string {
    return this.rendering?.componentName || '';
  }

  get primaryLink(): TLinkField | undefined {
    const button = this.fields?.apiData?.buttonHero1;
    if (!button?.url || !button?.label) return undefined;
    return {
      value: {
        href: button.url,
        title: button.label,
        target: button.target,
      },
    };
  }

  get secondaryLink(): TLinkField | undefined {
    const button = this.fields?.apiData?.buttonHero2;
    if (!button?.url || !button?.label) return undefined;
    return {
      value: {
        href: button.url,
        title: button.label,
        target: button.target,
      },
    };
  }

  get title(): string | undefined {
    return this.fields?.apiData?.title;
  }

  get textColor(): TStringField | undefined {
    return this.fields?.['textColor']?.fields?.Key;
  }

  get dataSource(): string | undefined {
    return this.rendering?.dataSource;
  }

  get isGradientDisabled(): boolean | undefined {
    return this.params?.disableGradient === '1';
  }

  get socialMediaBarProps(): TSocialMediaBarProps | undefined {
    return isEmptyObject(this.fields?.socialMediaBar) ? undefined : this.fields?.socialMediaBar;
  }

  get isUnreleased(): boolean {
    return !!this.fields?.isComponentUnreleased;
  }

  get headingTag(): THeadingTag {
    return (this.params?.headingTag?.toLowerCase() as THeadingTag) || 'h3';
  }

  get defaultDesktopImage(): string | undefined {
    return this.context?.defaultBackgroundImageParameters?.desktopImageData?.specialOfferDetailHeroBannerCardImage;
  }

  get defaultMobileImage(): string | undefined {
    return this.context?.defaultBackgroundImageParameters?.mobileImageData?.specialOfferDetailHeroBannerCardImage;
  }

  get productImageProps(): TProductImageProps {
    return {
      mobileRendition: this.mobileImage,
      desktopRendition: this.desktopImage,
    };
  }
}
