import React, { FC } from 'react';
import { iconSize } from '../../../../constants/icon.constants';
import { TSingleCard } from '../../../../types/card.types';
import CustomExternalAnchor from '../../customExternalAnchor';
import { CustomText } from '../../customText';
import Icon from '../../icon';
import Picture from '../../picture';

const Card: FC<TSingleCard> = (props): JSX.Element => {
  const { isEE, image, mobileImage, title, link, id } = props;
  return (
    <>
      <Picture
        editorialImageProps={{
          id: id,
          desktopImage: image,
          mobileImage: mobileImage,
          desktopTransformation: 'Size500',
          mobileTransformation: 'Size300',
          cssClassImageEl: 'stories-card__bg-image sd',
        }}
      />
      <div className="stories-card__content">
        {title?.value && <CustomText tag="p" field={title} className="stories-card__title" />}
        <div className="stories-card__link-wrapper">
          {link?.value?.text && isEE ? (
            <CustomExternalAnchor field={link} className="stories-card__link" />
          ) : (
            link?.value?.text && <span className="stories-card__link">{link?.value?.text}</span>
          )}
          {link?.value?.text && <Icon iconName={'arrow-chevron-right'} size={iconSize.mbl} extraClassName="stories-card__link-icon" />}
        </div>
      </div>
    </>
  );
};

export default Card;
