import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React, { FC } from 'react';
import useEEActive from '../../../../hooks/useEEActive';
import { CustomText } from '../../../@shared/customText';
import { TCardText } from '../../types/cardColumnLayout.types';

const CardText: FC<TCardText> = (props) => {
  const isEE = useEEActive();
  const { title, subheading, textcolor } = props;

  if (!isEE && !title?.value && !subheading?.value) return null;
  return (
    <div className="card-column__content-text">
      {title && <CustomText tag="div" field={title} className={`card-column__title card-layout__textcolor--${textcolor}`} />}
      {subheading && (
        <RichText tag="div" field={subheading} className={`card-column__description card-layout__textcolor--${textcolor} rich-text`} />
      )}
    </div>
  );
};

export default CardText;
