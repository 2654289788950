export const localstorageService = () =>
  typeof localStorage !== 'undefined'
    ? localStorage
    : {
      clear: () => undefined,
      getItem: () => undefined,
      key: () => null,
      removeItem: () => undefined,
      setItem: () => undefined,
    };

export const sessionStorageService = () =>
  typeof sessionStorage !== 'undefined'
    ? sessionStorage
    : {
        clear: () => undefined,
        getItem: () => undefined,
        key: () => null,
        removeItem: () => undefined,
        setItem: () => undefined,
      };

export const windowService = () => (typeof window !== 'undefined' ? window : {});
export const documentService = () => (typeof document !== 'undefined' ? document : {});
