import { useContext } from 'react';
import { LayoutServiceData, SitecoreContextReactContext, SitecoreContextValue } from '@sitecore-jss/sitecore-jss-react';
import { WithSitecoreContextOptions } from '@sitecore-jss/sitecore-jss-react/types/enhancers/withSitecoreContext';
import { TSitecoreContext } from '../types/sitecore.types';

type TSitecoreContextType = {
  sitecoreContext: TSitecoreContext;
  updateSitecoreContext?: (value: SitecoreContextValue | LayoutServiceData) => void
}

export default function useSitecoreContext(options?: WithSitecoreContextOptions): TSitecoreContextType {
  const reactContext = useContext(SitecoreContextReactContext);
  const updatable = options?.updatable;

  return {
    sitecoreContext: reactContext.context as TSitecoreContext,
    updateSitecoreContext: updatable ? reactContext.setContext : undefined,
  };
}
