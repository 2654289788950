import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React, { FC } from 'react';
import { iconSize } from '../../../../constants/icon.constants';
import Icon from '../../../@shared/icon';
import RoutableNotEditableLink from '../../../@shared/routableNotEditableLink';
import { TSearchItem } from '../../types/search.types';
import { TSearchResultsList } from '../../types/result.types';

const SearchResultsList: FC<TSearchResultsList> = (props) => {
  const { results, t } = props;
  if (results?.length) {
    return (
      <div className="search__results__list">
        {results?.map((result: TSearchItem) => (
          <article className={'search__item'}>
            <header>
              <RoutableNotEditableLink href={result?.link} target="_blank">
                <h3 className="search__item__title">{result?.title}</h3>
              </RoutableNotEditableLink>
            </header>
            <RichText field={{ value: result?.description }} editable={false} tag="div" className="search__item__description rich-text" />
            <RichText field={{ value: result?.highlight }} editable={false} tag="div" className="search__item__snippet rich-text" />
          </article>
        ))}
      </div>
    );
  }
  return (
    <div className="search__no-results">
      <Icon iconName="action-close-circle-full" size={iconSize.xlg} extraClassName="search__no-results--icon" />
      <h3 className="search__no-results__title">{t('cnhi-noMatchesFoundTitle')}</h3>
      <p className="search__no-results__description">{t('cnhi-noMatchesFoundDescription')}</p>
    </div>
  );
};

export default SearchResultsList;
