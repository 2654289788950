import React, { FC, useContext, useEffect, useState } from 'react';
import { iconSize } from '../../../../constants/icon.constants';
import { ALL_SP_CATEGORIES, ALL_SP_LINES, ALL_SP_SERIES } from '../../../../constants/specialOffersFilters.constants';
import ctaTypes from '../../../../enums/CTA.enums';
import { documentService } from '../../../../utils/window.utils';
import DictionaryTerm from '../../../@shared/dictionaryTerm';
import Accordion from '../../../@shared/filters/components/accordion';
import Icon from '../../../@shared/icon';
import { FilterContext } from '../../fiilterContext';
import {
  TProductSpecialOffersListingCategoryFilter,
  TProductSpecialOffersListingLineFilter,
  TProductSpecialOffersListingSeriesFilter,
} from '../../types/productSpecialOffersListing.types';
import SortCTA from '../sortCTA';
import './styles.scss';

const MobileFilter: FC<{}> = () => {
  const filters = useContext(FilterContext);
  const { model, lineSelectedInd, seriesSelectedInd, categorySelectedInd, updateFilters, resetFilters } = filters;
  const [filtersCopy, setFiltersCopy] = useState({
    lineSelectedIndCopy: lineSelectedInd,
    seriesSelectedIndCopy: seriesSelectedInd,
    categorySelectedIndCopy: categorySelectedInd,
  });
  const { lineSelectedIndCopy, seriesSelectedIndCopy, categorySelectedIndCopy } = filtersCopy;
  const [shouldOpenFilterModal, setShouldOpenFilterModal] = useState(false);
  const [selectedAccordion, setSelectedAccordion] = useState<'Category' | 'Line' | 'Series' | undefined>();

  const shouldShowCTAWrapper =
    !(
      lineSelectedIndCopy === lineSelectedInd &&
      seriesSelectedIndCopy == seriesSelectedInd &&
      categorySelectedIndCopy === categorySelectedInd
    ) ||
    categorySelectedInd !== ALL_SP_CATEGORIES ||
    lineSelectedIndCopy !== ALL_SP_LINES ||
    seriesSelectedIndCopy !== ALL_SP_SERIES;

  useEffect(() => {
    setFiltersCopy({
      lineSelectedIndCopy: lineSelectedInd,
      seriesSelectedIndCopy: seriesSelectedInd,
      categorySelectedIndCopy: categorySelectedInd,
    });
  }, [filters]);

  useEffect(() => {
    const docElement = documentService();
    if (!docElement?.body) return;

    if (shouldOpenFilterModal) docElement.body.classList.add('body--with-filter-model');
    else docElement.body.classList.remove('body--with-filter-model');
  }, [shouldOpenFilterModal]);

  const categoryOptions: TProductSpecialOffersListingCategoryFilter[] = [
    { id: ALL_SP_CATEGORIES, title: <DictionaryTerm keyword="cnhi-special-offers-listing-all-categories" /> },
    ...model.categories,
  ];

  const lineOptions: TProductSpecialOffersListingLineFilter[] = [
    { id: ALL_SP_LINES, title: <DictionaryTerm keyword="cnhi-special-offers-listing-all-lines" /> },
    ...model.lines,
  ];

  const seriesOptions: TProductSpecialOffersListingSeriesFilter[] =
    lineSelectedIndCopy === ALL_SP_LINES
      ? []
      : [
          { id: ALL_SP_SERIES, title: <DictionaryTerm keyword="cnhi-special-offers-listing-all-series" /> },
          ...(model.lines?.[lineSelectedIndCopy]?.series || []),
        ];

  const handleCategoryOptionClick = (newcategorySelectedInd) => {
    setFiltersCopy({
      ...filtersCopy,
      categorySelectedIndCopy: newcategorySelectedInd === 0 ? ALL_SP_CATEGORIES : newcategorySelectedInd - 1,
      lineSelectedIndCopy: ALL_SP_LINES,
      seriesSelectedIndCopy: ALL_SP_SERIES,
    });
  };

  const handleLineOptionClick = (newLineSelectedInd) => {
    setFiltersCopy({
      lineSelectedIndCopy: newLineSelectedInd === 0 ? ALL_SP_LINES : newLineSelectedInd - 1,
      seriesSelectedIndCopy: ALL_SP_SERIES,
      categorySelectedIndCopy: ALL_SP_CATEGORIES,
    });
  };

  const handleSeriesOptionClick = (newSeriesSelectedInd) => {
    setFiltersCopy({
      ...filtersCopy,
      seriesSelectedIndCopy: newSeriesSelectedInd === 0 ? ALL_SP_SERIES : newSeriesSelectedInd - 1,
      categorySelectedIndCopy: ALL_SP_CATEGORIES,
    });
  };

  const toggleModal = () => setShouldOpenFilterModal(!shouldOpenFilterModal);

  const revertToOldFilters = () => {
    setFiltersCopy({
      lineSelectedIndCopy: lineSelectedInd,
      seriesSelectedIndCopy: seriesSelectedInd,
      categorySelectedIndCopy: categorySelectedInd,
    });
  };

  const applyNewFilters = () => {
    updateFilters({
      lineSelectedInd: lineSelectedIndCopy,
      seriesSelectedInd: seriesSelectedIndCopy,
      categorySelectedInd: categorySelectedIndCopy,
    });
  };

  const toggleAccordion = (accordionName: typeof selectedAccordion) => {
    if (accordionName === selectedAccordion) setSelectedAccordion(undefined);
    else {
      setSelectedAccordion(accordionName);
    }
  };

  return (
    <div className="special-offers-listing-filter--mbl">
      <div className="offers-filter-modal-switch" onClick={toggleModal}>
        <DictionaryTerm keyword="cnhi-special-offers-listing-filter-title" />
        <Icon iconName="set-nav-services-funnel" color="primary" size={iconSize['mbl']} />
      </div>
      {shouldOpenFilterModal && (
        <div className="offers-filter-modal">
          <div className="offers-filter-modal__header">
            <span className="offers-filter-modal__title">
              <DictionaryTerm keyword="cnhi-special-offers-listing-filter-title" />
            </span>
            <Icon
              iconName="action-close"
              color="white"
              size={iconSize['dsk']}
              onClick={() => {
                setSelectedAccordion(undefined);
                setShouldOpenFilterModal(false);
                revertToOldFilters();
              }}
            />
          </div>
          <div className="offers-filter-modal__body">
            <Accordion
              accordionTitle={<DictionaryTerm keyword="cnhi-special-offers-listing-category-filter-title" />}
              accordionOptions={categoryOptions}
              handleOptionClick={handleCategoryOptionClick}
              selectedOptionInd={categorySelectedIndCopy === ALL_SP_CATEGORIES ? 0 : categorySelectedIndCopy + 1}
              canShowAccordionContent={selectedAccordion === 'Category'}
              handleSwitchClick={() => toggleAccordion('Category')}
              isToggleIndependent={false}
            />
            <Accordion
              accordionTitle={<DictionaryTerm keyword="cnhi-special-offers-listing-line-filter-title" />}
              accordionOptions={lineOptions}
              handleOptionClick={handleLineOptionClick}
              selectedOptionInd={lineSelectedIndCopy === ALL_SP_LINES ? 0 : lineSelectedIndCopy + 1}
              canShowAccordionContent={selectedAccordion === 'Line'}
              handleSwitchClick={() => toggleAccordion('Line')}
              isToggleIndependent={false}
            />
            {String(seriesSelectedIndCopy) && lineSelectedIndCopy !== ALL_SP_LINES && (
              <Accordion
                accordionTitle={<DictionaryTerm keyword="cnhi-special-offers-listing-series-filter-title" />}
                accordionOptions={seriesOptions}
                handleOptionClick={handleSeriesOptionClick}
                selectedOptionInd={seriesSelectedIndCopy === ALL_SP_SERIES ? 0 : seriesSelectedIndCopy + 1}
                canShowAccordionContent={selectedAccordion === 'Series'}
                handleSwitchClick={() => toggleAccordion('Series')}
                isToggleIndependent={false}
              />
            )}
          </div>
          {shouldShowCTAWrapper && (
            <div className="offers-filter-modal__ctas">
              <span
                className={`${ctaTypes['baseButton']} ${ctaTypes['primaryGhost']}`}
                onClick={() => {
                  setSelectedAccordion(undefined);
                  resetFilters();
                }}
              >
                <DictionaryTerm keyword="cnhi-special-offers-listing-reset-filters" />
              </span>
              <span
                className={`${ctaTypes['baseButton']} ${ctaTypes['primaryYellow']}`}
                onClick={() => {
                  setSelectedAccordion(undefined);
                  applyNewFilters();
                  toggleModal();
                }}
              >
                <DictionaryTerm keyword="cnhi-special-offers-listing-apply-filters" />
              </span>
            </div>
          )}
        </div>
      )}
      <div className="offers-filter__sort-cta-wrapper">
        <SortCTA className="offers-filter__sort-cta" />
      </div>
    </div>
  );
};

export default MobileFilter;
