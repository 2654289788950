import React, { FC } from 'react';
import { isEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isEmptyResponse } from '../../utils/checkEmptyResponse';
import useExperienceEditorPlaceholder from '../../hooks/useExperienceEditorPlaceholder';
import ExperienceEditorComponentPlaceholder from '../@shared/experienceEditorComponentPlaceholder';
import EmptyApiDataMessage from '../@shared/emptyApiDataMessage';
import { TSeriesListingProps } from './types/seriesListing.types';
import ProductCard from './components/productCard';
import { SeriesListingModel } from './models/seriesListing.model';
import '../../styles/@shared/product-card-dashboard.scss';
import UnreleasedComponent from '../@shared/unreleasedComponent';

const ProductSeriesListing: FC<TSeriesListingProps> = (props) => {
    const model = new SeriesListingModel(props);
    const apiResponse = isEmptyResponse(props);
    const isPlaceholderShown = useExperienceEditorPlaceholder(props);

    // Component is unreleased
    if (model.isUnreleased) {
        return <UnreleasedComponent/>
    }

    // star  node opened directly in EE
    if (isPlaceholderShown) {
        return <ExperienceEditorComponentPlaceholder image={model.thumbnailEE} componentName={model.componentName}/>;
    }

    // not opened from star node
    // data missing when navigating via from products node
    if (!apiResponse && isEditorActive()) {
        return <EmptyApiDataMessage componentName={model.componentName}/>;
    }

    // No data and not in EE
    if (!apiResponse && !isPlaceholderShown && !isEditorActive()) {
        return null;
    }

    return (
        <section>
            <div className="series-listing product-card-dashboard__wrapper">
                <div className="product-card-dashboard">
                    <section className="product-card-dashboard__products">
                        {model?.items?.map((productCard) => (
                            <ProductCard {...productCard} baseUrl={model.baseUrl} key={productCard?.id}/>
                        ))}
                    </section>
                </div>
            </div>
        </section>
    );
};

export default withSitecoreContext()(ProductSeriesListing);
