import React from 'react';
import RoutableSitecoreLink from '../../../../../@shared/routableSitecoreLink';
import './styles.scss';
import { TOtherQuickLinkItemProps } from './types/otherQuickLinkItem.types';
import Icon from '../../../../../@shared/icon';
import { googleCtaClickEvent } from '../../../../../../utils/analytics';

const OtherQuickLinkItem = ({ quickLink, index, totEl }: TOtherQuickLinkItemProps) => {
  const lineClass = index === totEl - 1 ? 'find-equipment-line find-equipment-line--last' : 'find-equipment-line';
  let itemClass, contentClass;

  if (totEl === 3) {
    itemClass = 'other-quick-link-item other-quick-link-item--3';
    contentClass = 'other-quick-link-item__content other-quick-link-item__content--3';
  } else {
    itemClass = 'other-quick-link-item other-quick-link-item--2';
    contentClass = 'other-quick-link-item__content other-quick-link-item__content--2';
  }

  return (
    <>
      <li className={itemClass}>
        <RoutableSitecoreLink
          field={quickLink?.fields?.link}
          onclick={() => googleCtaClickEvent('Quick Links', quickLink?.fields?.link?.value?.href, quickLink?.fields?.link?.value?.text, '')}
        >
          <div className={contentClass}>
            <Icon iconField={quickLink?.fields?.icon} size={32} />
            <div className="other-quick-link-item__text">
              <p>{quickLink?.fields?.title?.value}</p>
            </div>
          </div>
        </RoutableSitecoreLink>
      </li>
      <li className={lineClass}></li>
    </>
  );
};

export default OtherQuickLinkItem;
