import React, { FormEvent, useEffect, useMemo, useRef, useState, FC } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { BreakpointsInPx } from '../../constants/breakpoints.constants';
import { iconSize } from '../../constants/icon.constants';
import { QueryParamsConst } from '../../constants/query-params.constants';
import { updateSearchResults } from '../../hooks/useQueryParams.effect';
import useMediaQuery from '../../hooks/useMediaQuery';
import { documentService } from '../../utils/window.utils';
import Icon from '../@shared/icon';
import UnreleasedComponent from '../@shared/unreleasedComponent';
import Pagination from '../@shared/pagination';
import Suggestions from './components/suggestions';
import { SearchApiModel } from './model/searchApiModel';
import { TSearchProps } from './types/search.types';
import './styles.scss';
import QueryTermResultCount from './components/result/resultCount';
import QueryResults from './components/result';

const SearchResults: FC<WithTranslation & TSearchProps> = (props) => {
  const { sitecoreContext, t } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const searchQueryString = location?.search;
  const searchParams = useMemo(() => new URLSearchParams(location?.search), [searchQueryString]);
  const page = searchParams.get(QueryParamsConst.PAGE) || '1';
  const query = searchParams.get(QueryParamsConst.QUERY) || '';
  const perPage = searchParams.get(QueryParamsConst.PER_PAGE) || '10';
  const filters = searchParams.get(QueryParamsConst.FILTERS) || '';
  const [inputValue, setInputValue] = useState(query);
  const isDesktop = useMediaQuery(`(min-width: ${BreakpointsInPx['tbl-p']})`);
  const isMobile = useMediaQuery(`(max-width: ${BreakpointsInPx['mbl']})`);
  const [, setSuggestionQuery] = useState('');
  const [isSuggestionsComponentVisible, setIsSuggestionsComponentVisible] = useState(false);
  const SUGGESTIONS_MIN_CHAR_LIMIT = 3;
  const SEPARATOR_CHAR = ',';
  const model = new SearchApiModel(props);
  const formRef = useRef<HTMLFormElement>(null);
  const pages = Math.ceil(model.counter / parseInt(perPage, 10));
  const activeFilters = filters.length ? filters.split(SEPARATOR_CHAR) : [];

  const onSubmit: (e: FormEvent<HTMLFormElement>) => void = (e: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if (!inputValue) return;
    updateSearchResults(
      navigate,
      {
        [QueryParamsConst.QUERY]: inputValue || '',
        [QueryParamsConst.FILTERS]: '',
        [QueryParamsConst.PAGE]: '1',
        [QueryParamsConst.PER_PAGE]: '10',
      },
      model.contextItemUrl
    );
  };

  const onPaginationChange = (selectedPage: number): void =>
    updateSearchResults(navigate, { [QueryParamsConst.PAGE]: selectedPage.toString() }, model.contextItemUrl);

  const onFiltersChange = (filterValue: string[]): void => {
    updateSearchResults(
      navigate,
      {
        [QueryParamsConst.FILTERS]: filterValue.join(SEPARATOR_CHAR),
        [QueryParamsConst.PAGE]: '1',
      },
      model.contextItemUrl
    );
  };

  const updateSuggestionsQuery = (event): void => {
    setSuggestionQuery(event?.target?.value || '');
  };
  const onKeyUp: (e: React.KeyboardEvent<HTMLInputElement>) => void = (e) => {
    if (e?.key === 'Enter') {
      formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
      return;
    }
    updateSuggestionsQuery(e);
  };

  const onUpdateQueryFromSuggestions = (q: string): void => {
    if (!inputValue) {
      return;
    }
    setInputValue(q);
    setIsSuggestionsComponentVisible(false);
    updateSearchResults(
      navigate,
      {
        [QueryParamsConst.QUERY]: q,
        [QueryParamsConst.FILTERS]: '',
        [QueryParamsConst.PAGE]: '1',
      },
      model.contextItemUrl
    );
  };

  const handleClickOutside = (event): void => {
    if (formRef.current?.contains(event?.target)) {
      return;
    }
    setIsSuggestionsComponentVisible(false);
  };

  useEffect(() => {
    documentService().addEventListener('mouseup', handleClickOutside);
    return (): void => {
      documentService().removeEventListener('mouseup', handleClickOutside);
    };
  }, []);

  const [isCollapsed, setIsCollapsed] = useState(false);
  const toggleCollapsed = (): void => {
    setIsCollapsed(!isCollapsed);
  };

  // Component is unreleased
  if (model.isUnreleased) {
    return <UnreleasedComponent />;
  }

  return (
    <div className="search">
      <div className="search__bar">
        <h2 className="search__title">{t('cnhi-searchTitle')}</h2>
        <div className="search__subtitle">
          <QueryTermResultCount query={query} t={t} counter={model.counter} />
        </div>
        <form onSubmit={onSubmit} ref={formRef}>
          <div className="search__wrapper">
            <div className="search__input-row">
              <div className="search__input-control">
                <Icon iconName="action-search" size={iconSize.dsk} extraClassName="search__input--search-icon" />
                {isSuggestionsComponentVisible && (
                  <Suggestions
                    language={sitecoreContext?.language || ''}
                    contextId={sitecoreContext?.itemId || ''}
                    className="search-suggestions--search-page-input"
                    suggestionQuery={inputValue || ''}
                    minimalLength={SUGGESTIONS_MIN_CHAR_LIMIT}
                    onUpdateQuery={onUpdateQueryFromSuggestions}
                  />
                )}
                <div className="search__input-control-container">
                  {inputValue && <label className="search__input-control-container-label">{t('cnhi-searchInputLabel')}</label>}
                  <input
                    name="Search"
                    placeholder={t('cnhi-searchInputPlaceholder')}
                    type="text"
                    className="search__input"
                    value={inputValue}
                    onKeyUp={onKeyUp}
                    onChange={(e): void => setInputValue(e?.target?.value)}
                    onFocus={(): void => setIsSuggestionsComponentVisible(true)}
                    autoComplete="off"
                  />
                </div>
              </div>
              <button type="submit" className={'btn-primary-yellow'} disabled={!inputValue}>
                {isMobile ? <Icon iconName={'action-search'} size={iconSize.xlg} /> : t('cnhi-searchCTA')}
              </button>
            </div>
          </div>
        </form>
      </div>

      <QueryResults
        query={query}
        t={t}
        isDesktop={isDesktop}
        isCollapsed={isCollapsed}
        filters={model.filters}
        activeFilters={activeFilters}
        onFiltersChange={onFiltersChange}
        toggleCollapsed={toggleCollapsed}
        results={model.results}
      />

      <Pagination currentPage={parseInt(page, 10)} pages={pages} goToPage={onPaginationChange} />
    </div>
  );
};

export default withTranslation()(withSitecoreContext()(SearchResults));
