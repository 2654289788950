import { isEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect, useRef, useState } from 'react';
import useExperienceEditorPlaceholder from '../../hooks/useExperienceEditorPlaceholder';
import useOnScreen from '../../hooks/useOnScreen';
import { isEmptyResponse } from '../../utils/checkEmptyResponse';
import { documentService, windowService } from '../../utils/window.utils';
import { getYoutubeVideoId } from '../../utils/youtubeUtils';
import { downloadImage } from '../../utils/imageDownload.utils';
import CustomCarouselDots from '../@shared/customCarouselDots';
import EmptyApiDataMessage from '../@shared/emptyApiDataMessage';
import ExperienceEditorComponentPlaceholder from '../@shared/experienceEditorComponentPlaceholder';
import UnreleasedComponent from '../@shared/unreleasedComponent';
import Carousel from './components/carousel';
import CarouselForEE from './components/carouselForEE';
import { HeroCarouselModel } from './models/heroCarousel.model';
import { TCarouselSettings, TFullScreenMediaGalleryCarouselSettings } from './types/herocarousel.types';

import './style.scss';

const appendDots = ({ carouselSlide, currentSlide }) => () => {
  return <CustomCarouselDots cardsCount={carouselSlide.length} activeCard={currentSlide + 1} />;
};

const ProductSeriesDetailFullScreenMediaGallery = (props) => {
  const isFieldsEmpty = props?.fields && Object.keys(props?.fields).length === 0;
  const model = new HeroCarouselModel(props);
  const apiResponse = isEmptyResponse(props);
  const isPlaceholderShown = useExperienceEditorPlaceholder(props);

  useEffect(() => {
    function resize() {
      const vh = windowService().innerHeight;
      documentService().documentElement.style.setProperty('--vh-100', `${vh}px`);
    }

    resize();
    windowService().addEventListener('resize', resize);
    return () => {
      windowService().removeEventListener('resize', resize);
    };
  }, []);

  const hasMultipleSlides = model?.carouselSlide?.length > 1;

  const [currentSlide, _setCurrentSlide] = useState(0);

  const slidePos = model?.carouselSlide[currentSlide];

  const carouselRef = useRef<any>();

  const onScreen = useOnScreen(carouselRef?.current?.innerSlider?.list);

  useEffect(() => {
    if (carouselRef?.current) {
      carouselRef.current?.slickGoTo?.(0, true);
    }
  }, [carouselRef, model.contextItemUrl]);

  useEffect(() => {
    if (!carouselRef?.current) return;
    if (onScreen) {
      carouselRef.current?.slickPlay?.();
    } else {
      carouselRef.current?.slickPause?.();
    }
  }, [onScreen, carouselRef]);

  // Component is unreleased
  if (model.isUnreleased) {
    return <UnreleasedComponent />;
  }

  // star  node opened directly in EE
  if (isPlaceholderShown) {
    return <ExperienceEditorComponentPlaceholder image={model.thumbnailEE} componentName={model.componentName} />;
  }

  // not opened from star node
  // data missing when navigating via from products node
  if (!apiResponse && isEditorActive()) {
    return <EmptyApiDataMessage componentName={model.componentName} />;
  }

  // No data and not in EE
  if (!apiResponse && !isPlaceholderShown && !isEditorActive()) {
    return null;
  }

  if (isFieldsEmpty) {
    return null;
  }

  const findLoadedImgSrc = (): string | undefined => {
    const sliderDOM = carouselRef.current?.innerSlider?.list;
    const activeSlickImgDOM: HTMLImageElement | null = (sliderDOM as HTMLDivElement).querySelector('.slick-active img');
    return activeSlickImgDOM?.currentSrc;
  };

  const MediaDownload = () => {
    const url = slidePos?.role === 'video' ? slidePos?.full : findLoadedImgSrc();
    const title = slidePos?.title || '';
    if (!url) return;
    downloadImage(url, props?.sitecoreContext, title);
  };
  const isYouTubeUrl = getYoutubeVideoId(slidePos?.full || '');
  const settings: TCarouselSettings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    initialSlide: 0,
    afterChange: (index): void => {
      _setCurrentSlide(index);
    },
    // eslint-disable-next-line
    appendDots: appendDots({ carouselSlide: model.carouselSlide || [], currentSlide }),
  };

  const settingsFullScreenMediaGallery: TFullScreenMediaGalleryCarouselSettings = {
    ...settings,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const carousel = props?.sitecoreContext?.pageEditing ? (
    <CarouselForEE ref={carouselRef} settings={settings} carouselSlide={model.carouselSlide} />
  ) : (
    <Carousel
      isYouTubeUrl={isYouTubeUrl}
      MediaDownload={MediaDownload}
      model={model}
      currentSlide={currentSlide}
      settingsFullScreenMediaGallery={settingsFullScreenMediaGallery}
      hasMultipleSlides={hasMultipleSlides}
      ref={carouselRef}
    />
  );

  return <section className={'fullscreen'}>{carousel}</section>;
};

export default withSitecoreContext()(ProductSeriesDetailFullScreenMediaGallery);
