import React, { FunctionComponent, useEffect, useRef } from 'react';
import Icon from '../../@shared/icon';

interface IHeroCarouselArrowProps {
  direction: 'previous' | 'next' | 'download';
  onClick: () => void;
  extraClassName?: string;
  animateClassName?: string;
  animateTriggerValue?: number | null;
}

const HeroCarouselArrow: FunctionComponent<IHeroCarouselArrowProps> = ({
  direction,
  onClick,
  extraClassName = '',
  animateClassName = '',
  animateTriggerValue = null,
}) => {
  const buttonRef = useRef(null);

  let icon;
  switch (direction) {
    case 'previous':
      icon = 'arrow-chevron-left';
      break;
    case 'next':
      icon = 'arrow-chevron-right';
      break;
    default:
      icon = 'action-download';
      break;
  }

  useEffect(() => {
    const button = buttonRef.current as HTMLElement | null;
    if (!button) return;
    if (!animateTriggerValue && animateClassName) {
      if (button.classList.contains(animateClassName)) {
        button.classList.remove(animateClassName);
      }
    }
    if (animateClassName && animateTriggerValue && buttonRef?.current) {
      if (button.classList.contains(animateClassName)) {
        button.classList.remove(animateClassName);
        setTimeout(() => button.classList.add(animateClassName), 0);
      } else {
        button.classList.add(animateClassName);
      }
    }
  }, [animateTriggerValue]); // eslint-disable-line

  return (
    <button
      ref={buttonRef}
      className={`controls__arrow controls__arrow--${direction} ${extraClassName}`}
      aria-label={direction + 'slide / item'}
      onClick={onClick}
    >
      <Icon iconName={icon} color={'white'} size={24} />
    </button>
  );
};

export default HeroCarouselArrow;
