import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { CarouselDirectionEnum, CarouselNavPositionEnum } from '../../../enums/carousel.enums';
import useOnScreen from '../../../hooks/useOnScreen';
import CarouselArrow from '../../@shared/carouselArrow';
import CarouselTransparentNavSection from '../../@shared/carouselTransparentNavSection';
import CustomCarouselDots from '../../@shared/customCarouselDots';
import { TCarousel } from '../types/herocarousel.types';
import HeroCarouselArrow from './heroCarouselArrow';
import HeroCarouselSlide from './heroCarouselSlide';

const Carousel = (props: TCarousel, ref) => {
  const { isYouTubeUrl, MediaDownload, model, currentSlide, settingsFullScreenMediaGallery, hasMultipleSlides } = props;
  const mediaDownload =
    !isYouTubeUrl && model?.carouselSlide?.length > 0 ? <HeroCarouselArrow direction={'download'} onClick={MediaDownload} /> : null;
  const fullscreenCarouselRef = useRef<HTMLDivElement | null>(null);
  const onScreen = useOnScreen(fullscreenCarouselRef?.current, 0.01, '10px');
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [videoPaused, setVideoPaused] = useState(false);

  useEffect(() => {
    setVideoPlaying(false);
  }, [currentSlide]);

  const goToPreviousSlide = (): void => {
    const prevSlideNo = currentSlide === 0 ? model.carouselSlide?.length - 1 : currentSlide - 1;
    ref.current?.slickGoTo(prevSlideNo);
    setVideoPlaying(false);
    setVideoPaused(false);
  };

  const goToNextSlide = (): void => {
    const nextSlideNo = currentSlide === model.carouselSlide?.length - 1 ? 0 : currentSlide + 1;
    ref.current?.slickGoTo(nextSlideNo);
    setVideoPlaying(false);
    setVideoPaused(false);
  };

  const playCallback = useCallback(() => {
    setVideoPlaying(true);
    setVideoPaused(false);
    ref?.current?.slickPause?.();
  }, [setVideoPlaying, setVideoPaused]);

  const pauseCallback = useCallback(() => {
    setVideoPlaying(false);
    setVideoPaused(true);
    ref?.current?.slickPlay?.();
  }, [setVideoPlaying, setVideoPaused]);

  const endCallback = useCallback(() => {
    setVideoPlaying(false);
    setVideoPaused(false);
    ref?.current?.slickPlay?.();
  }, [setVideoPlaying, setVideoPaused]);

  useEffect(() => {
    if (onScreen) {
      ref?.current?.slickPlay?.();
    } else {
      ref?.current?.slickPause?.();
    }
  }, [onScreen, ref]);

  return (
    <Fragment>
      {model ? (
        <div className="fullscreen__carousel" ref={fullscreenCarouselRef}>
          <Slider {...settingsFullScreenMediaGallery} ref={ref}>
            {model?.carouselSlide?.map((itemFields, index) => (
              <div className="slide" key={index}>
                <HeroCarouselSlide
                  key={'Slide_' + index}
                  slideInfo={{ currentSlide, index }}
                  fields={itemFields}
                  videoPlaying={videoPlaying}
                  videoPaused={videoPaused}
                  playCallback={playCallback}
                  pauseCallback={pauseCallback}
                  endCallback={endCallback}
                  activeCard={index === currentSlide}
                  index={index}
                />
              </div>
            ))}
          </Slider>
          {mediaDownload}
          {hasMultipleSlides && (
            <>
              <CarouselArrow
                direction={CarouselDirectionEnum.PREVIOUS}
                cssClass={`
                fullscreen__carousel__animate-icons
                ${videoPlaying && 'fullscreen__carousel__animate-icons--animate-fade'}
                ${videoPaused && 'fullscreen__carousel__animate-icons--show'}
              `}
                onClick={goToPreviousSlide}
              />

              <CarouselTransparentNavSection position={CarouselNavPositionEnum.LEFT} handleTouchStart={goToPreviousSlide} />
              <div className={`controls ${(videoPlaying || videoPaused) && 'fullscreen__carousel__animate-icons--hide'}`}>
                <CustomCarouselDots cardsCount={model.carouselSlide?.length} activeCard={currentSlide + 1} />
              </div>
              <CarouselTransparentNavSection position={CarouselNavPositionEnum.RIGHT} handleTouchStart={goToNextSlide} />
              <CarouselArrow
                direction={CarouselDirectionEnum.NEXT}
                cssClass={`
                fullscreen__carousel__animate-icons
                ${videoPlaying && 'fullscreen__carousel__animate-icons--animate-fade'}
                ${videoPaused && 'fullscreen__carousel__animate-icons--show'}
              `}
                onClick={goToNextSlide}
              />
            </>
          )}
        </div>
      ) : (
        ''
      )}
    </Fragment>
  );
};

export default React.forwardRef(Carousel);
