import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { TSeoTagsProps } from '../types/seoTags.types';

const OgMetaTags: FC<TSeoTagsProps> = ({ model }) => (
  <Helmet>
    {model.ogTitle && <meta property="og:title" content={model.ogTitle} />}
    {model.ogSiteName && <meta property="og:site_name" content={model.ogSiteName} />}
    {model.ogUrl && <meta property="og:url" content={model.ogUrl} />}
    {model.ogImage && <meta property="og:image" content={model.ogImage} />}
    {model.ogType && <meta property="og:type" content={model.ogType} />}
    {model.ogDescription && <meta property="og:description" content={model.ogDescription} />}
    {model.ogLocale && <meta property="og:locale" content={model.ogLocale} />}
    {model.ogLocaleAlternate.map((lang) => (
      <>{!!lang && <meta key={lang} property="og:locale:alternate" content={lang} />}</>
    ))}

    {model.ogPriceAmount && <meta property="og:price:amount" content={model.ogPriceAmount} />}
    {model.ogPriceCurrency && <meta property="og:price:currency" content={model.ogPriceCurrency} />}
  </Helmet>
);

export default OgMetaTags;
