import React, { FC } from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { CustomText } from '../customText';
import { TextComponentDeciderProps } from './textComponentDecider.types';
import DictionaryTerm from '../dictionaryTerm';

const TextComponentDecider: FC<TextComponentDeciderProps> = (props) => {
  const { type, ...restOfProps } = props;

  if (type === 'CustomText') {
    return <CustomText {...(restOfProps as any)} />;
  } else if (type === 'RichText') {
    return <RichText {...(restOfProps as any)} />;
  } else if (type === 'DictionaryTerm') {
    if (props?.dictionaryKey) {
      return (
        <DictionaryTerm
          customTextClass={props?.className as string}
          keyword={props.dictionaryKey || ''}
          isCustomText={true}
          customTextTag={props.tag}
        />
      );
    }
    return null;
  }
  return <Text {...restOfProps} />;
};

export default TextComponentDecider;
