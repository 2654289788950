import React, { FC } from 'react';

import { isEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isEmptyResponse } from '../../utils/checkEmptyResponse';
import useExperienceEditorPlaceholder from '../../hooks/useExperienceEditorPlaceholder';
import ExperienceEditorComponentPlaceholder from '../@shared/experienceEditorComponentPlaceholder';
import EmptyApiDataMessage from '../@shared/emptyApiDataMessage';
import OverviewText from './components/overviewText';
import OverviewCarousel from './components/overviewCarousel';
import { TContentHubSeriesOverviewProps } from './types/contentHubSeriesPlmOverview.type';
import './styles.scss';
import UnreleasedComponent from '../@shared/unreleasedComponent';

const ProductSeriesDetailPLMOverview: FC<TContentHubSeriesOverviewProps> = (props) => {
    const apiResponse = isEmptyResponse(props);
    const isPlaceholderShown = useExperienceEditorPlaceholder(props);
    const isEE = !!props.sitecoreContext?.pageEditing;

    // Component is unreleased
    if (props?.fields?.isComponentUnreleased) {
        return <UnreleasedComponent/>
    }

    // star  node opened directly in EE
    if (isPlaceholderShown) {
        return (
            <ExperienceEditorComponentPlaceholder image={props?.fields?.itemData?.thumbnail}
                                                  componentName={props?.rendering?.componentName}/>
        );
    }

    // not opened from star node
    // data missing when navigating via from products node
    if (!apiResponse && isEditorActive()) {
        return <EmptyApiDataMessage componentName={props?.rendering?.componentName}/>;
    }

    // No data and not in EE
    if (!apiResponse && !isPlaceholderShown && !isEditorActive()) {
        return null;
    }

    return (
        <section>
            <div className="plm-series-detail-overview">
                <div className="plm-series-detail-overview__container">
                    <OverviewCarousel {...props} />
                    <OverviewText {...props} isEE={isEE}/>
                </div>
            </div>
        </section>
    );
};

export default withSitecoreContext()(ProductSeriesDetailPLMOverview);
