import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React, { FC } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { appendToUrlPath } from '../../../../utils/link.utils';
import ctaTypes from '../../../../enums/CTA.enums';
import RoutableNotEditableLink from '../../../@shared/routableNotEditableLink';
import CTA from '../../../@shared/CTA';
import Picture from '../../../@shared/picture';
import { TProductCard } from '../../types/seriesListing.types';

import '../../../../styles/@shared/product-card.scss';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { BreakpointsInPx } from '../../../../constants/breakpoints.constants';

const ProductCard: FC<WithTranslation & TProductCard> = (props) => {
  const { t } = props;
  const { titleUrl, title, eyebrow, summarySpecs, thumbnail, baseUrl, assets, tagging } = props;
  const isMobile = useMediaQuery(`(max-width:${BreakpointsInPx['tbl-p']})`);

  return (
    <article className={`product-card__wrapper ${encodeURI(eyebrow || '')}`}>
      <RoutableNotEditableLink
        className="product-card"
        href={appendToUrlPath(baseUrl || '', titleUrl || '')}
        onClick={(e) => !titleUrl && e.preventDefault()}
      >
        <div className={`product-card__image-dots-wrapper ${!thumbnail ? 'product-card__image-dots-wrapper--no-dots' : ''}`}>
          <div className="product-card__image-wrapper">
            <Picture
              productImageProps={{
                mobileRendition: assets?.[0]?.rendition_mobile_size200,
                desktopRendition: assets?.[0]?.rendition_desktop_size500,
                imageClassName: 'product-card__image',
                alt: assets?.[0]?.alt,
              }}
            />
            {!isMobile && tagging && (
              <div className="product-card__price-tag">
                {tagging}
              </div>
            )}
          </div>
        </div>
        <div className="product-card__content">
          <div className="product-card__content--title-summary">
            {isMobile && tagging && (
              <div className="product-card__price-tag">
                {tagging}
              </div>
            )}
            {title && <RichText className="product-card__title rich-text" tag="p" field={{ value: title }} editable={false} />}
            {summarySpecs && summarySpecs?.length > 0 && (
              <RichText
                tag="div"
                field={{ value: summarySpecs[0].value }}
                editable={false}
                className="product-card__summary-specs-value rich-text"
              />
            )}
          </div>
          {titleUrl && (
            <CTA type={ctaTypes.primaryYellow} href={appendToUrlPath(baseUrl || '', titleUrl || '')} extraClassName="product-card__button">
              {t('cnhi-viewProductPage')}
            </CTA>
          )}
        </div>
      </RoutableNotEditableLink>
    </article>
  );
};

export default withTranslation()(ProductCard);
