import React, { FC } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import Icon from '../../../@shared/icon';
import { TFilterControlProps } from '../../types/filterControl.types';
import './styles.scss';

const FilterControl: FC<WithTranslation & TFilterControlProps> = ({ filter, isActive, onChange, t }) => (
  <li className="filter-control" key={`filter-item-${filter.filterLabel}`}>
    <input onChange={onChange} type="checkbox" checked={isActive} value={filter.value} id={`filter-control-${filter.value}`} />
    <label htmlFor={`filter-control-${filter.value}`} className="filter-control__align">
      <span className="filter-control__checkbox">
        <Icon
          extraClassName="filter-control__checkbox-icon"
          iconName={!isActive ? 'action-checkbox-default' : 'action-checkbox-checked'}
          size={24}
        />
      </span>
      {t(filter.filterLabel)} ({filter.filterCount})
    </label>
  </li>
);

export default withTranslation()(FilterControl);
