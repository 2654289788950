export function getYoutubeVideoId(link: string) {
  if (!link) return;
  const trimmedLink = link.trim();
  let videoId = '';
  if (trimmedLink.includes('youtube')) {
    if (trimmedLink.includes('watch')) {
      videoId = trimmedLink.split('v=')[1];
      const ampPos = videoId.indexOf('&');
      if (ampPos !== -1) {
        videoId = videoId.substring(0, ampPos);
      }
    } else if (trimmedLink.includes('embed')) {
      videoId = trimmedLink.split('embed/')[1];
      const quesPos = videoId.indexOf('?');
      if (quesPos !== -1) {
        videoId = videoId.substring(0, quesPos);
      }
    }
  } else if (trimmedLink.includes('youtu.be')) {
    videoId = trimmedLink.split('youtu.be/')[1];
  }
  if (videoId) {
    return videoId;
  }
}

const ThumbnailResolutionOptions = ['maxresdefault', 'hqdefault', 'mqdefault', 'sddefault', 'default'] as const;

type TThumbnailResolution = typeof ThumbnailResolutionOptions[number];

export function getYoutubeThumbNail(videoId: string, resolution: TThumbnailResolution = 'maxresdefault') {
  return `https://img.youtube.com/vi/${videoId}/${resolution}.jpg`;
}

type TThumbnailprops = {
  showThumbnail: boolean;
  thumbnailImgSrc: string | null;
};

export function getThumbnailProps(link: string, resolution?: TThumbnailResolution): TThumbnailprops {
  const videoID = getYoutubeVideoId(link);
  if (!videoID) {
    return { showThumbnail: false, thumbnailImgSrc: null };
  }
  return { showThumbnail: true, thumbnailImgSrc: getYoutubeThumbNail(videoID, resolution) };
}

function checkAndhandleSrcSet(imgElement: HTMLImageElement, currentres, replaceres) {
  if (imgElement.parentElement?.tagName === 'PICTURE') {
    const pictureChildrens = Array.from(imgElement.parentElement.children);
    for (const element of pictureChildrens) {
      if (element.tagName === 'SOURCE' && (element as HTMLSourceElement).srcset.indexOf(currentres) > 0) {
        (element as HTMLSourceElement).srcset = (element as HTMLSourceElement).srcset.replace(currentres, replaceres);
      }
    }
  }
}

export function handleThumbnailImage(event, isVideo = false, imgElement: HTMLImageElement | null = null): void {
  if (!isVideo) return;

  const img = imgElement || event?.target;
  const imgUrl = img.src;

  if (img.naturalWidth === 120 && img.naturalHeight === 90) {
    for (let i = 0, len = ThumbnailResolutionOptions.length - 1; i < len; i++) {
      if (img.currentSrc.indexOf(ThumbnailResolutionOptions[i]) > 0) {
        img.src = imgUrl.replace(ThumbnailResolutionOptions[i], ThumbnailResolutionOptions[i + 1]);
        checkAndhandleSrcSet(img, ThumbnailResolutionOptions[i], ThumbnailResolutionOptions[i + 1]);
        break;
      }
    }
  }
}
