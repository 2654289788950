import React, { FC } from 'react';
import { iconSize } from '../../../../constants/icon.constants';
import ctaTypes from '../../../../enums/CTA.enums';
import Icon from '../../icon';

const IconInCTA: FC<any> = (props) => {
  const { type, iconName } = props;
  return (
    <div className="cta-arrow">
      <Icon
        iconName={iconName || 'arrow-chevron-right'}
        color={type === ctaTypes.secondaryBlue ? 'primary' : 'white'}
        size={iconSize['dsk']}
      />
    </div>
  );
};

export default IconInCTA;
