import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import breakpoints from '../../../constants/breakpoints.constants';
import useEEActive from '../../../hooks/useEEActive';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { ModalProps } from '../../../types/modalDialog.types';
import { adjustModalPositionBasedOnContent } from '../../../utils/modal.utils';
import { documentService, windowService } from '../../../utils/window.utils';
import CloseButton from './components/closeButton';
import './styles.scss';

const ModalDialog = ({
  isOpen = false,
  close,
  children,
  shouldCloseOnOverlayClick = true,
  className,
  overlayClassName = className,
  hasMaxHeightRestricted = false,
  onAfterOpen = undefined,
  hideCloseButton = false,
  skipModalPositionAdjustment = false,
}: ModalProps): JSX.Element => {
  const [contentEleNode, setContentEleNode] = useState<HTMLDivElement | null>(null);
  const isEEActiveOrPreview = useEEActive(true);
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints['tbl-p']}px)`);

  const adjustToToolbarHeight = (element: HTMLIFrameElement): void => {
    const height = element.clientHeight;
    if (!height) return;
    documentService()?.documentElement?.style?.setProperty('--ee-toolbar-offset', `${height}px`);
  };

  useEffect(() => {
    const resize = (): void => {
      adjustModalPositionBasedOnContent();
    };
    windowService().addEventListener('resize', resize);
    return (): void => {
      windowService().removeEventListener('resize', resize);
    };
  }, []);

  useEffect(() => {
    if (isOpen && isEEActiveOrPreview && document) {
      const EEToolbar = documentService()?.getElementById('scWebEditRibbon') as HTMLIFrameElement;
      if (!EEToolbar) return;

      if (!('MutationObserver' in window)) return;

      adjustToToolbarHeight(EEToolbar);

      const mutationCallback = (mutationsList: MutationRecord[]): void => {
        for (const mutation of mutationsList) {
          if ((mutation?.target as HTMLIFrameElement)?.id !== 'scWebEditRibbon') return;
          adjustToToolbarHeight(mutation?.target as HTMLIFrameElement);
        }
      };

      const observer = new MutationObserver(mutationCallback);

      const config = { attributes: true, childList: false, subtree: false };

      observer.observe(EEToolbar, config);

      return (): void => {
        observer.disconnect();
      };
    }
  }, [isOpen, isEEActiveOrPreview]); // eslint-disable-line

  useEffect(() => {
    const body = documentService()?.body;
    const stickyTopWrapper = documentService()?.getElementById?.('sticky-top-wrapper');
    const scrollbarWidth = windowService()?.innerWidth - documentService()?.documentElement?.clientWidth;

    if (!body) return;

    if (isOpen) {
      body.style.marginRight = `${scrollbarWidth}px`;
      body.style.overflow = 'hidden';
      if (stickyTopWrapper) {
        stickyTopWrapper.style.marginRight = `${scrollbarWidth}px`;
      }
    }

    return () => {
      body.style.overflow = '';
      body.style.marginRight = '';
      if (stickyTopWrapper) {
        stickyTopWrapper.style.marginRight = '';
      }
    };
  }, [isOpen]);

  useEffect(() => {
    if (!contentEleNode || !isOpen || skipModalPositionAdjustment) return;
    if (!('MutationObserver' in window)) return;
    let modalHeight = contentEleNode?.getBoundingClientRect()?.height || 0;
    const observer = new MutationObserver(() => {
      const newModalHeight = contentEleNode?.getBoundingClientRect()?.height;
      if (newModalHeight !== modalHeight) {
        modalHeight = newModalHeight;
        adjustModalPositionBasedOnContent();
      }
    });
    const config = { attributes: false, childList: true, subtree: true };
    observer.observe(contentEleNode, config);
    return (): void => {
      observer?.disconnect();
    };
  }, [contentEleNode, isOpen, skipModalPositionAdjustment]);

  const closeModal = useCallback((): void => {
    close && close(false);
  }, [close]);

  const onAfterOpenCallback = useCallback((): void => {
    !skipModalPositionAdjustment && adjustModalPositionBasedOnContent();
    if (onAfterOpen) {
      onAfterOpen();
    }
  }, [adjustModalPositionBasedOnContent, onAfterOpen]);

  const overlayElementCallback = useCallback(
    (
      props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLDivElement> & React.HTMLAttributes<HTMLDivElement>,
      contentElement: JSX.Element
    ): JSX.Element => (
      <div {...props}>
        {!hideCloseButton ? <CloseButton closeModal={closeModal} isEEActiveOrPreview={isEEActiveOrPreview} isDesktop={isDesktop} /> : null}
        <>{contentElement}</>
      </div>
    ),
    [closeModal, isEEActiveOrPreview, isDesktop, hideCloseButton]
  );

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={onAfterOpenCallback}
      parentSelector={(): void => documentService()?.querySelector?.('#root')}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      className={`my-modal-dialog ${className} ${hasMaxHeightRestricted ? 'my-modal-dialog--has-max-height' : ''}`}
      overlayClassName={`my-modal-dialog__overlay ${overlayClassName} ${isEEActiveOrPreview ? 'my-modal-dialog__overlay--ee-active' : ''} ${
        hasMaxHeightRestricted ? 'my-modal-dialog__overlay--has-max-height' : ''
      }`}
      overlayElement={overlayElementCallback}
      appElement={typeof document !== 'undefined' ? documentService()?.querySelector?.('#root') : null}
      contentRef={setContentEleNode}
    >
      {children}
    </Modal>
  );
};
export default ModalDialog;
