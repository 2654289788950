import React, { FC, useState } from 'react';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { TPromoBannerProps } from './types/promoBanner.types';
import { PromoBannerModel } from './models/promoBanner.model';
import CTA from '../@shared/CTA';
import './styles.scss';
import ctaTypes from '../../enums/CTA.enums';
import Icon from '../@shared/icon';
import Picture from '../@shared/picture';
import UnreleasedComponent from '../@shared/unreleasedComponent';
import AnalyticsVisibleEvent from '../@shared/analyticsVisibleEvent';
import { googleUpdatesClickEvent } from '../../utils/analytics';

const PromoBanner: FC<TPromoBannerProps> = (props: TPromoBannerProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const closePromoBanner = () => {
    setIsOpen(false);
  };
  const promoBannerModel = new PromoBannerModel(props);

  // Component is unreleased
  if (promoBannerModel.isUnreleased) {
    return <UnreleasedComponent />;
  }

  return (
    <AnalyticsVisibleEvent event={'promo_banner_visible'} title={promoBannerModel?.heading?.value} isEditorial={true}>
      <section className={`promo-banner__container ${!isOpen ? 'promo-banner__container--closed' : ''}`}>
        <button className="promo-banner__close-button" onClick={closePromoBanner}>
          <Icon iconName="action-close" size={24} color="white" />
        </button>
        <div className="promo-banner">
          <div className="promo-banner__image-wrapper">
            <Picture
              editorialImageProps={{
                id: props.id,
                desktopImage: promoBannerModel.image,
                mobileImage: promoBannerModel.image,
                desktopTransformation: 'Size300',
                mobileTransformation: 'Size500',
                cssClassImageEl: 'promo-banner__image',
              }}
            />
          </div>
          <div className="promo-banner__content">
            <Text tag={promoBannerModel.headingTag} className="promo-banner__heading" field={promoBannerModel.heading} />
            <Text tag="p" className="promo-banner__text" field={promoBannerModel.bodyText} />
            <CTA
              type={ctaTypes.secondaryWhite}
              field={promoBannerModel.link}
              extraClassName="promo-banner__cta"
              onClick={() => {
                googleUpdatesClickEvent(
                  'promo_banner_click',
                  promoBannerModel?.heading?.value,
                  promoBannerModel?.link?.value?.href,
                  promoBannerModel?.link?.value?.text
                );
              }}
            />
          </div>
        </div>
      </section>
    </AnalyticsVisibleEvent>
  );
};

export default withSitecoreContext()(PromoBanner);
