import { useEffect, useRef, useState } from 'react';

type TUseScrollWithShadowProps = {
  bottomBuffer?: number;
};

const useScrollWithShadow = (props: TUseScrollWithShadowProps = {}) => {
  const scrollElementRef = useRef<any>(null);
  const [showShadowTop, setShowShadowTop] = useState<boolean>(false);
  const [showShadowBottom, setShowShadowBottom] = useState<boolean>(false);

  const getBoxShadow = (clientHeight: number, scrollTop: number, scrollHeight: number) => {
    const bottomBuffer = props?.bottomBuffer || 0;
    const scrolledBy = scrollHeight - Math.round(scrollTop) - bottomBuffer;

    const visibleTopShadow = scrollTop > 0 && scrollHeight > clientHeight;
    const visibleBottomShadow = scrollHeight > clientHeight && clientHeight < scrolledBy;

    setShowShadowTop(visibleTopShadow);
    setShowShadowBottom(visibleBottomShadow);
  };

  const onScrollHandler = () => {
    if (!scrollElementRef?.current) return;
    getBoxShadow(scrollElementRef?.current?.clientHeight, scrollElementRef?.current?.scrollTop, scrollElementRef?.current?.scrollHeight);
  };

  useEffect(() => {
    const resetRefSizes = (ref: any) => {
      if (!ref.current) return;
      getBoxShadow(scrollElementRef?.current?.clientHeight, scrollElementRef?.current?.scrollTop, scrollElementRef?.current?.scrollHeight);
    };

    resetRefSizes(scrollElementRef);
  }, [scrollElementRef?.current?.scrollHeight]);

  return { showShadowTop, showShadowBottom, scrollElementRef, onScrollHandler };
};

export default useScrollWithShadow;
