import React, { FC } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { TFilterProps } from '../../types/filter.types';
import FilterControl from '../filterControl';
import Icon from '../../../@shared/icon';

const Filter: FC<WithTranslation & TFilterProps> = ({ filters, activeFilters, onFiltersChange, t }) => {
  const handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void = (e) => {
    let updatedActiveFilters;
    const filterValue: string = e?.target?.value || '';

    if (activeFilters.includes(filterValue)) {
      updatedActiveFilters = activeFilters.filter((value) => filterValue !== value);
    } else {
      updatedActiveFilters = [...activeFilters, filterValue];
    }
    onFiltersChange(updatedActiveFilters);
  };

  const handleReset = (): void => {
    onFiltersChange([]);
  };

  const resetBtn = (
    <button className="search__filters__header__reset-btn" onClick={handleReset}>
      {t('cnhi-resetFilters')}
      <Icon iconName="action-refresh" size={24} extraClassName="search__filters__header--icon" />
    </button>
  );

  return (
    <div className="search__filters">
      <div className="search__filters__header">
        <div className="search__filters__header--dsk">
          <div className="search__filters__header--dsk-resetbtn">
            <h2 className="search__filters__header--title">{t('cnhi-filterBy')}</h2>
            {resetBtn}
          </div>
          <h3 className="search__filters__subtitle">{t('cnhi-filterType')}</h3>
        </div>
        <div className="search__filters__header--mbl">
          <h2 className="search__filters__header--title">{t('cnhi-filterBy')}</h2>
          <div className="search__filters__header--mbl-resetbtn">
            <h3 className="search__filters__subtitle">{t('cnhi-filterType')}</h3>
            {resetBtn}
          </div>
        </div>
      </div>
      <div>
        <ul>
          {filters?.map((filter) => (
            <FilterControl
              filter={filter}
              isActive={activeFilters.includes(filter.value)}
              onChange={handleChange}
              key={`filter-item-${filter.filterLabel}`}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default withTranslation()(Filter);
