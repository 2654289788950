import { TComponentParamsGeneral, THeadingTag, TLinkField, TStringField } from '../../../types/sitecore.types';
import { TCardProps } from '../../../types/card.types';
import { CardModel } from '../../../lib/shared-models/card.model';
import { TStoriesFields, TStoriesProps } from '../types/blogNews.types';

export class StoriesModel {
    protected readonly fields?: TStoriesFields | null;
    private readonly params?: TComponentParamsGeneral;

    constructor(props: TStoriesProps) {
        this.fields = props?.fields || null;
        this.params = props?.params;
    }

    get title(): TStringField | undefined {
        return this.fields?.title;
    }

    get content(): TStringField | undefined {
        return this.fields?.subtitle;
    }

    get cards(): CardModel[] {
        const cardList = this.fields?.items;
        if (cardList?.length) {
            return cardList.map((el: TCardProps) => new CardModel(el));
        }
        return [];
    }

    get eyebrow(): TStringField | undefined {
        return this.fields?.eyebrow;
    }

    get link(): TLinkField | undefined {
        return this.fields?.link;
    }

    get isUnreleased(): boolean {
        return !!this.fields?.isComponentUnreleased;
    }

    get headingTag(): THeadingTag {
        return this.params?.headingTag?.toLowerCase() as THeadingTag || 'h3';
      }
}
