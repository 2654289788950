import { ComponentRendering, Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { LinkField } from '@sitecore-jss/sitecore-jss-react/types/components/Link';
import React, { useEffect, useState, FC } from 'react';
import Slider from 'react-slick';
import { BreakpointsInPx } from '../../constants/breakpoints.constants';
import { CarouselDirectionEnum } from '../../enums/carousel.enums';
import useMediaQuery from '../../hooks/useMediaQuery';
import { windowService } from '../../utils/window.utils';
import { findCtaType } from '../../utils/popUpCtaType.utils';
import CustomCarouselDots from '../@shared/customCarouselDots';
import Heading from '../@shared/heading';
import CustomArrow from '../@shared/carouselArrow';
import UnreleasedComponent from '../@shared/unreleasedComponent';
import CarouselSlide from './carouselSlide';
import { SpecialOffersModel, SpecialOffersSlideModel } from './model/carousel.model';
import { TSpecialOffersProps } from './types/carousel.types';
import './styles.scss';

const CardCarousel: FC<TSpecialOffersProps> = (props) => {
  const isEE = props.sitecoreContext?.pageEditing;
  const model = new SpecialOffersModel(props);

  const isDesktop = useMediaQuery(`(min-width: ${BreakpointsInPx['tbl-p']})`, isEE);
  const [activeCard, updateActiveCard] = useState(1);
  const [isCarousel, setCarousel] = useState(true);

  const containerPaddingLeft = 32;
  const largeImageWidth = isDesktop ? 664 : 300;
  const smallImageWidth = isDesktop ? 392 : 300;
  const gapBetweenImages = 64;

  const totalElementsWidth =
    model.items.reduce((sumWidths: number, curr: SpecialOffersSlideModel) => {
      if (curr?.type?.value === 'offerCardWide') {
        sumWidths += largeImageWidth;
      } else {
        sumWidths += smallImageWidth;
      }
      sumWidths += gapBetweenImages;
      return sumWidths;
    }, 0) || 0 + containerPaddingLeft;

  const screenWidth = Math.min(windowService()?.innerWidth, 1680);
  const shouldInstanceCarousel = totalElementsWidth > screenWidth;

  const iconimage = 'dcx-arrow-i-os-forward';

  const getCarouselDots = (): JSX.Element => <CustomCarouselDots activeCard={activeCard} cardsCount={(model.items || []).length} />;

  useEffect(() => {
    setCarousel(shouldInstanceCarousel);
  }, [isCarousel, shouldInstanceCarousel]);

  const settings = {
    infinite: !isEE && isCarousel,
    dots: isCarousel,
    arrows: !isEE && isCarousel,
    variableWidth: true,
    speed: 500,
    slidesToScroll: 1,
    nextArrow: <CustomArrow direction={CarouselDirectionEnum.NEXT} />,
    prevArrow: <CustomArrow direction={CarouselDirectionEnum.PREVIOUS} />,
    beforeChange: (_: unknown, newIndex: number): void => {
      updateActiveCard(newIndex + 1);
    },
    appendDots: getCarouselDots,
  };

  const popUpCtaType = findCtaType(props.rendering);
  const secondaryCTAclassName = popUpCtaType === 'primary-cta' ? 'special-offers-editorial__ml-24' : '';
  const specialOffersClassName = model?.eyebrow ? '' : 'special-offers-editorial--without-eyebrow';

  // Component is unreleased
  if (model.isUnreleased) {
    return <UnreleasedComponent />;
  }

  return (
    <div className="special-offers-editorial-wrapper">
      <div className={`special-offers-editorial ${specialOffersClassName}`}>
        <Heading
          editable={true}
          eyebrow={model.eyebrow as string}
          title={model.title as string}
          subtitle={model.subtitle as string}
          link={model.link as LinkField}
          extraClassNameLink={secondaryCTAclassName}
          customTextTitle
          tag={model.headingTag}
          isGenericPopup={!!props.rendering?.placeholders?.['jss-generic-popup']?.length}
        >
          <div className={'special-offers-editorial__pop-up'}>
            <Placeholder name={'jss-generic-popup'} rendering={props.rendering as ComponentRendering} />
          </div>
        </Heading>
        {model.items?.length <= 1 ? (
          <div className="special-offers-editorial__slide special-offers-editorial__singleCard">
            <CarouselSlide item={model.items?.[0]} isEE={isEE} iconimage={iconimage} isSingleCard={true} />
          </div>
        ) : (
          <Slider {...settings}>
            {model.items.map((item) => (
              <div key={item?.id} className="special-offers-editorial__slide">
                <CarouselSlide item={item} isEE={isEE} iconimage={iconimage} />
              </div>
            ))}
          </Slider>
        )}
        <div className="special-offers-editorial__footer"></div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(CardCarousel);
