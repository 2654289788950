import React from 'react';
import { isEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { ProductPlmSeriesFeatureProps } from './types/productLinePlmSeriesSection.types';
import { PlmSeriesFeatureModel } from './models/productLinePlmSeriesSection.model';
import MobileSeriesDetails from './components/mobilePlmSeriesSection';
import DesktopSeriesDetails from './components/desktopPlmSeriesSection';
import useExperienceEditorPlaceholder from '../../hooks/useExperienceEditorPlaceholder';
import useMediaQuery from '../../hooks/useMediaQuery';
import { isEmptyResponse } from '../../utils/checkEmptyResponse';
import ExperienceEditorComponentPlaceholder from '../@shared/experienceEditorComponentPlaceholder';
import EmptyApiDataMessage from '../@shared/emptyApiDataMessage';
import UnreleasedComponent from '../@shared/unreleasedComponent';
import './styles.scss';

const ProductLinePlmSeriesSection = (props: ProductPlmSeriesFeatureProps) => {
  const isDesktop = useMediaQuery('(min-width: 768px)');
  const apiResponse = isEmptyResponse(props);
  const isPlaceholderShown = useExperienceEditorPlaceholder(props);
  const modelGroups = PlmSeriesFeatureModel(props);
  const data = modelGroups.data;
  const baseUrl = modelGroups.baseUrl;
  const isEEActive = props?.sitecoreContext?.pageEditing;
  const rendering = props?.rendering;

  // Component is unreleased
  if (props?.fields?.isComponentUnreleased) {
    return <UnreleasedComponent />;
  }

  // star  node opened directly in EE
  if (isPlaceholderShown) {
    return (
      <ExperienceEditorComponentPlaceholder image={props?.fields?.itemData?.thumbnail} componentName={props?.rendering?.componentName} />
    );
  }

  // not opened from star node
  // data missing when navigating via from products node
  if (!apiResponse && isEditorActive()) {
    return <EmptyApiDataMessage componentName={props?.rendering?.componentName} />;
  }

  // No data and not in EE
  if (!apiResponse && !isPlaceholderShown && !isEditorActive()) {
    return null;
  }

  if (Array.isArray(data)) {
    if (data.length === 0) {
      return null;
    }
    if (!isDesktop) {
      return (
        <>
          {data.map((model) => {
            return <MobileSeriesDetails baseUrl={baseUrl} model={model} key={model.title} isEEActive={!!isEEActive} rendering={rendering} />;
          })}
        </>
      );
    } else {
      return (
        <>
          {data.map((model) => {
            return (
              <div className="plm-screen-wrapper" key={model.title}>
                <DesktopSeriesDetails model={model} baseUrl={baseUrl} isEEActive={!!isEEActive} rendering={rendering} />
              </div>
            );
          })}
        </>
      );
    }
  }

  if (!isDesktop) {
    return <MobileSeriesDetails baseUrl={baseUrl} model={data} isEEActive={!!isEEActive} rendering={rendering} />;
  } else {
    return (
      <div className="plm-screen-wrapper">
        <DesktopSeriesDetails baseUrl={baseUrl} model={data} isEEActive={!!isEEActive} rendering={rendering} />
      </div>
    );
  }
};

export default withSitecoreContext()(ProductLinePlmSeriesSection);
