import React, { FC } from 'react';
import { TCustomCarouselNavPositionProps } from './types/carouselTransparentNavSection.types';
import './styles.scss';

const CarouselTransparentNavSection: FC<TCustomCarouselNavPositionProps> = ({
  position,
  handleTouchStart,
  extraClassName = '',
  children = null,
}: TCustomCarouselNavPositionProps) => {
  const localHandleStart = (event) => {
    if (event) {
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
    }
    if (handleTouchStart) {
      handleTouchStart();
    }
  };

  return (
    <>
      <button
        className={`
            carousel-transparent-nav-section
            carousel-transparent-nav-section--${position}
            ${extraClassName}
        `}
        onTouchStart={localHandleStart}
      ></button>
      {children}
    </>
  );
};

export default CarouselTransparentNavSection;
