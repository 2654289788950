import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';

const SpecCell = ({ specIndex, subArr, accIndex, subObj, showHiddenRightCells, hide, colOrdInIndex, fadeIn }) => {
  if (!showHiddenRightCells && specIndex > 5) return null;

  return (
    <td
      className={`
        model-detail-specification-table__table-cell
        model-detail-specification-table__table-cell--${specIndex % 2 === 0 ? 'even' : 'odd'}
        ${hide || subArr.length === accIndex + 1 ? 'model-detail-specification-table__table-cell--border-hidden' : ''}
      `}
    >
      <RichText
        tag="div"
        field={subObj.specs?.[colOrdInIndex[specIndex]]}
        className={`
          model-detail-specification-table__spec 
          rich-text 
          model-detail-specification-table__cell-animation
          ${fadeIn ? 'model-detail-specification-table__cell-animation--show-animation' : ''}
          ${hide ? 'model-detail-specification-table__cell-animation--hide' : ''}`}
      />
    </td>
  );
};

export default React.memo(SpecCell);
