enum ctaTypes {
  primaryYellow = 'p-yellow',
  primaryBlue = 'p-blue',
  primaryGhost = 'p-ghost',
  secondaryBlue = 's-blue',
  secondaryWhite = 's-white',
  baseButton = 'button',
  smallButton = 'button--small',
}

export default ctaTypes;
