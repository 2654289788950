import React, { FC, MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import ctaTypes from '../../../enums/CTA.enums';
import useEEActive from '../../../hooks/useEEActive';
import CTA from '../CTA';
import TextComponentDecider from '../textComponentDecider';
import { HeadingModel } from './model/heading.model';
import { THeadingProps } from './types/heading.types';
import { targetLinkdsk } from '../../../utils/targetDskLink';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { BreakpointsInPx } from '../../../constants/breakpoints.constants';
import './styles.scss';
import { googleCTAClickEvent } from '../../../utils/analytics';
import { getTextType } from '../../../utils/getTextType.utils';
import useSitecoreContext from '../../../hooks/useSitecoreContext';

const Heading: FC<THeadingProps> = (props) => {
  const isEE = useEEActive();
  const model = useMemo(() => new HeadingModel(props, isEE), [props, isEE]);
  const {
    extraClassNameLink = '',
    children,
    customTextTitle = false,
    customTextSubtitle = false,
    tag = 'h2',
    isGenericPopup,
    useDictionaryFallback = false,
    titleDictionaryKey,
    eyebrowDictionaryKey,
    descriptionDictionaryKey,
  } = props;
  const isDesktop = useMediaQuery(`(min-width: ${BreakpointsInPx['tbl-p']})`);
  const buttonRef: MutableRefObject<HTMLElement | null> = useRef(null);
  const {sitecoreContext} = useSitecoreContext();
  const productLine = sitecoreContext?.analytics?.['productLine'] ? sitecoreContext?.analytics?.['productLine'] : '';
  const productSeries = sitecoreContext?.analytics?.['productSeries'] ? sitecoreContext?.analytics?.['productSeries'] : '';

  const handleClickButton = useCallback(
    (e) => {
      if (props?.cbClickButton) {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();
        props.cbClickButton && props.cbClickButton();
      }
    },
    [props]
  );
  useEffect(() => {
    if (buttonRef?.current) {
      buttonRef.current.addEventListener('click', handleClickButton);
    }

    return () => {
      buttonRef?.current?.removeEventListener('click', handleClickButton);
    };
  }, [buttonRef, handleClickButton]);

  const headingEyebrow = (model.eyebrowExistsOrIsEE || useDictionaryFallback) && (
    <TextComponentDecider
      type={getTextType('Text', !model.eyebrow?.value)}
      tag={'span'}
      field={model.eyebrow}
      className="heading__eyebrow"
      editable={model.editable}
      dictionaryKey={eyebrowDictionaryKey}
    />
  );
  const headingGenericPopupCTA = isGenericPopup && (Boolean(children) || isEE) && (
    <span className="heading__generic-popup">{children}</span>
  );

  const headingTitle = (model.titleExistsOrIsEE || useDictionaryFallback) && (
    <TextComponentDecider
      type={getTextType(customTextTitle ? 'CustomText' : 'Text', !model.title?.value)}
      tag={tag}
      field={model.title}
      className={`heading__title ${isGenericPopup ? 'heading__title--margin-bottom' : ''}`}
      editable={model.editable}
      dictionaryKey={titleDictionaryKey}
    />
  );

  const headingLinkCTA = model.isLinkExistsOrIsEE && (
    <span className={`heading__link ${extraClassNameLink}`} ref={buttonRef}>
      <CTA
        type={ctaTypes.secondaryBlue}
        field={model.link}
        target={targetLinkdsk(model?.link, isDesktop)}
        extraClassName="heading__link--no-padding-left"
        onClick={() => {
          if (props?.useAnalytics) {
            googleCTAClickEvent(
              props?.event || '',
              model?.link?.value?.href,
              model?.link?.value.text ?? model?.link?.value.title,
              productLine,
              productSeries
            );
          }
        }}
      />
    </span>
  );

  const headingSubtitle = (model.subtitleExistsOrIsEE || useDictionaryFallback) && (
    <TextComponentDecider
      type={getTextType(customTextSubtitle ? 'CustomText' : 'RichText', !model.subtitle?.value)}
      tag="div"
      field={model.subtitle}
      className="heading__subtitle rich-text"
      editable={model.editable}
      dictionaryKey={descriptionDictionaryKey}
    />
  );

  return isDesktop ? (
    <div className="heading">
      {headingEyebrow}
      <div className="heading__title-wrapper">
        {headingTitle}
        {headingLinkCTA}
      </div>
      {headingGenericPopupCTA}
      {headingSubtitle}
    </div>
  ) : (
    <div className="heading">
      {headingEyebrow}
      {headingTitle}
      <div className="heading__link-wrapper">
        {headingLinkCTA}
        {headingGenericPopupCTA}
      </div>
      {headingSubtitle}
    </div>
  );
};
export default React.memo(Heading);
