import React, { FC, useEffect, useState } from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { documentService, windowService } from '../../../../utils/window.utils.js';
import { ContentHubSeriesView360HotComponentProps } from '../../types/productSeriesView360.types';

const View360HotSpot: FC<ContentHubSeriesView360HotComponentProps> = (props) => {
  const settings = props?.sitecoreContext?.settings as any;
  const baseUrl = settings?.view360BaseUrl;
  const hotScript = settings?.view360HotSpotScript;
  const cursorUrl = settings?.view360Cursor;
  const SCRIPT_ID = 'view-360-hot-spot';
  const CONFIG_SCRIPT_ID = 'view-360-hot-spot-config';

  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [isConfigScriptLoaded, setIsConfigScriptLoaded] = useState(false);

  const initWivraViewerHot = (): void => {
    if (!props.hotInitConfig) {
      return;
    }

    const wOpt = props.hotInitConfig;

    if (baseUrl) {
      wOpt.tutorialImg = `${baseUrl}${wOpt.tutorialImg}`;
      wOpt.imgHotspotOff = `${baseUrl}${wOpt.imgHotspotOff}`;
      wOpt.imgHotspotOn = `${baseUrl}${wOpt.imgHotspotOn}`;
      wOpt.imgHotspot360 = `${baseUrl}${wOpt.imgHotspot360}`;
      wOpt.styleCursor =
        documentService()?.all || windowService()?.navigator.userAgent.indexOf('Trident/7.0') > 0
          ? `url('${cursorUrl}'), auto`
          : `url('${cursorUrl}') 18 18, auto`;
    }

    window['wivraViewerHot'](wOpt);
  };

  const loadScript: (src: string, scriptId: string, cb: () => void) => void = (src, scriptId, cb) => {
    if (documentService().getElementById(scriptId)) {
      return;
    }
    const _scriptTag = documentService()?.createElement?.('script');
    _scriptTag.src = src;
    _scriptTag.type = 'text/javascript';
    _scriptTag.onload = function () {
      cb();
    };
    _scriptTag.id = scriptId;
    documentService().body.appendChild(_scriptTag);
  };

  const tryInit360Script: (_attempt?: number) => void = (_attempt) => {
    const MAX_NUM_OF_ATTEMPTS = 5;
    const attempt = _attempt || 1;

    if (windowService()?.wivraViewerHot) {
      initWivraViewerHot();
      return;
    }

    if (attempt <= MAX_NUM_OF_ATTEMPTS) {
      setTimeout(() => tryInit360Script(attempt + 1), 250);
      return;
    }

    console.error('Error: Unable to init 360 Series View. Failed to load script.');
  };

  useEffect(() => {
    if (!hotScript) {
      return;
    }

    if (documentService().getElementById(SCRIPT_ID)) {
      setIsScriptLoaded(true);
      return;
    }

    loadScript(hotScript, SCRIPT_ID, () => {
      setIsScriptLoaded(true);
    });
  }, [hotScript, SCRIPT_ID]);

  useEffect(() => {
    if (!props.hotspotConfigPath) {
      return;
    }

    loadScript(props.hotspotConfigPath, CONFIG_SCRIPT_ID, () => {
      setIsConfigScriptLoaded(true);
    });

    return () => {
      documentService().getElementById(CONFIG_SCRIPT_ID)?.remove();
    };
  }, [props.hotspotConfigPath, CONFIG_SCRIPT_ID]);

  useEffect(() => {
    if (!isScriptLoaded || !isConfigScriptLoaded) {
      return;
    }

    tryInit360Script();
  }, [props.hotspotConfigPath, isScriptLoaded, isConfigScriptLoaded]); // eslint-disable-line

  if (!props.hotInitConfig || !props.hotspotConfigPath || !baseUrl || !hotScript) {
    return null;
  }

  return (
    <div className="series-view-360">
      <h2>{props.title}</h2>
      <div id="wivra_hotspot"></div>
    </div>
  );
};

export default withSitecoreContext()(View360HotSpot);
