import React, { FC, useMemo, useRef, useState } from 'react';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { useLocation, useNavigate } from 'react-router';
import { BreakpointsInPx } from '../../constants/breakpoints.constants';
import { headerHeight } from '../../constants/header.constants';
import { SP_QUERY_STRINGS } from '../../constants/special-offers-page.constants';
import { ALL_SP_CATEGORIES, ALL_SP_LINES, ALL_SP_SERIES } from '../../constants/specialOffersFilters.constants';
import useExperienceEditorPlaceholder from '../../hooks/useExperienceEditorPlaceholder';
import { isEmptyResponse } from '../../utils/checkEmptyResponse';
import { windowService } from '../../utils/window.utils';
import EmptyApiDataMessage from '../@shared/emptyApiDataMessage';
import ExperienceEditorComponentPlaceholder from '../@shared/experienceEditorComponentPlaceholder';
import NoMatch from '../@shared/filters/components/noMatch';
import Pagination from '../@shared/pagination';
import UnreleasedComponent from '../@shared/unreleasedComponent';
import DesktopFilter from './components/desktopFilter';
import MobileFilter from './components/mobileFilter';
import SpecialOffersCard from './components/specialOffersCard';
import { FilterContext, TFilterContextData } from './fiilterContext';
import { ProductSpecialOffersListingModel } from './models/productSpecialOffersListing.model';
import { TProductSpecialOffersListingProps } from './types/productSpecialOffersListing.types';
import useSitecoreContext from '../../hooks/useSitecoreContext';
import './styles.scss';
import useMediaQuery from '../../hooks/useMediaQuery';

const ProductSpecialOffersListing: FC<TProductSpecialOffersListingProps> = (props) => {

  const apiResponse = isEmptyResponse(props) && Number(props?.fields?.apiData?.cards?.length) > 0;
  const isPlaceholderShown = useExperienceEditorPlaceholder(props);
  const context = useSitecoreContext();
  const model = useMemo(() => new ProductSpecialOffersListingModel(props, context?.sitecoreContext), [props, context]);
  const isWide = useMediaQuery(`(min-width: ${BreakpointsInPx['tbl-l']})`);
  const location = useLocation();
  const navigate = useNavigate();
  const filterQueryString = location?.search;

  const [selectedFilters, setSelectedFilters] = useState<TFilterContextData>(() => ({
    // model will not be re-intialized when state data is returned from function
    ...model.getDefaultContextData(filterQueryString),
    model,
    sortBy: 'asc',
  }));
  const cardContainerRef = useRef<HTMLDivElement | null>(null);
  const noOfCardsPerPage = 6;
  const filteredCards = model.filterCards(selectedFilters);
  const totalPageCount = Math.ceil(filteredCards.length / noOfCardsPerPage);
  const filterParams = useMemo(() => new URLSearchParams(location?.search), [filterQueryString]);
  const pageParam = parseInt(filterParams.get(SP_QUERY_STRINGS.PAGE) || '1');
  const [pageNo, setPageNo] = useState<number>(pageParam <= totalPageCount ? pageParam : 1);
  const startIndex = (pageNo - 1) * noOfCardsPerPage;
  const endIndex = pageNo * noOfCardsPerPage;

  const onPaginationChange = (selectedPage: number): void => {
    setPageNo(selectedPage);
    model.updatePageQueryParams(navigate, selectedPage);
    windowService()?.scroll({
      top: (cardContainerRef?.current?.offsetTop ?? 0) - headerHeight,
      left: 0,
      behavior: 'smooth',
    });
  };

  const updateFilters = (newFilters: Partial<TFilterContextData> = {}) => {
    const newSetSelectedFilters = { ...selectedFilters, ...newFilters };
    setSelectedFilters(newSetSelectedFilters);
    model.updateQueryParams(navigate, newSetSelectedFilters);
    setPageNo(1);
    model.updatePageQueryParams(navigate, '');
  };

  const resetFilters = () => {
    updateFilters({ lineSelectedInd: ALL_SP_LINES, seriesSelectedInd: ALL_SP_SERIES, categorySelectedInd: ALL_SP_CATEGORIES });
    setPageNo(1);
    model.resetAllQueryParams(navigate);
  };

  const filterContextValue = useMemo(() => ({ ...selectedFilters, updateFilters, resetFilters }), [
    selectedFilters,
    updateFilters,
    resetFilters,
  ]);

  // Component is unreleased
  if (model.isUnreleased) {
    return <UnreleasedComponent />;
  }

  //  star node opened directly in EE
  if (isPlaceholderShown) {
    return (
      <ExperienceEditorComponentPlaceholder image={props.fields?.itemData?.thumbnail} componentName={props?.rendering?.componentName} />
    );
  }

  // not opened from star node
  // data missing when navigating via from products node
  if (!apiResponse && isEditorActive()) {
    return <EmptyApiDataMessage componentName={props?.rendering?.componentName} />;
  }

  // No data and not in EE
  if (!apiResponse && !isPlaceholderShown && !isEditorActive()) {
    return null;
  }

  return (
    <FilterContext.Provider value={filterContextValue}>
      {isWide ? <DesktopFilter /> : <MobileFilter />}
      <div ref={cardContainerRef} className="special-offers-listing">
        <div className="special-offers-listing__card-container">
          {filteredCards.length > 0 && (
            <ul className="special-offers-listing__card-list">
              {filteredCards.slice(startIndex, endIndex).map((item) => {
                return (
                  <li className="special-offers-listing__card" key={`${item.id}`}>
                    <SpecialOffersCard {...item} placeholderImage={model?.defaultCardImage} />
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        {filteredCards?.length === 0 && (
          <NoMatch
            titleDictionaryKey="cnhi-special-offers-listing-no-cards-found-title"
            descriptionDictionaryKey="cnhi-special-offers-listing-no-cards-found-description"
          />
        )}
        <Pagination currentPage={pageNo} pages={totalPageCount} goToPage={onPaginationChange} />
      </div>
    </FilterContext.Provider>
  );
};

export default ProductSpecialOffersListing;
