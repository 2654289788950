import React, { useContext } from 'react';
import { SitecoreContextReactContext } from '@sitecore-jss/sitecore-jss-react';
import Picture from '../../../@shared/picture';
// TODO: Handle print of table via original table tag styles, instead of this seperate function
// current limitation: table tags not so flexible to work with css.

function PrintTable({ colHeadings, mrpRow, categoryRows }) {
  const sitecoreContextFactory = useContext(SitecoreContextReactContext);
  const headerFields = sitecoreContextFactory?.context?.route?.placeholders?.['jss-header']?.[0]?.fields;
  return (
    <div className="model-detail-specification-table-print-wrapper">
      <div className="model-detail-specification-table-print model-detail-specification-table-print__grid">
        {!!headerFields && (
          <div className="model-detail-specification-table-print__image">
            <Picture
              editorialImageProps={{
                desktopImage: headerFields?.globalAssets?.logo,
                mobileImage: headerFields?.globalAssets?.mobileLogo,
                desktopTransformation: 'Size200',
                mobileTransformation: 'Size200',
                cssClassImageEl: 'print-navigation-logo-image',
              }}
            />
          </div>
        )}
        <div className="model-detail-specification-table-print__col-heading"></div>
        {colHeadings?.map((val) => (
          <div className="model-detail-specification-table-print__col-heading">{val}</div>
        ))}
        {mrpRow.label && (
          <div
            className={`
                   model-detail-specification-table-print__left-side-accordion-spec-title
                   model-detail-specification-table-print__left-most-cell
                   model-detail-specification-table-print__spec-row-child
                 `}
          >
            <div
              className={`model-detail-specification-table-print__left-side-accordion-spec-title__inner-box model-detail-specification-table-print__left-side-accordion-spec-title__inner-box--last-row`}
            >
              <div>{mrpRow.label}</div>
            </div>
          </div>
        )}
        {mrpRow.prices.map((spec, specIndex) => (
          <div
            className={`
                    model-detail-specification-table-print__spec
                    model-detail-specification-table-print__spec--${specIndex % 2 === 0 ? 'even' : 'odd'}
                    model-detail-specification-table-print__spec--last-row
                  `}
          >
            {spec}
          </div>
        ))}
        {categoryRows.map((obj, index) => (
          <>
            <div className="model-detail-specification-table-print__spec-category-row">
              <div className="model-detail-specification-table-print__left-most-cell model-detail-specification-table-print__spec-category-row__inner-box-wrapper">
                <div className="model-detail-specification-table-print__spec-category-row__inner-box">{obj.group}</div>
              </div>
              <div className="model-detail-specification-table-print__spec-category-row__empty-row"></div>
            </div>
            {obj.items &&
              obj.items.map((subObj, accIndex, subArr) => (
                <>
                  {subObj.key && (
                    <div
                      className={`
                                model-detail-specification-table-print__left-side-accordion-spec-title
                                model-detail-specification-table-print__left-most-cell
                                model-detail-specification-table-print__spec-row-child
                              `}
                    >
                      <div
                        className={`model-detail-specification-table-print__left-side-accordion-spec-title__inner-box ${
                          subArr.length === accIndex + 1
                            ? 'model-detail-specification-table-print__left-side-accordion-spec-title__inner-box--last-row'
                            : ''
                        }`}
                      >
                        <div>{subObj.key}</div>
                      </div>
                    </div>
                  )}
                  {subObj.specs?.map((spec, specIndex) => (
                    <div
                      className={`
                                model-detail-specification-table-print__spec
                                model-detail-specification-table-print__spec--${specIndex % 2 === 0 ? 'even' : 'odd'}
                                model-detail-specification-table-print__spec-row-child
                                ${subArr.length === accIndex + 1 ? 'model-detail-specification-table-print__spec--last-row' : ''}
                              `}
                    >
                      {spec.value}
                    </div>
                  ))}
                </>
              ))}
          </>
        ))}
      </div>
    </div>
  );
}

export default PrintTable;
