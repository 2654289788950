import React, { FC } from 'react';
import { TField } from '../../../../../types/sitecore.types';
import { CustomText } from '../../../../@shared/customText';
import { TCustomLabelProps } from './types';

const CustomLabel: FC<TCustomLabelProps> = (props) => {
  const field: TField<string> = {
    value: props.label,
    editable: '',
  };

  return <CustomText field={field} tag={props?.tag} editable={false} />;
};

export default CustomLabel;
