import React, { FC } from 'react';
import Picture from '../../../@shared/picture';
import { TPlmSolutionCardImage } from '../../types/productSeriesDetailPlmSolutions.types';

const CardImage: FC<TPlmSolutionCardImage> = (props) => {
  const { asset } = props;
  return (
    <div className={'product-plm-solutions-card__media'}>
      {asset && (
        <Picture
          productImageProps={{
            desktopRendition: asset?.rendition_desktop_size500,
            mobileRendition: asset?.rendition_mobile_size200,
            alt: asset?.alt,
            pictureClassName: 'card-column__media-picture',
          }}
        />
      )}
    </div>
  );
};

export default CardImage;
