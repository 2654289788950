import React, { FC, useRef, useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  TGridCSSVariables,
  DesktopRowHeaderCellWidth,
  DesktopSpecCellWidth,
  TableMaxWidth,
  MobileSpecCellWidth,
  MobileRowHeaderCellWidth,
} from '../../../../constants/table.constants';
import { iconSize } from '../../../../constants/icon.constants';
import breakpoints, { BreakpointsInPx } from '../../../../constants/breakpoints.constants';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import useStickyWrapper from '../../../../hooks/useStickyWrapper';
import { windowService, documentService } from '../../../../utils/window.utils';
import Icon from '../../../@shared/icon';
import { TableRefType, TContentHubModelDetailSpecificationTableSectionProps } from '../../types/index.types';
import TableHeader from './header';
import PrintTableContent from './printContent';
import MainTable from './table';
import { getTextType } from '../../../../utils/getTextType.utils';
import { notesKeyModelSpecification } from '../../../../constants/dictionary.constants';
import TextComponentDecider from '../../../@shared/textComponentDecider';
import useShowProductComponentTitle from '../../../../hooks/useShowProductComponentTitle';
import useSitecoreContext from '../../../../hooks/useSitecoreContext';

import '../../../../styles/@shared/specs-table.scss';

const SpecificationTable: FC<WithTranslation & TContentHubModelDetailSpecificationTableSectionProps> = ({
  colHeadings,
  mrpRow,
  originalMrpRow,
  categoryRows,
  originalCategoryRows,
  eyebrow,
  title,
  button1,
  contextItemUrl,
  searchTerm,
  setSearchTerm,
  convertToExcelLink,
  iconsList,
  t,
  notes,
}) => {
  const tableRef = useRef<TableRefType | null>(null);
  const [showHiddenRightCells, setShowHiddenRightCells] = useState(false);
  const [leftMostVisibleColumn, setLeftMostVisibleColumn] = useState(0);
  const [fixedColumnHeading, setFixedColumnHeading] = useState(false);
  const [expandedRows, setExpandedRows] = useState([0]);
  const [hideArrowButton, setHideArrowButton] = useState(false);
  const isDesktop = useMediaQuery(`(min-width: ${BreakpointsInPx['tbl-p']})`);
  const stickyWrapperEle = useStickyWrapper();
  const hasNotesValue = !!notes?.value;
  const showNotes = useShowProductComponentTitle(hasNotesValue, [notesKeyModelSpecification]);

  const [dropdownAtColumnIndex, setDropdownAtColumnIndex] = useState<number | null>(null);
  const [colOrdInIndex, setColOrdInIndex] = useState<Array<number>>([]);
  const [printActive, setPrintActive] = useState(false);

  let previousViewWidth;

  const {sitecoreContext} = useSitecoreContext();
  const productLine = sitecoreContext?.analytics?.['productLine'];
  const productSeries = sitecoreContext?.analytics?.['productSeries'];

  useEffect(() => {
    setColOrdInIndex(colHeadings.map((_, i) => i));
  }, [contextItemUrl]);

  useEffect(() => {
    const GridRef = tableRef.current?.GridRef;
    if (GridRef && GridRef.current && colHeadings.length) {
      const gridEl = GridRef.current as HTMLElement | null;
      if (!gridEl) return;

      gridEl.style.setProperty(TGridCSSVariables.gridColumns, colHeadings.length.toString());
    }
  }, [tableRef, colHeadings]);

  const showOrHideFixedColumn = (
    gridElDim: DOMRect,
    stickyWrapperEle: HTMLElement | null,
    topMostEmptyColHeadingDim: DOMRect,
    previousTop: number,
    fixedColShowing: boolean,
    setFixedColumnHeading: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    let visibleTopLimit = stickyWrapperEle?.getBoundingClientRect().height || 0;
    let visibleBottomLimit = visibleTopLimit;

    // handling scroll down and removing the sticky row height if it is visible
    if (visibleTopLimit > 0 && gridElDim.top > previousTop && fixedColShowing) {
      visibleTopLimit -= topMostEmptyColHeadingDim.height;
      visibleBottomLimit -= topMostEmptyColHeadingDim.height;
    }

    // handling scroll up and adding buffer space of sticky row height if it is hidden
    if (gridElDim.top < previousTop && !fixedColShowing) {
      visibleBottomLimit += topMostEmptyColHeadingDim.height;
    }

    // check if table is visible and table top is above visible point and bottom is within view
    if (gridElDim.top < visibleTopLimit && gridElDim.bottom > visibleBottomLimit) {
      setFixedColumnHeading(() => true);
      return true;
    } else {
      setFixedColumnHeading(() => false);
      return false;
    }
  };

  useEffect(() => {
    let fixedColShowing = false;
    let previousTop = 0;

    function scrollHandler(): void {
      const GridRef = tableRef.current?.GridRef;
      if (GridRef && GridRef.current) {
        const gridEl = GridRef.current as HTMLElement | null;
        if (!gridEl) return;
        const gridElDim = gridEl.getBoundingClientRect();

        const topMostEmptyColHeadingDim = gridEl
          .getElementsByClassName('model-detail-specification-table__col-heading')?.[0]
          ?.getBoundingClientRect();

        if (!topMostEmptyColHeadingDim) return;

        fixedColShowing = showOrHideFixedColumn(
          gridElDim,
          stickyWrapperEle,
          topMostEmptyColHeadingDim,
          previousTop,
          fixedColShowing,
          setFixedColumnHeading
        );
        previousTop = gridElDim.top;
      }
    }

    documentService().addEventListener('scroll', scrollHandler);
    return (): void => documentService().removeEventListener('scroll', scrollHandler);
  }, [tableRef, stickyWrapperEle]);

  const handleGridOutsideSpace = (clientWidth: number, TableMaxWidth: number) => {
    const gridEl = tableRef.current?.GridRef?.current;
    if (!gridEl) return;
    if (clientWidth && clientWidth > TableMaxWidth) {
      const remainingWidth = clientWidth - TableMaxWidth;
      gridEl.style.setProperty(TGridCSSVariables.gridOutsideSpaceHalved, `${remainingWidth / 2}px`);
    } else {
      gridEl.style.setProperty(TGridCSSVariables.gridOutsideSpaceHalved, '0px');
    }
  };

  const handleShowOrHideArrows = (maxGridWidth: number, cellWidth: number): string => {
    const headerCellWidth = windowService().innerWidth >= breakpoints['tbl-p'] ? DesktopRowHeaderCellWidth : MobileRowHeaderCellWidth;
    let columnWidth = cellWidth + 'px';

    if (maxGridWidth - colHeadings.length * cellWidth > headerCellWidth) {
      columnWidth = (maxGridWidth - headerCellWidth) / colHeadings.length + 'px';
      setHideArrowButton(true);
      return columnWidth;
    } else {
      setHideArrowButton(false);
      return columnWidth;
    }
  };

  useEffect(() => {
    // eslint-disable-next-line
    if (!previousViewWidth) previousViewWidth = windowService()?.innerWidth;
    function resizeHandler(): void {
      const GridRef = tableRef.current?.GridRef;
      if (GridRef && GridRef.current) {
        const gridEl = GridRef.current as HTMLElement | null;
        if (!gridEl) return;
        gridEl.style.setProperty(TGridCSSVariables.gridScrollBy, '0');
        gridEl.style.setProperty(TGridCSSVariables.gridLeftCellsMoveBy, '0');
        setLeftMostVisibleColumn(0);
        if (previousViewWidth !== windowService()?.innerWidth) {
          setExpandedRows([0]); // do this only on horizontal resize
          previousViewWidth = windowService()?.innerWidth;
        }
        const clientWidth = documentService().documentElement.clientWidth;

        const maxGridWidth = Math.min(clientWidth, TableMaxWidth);
        const cellWidth = windowService().innerWidth >= breakpoints['tbl-p'] ? DesktopSpecCellWidth : MobileSpecCellWidth;
        const columnWidth = handleShowOrHideArrows(maxGridWidth, cellWidth);
        gridEl.style.setProperty(TGridCSSVariables.gridColumnWidth, columnWidth);
        handleGridOutsideSpace(clientWidth, TableMaxWidth);
      }
    }

    resizeHandler();
    windowService().addEventListener('resize', resizeHandler);
    return (): void => windowService().removeEventListener('resize', resizeHandler);
  }, [tableRef, contextItemUrl, colHeadings]); // eslint-disable-line

  useEffect(() => {
    const GridRef = tableRef.current?.GridRef;
    if (leftMostVisibleColumn > -1 && GridRef && GridRef.current && colHeadings.length > 0) {
      let newGridScrollBy = DesktopSpecCellWidth * leftMostVisibleColumn;
      const clientWidth = Math.min(documentService().documentElement.clientWidth, TableMaxWidth);
      const maxMove = DesktopRowHeaderCellWidth + DesktopSpecCellWidth * colHeadings.length - clientWidth;
      if (newGridScrollBy > maxMove && maxMove > 0) {
        newGridScrollBy = maxMove;
      }
      const gridEl = GridRef.current as HTMLElement | null;
      if (!gridEl) return;
      gridEl.style.setProperty(TGridCSSVariables.gridScrollBy, `-${newGridScrollBy}px`);
      gridEl.style.setProperty(TGridCSSVariables.gridLeftCellsMoveBy, `${newGridScrollBy}px`);
      gridEl.style.setProperty(TGridCSSVariables.gridFixedColHeadsMoveBy, `-${newGridScrollBy}px`);
    }
  }, [leftMostVisibleColumn, tableRef, colHeadings]);

  useEffect(() => {
    // Closing dropdown if clicked anywhere else
    function handleClick(event): void {
      if (!isDesktop) return;
      if (!event?.target?.getAttribute('data-is-dropdown-switch')) {
        setDropdownAtColumnIndex(null);
      }
    }

    const docElement = documentService();
    if (!docElement?.body) return;

    docElement.addEventListener('click', handleClick, false);
    return (): void => docElement.removeEventListener('click', handleClick, false);
  }, [isDesktop]);

  useEffect(() => {
    // prevent background body scrolling when model is opened in mobile view
    if (!isDesktop) {
      const docElement = documentService();
      if (!docElement?.body) return;

      if (dropdownAtColumnIndex !== null) docElement.body.classList.add('body--with-spec-model');
      else docElement.body.classList.remove('body--with-spec-model');
    }
  }, [dropdownAtColumnIndex, isDesktop]);

  useEffect(() => {
    if (!searchTerm || !categoryRows) return;
    setExpandedRows(categoryRows.map((_, i) => i));
  }, [searchTerm]); // eslint-disable-line

  const moveGridLeft = (): void => {
    const GridRef = tableRef.current?.GridRef;
    if (GridRef && GridRef.current) {
      setLeftMostVisibleColumn((cardNo) => (cardNo > 0 ? cardNo - 1 : 0));
    }
  };

  const moveGridRight = (): void => {
    const TableWrapperRef = tableRef.current?.TableWrapperRef;

    if (TableWrapperRef && TableWrapperRef.current && colHeadings) {
      const tableWrapperEl = TableWrapperRef.current as HTMLElement | null;
      if (!tableWrapperEl) return;
      const availableWidth = tableWrapperEl.clientWidth - DesktopRowHeaderCellWidth;
      const visibleColumns = Math.floor(availableWidth / DesktopSpecCellWidth);
      const maxMove = colHeadings.length - 1 - visibleColumns;

      if (!showHiddenRightCells) setShowHiddenRightCells(true);

      setLeftMostVisibleColumn((cardNo) => (cardNo <= maxMove ? cardNo + 1 : cardNo));
    }
  };

  const toggleAllRows = (): void => {
    if (!categoryRows) return;
    if (expandedRows.length === categoryRows.length) {
      setExpandedRows([]);
    } else {
      setExpandedRows(categoryRows.map((_, i) => i));
    }
  };

  const toggleRow = (index: number): void => {
    const GridRef = tableRef.current?.GridRef;
    if (GridRef && GridRef.current) {
      const arrIndex = expandedRows.findIndex((val) => val === index);
      if (arrIndex > -1) {
        setExpandedRows((expandedRow) => expandedRow.filter((val) => val !== index));
      } else {
        setExpandedRows((expandedRow) => [...expandedRow, index]);
      }
    }
  };

  const printStart = () => {
    setPrintActive(() => true);
  };

  const printEnd = () => {
    setPrintActive(() => {
      return false;
    });
  };

  const clearSearch = () => {
    setSearchTerm('');
    setExpandedRows([0]);
  };

  const GridRef = tableRef.current?.GridRef;
  let gridFixedColHeadsMoveBy = '';
  let gridColumnWidth = '';
  let gridOutsideSpaceHalved = '';
  let gridLeftCellsMoveBy = '';

  if (GridRef) {
    const gridEl = GridRef.current as HTMLElement | null;
    gridFixedColHeadsMoveBy = gridEl?.style.getPropertyValue(TGridCSSVariables.gridFixedColHeadsMoveBy) || '';
    gridColumnWidth = gridEl?.style.getPropertyValue(TGridCSSVariables.gridColumnWidth) || '';
    gridOutsideSpaceHalved = gridEl?.style.getPropertyValue(TGridCSSVariables.gridOutsideSpaceHalved) || '';
    gridLeftCellsMoveBy = gridEl?.style.getPropertyValue(TGridCSSVariables.gridLeftCellsMoveBy) || '';
  }

  const mainTableProps = {
    fixedColumnHeading,
    searchTerm,
    mrpRow,
    categoryRows,
    gridColumns: colHeadings.length.toString(),
    gridFixedColHeadsMoveBy,
    gridColumnWidth,
    gridOutsideSpaceHalved,
    gridLeftCellsMoveBy,
    colHeadings,
    setDropdownAtColumnIndex,
    setColOrdInIndex,
    dropdownAtColumnIndex,
    colOrdInIndex,
    expandedRows,
    toggleRow,
    productLine,
    productSeries,
    showHiddenRightCells,
    setShowHiddenRightCells,
  };

  return (
    <div className="model-details-specification-table-wrapper">
      <TableHeader
        eyebrow={eyebrow}
        title={title}
        button1={button1}
        iconsList={iconsList}
        isDesktop={isDesktop}
        convertToExcelLink={convertToExcelLink}
        categoryRows={categoryRows}
        expandedRows={expandedRows}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        printTable={printStart}
        hideArrowButton={hideArrowButton}
        moveGridLeft={moveGridLeft}
        moveGridRight={moveGridRight}
        toggleAllRows={toggleAllRows}
        productLine={productLine}
        productSeries={productSeries}
        clearSearch={clearSearch}
      />
      {searchTerm && mrpRow.hideRow && !categoryRows?.length && (
        <div className="model-detail-specification-table__search-no-match">
          <Icon iconName="info-alert-notif-info" size={iconSize.xlg} extraClassName="table-info-icon" />
          <p className="model-detail-specification-table__search-no-match-title">{t('cnhi-noMatchesFoundTitle')}</p>
          <p className="model-detail-specification-table__search-no-match-desc">{t('cnhi-noMatchesFoundDescription')}</p>
        </div>
      )}
      <PrintTableContent
        printActive={printActive}
        cleanupFunction={printEnd}
        colHeadings={colHeadings}
        mrpRow={originalMrpRow}
        categoryRows={originalCategoryRows}
      />
      {(!searchTerm || (searchTerm && (categoryRows?.length || !mrpRow.hideRow))) && <MainTable {...mainTableProps} ref={tableRef} />}
        {showNotes && (<div className="model-detail-specification-table__notes-wrapper">
          <TextComponentDecider
            type={getTextType('RichText', !notes?.value)}
            tag={'div'}
            field={{ value: notes?.value }}
            className="model-detail-specification-table__notes rich-text"
            dictionaryKey={notesKeyModelSpecification}
          />
        </div>)}
    </div>
  );
};

export default React.memo(withTranslation()(SpecificationTable));
