import { SitecoreForm } from '@sitecore-jss/sitecore-jss-forms';
import React, { FC, useState } from 'react';
import { TParamsField } from '../../types/sitecoreJssForm.types';
import { TDropdownAnalyticsSendData } from '../productSelector/dropdownContext';

type TCustomFormContext = {
  analytics: {
    firstUserActionState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    formName: string;
  };
  params: TParamsField;
  selectedCountry: [string, React.Dispatch<React.SetStateAction<string>>];
  setProductSelector: React.Dispatch<React.SetStateAction<TDropdownAnalyticsSendData[]>>;
  setSelectedDealerName: (dealerName: string) => void;
};

export const CustomFormContext = React.createContext<TCustomFormContext | undefined>(undefined);

const CustomFormContextProvider: FC<{
  sitecoreForm: SitecoreForm;
  params: TParamsField;
  children?: JSX.Element;
  setProductSelector: React.Dispatch<React.SetStateAction<TDropdownAnalyticsSendData[]>>;
  setSelectedDealerName: (dealerName: string) => void;
}> = ({ children, sitecoreForm, params, setProductSelector, setSelectedDealerName }) => {
  const _firstUserActionState = useState<boolean>(false);
  const _selectedCountry = useState<string>('');

  const customFormContextFactory = (): TCustomFormContext => {
    return {
      analytics: {
        firstUserActionState: _firstUserActionState,
        formName: sitecoreForm?.metadata?.name ?? '',
      },
      params,
      selectedCountry: _selectedCountry,
      setProductSelector,
      setSelectedDealerName,
    };
  };

  return <CustomFormContext.Provider value={customFormContextFactory()}>{children}</CustomFormContext.Provider>;
};

export default CustomFormContextProvider;
