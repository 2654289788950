import { TComponentParamsGeneral, THeadingTag, TImageField, TLinkField, TStringField, TVideoSitecoreField } from '../../../types/sitecore.types';
import {
  TSpecialOffersFields,
  TSpecialOffersProps,
  TSpecialOffersSlideFields,
  TSpecialOffersSlideProps
} from '../types/carousel.types';
import { LinkField } from '@sitecore-jss/sitecore-jss-react/types/components/Link';

export class SpecialOffersSlideModel {
    private readonly fields: null | TSpecialOffersSlideFields;
    private readonly idSlide?: string;

    constructor(props: TSpecialOffersSlideProps) {
        this.fields = props?.fields || null;
        this.idSlide = props.id ?? props.dataSource;
    }

    get id(): string {
        return this.idSlide as string;
    }

    get type(): TStringField | null {
        return this.fields?.cardType?.fields?.Key || null;
    }

    get title(): TStringField | null {
        return this.fields?.title || null;
    }

    get subtitle(): TStringField | null {
        return this.fields?.subtitle || null;
    }

    get label(): TStringField | null {
        return this.fields?.label || null;
    }

    get badgeLink(): TLinkField | null {
        return this.fields?.badgeLink || null;
    }

    get desktopImage(): TImageField | undefined {
        return this.fields?.image;
    }

    get mobileImage(): TImageField | undefined {
        return this.fields?.mobileImage;
    }

    get buttonPrimary(): TLinkField | null {
        return this.fields?.buttonPrimary || null;
    }

    get buttonSecondary(): TLinkField | null {
        return this.fields?.buttonSecondary || null;
    }

    get useVideo(): boolean {
        return this.fields?.useVideo?.value || false;
    }

    get video(): TVideoSitecoreField | null {
        return this.fields?.video || null;
    }

    get showPopupWithSplitModule(): boolean {
        return (
            (!this.buttonPrimary || (!this.buttonPrimary?.value?.url && !this.buttonPrimary?.value?.href)) &&
            !!this.fields?.items?.[0].fields?.items?.[0] &&
            !!this.splitModuleLabel
        );
    }

    get splitModules(): any {
        return this.fields?.items?.[0]?.fields?.items;
    }

    get splitModuleLabel(): string | undefined {
        return this.fields?.items?.[0]?.fields?.popUpLabel?.value;
    }
}

export class SpecialOffersModel {
    private readonly fields: null | TSpecialOffersFields;
    private readonly params?: TComponentParamsGeneral;

    constructor(props: TSpecialOffersProps) {
        this.fields = props?.fields || null;
        this.params = props?.params;
    }

    get eyebrow(): TStringField | null {
        return this.fields?.eyebrow || null;
    }

    get link(): LinkField | null {
        return this.fields?.link || null;
    }

    get title(): TStringField | null {
        return this.fields?.title || null;
    }

    get subtitle(): TStringField | null {
        return this.fields?.subtitle || null;
    }

    get items(): SpecialOffersSlideModel[] {
        return (
            this.fields?.items?.map((Card) => {
                return new SpecialOffersSlideModel(Card);
            }) || []
        );
    }

    get isUnreleased(): boolean {
        return !!this.fields?.isComponentUnreleased;
    }

    get headingTag(): THeadingTag {
        return this.params?.headingTag?.toLowerCase() as THeadingTag || 'h2';
    }
}
