import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { iconSize } from '../../../../../constants/icon.constants';
import Icon from '../../../../@shared/icon';
import { THeaderInputBoxProps } from '../../../types/index.types';

const InputBox: FC<THeaderInputBoxProps> = (props) => {
  const { searchTerm, setSearchTerm, clearSearch } = props;
  const { t } = useTranslation();
  return (
    <div className="model-details-specification-table-header__search-input__container">
      <Icon iconName="action-search" size={iconSize.dsk} extraClassName="table-search-icon" />
      <div className="model-details-specification-table-header__search-input__inner-container">
        {searchTerm && (
          <label slot="label" className="model-details-specification-table-header__search-input__label">
            {t('cnhi-inputLabel')}
          </label>
        )}
        <input
          name="Search"
          placeholder={t('cnhi-inputLabel')}
          type="text"
          className="model-details-specification-table-header__search-input__input"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.currentTarget.value)}
        ></input>
      </div>
      {searchTerm && <Icon iconName="action-close" size={iconSize.dsk} extraClassName="table-search-clear-icon" onClick={clearSearch} />}
    </div>
  );
};
export default InputBox;
