import React from 'react';
import { isEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isEmptyResponse } from '../../utils/checkEmptyResponse';
import useExperienceEditorPlaceholder from '../../hooks/useExperienceEditorPlaceholder';
import useMediaQuery from '../../hooks/useMediaQuery';
import MobileSeriesDetails from './components/mobileSeriesDetails';
import DesktopSeriesDetails from './components/desktopSeriesDetails';
import UnreleasedComponent from '../@shared/unreleasedComponent';
import ExperienceEditorComponentPlaceholder from '../@shared/experienceEditorComponentPlaceholder';
import EmptyApiDataMessage from '../@shared/emptyApiDataMessage';
import { TContentHubSeriesDetailsProps } from '../@shared/innerComponentsPopup/types/innerComponentsPopup.types';
import './styles.scss';

const ProductSeriesDetailFeature = (props: TContentHubSeriesDetailsProps) => {
  const isDesktop = useMediaQuery('(min-width: 768px)');
  const apiResponse = isEmptyResponse(props);
  const isPlaceholderShown = useExperienceEditorPlaceholder(props);

  // Component is unreleased
  if (props?.fields?.isComponentUnreleased) {
    return <UnreleasedComponent />;
  }

  // star  node opened directly in EE
  if (isPlaceholderShown) {
    return (
      <ExperienceEditorComponentPlaceholder image={props?.fields?.itemData?.thumbnail} componentName={props?.rendering?.componentName} />
    );
  }

  // not opened from star node
  // data missing when navigating via from products node
  if ((!apiResponse || props?.fields?.apiData?.innerComponents?.length === 0) && isEditorActive()) {
    return <EmptyApiDataMessage componentName={props?.rendering?.componentName} />;
  }

  // No data and not in EE
  if ((!apiResponse || props?.fields?.apiData?.innerComponents?.length === 0) && !isPlaceholderShown && !isEditorActive()) {
    return null;
  }

  if (isDesktop) {
    return (
      <div className="screen-wrapper">
        <DesktopSeriesDetails {...props} />
      </div>
    );
  } else {
    return <MobileSeriesDetails {...props} />;
  }
};

export default withSitecoreContext()(ProductSeriesDetailFeature);
