import { ComponentRendering } from '@sitecore-jss/sitecore-jss-react';
import { ContentHubSeriesView360Props, TContentHubSeriesView360Fields, THotInitConfig } from '../types/productSeriesView360.types';

export class ProductSeriesView360Model {
  private readonly fields?: TContentHubSeriesView360Fields;
  private readonly rendering?: ComponentRendering;

  constructor(props: ContentHubSeriesView360Props) {
    this.fields = props?.fields || null;
    this.rendering = props?.rendering;
  }

  get thumbnailEE(): string {
    return this.fields?.itemData?.thumbnail || '';
  }

  get componentName(): string {
    return this.rendering?.componentName || '';
  }

  get title(): string | undefined {
    return this.fields?.apiData?.title;
  }

  get hotspotConfigPath(): string | undefined {
    return this.fields?.apiData?.hotspotConfigPath;
  }

  get hotInitConfig(): THotInitConfig | undefined {
    return this.fields?.apiData?.vivraHotInitConfig;
  }

  get isUnreleased(): boolean {
    return !!this.fields?.isComponentUnreleased;
  }
}
