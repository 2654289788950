import React, { useEffect, useState } from 'react';
import Slide from 'react-reveal/Slide';
import { Placeholder, ComponentRendering } from '@sitecore-jss/sitecore-jss-react';
import { SeriesDetailsModel } from '../models/contentHubSeriesDetails.model';
import { TComponentDetails, TContentHubSeriesDetailsProps } from '../../@shared/innerComponentsPopup/types/innerComponentsPopup.types';
import ComponentDetails from '../../@shared/innerComponentsPopup/componentDetails';
import AnalyticsVisibleEvent from '../../@shared/analyticsVisibleEvent';
import TextComponentDecider from '../../@shared/textComponentDecider';
import { getTextType } from '../../../utils/getTextType.utils';
import { titleKeySeriesFeature } from '../../../constants/dictionary.constants';
import useScrollWithShadow from '../../../hooks/useScrollWithShadow';
import { windowService } from '../../../utils/window.utils';

const DesktopSeriesDetails = (props: TContentHubSeriesDetailsProps) => {
  const model = new SeriesDetailsModel(props);

  const isEEActive = props?.sitecoreContext?.pageEditing;
  const [componentArr, setComponentArr] = useState<TComponentDetails[]>([]);
  const { showShadowTop, showShadowBottom, scrollElementRef, onScrollHandler } = useScrollWithShadow();
  const [shadowWidth, setShadowWidth] = useState(0);

  useEffect(() => {
    function handleResize() {
      const newShadowWidth = scrollElementRef?.current?.clientWidth || 0;
      setShadowWidth(newShadowWidth);
    }

    handleResize();
    windowService().addEventListener('resize', handleResize);
    return (): void => windowService().removeEventListener('resize', handleResize);
  }, [scrollElementRef]);

  useEffect(() => {
    if (model?.innerComponents?.length && model?.innerComponents[0]) {
      setComponentArr([model?.innerComponents[0]]);
    }
  }, [model.contextItemUrl]);

  const automaticScrollTop = (event): void => {
    event.target?.parentElement?.scrollBy({
      top: -50,
      left: 0,
      behavior: 'smooth',
    });
  };
  const automaticScrollBottom = (event): void => {
    event.target?.parentElement?.scrollBy({
      top: 50,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <AnalyticsVisibleEvent event="series_features_visible">
      <section className="series-details">
        <div className="series-details__container">
          <div className="series-details__tabs-wrapper" onScroll={onScrollHandler} ref={scrollElementRef}>
            {showShadowTop && (
              <div
                className="series-details__shadow series-details__shadow-top"
                style={{ width: shadowWidth }}
                onClick={automaticScrollTop}
              ></div>
            )}
            {showShadowBottom && (
              <div
                className="series-details__shadow series-details__shadow-bottom"
                style={{ width: shadowWidth }}
                onClick={automaticScrollBottom}
              ></div>
            )}
            <div className="series-details__header">
              <TextComponentDecider
                type={getTextType('Text', !model.title)}
                tag={'h2'}
                field={{ value: model?.title }}
                className="series-details__title"
                dictionaryKey={titleKeySeriesFeature}
              />
              <div className={`series-details__popup ${isEEActive ? 'generic-popupEE-width' : ''}`}>
                <Placeholder name={'jss-generic-popup'} rendering={props?.rendering as ComponentRendering} />
              </div>
            </div>
            <ul className="series-details__tabs">
              {model?.innerComponents?.map((component) => {
                return (
                  <li
                    key={`${component?.id} `}
                    className={`series-details__tab ${componentArr[componentArr.length - 1]?.id === component?.id ? 'series-details__tab--selected' : ''
                      }`}
                  >
                    <div
                      className={`series-details__tab-button ${componentArr[componentArr.length - 1]?.id === component?.id ? 'series-details__tab-button--selected' : ''
                        }`}
                      onClick={() => {
                        setComponentArr((data) => {
                          if (component.id !== data?.[data?.length - 1]?.id) {
                            data.push(component);
                          }
                          if (data.length >= 3) {
                            return [...data.slice(1)];
                          }
                          return [...data];
                        });
                      }}
                    >
                      <h3>{component.eyebrow}</h3>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <section className="series-details__component-section">
            {componentArr.length > 0 &&
              componentArr.map(
                (_) =>
                  _ && (
                    <Slide bottom duration={1000} key={`${_.id}}`}>
                      <div className="series-details__component-wrapper">
                        <ComponentDetails component={_} className="series-details__component" useAnalytics />
                      </div>
                    </Slide>
                  )
              )}
          </section>
        </div>
      </section>
    </AnalyticsVisibleEvent>
  );
};

export default DesktopSeriesDetails;
