import React, { FC } from 'react';
import { iconSize } from '../../../../constants/icon.constants';
import Icon from '../../../@shared/icon';
import Picture from '../../../@shared/picture';
import { ColumnLayoutEnum, TCardImage } from '../../types/cardColumnLayout.types';

const CardImage: FC<TCardImage> = (props) => {
  const { imageOrThumbnail, useVideo, layout, imageIsPresent, yellowDotsImage, mobileImageOrThumbnail, showOverlayVideo } = props;
  return (
    <div className={imageOrThumbnail?.value?.src || useVideo ? `card-column__media card-column__media--${layout}` : ''}>
      {imageIsPresent && (
        <img
          className={`card-column__media-bg-image card-column__media-bg-image--${layout}`}
          src={yellowDotsImage}
          alt={''}
          loading="lazy"
        />
      )}
      {imageIsPresent && (
        <Picture
          editorialImageProps={{
            isVideoThumbnail: useVideo,
            desktopImage: imageOrThumbnail,
            mobileImage: mobileImageOrThumbnail,
            desktopTransformation: layout === ColumnLayoutEnum.FourColumnLayout ? 'Size500' : 'Size1000',
            mobileTransformation: 'Size500',
            alt: imageOrThumbnail?.value?.alt as string,
            cssClassPictureEl: `card-column__media-picture card-column__media-picture--${layout}`,
          }}
        />
      )}
      {useVideo && (
        <div onClick={showOverlayVideo} className="card-column__media-video-play-button">
          <Icon iconName="action-play" size={iconSize.xlg} extraClassName="card-column__media-video-play-button-icon" />
        </div>
      )}
    </div>
  );
};

export default CardImage;
