import React, { FC } from 'react';
import '../../../../styles/@shared/product-card.scss';
import { TCHSpecialOffersDownloadBrochuresDataAssets } from '../../types/productSpecialOffersDownloadBrochures.types';
import DictionaryTerm from '../../../@shared/dictionaryTerm';
import RoutableNotEditableLink from '../../../@shared/routableNotEditableLink';
import Icon from '../../../@shared/icon';
import Picture from '../../../@shared/picture';
import { iconSize } from '../../../../constants/icon.constants';
import './styles.scss';

const BrochureCard: FC<TCHSpecialOffersDownloadBrochuresDataAssets> = (props) => {
  // eslint-disable-next-line @typescript-eslint/camelcase
  const { title, alt, size, full, icon, rendition_desktop_size500, rendition_mobile_size500, digitalBrochureUrl, alternateImageUrl } = props;

  const desktopRendition = alternateImageUrl ? { url: `${alternateImageUrl}&t=Size500` } : rendition_desktop_size500;
  const mobileRendition = alternateImageUrl ? { url: `${alternateImageUrl}&t=Size500` } : rendition_mobile_size500;

  return (
    <article className="special-offer-brochure-card__article">
      <div className="special-offer-brochure-card__image-container">
        <Picture
          productImageProps={{
            // eslint-disable-next-line @typescript-eslint/camelcase
            mobileRendition: mobileRendition,
            // eslint-disable-next-line @typescript-eslint/camelcase
            desktopRendition: desktopRendition,
            pictureClassName: 'special-offer-brochure-card__article__picture--fixed-aspect-ratio',
            alt: alt,
          }}
        />
      </div>

      <div className="special-offer-brochure-card__content-block">
        {title ? <h2 className="special-offer-brochure-card__title">{title}</h2> : null}
        {size ? <span className="special-offer-brochure-card__sub-heading"> {size}MB </span> : null}
      </div>
      <div className="special-offer-brochure-card__link-wrapper">
        {digitalBrochureUrl ? (
          <RoutableNotEditableLink
            href={digitalBrochureUrl}
            target="_blank"
            className={'special-offer-brochure-card__link special-offer-brochure-card__link--view'}
          >
            <span className="special-offer-brochure-card__link special-offer-brochure-card__link--label">
              <DictionaryTerm keyword="cnhi-view" />
            </span>
            <Icon iconField={icon[2]} color="primary" extraClassName="special-offer-brochure-card__link__icon" size={iconSize.mbl} />
          </RoutableNotEditableLink>
        ) : (
          <div className="a2a_kit a2a_kit_size_32 a2a_default_style ">
            <a
              className="a2a_dd special-offer-brochure-card__link special-offer-brochure-card__link--share special-offer-brochure-card__add-to-any"
              data-a2a-url={full}
              data-a2a-title={title}
            >
              <span role="button" className="special-offer-brochure-card__link--label">
                <DictionaryTerm keyword="cnhi-share" />
              </span>
              <Icon iconField={icon[0]} color={'primary'} size={iconSize.mbl} />
            </a>
          </div>
        )}
        <RoutableNotEditableLink
          href={full}
          target="_blank"
          className={'special-offer-brochure-card__link special-offer-brochure-card__link--download'}
        >
          <span className="special-offer-brochure-card__link special-offer-brochure-card__link--label">
            <DictionaryTerm keyword="cnhi-download" />
          </span>
          <Icon iconField={icon[1]} color={'primary'} extraClassName="special-offer-brochure-card__link__icon" size={iconSize.mbl} />
        </RoutableNotEditableLink>
      </div>
    </article>
  );
};

export default BrochureCard;
