import React, { FC, useEffect, useState, useRef } from 'react';
import LazyLoad from 'react-lazyload';
import ReactPlayer from 'react-player/lazy';

import { BreakpointsInPx } from '../../../constants/breakpoints.constants';
import { iconSize } from '../../../constants/icon.constants';
import useMediaQuery from '../../../hooks/useMediaQuery';
import Icon from '../icon';
import Picture from '../picture';
import { TEditorialImageProps, TProductImageProps } from '../picture/types/picture.types';
import { VideoPlayerProps } from './index.types';

import './index.scss';

const VideoPlayer: FC<VideoPlayerProps> = ({
  link,
  playIconClickCallback,
  playCallback,
  pauseCallback,
  endCallback,
  autoPlay = false,
  controls = true,
  muted = false,
  loop = false,
  width = '100%',
  height = '100%',
  extraClassName = '',
  iconWrapperClassName = '',
  videoPlayerWrapperClassName = '',
  showThumbnail = false,
  mobilePlayIconSize = iconSize.dsk,
  desktopPlayIconSize = iconSize.xlg,
  editorialImageProps,
  productImageProps,
  activeCard = true,
}) => {
  const isDesktop = useMediaQuery(`(min-width: ${BreakpointsInPx['tbl-p']})`);
  const [hideThumbnail, setHideThumbnail] = useState(false);
  const [playing, setPlaying] = useState<boolean>(() => autoPlay);

  const videoPlayerWrapperRef = useRef<any>(null);
  const videoPlayerRef = useRef<any>(null);
  const [onScreen, setOnScreen] = useState(false);
  const loadedSecondsRef = useRef<number>(0);
  const [runOnReady, setRunOnReady] = useState(false);

  useEffect(() => {
    const options = {
      rootMargin: '10px',
      threshold: 0.01,
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (!entry) return;
        setOnScreen(entry.isIntersecting);
      });
    };

    const observer = new IntersectionObserver(observerCallback, options);

    if (videoPlayerWrapperRef?.current) {
      observer.observe(videoPlayerWrapperRef?.current);
    }

    return () => {
      if (videoPlayerWrapperRef?.current) {
        observer.unobserve(videoPlayerWrapperRef?.current);
      }
    };
  }, [videoPlayerWrapperRef, setOnScreen]);

  useEffect(() => {
    if (!onScreen) {
      setRunOnReady(true);
    }
  }, [onScreen]);

  useEffect(() => {
    setOnScreen(activeCard);
  }, [activeCard]);

  useEffect(() => {
    if (link) {
      setHideThumbnail(false);
    }
  }, [link]);

  const localPlayCallback = () => {
    setPlaying(true);
    if (playCallback) {
      playCallback();
    }
  };

  const localPauseCallback = () => {
    setPlaying(false);
    if (pauseCallback) {
      pauseCallback();
    }
  };

  const localEndCallback = () => {
    setPlaying(false);
    if (endCallback) {
      endCallback();
    }
  };

  const handlePlayVideo = () => {
    playIconClickCallback?.();
    setHideThumbnail(true);
  };

  const loadLastLoadedSecond = () => {
    if (!runOnReady) return;
    videoPlayerRef?.current?.seekTo(loadedSecondsRef.current, 'seconds');
    setRunOnReady(false);
  };

  const playIconSize = isDesktop ? desktopPlayIconSize : mobilePlayIconSize;

  let localEditorialImageProps: TEditorialImageProps | undefined;
  if (editorialImageProps) {
    localEditorialImageProps = {
      ...editorialImageProps,
      isVideoThumbnail: true,
      cssClassImageEl: (editorialImageProps?.cssClassImageEl || '') + ' youtube-video-wrapper',
    };
  }

  let localProductImageProps: TProductImageProps | undefined;
  if (productImageProps) {
    localProductImageProps = {
      ...productImageProps,
      isVideoThumbnail: true,
      imageClassName: (productImageProps?.imageClassName || '') + ' youtube-video-wrapper',
    };
  }

  const reactPlayerConfig = {
    file: {
      attributes: {
        preload: 'metadata',
      },
    },
    youtube: {
      playerVars: { autoplay: autoPlay ? 1 : 0 },
    },
  };

  return (
    <div style={{ width: '100%', height: '100%' }} ref={videoPlayerWrapperRef} className={videoPlayerWrapperClassName}>
      {showThumbnail && !hideThumbnail ? (
        <div className="youtube-video-wrapper">
          <div
            onTouchStart={productImageProps?.onTouchStart || editorialImageProps?.onTouchStart}
            onTouchEnd={productImageProps?.onTouchEnd || editorialImageProps?.onTouchEnd}
            className={iconWrapperClassName ? iconWrapperClassName : 'play-icon-center play-icon-defaults'}
            onClick={handlePlayVideo}
          >
            <Icon iconName="action-play" extraClassName="youtube-video-wrapper__icon" size={playIconSize} />
          </div>
          {(localEditorialImageProps || localProductImageProps) && (
            <Picture editorialImageProps={localEditorialImageProps} productImageProps={localProductImageProps} />
          )}
        </div>
      ) : (
        <LazyLoad offset={0} once={true} style={{ width: '100%', height: '100%' }}>
          {onScreen ? (
            <ReactPlayer
              url={link}
              controls={controls}
              onReady={loadLastLoadedSecond}
              onPlay={localPlayCallback}
              onPause={localPauseCallback}
              onEnded={localEndCallback}
              onProgress={({ playedSeconds }) => {
                loadedSecondsRef.current = playedSeconds;
              }}
              width={width}
              height={height}
              muted={muted}
              loop={loop}
              className={`youtube-video-wrapper ${extraClassName}`}
              playing={onScreen && playing}
              ref={videoPlayerRef}
              config={reactPlayerConfig}
            />
          ) : (
            <div style={{ width: '100%', height: '100%' }}></div>
          )}
        </LazyLoad>
      )}
    </div>
  );
};
export default React.memo(VideoPlayer);
