import { TContentHubSeriesOverviewProps, TGalleryItem } from '../types/contentHubSeriesPlmOverview.type';

export class OverviewCarouselModel {
  private readonly fields?;

  constructor(props: TContentHubSeriesOverviewProps) {
    this.fields = props?.fields || null;
  }

  get slides(): Array<TGalleryItem> {
    return this.fields?.apiData?.heroAssets?.length > 0 ? this.fields?.apiData?.heroAssets : this.fields?.apiData?.assets;
  }

  get video(): string {
    return this.fields?.apiData?.videoUrl;
  }
}
