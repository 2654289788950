import { TContentHubButton, TRendition } from '../../../types/sitecore.types';
import { WithSitecoreContextProps } from '@sitecore-jss/sitecore-jss-react/types/enhancers/withSitecoreContext';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-react';
import { TThumbnailPreviewEE } from '../../../types/thumbnailEE.types';

export type TContentHubSeriesOverviewProps = WithSitecoreContextProps & {
  fields: TContentHubSeriesOverviewFields;
  rendering?: ComponentRendering;
};

export type TContentHubSeriesOverviewFields = {
  apiData: TSeriesOverviewData;
  itemData?: TThumbnailPreviewEE;
  isComponentUnreleased?: boolean;
};

export type TSeriesOverviewData = {
  id: number;
  componentType: string;
  eyebrow: string;
  title: string;
  description: string;
  button1: TContentHubButton;
  button2: TContentHubButton;
  gallery: Array<TGalleryItem>;
  innerComponents?: TComponentDetails[];
};

export type TGalleryItem = {
  role?: 'main' | 'video' | 'extvideo';
  full?: string;
  bigthumbnail?: string;
  thumbnail?: string;
  alt?: string;
  title?: string;
  description?: string;
  mediaType?: string;
  video_mp4?: string;
  // FE defined
  isVideo?: boolean;

  rendition_desktop_size1100?: TRendition;
  rendition_mobile_size500?: TRendition;
};

export enum InnerComponentTypes {
  Split = 'split',
  Card = 'card',
}

export type TSeriesDetailCarouselIndicatorProps = {
  clickHandler?: (e: React.MouseEvent | React.KeyboardEvent) => void;
  isSelected: boolean;
  index: number;
  baseClassName?: string;
  goSelected: (e: React.MouseEvent | React.KeyboardEvent | number) => void;
};
export type TContentHubSeriesDetailsFields = {
  apiData: TSeriesDetailsData;
  itemData?: TThumbnailPreviewEE;
};

export type TBreadcrumb = {
  titleUrl: string;
  title: string;
};
export type TButton = {
  label: string;
  url: string;
  target?: string;
};

export type TSummarySpec = {
  key: string;
  value: string;
  active?: string;
  position?: string;
  status?: string;
};

export type TComponentTab = {
  key: string;
  value: string;
};

export type TAsset = Partial<{
  role: 'main' | 'video' | 'extvideo';
  alt: string;
  bigthumbnail: string;
  thumbnail: string;
  full: string;
}>;

export type TSeo = {
  title: string;
  description: string;
  keywords: string;
  ogTitle: string;
  ogType: string;
  ogImage: string;
  ogUrl: string;
  ogDescription: string;
  ogSiteName: string;
  jsonLdSchema: string;
  canonicalUrl: string;
};

export type TImage = {
  image?: string;
  imageAlt?: string;
  mobileImage?: string;
  imageAltMobile?: string;
};

export type TSeriesDetailsData = {
  id: number;
  titleUrl?: string;
  title?: string;
  formattedTitle?: string;
  eyebrow?: string;
  innerComponents?: TComponentDetails[];
  description?: string;
  msrp?: string;
  optionalLabel1?: string;
  optionalLabel2?: string;
  breadcrumb?: TBreadcrumb[];
  button1?: TButton;
  button2?: TButton;
  button3?: TButton;
  summarySpecs?: TSummarySpec[];
  components?: TComponentTab[];
  active?: boolean;
  showInNavigation?: boolean;
  assets?: TAsset[];
  videoUrl?: string;
  seo?: TSeo;
};

export type TComponentDetails = {
  id: number;
  componentType?: InnerComponentTypes;
  title?: string;
  layoutType?: string;
  eyebrow?: string;
  description?: string;
  button1?: TButton;
  button2?: TButton;
  image?: string;
  imageAlt?: string;
  assets?: TAsset[];
  videoUrl?: string;
  videoDescription?: string;
  innerComponents?: TComponentDetails[];
};

export type TComponentDetailsProps = {
  className?: string;
  component?: TComponentDetails;
};

export type TBannerProps = {
  className?: string;
  component: TComponentDetails;
  hasFixedAspectRatio?: boolean;
};

export type TGalleryProps = {
  assets?: TAsset[];
  hasFixedAspectRatio?: boolean;
};

export type TModalComponentsListProps = {
  components: Array<TComponentDetails>;
};

export type TModalComponentsItemProps = {
  component: TComponentDetails;
};
