import React, { FC, useState, useEffect } from 'react';
import { BreakpointsInPx } from '../../../../constants/breakpoints.constants';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import Picture from '../../../@shared/picture';
import VideoPlayer from '../../../@shared/videoPlayer';
import { THeroCardProps } from '../../types/heroCard.type';
import './styles.scss';

const HeroCard: FC<THeroCardProps> = ({
  slide,
  playCallback,
  pauseCallback,
  endCallback,
  activeCard,
  fromCarousel = false,
  moveLeft,
  moveRight,
  index,
}) => {
  const isWide = useMediaQuery(`(min-width: ${BreakpointsInPx['tbl-p']})`);
  const [swipeStart, setSwipeStart] = useState(0);

  useEffect(() => {
    setSwipeStart(0);
  }, [activeCard, index]);

  const handleTouchStartVideoPlay = (event) => {
    if (isWide) return;
    const value = event?.changedTouches?.[0]?.pageX;
    if (!value) return;
    setSwipeStart(value);
  };

  const handleTouchEndVideoPlay = (event) => {
    if (isWide) return;
    const value = event?.changedTouches?.[0]?.pageX;
    if (!value) {
      setSwipeStart(0);
      return;
    }

    if (value < swipeStart && swipeStart - value > 20) {
      if (moveRight) moveRight();
    } else if (value > swipeStart && value - swipeStart > 20) {
      if (moveLeft) moveLeft();
    }
    setSwipeStart(0);
  };

  const handleTouchStart = (event) => {
    if (fromCarousel) return;
    const value = event?.changedTouches?.[0]?.pageX;
    if (!value) return;
    setSwipeStart(value);
  };

  const handleTouchMove = () => {
    if (fromCarousel) return;
  };

  const handleTouchEnd = (event) => {
    if (fromCarousel) return;
    const value = event?.changedTouches?.[0]?.pageX;
    if (!value) {
      setSwipeStart(0);
      return;
    }

    if (value < swipeStart && swipeStart - value > 20) {
      if (moveRight) moveRight();
    } else if (value > swipeStart && value - swipeStart > 20) {
      if (moveLeft) moveLeft();
    } else {
      setSwipeStart(0);
    }
  };

  const productImageProps = {
    desktopRendition: slide.rendition_desktop_size1100,
    mobileRendition: slide.rendition_mobile_size500,
    alt: slide.alt,
    pictureClassName: 'plm-series-detail-overview-hero-card__picture',
    onTouchStart: handleTouchStartVideoPlay,
    onTouchEnd: handleTouchEndVideoPlay,
    prioritize: index === 0,
  };

  return (
    <>
      {isWide && !fromCarousel && (
        <div className="plm-series-detail-overview-hero-card__focus-image__text-wrapper">
          <div className="plm-series-detail-overview-hero-card__focus-image__text-title">{slide.title}</div>
          <div className="plm-series-detail-overview-hero-card__focus-image__text-description">{slide.description}</div>
        </div>
      )}
      <div
        className={
          fromCarousel
            ? 'plm-series-detail-overview-carousel__slide-wrapper'
            : 'plm-series-detail-overview-expandable-overlay__slider-wrapper'
        }
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {slide?.role === 'video' ? (
          <>
            {fromCarousel ? (
              <div className="plm-series-detail-overview-hero-card__focus-image">
                {(slide.full || slide.youtubeUrl) && (
                  <VideoPlayer
                    className="plm-series-detail-overview-modal-video"
                    link={slide.full || slide.youtubeUrl}
                    autoPlay={true}
                    showThumbnail={true}
                    muted={!isWide}
                    playCallback={playCallback}
                    pauseCallback={pauseCallback}
                    endCallback={endCallback}
                    productImageProps={productImageProps}
                  />
                )}
              </div>
            ) : (
              <VideoPlayer
                className="plm-series-detail-overview-modal-video"
                link={slide.full || slide.youtubeUrl}
                autoPlay={true}
                showThumbnail={true}
                muted={!isWide}
                playCallback={playCallback}
                pauseCallback={pauseCallback}
                endCallback={endCallback}
                productImageProps={productImageProps}
              />
            )}
          </>
        ) : (
          <Picture productImageProps={productImageProps} />
        )}
      </div>
    </>
  );
};

export default React.memo(HeroCard);
