import { TQuickLinksLinkProps } from '../components/quickLinksLink/types/quickLinksLink.types';
import { TQuickLinksFields, TQuickLinksProps } from '../types/quickLinks.types';

export class QuickLinksModel {
    protected readonly fields?: TQuickLinksFields | null;

    constructor(props: TQuickLinksProps) {
        this.fields = props?.fields || null;
    }

    get desktopLinks(): TQuickLinksLinkProps[] | undefined {
        return Array.isArray(this.fields?.items) ? this.fields?.items : [];
    }

    get mobileLinks(): TQuickLinksLinkProps[] | undefined {
        return Array.isArray(this.fields?.items) ? this.fields?.items.filter((el) => el.fields?.isMobile?.value) : [];
    }

    get othersLinkLabel(): string {
        return this.fields?.mobileSectionHeading?.value ?? '';
    }

    get isUnreleased(): boolean {
        return !!this.fields?.isComponentUnreleased;
    }
}
