import { useState, useEffect } from 'react';
import { windowService } from '../utils/window.utils';

const useMediaQuery = (
  query: string,
  initialState = false,
  //eslint-disable-next-line
  callbackOnChange = (mediaMatches: boolean) => {
    // This is intentional
  }
) => {
  const [matches, setMatches] = useState(initialState);

  useEffect(() => {
    const media = windowService().matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
      callbackOnChange(media.matches);
    }
    const listener = () => setMatches(media.matches);
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, query]); //eslint-disable-line

  return matches;
};

export default useMediaQuery;
