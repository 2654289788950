import { FieldTypes } from '@sitecore-jss/sitecore-jss-react-forms';
import React, { FC } from 'react';
import { TFieldWrapperProps } from '../../types/fieldWrapper.types';
import customFieldTypes from '../../types/field.types';
import PageTitle from '../pageTitleField';
import PageUrl from '../pageUrlField';

const FieldWrapper: FC<TFieldWrapperProps> = (props) => {
  const radioButtonComponents = [customFieldTypes.preferredCommunicationFieldTypeItemId, FieldTypes.RadioButtonList];
  const pageTitleComponent = [customFieldTypes.pageTitleFieldTypeItemId, PageTitle];
  const pageUrlComponent = [customFieldTypes.pageUrlFieldTypeItemId, PageUrl];
  const hasPageTitleOrPageUrl = [...pageTitleComponent, ...pageUrlComponent]?.includes(props?.field?.model?.fieldTypeItemId as FieldTypes);
  const hasRadioBtn = radioButtonComponents.indexOf(props.field.model.fieldTypeItemId as FieldTypes) !== -1;
  const cssClass = props?.field?.model?.cssClass || 'width-1';

  return (
    <div
      className={`sitecore-jss-form__field-wrapper ${cssClass} ${hasPageTitleOrPageUrl ? 'hidden' : ''} ${
        hasRadioBtn ? 'sitecore-jss-form__field-wrapper--radio-btn' : ''
      }`}
    >
      {props.children}
    </div>
  );
};

export default FieldWrapper;
