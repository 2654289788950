import React, { useEffect, useRef, useState, FC } from 'react';
import { TSubValueRow } from '../../../types/index.types';
import formatText from './formatText';
import SpecCell from './specCell';

const FADE_TIME = 600;

const SubValueRow: FC<TSubValueRow> = ({ subObj, subArr, accIndex, searchTerm, hide, colOrdInIndex, showHiddenRightCells }) => {
  const [hideRow, setHideRow] = useState(hide);
  const [fadeIn, setFadeIn] = useState(false);
  const prevHideRef = useRef(false);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    const prevHide = prevHideRef.current;

    prevHideRef.current = hide;

    if (!prevHide && hide) {
      timerRef.current = setTimeout(() => {
        setHideRow(() => hide);
      }, FADE_TIME);
    }

    if (prevHide && !hide) {
      setHideRow(() => hide);
      setFadeIn(() => true);
      setTimeout(() => {
        setFadeIn(() => false);
      }, FADE_TIME);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [hide, setHideRow]);

  if (hideRow) return null;

  return (
    <tr key={`model-detail-specification-table__left-most-cell-${subObj.id}`}>
      {subObj.id && (
        <td
          className={`model-detail-specification-table__left-most-cell 
            ${hide || (subArr.length === accIndex + 1 ? 'model-detail-specification-table__left-most-cell--border-hidden' : '')}
          `}
        >
          <div
            className={`
                model-detail-specification-table__left-side-accordion-spec-title
                ${
                  hide || subArr.length === accIndex + 1
                    ? 'model-detail-specification-table__left-side-accordion-spec-title--border-hidden'
                    : ''
                }
                model-detail-specification-table__cell-animation
                ${fadeIn ? 'model-detail-specification-table__cell-animation--show-animation' : ''}
                ${hide ? 'model-detail-specification-table__cell-animation--hide' : ''}
                ${searchTerm ? 'model-detail-specification-table__left-side-accordion-spec-title--search-item' : ''}
            `}
          >
            {formatText(subObj.key, searchTerm, true)}
          </div>
        </td>
      )}
      {subObj.specs?.map((_, specIndex) => (
        <SpecCell
          specIndex={specIndex}
          subArr={subArr}
          accIndex={accIndex}
          subObj={subObj}
          hide={hide}
          fadeIn={fadeIn}
          colOrdInIndex={colOrdInIndex}
          showHiddenRightCells={showHiddenRightCells}
        />
      ))}
    </tr>
  );
};

export default SubValueRow;
