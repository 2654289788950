import { ComponentRendering } from '@sitecore-jss/sitecore-jss-react';
import { WithSitecoreContextProps } from '@sitecore-jss/sitecore-jss-react/types/enhancers/withSitecoreContext';
import { TRendition } from '../../../../types/sitecore.types';
import { TThumbnailPreviewEE } from '../../../../types/thumbnailEE.types';

export type TSeriesDetailCarouselIndicatorProps = {
  clickHandler?: (e: React.MouseEvent | React.KeyboardEvent) => void;
  isSelected: boolean;
  index: number;
  baseClassName?: string;
  goSelected: (e: React.MouseEvent | React.KeyboardEvent | number) => void;
};

export type TContentHubSeriesDetailsProps = WithSitecoreContextProps & {
  fields: TContentHubSeriesDetailsFields;
  rendering?: ComponentRendering;
};

export enum InnerComponentTypes {
  Split = 'split',
  Card = 'card',
}

export type TSeriesDetailFeatureItemData = TThumbnailPreviewEE & {
  contextItemUrl?: string;
};

export type TContentHubSeriesDetailsFields = {
  apiData: TSeriesDetailsData;
  itemData?: TSeriesDetailFeatureItemData;
  isComponentUnreleased?: boolean;
};

export type TBreadcrumb = {
  titleUrl: string;
  title: string;
};
export type TButton = {
  label: string;
  url: string;
  target?: string;
};

export type TSummarySpec = {
  key: string;
  value: string;
  active?: string;
  position?: string;
  status?: string;
};

export type TComponentTab = {
  key: string;
  value: string;
};

export type TFeatureRenditions = {
  rendition_desktop_size1200?: TRendition;
  rendition_desktop_size1000?: TRendition;
  rendition_desktop_size500?: TRendition;
  rendition_mobile_size500?: TRendition;
};

export type TAsset = TFeatureRenditions &
  Partial<{
    role: 'main' | 'video' | 'extvideo';
    alt: string;
    bigthumbnail: string;
    thumbnail: string;
    full: string;
  }>;

export type TSeo = {
  title: string;
  description: string;
  keywords: string;
  ogTitle: string;
  ogType: string;
  ogImage: string;
  ogUrl: string;
  ogDescription: string;
  ogSiteName: string;
  jsonLdSchema: string;
  canonicalUrl: string;
};

export type TImage = {
  image?: string;
  imageAlt?: string;
  mobileImage?: string;
  imageAltMobile?: string;
};

export type TSeriesDetailsData = {
  id: number;
  titleUrl?: string;
  title?: string;
  formattedTitle?: string;
  eyebrow?: string;
  innerComponents?: TComponentDetails[];
  description?: string;
  msrp?: string;
  optionalLabel1?: string;
  optionalLabel2?: string;
  breadcrumb?: TBreadcrumb[];
  button1?: TButton;
  button2?: TButton;
  button3?: TButton;
  summarySpecs?: TSummarySpec[];
  components?: TComponentTab[];
  active?: boolean;
  showInNavigation?: boolean;
  assets?: TAsset[];
  seo?: TSeo;
};

export type TComponentDetails = {
  id: number;
  componentType?: InnerComponentTypes;
  title?: string;
  layoutType?: string;
  eyebrow?: string;
  description?: string;
  button1?: TButton;
  button2?: TButton;
  image?: string;
  imageAlt?: string;
  assets?: TAsset[];
  innerComponents?: TComponentDetails[];
  button?: TButton;
};

export type TComponentDetailsProps = {
  className?: string;
  component: TComponentDetails;
  useAnalytics?: boolean;
};

export type TBannerProps = {
  mediaHeightClassName?: string;
  component: TComponentDetails;
  mediaCarouselClassName?: string;
  mobileRenditionKey?: keyof TFeatureRenditions;
  desktopRenditionKey?: keyof TFeatureRenditions;
};

export type TGalleryProps = {
  assets?: TAsset[];
  hasFixedAspectRatio?: boolean;
};

export type TModalComponentsListProps = {
  components: Array<TComponentDetails>;
};

export type TModalComponentsItemProps = {
  component: TComponentDetails;
  cardsCount?: number;
};

export type TInnerSplitModuleButtons = {
  button1?: TButton;
  button2?: TButton;
  isDesktop: boolean;
};
