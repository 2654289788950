import { ComponentRendering } from '@sitecore-jss/sitecore-jss-react';
import { BreadcrumbList } from 'schema-dts';
import { helmetJsonLdProp } from 'react-schemaorg';
import { Params, TNavigationBarFields, TNavigationBarProps, } from '../types/breadcrumbBar.types';
import { windowService } from '../../../../utils/window.utils';
import { TJsonLDObj } from '../../../../types/sitecore.types';
import { TNavigationBarBreadcrumbItem } from '../../navigationBarItem/types/navigationBarItem.types';

export class NavigationBarModel {
    private readonly fields?: TNavigationBarFields | null;
    private readonly params?: Params;
    private readonly rendering?: ComponentRendering;

    constructor(props: TNavigationBarProps) {
        this.fields = props?.fields || null;
        this.params = props?.params || null;
        this.rendering = props?.rendering;
    }

    get thumbnailEE(): string {
        return this.fields?.itemData?.thumbnail || '';
    }

    get componentName(): string {
        return this.rendering?.componentName || '';
    }

    get breadcrumbItems(): Array<TNavigationBarBreadcrumbItem> {
        return this.fields?.itemData?.fields?.breadcrumbs || [];
    }

    get productsPageLabel(): string | undefined {
        return this.fields?.itemData?.fields?.productsPageTitle;
    }

    get productsPageUrl(): string | undefined {
        return this.fields?.itemData?.fields?.productsPageLink;
    }

    get backButtonLabel(): string | undefined {
        return this.fields?.itemData?.fields?.backButtonText;
    }

    get backButtonUrl(): string | undefined {
        return this.fields?.itemData?.fields?.backButtonUrl;
    }

    get isPlm(): boolean {
        return this.params?.isPLM === '1';
    }

    get breadcrumbJSONLD(): TJsonLDObj {
        const origin = windowService()?.location?.origin;
        return helmetJsonLdProp<BreadcrumbList>({
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: this.breadcrumbItems.map((item, index) => ({
                '@type': 'ListItem',
                position: index + 1,
                name: item.text,
                item: `${origin}${item.url}`,
            })),
        });
    }

    get isUnreleased(): boolean {
        return !!this.fields?.isComponentUnreleased;
    }
}
