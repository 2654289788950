import React, { FC } from 'react';

import EditorialImage from './components/editorialImage';
import { default as ProductImage } from './components/contentHubImage';
import { TImageComponentProps } from './types/picture.types';

import './styles.scss';

const Picture: FC<TImageComponentProps> = ({ productImageProps, editorialImageProps }) => {
  if (editorialImageProps) {
    return <EditorialImage {...editorialImageProps} />;
  }

  if (productImageProps) {
    return <ProductImage {...productImageProps} />;
  }

  return null;
};

export default Picture;
