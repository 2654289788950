import React from 'react';
import { getThumbnailProps } from '../../../../utils/youtubeUtils';
import MediaCarousel from '../../../@shared/mediaCarousel';
import { TMediaCarouselSlide } from '../../../@shared/mediaCarousel/types/mediaCarousel.types';
import { TBannerProps } from '../types/innerComponentsPopup.types';

const Banner = (props: TBannerProps): JSX.Element => {
  const { component: model, mediaCarouselClassName, mediaHeightClassName, desktopRenditionKey, mobileRenditionKey } = props;
  const mediaCarouselSlides: TMediaCarouselSlide[] = [];

  model.assets?.forEach((asset) => {
    mediaCarouselSlides.push({
      isVideo: (asset.role === 'video' || asset.role === 'extvideo') && !!asset.full,
      videoUrl: { value: { href: asset.full } },
      productImageProps: {
        mobileRendition: asset?.[mobileRenditionKey || 'rendition_mobile_size500'],
        desktopRendition: asset?.[desktopRenditionKey || 'rendition_desktop_size1200'],
        ...(asset.role === 'extvideo' && { desktopRendition: { url: getThumbnailProps(asset.full || '')?.thumbnailImgSrc || '' } }),
        alt: asset.alt,
      },
    });
  });

  return <MediaCarousel slides={mediaCarouselSlides} mediaHeightClassName={mediaHeightClassName} extraClassName={mediaCarouselClassName} />;
};

export default Banner;
