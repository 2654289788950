import { createContext } from 'react';
import { ALL_SP_CATEGORIES, ALL_SP_LINES, ALL_SP_SERIES } from '../../constants/specialOffersFilters.constants';
import { ProductSpecialOffersListingModel } from './models/productSpecialOffersListing.model';

export type TFilterContextData = {
  categorySelectedInd: number | typeof ALL_SP_CATEGORIES;
  seriesSelectedInd: number | typeof ALL_SP_SERIES;
  lineSelectedInd: number | typeof ALL_SP_LINES;
  model: ProductSpecialOffersListingModel;
  sortBy: 'asc' | 'desc';
};

const FilterContext = createContext<
  TFilterContextData & { updateFilters: (newFilters: Partial<TFilterContextData>) => any; resetFilters: () => any }
>({
  categorySelectedInd: ALL_SP_CATEGORIES,
  seriesSelectedInd: ALL_SP_SERIES,
  lineSelectedInd: ALL_SP_LINES,
  model: new ProductSpecialOffersListingModel({}),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateFilters: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetFilters: () => {},
  sortBy: 'asc',
});

export { FilterContext };
