import {
  ColumnLayoutEnum,
  TCardColumnLayoutFields,
  TCardColumnLayoutProps,
  TCardFields
} from '../types/cardColumnLayout.types';
import { TComponentParamsGeneral, THeadingTag, TLinkField, TStringField } from '../../../types/sitecore.types';
import ctaTypes from '../../../enums/CTA.enums';

export class CardColumnLayoutModel {
    private readonly fields?: TCardColumnLayoutFields;
    private readonly params?: TComponentParamsGeneral;

    constructor(props: TCardColumnLayoutProps) {
        this.fields = props?.fields || undefined;
        this.params = props?.params;
    }

    get layout(): string | ColumnLayoutEnum {
        return this.fields?.columnLayout?.fields?.Key?.value || ColumnLayoutEnum.FourColumnLayout;
    }

    get title(): TStringField | undefined {
        return this.fields?.sectionHeading;
    }

    get eyebrow(): TStringField | undefined {
        return this.fields?.sectionEyebrow;
    }

    get subtitle(): TStringField | undefined {
        return this.fields?.sectionSubheading;
    }

    get link(): TLinkField | undefined {
        return this.fields?.sectionCtaTextLink;
    }

    get cards(): TCardFields[] {
        return this.fields?.items || [];
    }

    get backgroundColor(): string | undefined {
        return this.fields?.componentBgColor?.fields?.Key?.value;
    }

    get textcolor(): string | undefined {
        return this.fields?.textElementColor?.fields?.Key?.value;
    }

    get primaryCTAStyle(): string | ctaTypes {
        return this.fields?.primaryCtaStyle?.fields?.Key?.value || 'primaryBlue';
    }

    get secondaryCTAStyle(): string | ctaTypes {
        return this.fields?.secondaryCtaStyle?.fields?.Key?.value || 'secondaryBluewithArrowicon';
    }

    get rowsPerPage(): number {
        return Number(this.fields?.rowsPerPage?.value) || 6;
    }

    get totalCardsCount(): number {
        return Number(this.fields?.totalCardsCount);
    }

    get isLabelIndicatorShown(): boolean {
        return !this.fields?.hideLabelIndicator?.value;
    }

    get isUiLineIndicatorShown(): boolean {
        return !this.fields?.hideUiLineIndicator?.value;
    }

    get dataSource(): string {
        return this?.fields?.dataSource || '';
    }

    get isUnreleased(): boolean {
        return !!this.fields?.isComponentUnreleased;
    }

    get headingTag(): THeadingTag {
        return this.params?.headingTag?.toLowerCase() as THeadingTag || 'h2';
    }
}
