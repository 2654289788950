import React, { FC, useEffect, useState } from 'react';
import { isEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isEmptyResponse } from '../../utils/checkEmptyResponse';
import useExperienceEditorPlaceholder from '../../hooks/useExperienceEditorPlaceholder';
import ExperienceEditorComponentPlaceholder from '../@shared/experienceEditorComponentPlaceholder';
import EmptyApiDataMessage from '../@shared/emptyApiDataMessage';
import { TModelsListingProps, TProductCard } from './types/modelsListing.types';
import { ModelsListingModel } from './model/modelsListing.model';
import './styles.scss';
import ModelListingCard from './components/modelListingCard';
import { documentService, windowService } from '../../utils/window.utils';
import { modelListing } from '../../constants/hash-route.constants';
import Icon from '../@shared/icon';
import { WithTranslation, withTranslation } from 'react-i18next';
import UnreleasedComponent from '../@shared/unreleasedComponent';
import TextComponentDecider from '../@shared/textComponentDecider';
import { decriptionKeyPlmModelListing, eyebrowKeyPlmModelListing, titleKeyPlmModelListing } from '../../constants/dictionary.constants';
import { getTextType } from '../../utils/getTextType.utils';
import useShowProductComponentTitle from '../../hooks/useShowProductComponentTitle';

const ProductPlmModelsListing: FC<WithTranslation & TModelsListingProps> = (props) => {
  const model = new ModelsListingModel(props);
  const { t } = props;
  const [showMore, setShowMore] = useState(false);
  const apiResponse = isEmptyResponse(props);
  const isPlaceholderShown = useExperienceEditorPlaceholder(props);
  const hasTitle = !!model.modelsTitle?.value;
  const showTitle = useShowProductComponentTitle(hasTitle, [titleKeyPlmModelListing]);

  useEffect(() => {
    const locationHref = windowService().location.href;
    if (locationHref.includes('#') && locationHref.split('#')[1] === modelListing) {
      documentService().getElementById(modelListing)?.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  // the first occurance in the model string is a number
  const columnsCount = model.columnLayout === '' ? 3 : parseInt(model.columnLayout.substring(0, 1));
  const initialDisplayLimit = columnsCount * 2;

  // Component is unreleased
  if (model.isUnreleased) {
    return <UnreleasedComponent />;
  }

  // star  node opened directly in EE
  if (isPlaceholderShown) {
    return <ExperienceEditorComponentPlaceholder image={model.thumbnailEE} componentName={model.componentName} />;
  }

  // not opened from star node
  // data missing when navigating via from products node
  if (!apiResponse && isEditorActive()) {
    return <EmptyApiDataMessage componentName={model.componentName} />;
  }

  // No data and not in EE
  if (!apiResponse && !isPlaceholderShown && !isEditorActive()) {
    return null;
  }

  const mapcard: FC<TProductCard[]> = (items: TProductCard[]) => {
    return (
      <>
        {items.map((item: TProductCard, index: number, array) => {
          const maxLastCards = columnsCount;
          const isLastCards = array.length - index <= maxLastCards;
          return <ModelListingCard key={`${item.title}_key`} {...item} baseUrl={model?.baseUrl} isLastCards={isLastCards} t={t} />;
        })}
      </>
    );
  };

  const showMoreModels: FC<number> = (itemsLength: number) => {
    if (itemsLength > initialDisplayLimit) {
      return (
        <div className={'model-series-listing__show-more row'}>
          <button className={'col-12 col-md-4 col-md-offset-4 model-series-listing__show-more-cta'} onClick={() => setShowMore(!showMore)}>
            {showMore ? t('cnhi-showLessPLMModels') : t('cnhi-showMorePLMModels')}
            <Icon iconName={showMore ? 'arrow-chevron-up' : 'arrow-chevron-down'} size={24} />
          </button>
        </div>
      );
    }
    return null;
  };
  return (
    <div className="model-series-listing-wrapper container" id={modelListing}>
      <div className="model-series-listing__header row">
        <div className="col-12 col-md-6">
          <TextComponentDecider
            type={getTextType('Text', !model.eyebrow?.value)}
            tag={'div'}
            field={model.eyebrow}
            className="model-series-listing__eyebrow "
            dictionaryKey={eyebrowKeyPlmModelListing}
          />
          {showTitle && (
            <div className="model-series-listing__title-wrapper">
              <TextComponentDecider
                type={getTextType('Text', !model.modelsTitle?.value)}
                tag={'h2'}
                field={model?.modelsTitle}
                className="model-series-listing__title"
                dictionaryKey={titleKeyPlmModelListing}
              />
            </div>
          )}
          <TextComponentDecider
            type={getTextType('RichText', !model.modelsDescription?.value)}
            tag={'div'}
            field={model?.modelsDescription}
            className="model-series-listing__subtitle rich-text"
            dictionaryKey={decriptionKeyPlmModelListing}
          />
        </div>
      </div>
      <div className={`model-series-listing__cards ${model.columnLayout === '2 Column' ? 'model-series-listing__cards--two-columns' : ''}`}>
        {model?.items && showMore && mapcard(model?.items)}

        {model?.items && !showMore && mapcard(model?.items.filter((_, index: number) => index < initialDisplayLimit))}
      </div>
      {showMoreModels((model?.items || []).length)}
    </div>
  );
};

export default withSitecoreContext()(withTranslation()(ProductPlmModelsListing));
