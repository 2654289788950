import React, { memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import CustomExternalAnchor from '../customExternalAnchor';

import { isInternalUrl } from '../../../utils/link.utils';

/** React component that turns Sitecore link values that start are internal into react-router route links */
const RoutableSitecoreLink = (props: any) => {
  const { field, editable = true, children, onclick } = props;
  const hasValidHref = field?.value?.href;
  const isEditing = editable && field?.editable;

  // only want to apply the routing link if not editing (if editing, need to render editable link value)
  if (hasValidHref && !isEditing) {
    const value = field.value;

    // determine if a link is a route or not. This logic may not be appropriate for all usages.
    if (isInternalUrl(value.href)) {
      return (
        <RouterLink onClick={onclick} to={value.href} title={value.title} target={value?.target} className={props.className ?? value.class}>
          {children || value.text || value.href}
        </RouterLink>
      );
    }
  }

  return <CustomExternalAnchor {...props} />;
};

export default memo(RoutableSitecoreLink);
