import { TImageField, TLinkField, TStringField } from '../../types/sitecore.types';
import { TCardFields, TCardProps } from '../../types/card.types';

export class CardModel {
  readonly fields?: TCardFields;
  public readonly id?: string;
  constructor(props: TCardProps) {
    this.fields = props.fields;
    this.id = props?.id;
  }

  get title(): TStringField | undefined {
    return this.fields?.title;
  }

  get subtitle(): TStringField | undefined {
    return this.fields?.subtitle;
  }

  get image(): TImageField | undefined {
    return this.fields?.image;
  }

  get mobileImage(): TImageField | undefined {
    return this.fields?.mobileImage;
  }

  get imageSrc(): string | undefined {
    return this.fields?.image?.value?.src;
  }

  get link(): TLinkField | undefined {
    return this.fields?.link;
  }
}
