import React, { FC } from 'react';
import NavigationItems from './components/navigationBarItem';
import { TNavigationBarItemProps } from './types/navigationBarItem.types';

const NavigationBarItem: FC<TNavigationBarItemProps> = ({ item, isLast = false }) => {
  const isNotClickable = item?.url?.trim().length === 0;
  const barItemClassName = isNotClickable ? ' nav-bar__item--text' : '';

  return (
    <>
      {item.text && (
        <li className={`nav-bar__item${isLast ? ' nav-bar__item--last' : barItemClassName}`}>
          <NavigationItems isNotClickable={isNotClickable} item={item} isLast={isLast} />
        </li>
      )}
    </>
  );
};

export default React.memo(NavigationBarItem);
