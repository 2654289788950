import React, { FC } from 'react';

import GeneralHeadOrMetaTags from './components/genericTags';
import TwitterMetaTags from './components/twitterMetaTags';
import OgMetaTags from './components/ogMetaTags';
import { TSeoTagsProps } from './types/seoTags.types';

const SeoTags: FC<TSeoTagsProps> = ({ model }) => (
  <>
    <GeneralHeadOrMetaTags model={model} />
    <OgMetaTags model={model} />
    <TwitterMetaTags model={model} />
  </>
);

export default SeoTags;
