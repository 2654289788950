import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { isInternalUrl } from '../../../../../utils/link.utils';
import RoutableSitecoreLink from '../../../routableSitecoreLink';
import { TLinkWithFieldProps } from '../../types/CTA.types';
import IconInCTA from '../icon';

const ValidHrefAndNotEditing: FC<TLinkWithFieldProps> = (props) => {
  const { field, onClick, ctaClassName, hasCTAIcon, children, target } = props;
  const value = field.value;

  // determine if a link is a route or not. This logic may not be appropriate for all usages.
  if (value.href && isInternalUrl(value.href)) {
    return (
      <RouterLink onClick={onClick} to={value.href} title={value.title} target={value?.target} className={ctaClassName ?? value.class}>
        {children || value.text || value.href}
        {hasCTAIcon && <IconInCTA />}
      </RouterLink>
    );
  } else {
    // for external links i.e when isInternalUrl returns false
    return (
      <RoutableSitecoreLink onclick={onClick} field={field} className={ctaClassName} target={target ?? value?.target}>
        {children || value.text || value.href}
        {hasCTAIcon && <IconInCTA />}
      </RoutableSitecoreLink>
    );
  }
};

export default ValidHrefAndNotEditing;
