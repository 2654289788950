import React, { useEffect, useRef, FC } from 'react';
import { windowService, documentService } from '../../../../utils/window.utils';
import { TPrintTableContent } from '../../types/index.types';
import PrintTable from './printTable';
import PrintTableCSS from './printTableStyles';

const PrintTableContent: FC<TPrintTableContent> = (props) => {
  const printWrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const cleanupFunction = () => props.cleanupFunction();
    if (props.printActive) {
      const clearTableStylesTag = (): void => {
        const styledTag = documentService().getElementById('model-details-specification-table-styles');
        if (styledTag) {
          styledTag.parentElement.removeChild(styledTag);
        }
      };
      clearTableStylesTag();
      const style = documentService().createElement('style');
      style.setAttribute('id', 'model-details-specification-table-styles');
      style.setAttribute('rel', 'stylesheet');
      style.setAttribute('type', 'text/css');
      style.setAttribute('media', 'print');

      style.appendChild(documentService().createTextNode(PrintTableCSS(props.colHeadings.length + 1)));
      documentService().head.appendChild(style);
      // Note: For some crazy reason window.print is not a blocking command when the page freshly load without cache
      // As a temporary fix adding eventListeners for after print
      /** afterprint event is replaced with focus event as afterprint is triggered even before the print operation
       *  is started in some mobile devices 
      */
      windowService().addEventListener('focus', clearTableStylesTag, { once: true });
      windowService().addEventListener('focus', cleanupFunction, { once: true });
      if (windowService().print) {
        windowService().print();
      }
    }
  }, [props.printActive, printWrapperRef]);

  if (!props.printActive) {
    return null;
  }

  return <PrintTable colHeadings={props.colHeadings} mrpRow={props.mrpRow} categoryRows={props.categoryRows} />;
};

export default React.memo(PrintTableContent);
