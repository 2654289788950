import React, { FC } from 'react';
import ctaTypes from '../../../enums/CTA.enums';
import { getExternalLinkRelAttribute } from '../../../utils/link.utils';
import RoutableNotEditableLink from '../routableNotEditableLink';
import { TCTAProps } from './types/CTA.types';
import LinkWithField from './components/LinkWithField';
import IconInCTA from './components/icon';

import './styles.scss';

const CTA: FC<TCTAProps> = ({ type, href, onClick, children, field, extraClassName, iconName, target }) => {
  const ctaClassName = [ctaTypes['baseButton'], type, extraClassName].join(' ');

  const isSecondaryCTA = type === ctaTypes.secondaryBlue || type === ctaTypes.secondaryWhite;
  const hasCTAIcon = isSecondaryCTA || !!iconName;

  if (field) {
    return (
      <LinkWithField onClick={onClick} field={field} ctaClassName={ctaClassName} hasCTAIcon={hasCTAIcon} target={target}>
        {children}
      </LinkWithField>
    );
  } else {
    return (
      <RoutableNotEditableLink
        href={href}
        target={target}
        className={ctaClassName}
        onClick={onClick}
        {...getExternalLinkRelAttribute(href)}
      >
        {children}
        {hasCTAIcon && <IconInCTA />}
      </RoutableNotEditableLink>
    );
  }
};
export default React.memo(CTA);
