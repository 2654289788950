import React, { FunctionComponent } from 'react';
import { Text, isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { THeroCarouselItemProps } from '../types/herocarousel.types';
import VideoPlayer from '../../@shared/videoPlayer';
import Picture from '../../@shared/picture';
import { TContentHubImageProps } from '../../@shared/picture/types/contentHubImage.types';
import { getThumbnailProps } from '../../../utils/youtubeUtils';

const HeroCarouselSlide: FunctionComponent<THeroCarouselItemProps> = ({
  fields,
  videoPlaying,
  videoPaused,
  playCallback,
  pauseCallback,
  endCallback,
}) => {
  const productImageProps: TContentHubImageProps = {
    ...((fields.role === 'video' || fields.role === 'main') && {
      desktopRendition: fields?.rendition_desktop_size1900,
      desktopSDRendition: fields?.rendition_desktop_size1200,
      tabletLandscapeRendition: fields?.rendition_tablet_size1000,
      tabletRendition: fields?.rendition_tablet_size1200,
      mobileRendition: fields?.rendition_mobile_size1100,
    }),
    ...(fields.role === 'extvideo' && { desktopRendition: { url: getThumbnailProps(fields.full || '')?.thumbnailImgSrc || '' } }),
    imageStyle: { zIndex: isEditorActive() ? 0 : -2 },
    imageClassName: 'slide__image',
    alt: fields.alt,
  };

  return (
    <div className={'slide__inner'}>
      {fields?.role && fields.role === 'main' ? (
        <Picture productImageProps={productImageProps} />
      ) : (
        <VideoPlayer
          link={fields?.full}
          title={fields?.title}
          autoPlay={true}
          muted={true}
          playCallback={playCallback}
          pauseCallback={pauseCallback}
          endCallback={endCallback}
          productImageProps={productImageProps}
          showThumbnail={true}
        />
      )}
      <div className={`slide-footer${videoPlaying || videoPaused ? ' slide-footer--hide-bg' : ''}`}>
        {fields.description && fields.role === 'main' && (
          <Text field={{ value: fields.description as string }} tag="div" className="slide-description" editable={false} />
        )}
      </div>
    </div>
  );
};

export default HeroCarouselSlide;
