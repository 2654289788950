import { TStringField } from '../../../types/sitecore.types';
import { TProductLinesData } from '../types/cards.types';

export class CardsModel {
  private readonly fields?: any;
  private readonly itemData?: {
    contextItemUrl?: string;
  };

  constructor(props: any) {
    this.fields = props?.fields || undefined;
    this.itemData = props?.fields?.itemData;
  }

  get title(): TStringField {
    return this.fields?.itemData?.fields?.lineListingTitle;
  }

  get cards(): TProductLinesData {
    return this.fields?.apiData;
  }

  get baseUrl(): string {
    return this.itemData?.contextItemUrl || '';
  }
}
