import React, { Fragment } from 'react';
import { TCardProps } from '../../../types/card.types';
import { CardModel } from '../../../lib/shared-models/card.model';
import useEEActive from '../../../hooks/useEEActive';
import './index.scss';
import RoutableSitecoreLink from '../routableSitecoreLink';
import { targetLinkdsk } from '../../../utils/targetDskLink';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { BreakpointsInPx } from '../../../constants/breakpoints.constants';
import Card from './components/card';

const StoriesCard = (props: TCardProps): JSX.Element => {
  const model = new CardModel(props);
  const isEE = useEEActive();
  const isDesktop = useMediaQuery(`(min-width: ${BreakpointsInPx['tbl-p']})`);

  return (
    <Fragment>
      {isEE ? (
        <div className="stories-card">
          <Card id={props.id} isEE={isEE} image={model.image} mobileImage={model.mobileImage} title={model.title} link={model.link} />
        </div>
      ) : (
        <RoutableSitecoreLink className="stories-card" field={model.link} target={targetLinkdsk(model?.link, isDesktop)}>
          <Card id={props.id} isEE={isEE} image={model.image} mobileImage={model.mobileImage} title={model.title} link={model.link} />
        </RoutableSitecoreLink>
      )}
    </Fragment>
  );
};

export default StoriesCard;
