import React, { FC } from 'react';
import VideoPlayer from '../../../@shared/videoPlayer';
import { TSplitModuleMediaVideo } from '../../types/splitModule.types';

const MediaVideo: FC<TSplitModuleMediaVideo> = (props) => {
  const { desktopImage, mobileImage, isDesktop, video } = props;

  return (
    <div className={'split-module__column split-module__video'}>
      <div className={'split-module__video-wrapper'}>
        <VideoPlayer
          link={video?.value?.href}
          autoPlay={true}
          showThumbnail={true}
          editorialImageProps={{
            mobileImage: mobileImage,
            desktopImage: desktopImage,
            alt: (isDesktop ? desktopImage?.value?.alt : mobileImage?.value?.alt) as string,
          }}
          width={'auto'}
        />
      </div>
    </div>
  );
};

export default MediaVideo;
