import { ComponentParams, ComponentRendering } from '@sitecore-jss/sitecore-jss-react';

export function findCtaType(rendering: ComponentRendering | undefined, popUpPlaceholderName = 'jss-generic-popup'): 'primary-cta' | '' {
  if (!rendering) return '';
  const params: ComponentParams | undefined = (
    rendering?.placeholders?.[popUpPlaceholderName]?.find?.(
      (_: ComponentRendering) => _?.componentName === 'GenericPopUp'
    ) as ComponentRendering
  )?.params;
  if (params && !params.ctaStyles) return 'primary-cta';
  return params?.ctaStyles?.toLocaleLowerCase()?.includes('primary') ? 'primary-cta' : '';
}
