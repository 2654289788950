import React, { FC } from 'react';
import { CarouselDirectionEnum, DirectionEnum } from '../../../enums/carousel.enums';
import { TCustomArrowProps } from './types/CarouselArrow.types';
import './styles.scss';
import Icon from '../icon';

const CustomArrow: FC<TCustomArrowProps> = ({ direction, onClick, cssClass='' }: TCustomArrowProps) => {
  const icon = `arrow-chevron-${direction === CarouselDirectionEnum.PREVIOUS ? DirectionEnum.LEFT : DirectionEnum.RIGHT}` as
    | 'arrow-chevron-left'
    | 'arrow-chevron-right';
  return (
    <div
      className={`custom-arrow custom-arrow--${
        direction === CarouselDirectionEnum.PREVIOUS ? DirectionEnum.LEFT : DirectionEnum.RIGHT
      } ${cssClass}`}
      onClick={onClick}
    >
      <div className="fade"></div>
      <button className="custom-arrow__button" aria-label={`${direction} slide / item`} type="button">
        <Icon iconName={icon} color="white" size={32} extraClassName="custom-arrow__icon" />
      </button>
    </div>
  );
};

export default CustomArrow;
