import React, { FC, SyntheticEvent } from 'react';
import Icon from '../../../@shared/icon';

type TOverviewCarouselArrowProps = {
  direction: 'previous' | 'next';
  onClick: (event: SyntheticEvent) => void;
  customClass?: string;
};

const OverviewCarouselArrow: FC<TOverviewCarouselArrowProps> = ({ direction, onClick, customClass = '' }) => {
  const icon = ('arrow-chevron-' + (direction === 'previous' ? 'left' : 'right')) as 'arrow-chevron-left' | 'arrow-chevron-right';
  return (
    <button
      className={`series-overview-carousel__arrow-control series-overview-carousel__arrow-control--${direction} ${customClass}`}
      aria-label={direction + 'slide / item'}
      onClick={onClick}
    >
      <Icon iconName={icon} size={24} color="white" />
    </button>
  );
};

OverviewCarouselArrow.displayName = 'OverviewCarouselArrow';

export default OverviewCarouselArrow;
