import { THeadingProps } from '../types/heading.types';
import { TLinkField, TStringField } from '../../../../types/sitecore.types';

export class HeadingModel {
  private readonly props: THeadingProps;
  private readonly isEE: boolean;

  constructor(props: THeadingProps, isEE: boolean) {
    this.props = props || null;
    this.isEE = isEE;
  }

  get title(): TStringField {
    if (typeof this.props.title === 'string') return { value: this.props.title } as TStringField;
    return this.props?.title as TStringField;
  }

  get eyebrow(): TStringField {
    if (typeof this.props.eyebrow === 'string') return { value: this.props.eyebrow } as TStringField;
    return this.props?.eyebrow as TStringField;
  }

  get subtitle(): TStringField {
    if (typeof this.props.subtitle === 'string') return { value: this.props.subtitle } as TStringField;
    return this.props?.subtitle as TStringField;
  }

  get link(): TLinkField | undefined {
    return this.props.link;
  }

  get editable(): boolean {
    return !!this.props.editable;
  }

  get isLinkExistsOrIsEE(): boolean {
    return !!(
      (this.props.link?.value?.text && (!!this.props?.link?.value?.href || !!this.props.cbClickButton)) ||
      (this.isEE && this.editable)
    );
  }

  get eyebrowExistsOrIsEE(): boolean {
    return !!(this.eyebrow?.value || (this.isEE && this.editable));
  }

  get titleExistsOrIsEE(): boolean {
    return !!(this.title?.value || (this.isEE && this.editable));
  }

  get subtitleExistsOrIsEE(): boolean {
    return !!(this.subtitle?.value || (this.isEE && this.editable));
  }
}
