import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React, { FC } from 'react';
import { iconSize } from '../../../constants/icon.constants';
import ctaTypes from '../../../enums/CTA.enums';
import { targetLinkdsk } from '../../../utils/targetDskLink';
import CTA from '../../@shared/CTA';
import CustomExternalAnchor from '../../@shared/customExternalAnchor';
import { CustomText } from '../../@shared/customText';
import Icon from '../../@shared/icon';
import Picture from '../../@shared/picture';
import RoutableSitecoreLink from '../../@shared/routableSitecoreLink';
import { TCarouselCardProps } from '../types/carousel.types';

const Card: FC<TCarouselCardProps> = (props) => {
  const {
    item,
    mobileImage,
    desktopImage,
    isWide,
    isEE,
    getDesktopTransformation,
    showOverlayVideo,
    handleSplitModuleModal,
    isSingleCard,
  } = props;

  return (
    <div className={`special-offers-card special-offers-card--${item?.type?.value === 'offerCardWide' ? 'large' : 'small'}`}>
      <div className="card-with-header-image slide-card slide-card--strech">
        <div className={`special-offers-card__image-wrapper ${item?.useVideo ? 'special-offers-card__image-wrapper--video' : ''}`}>
          <Picture
            editorialImageProps={{
              id: item?.id,
              mobileImage: mobileImage,
              desktopImage: desktopImage,
              mobileTransformation: 'Size300',
              desktopTransformation: getDesktopTransformation(item?.type?.value, isSingleCard),
              cssClassImageEl: 'slide-card__image',
              cssClassPictureEl: 'slide-card__image',
              alt: (isWide ? desktopImage?.value?.alt : mobileImage?.value?.alt) as string,
              isVideoThumbnail: item?.useVideo,
            }}
          />
          {item?.useVideo && (
            <div onClick={showOverlayVideo} className="special-offers-card__video-play-button">
              <Icon iconName="action-play" extraClassName="special-offers-card__video-play-button-icon" />
            </div>
          )}
          {item?.badgeLink?.value?.href && (
            <div className="card-label">
              <CustomExternalAnchor field={item?.badgeLink} target={targetLinkdsk(item?.badgeLink, isWide)} />
            </div>
          )}
        </div>

        <div className="special-offers-card__border">
          <div className="special-offers-card__title">
            <span>
              {item?.title && <CustomText tag="div" field={item?.title} className="slide-card__text slide-card__text--title" />}
              {item?.subtitle && <RichText tag="div" field={item?.subtitle} className="slide-card__text slide-card__content rich-text" />}
            </span>
          </div>

          <div className="slide-card__button-wrapper">
            {item?.showPopupWithSplitModule ? (
              <CTA
                type={ctaTypes.primaryYellow}
                onClick={() => {
                  handleSplitModuleModal(true);
                }}
                extraClassName="slide-card__button btn-primary-yellow"
              >
                {item?.splitModuleLabel}
              </CTA>
            ) : (
              (isEE || item?.buttonPrimary?.value?.text || item?.buttonPrimary?.value?.href) && (
                <RoutableSitecoreLink
                  className="slide-card__button btn-primary-yellow"
                  field={item?.buttonPrimary}
                  target={targetLinkdsk(item?.buttonPrimary || undefined, isWide)}
                />
              )
            )}

            {item?.type?.value === 'offerCardWide' && (isEE || (item?.buttonSecondary?.value?.text && item?.buttonSecondary?.value?.href)) && (
              <div className="slide-card__button">
                <span className="link-with-arrow">
                  <CustomExternalAnchor field={item?.buttonSecondary} target={targetLinkdsk(item?.buttonSecondary || undefined, isWide)} />
                  <Icon iconName="arrow-chevron-right" size={iconSize.dsk} extraClassName="link-with-arrow-icon" />
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
