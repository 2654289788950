import React, { useState } from 'react';
import { SeriesDetailsModel } from '../models/contentHubSeriesDetails.model';
import { Placeholder, ComponentRendering } from '@sitecore-jss/sitecore-jss-react';
import { TContentHubSeriesDetailsProps } from '../../@shared/innerComponentsPopup/types/innerComponentsPopup.types';
import ComponentDetails from '../../@shared/innerComponentsPopup/componentDetails';
import Icon from '../../@shared/icon';
import AnalyticsVisibleEvent from '../../@shared/analyticsVisibleEvent';
import TextComponentDecider from '../../@shared/textComponentDecider';
import { getTextType } from '../../../utils/getTextType.utils';
import { titleKeySeriesFeature } from '../../../constants/dictionary.constants';

const MobileSeriesDetails = (props: TContentHubSeriesDetailsProps) => {
  const model = new SeriesDetailsModel(props);
  const isEEActive = props?.sitecoreContext?.pageEditing;
  const [collapsedTabs, setCollapsedTabs] = useState(model.innerComponents?.map(({ id }) => id));

  const toggleTab = (tabId: number) => {
    if (collapsedTabs.includes(tabId)) {
      setCollapsedTabs(collapsedTabs.filter((tab) => tab !== tabId));
    } else {
      setCollapsedTabs([...collapsedTabs, tabId]);
    }
  };

  return (
    <AnalyticsVisibleEvent event="series_features_visible">
      <section className="series-details">
        <div className="series-details__container">
          <div className="series-details__header">
            <TextComponentDecider
              type={getTextType('Text', !model.title)}
              tag={'h2'}
              field={{ value: model?.title }}
              className="series-details__title"
              dictionaryKey={titleKeySeriesFeature}
            />
            <div className={`series-details__popup ${isEEActive ? 'generic-popupEE-width' : ''}`}>
              <Placeholder name={'jss-generic-popup'} rendering={props?.rendering as ComponentRendering} />
            </div>
          </div>
          <ul className="series-details__tabs">
            {model?.innerComponents?.map((component, index) => {
              const isCollapsed = collapsedTabs.includes(component.id);
              return (
                <li className={`series-details__tab ${!isCollapsed ? 'series-details__tab--selected' : ''}`} key={component?.id}>
                  <button
                    className="series-details__tab-button"
                    onClick={() => {
                      toggleTab(component.id);
                    }}
                  >
                    <div className="series-details__title-width">
                      <h3>{component.eyebrow}</h3>
                    </div>
                    <Icon size={24} iconName={isCollapsed ? 'arrow-chevron-down' : 'arrow-chevron-up'} />
                  </button>
                  <ComponentDetails
                    component={model?.innerComponents?.[index]}
                    className={`${isCollapsed ? 'series-details__component--hidden' : ''}`}
                    useAnalytics
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </section>
    </AnalyticsVisibleEvent>
  );
};

export default MobileSeriesDetails;
