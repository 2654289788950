import { Image } from '@sitecore-jss/sitecore-jss-react';
import React, { FC } from 'react';
import { primaryCTA, secondaryCTA } from '../../../../constants/analytics.constants';
import { BreakpointsInPx } from '../../../../constants/breakpoints.constants';
import ctaTypes from '../../../../enums/CTA.enums';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { googleCtaClickEvent } from '../../../../utils/analytics';
import { targetLinkdsk } from '../../../../utils/targetDskLink';
import CTA from '../../../@shared/CTA';
import RoutableSitecoreLink from '../../../@shared/routableSitecoreLink';
import { TCardButtons } from '../../types/cardColumnLayout.types';

const primaryCta = {
  primaryYellow: ctaTypes.primaryYellow,
  primaryDarkblue: ctaTypes.primaryBlue,
  primaryGhost: ctaTypes.primaryGhost,
};
const secondaryCta = {
  secondaryWhite: ctaTypes.secondaryWhite,
  secondaryBluewithArrowicon: ctaTypes.secondaryBlue,
};

const CardButtons: FC<TCardButtons> = (props) => {
  const {
    showPopupWithSplitModule,
    cta1,
    cta2,
    splitModuleLabel,
    useImageCTA,
    title,
    primaryStyle,
    appStoreImage,
    googlePlayImage,
    secondaryStyle,
    handleSplitModuleModal,
  } = props;
  const isDesktop = useMediaQuery(`(min-width: ${BreakpointsInPx['tbl-p']})`);
  if (!showPopupWithSplitModule && !cta1?.value?.text && !cta1?.value?.href && !cta2?.value?.text && !cta2?.value?.href) {
    return null;
  }

  return (
    <div className={'card-column__content-buttons'}>
      {showPopupWithSplitModule && (
        <CTA
          type={primaryCta[primaryStyle]}
          onClick={(): void => {
            handleSplitModuleModal(true);
            googleCtaClickEvent(primaryCTA, '#', splitModuleLabel, title?.value);
          }}
          extraClassName="card-column__popup-cta"
        >
          {splitModuleLabel}
        </CTA>
      )}
      {cta1?.value?.text &&
        cta1?.value?.href &&
        (useImageCTA?.value ? (
          <RoutableSitecoreLink field={cta1} target={targetLinkdsk(cta1, isDesktop)} className="card-column__app-store-cta">
            <Image loading="lazy" field={appStoreImage?.value} />
          </RoutableSitecoreLink>
        ) : (
          <CTA
            type={primaryCta[primaryStyle]}
            field={cta1}
            target={targetLinkdsk(cta1, isDesktop)}
            extraClassName="card-column__primary-cta"
            onClick={() => googleCtaClickEvent(primaryCTA, cta1?.value?.href, cta1?.value?.text, '')}
          />
        ))}
      {cta2?.value?.text &&
        cta2?.value?.href &&
        (useImageCTA?.value ? (
          <RoutableSitecoreLink field={cta2} target={targetLinkdsk(cta2, isDesktop)} className="card-column__play-store-cta">
            <Image loading="lazy" field={googlePlayImage?.value} />
          </RoutableSitecoreLink>
        ) : (
          <CTA
            type={secondaryCta[secondaryStyle]}
            field={cta2}
            target={targetLinkdsk(cta2, isDesktop)}
            extraClassName="card-column__secondary-cta"
            onClick={() => googleCtaClickEvent(secondaryCTA, cta2?.value?.href, cta2?.value?.text, '')}
          />
        ))}
    </div>
  );
};

export default CardButtons;
