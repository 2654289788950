import { RichText, Text, isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect, useRef } from 'react';
import { BreakpointsInPx } from '../../constants/breakpoints.constants';
import ctaTypes from '../../enums/CTA.enums';
import useEEActive from '../../hooks/useEEActive';
import useExperienceEditorPlaceholder from '../../hooks/useExperienceEditorPlaceholder';
import useMediaQuery from '../../hooks/useMediaQuery';
import '../../styles/@shared/hero-banner.scss';
import { isEmptyResponse } from '../../utils/checkEmptyResponse';
import { targetLinkdsk } from '../../utils/targetDskLink';
import CTA from '../@shared/CTA';
import { CustomText } from '../@shared/customText';
import EmptyApiDataMessage from '../@shared/emptyApiDataMessage';
import ExperienceEditorComponentPlaceholder from '../@shared/experienceEditorComponentPlaceholder';
import Picture from '../@shared/picture';
import SocialMediaBar from '../@shared/socialMediaBar';
import UnreleasedComponent from '../@shared/unreleasedComponent';
import { ProductSpecialOffersDetailHeroBannerModel } from './models/ProductSpecialOffersDetailHeroBanner.model';
import { ProductSpecialOffersDetailHeroBannerProps } from './types/ProductSpecialOffersDetailHeroBanner.types';
import { appendRootToInternalLink } from '../../utils/appendRootToInternalLink.utils';
import useSitecoreContext from '../../hooks/useSitecoreContext';

const ProductSpecialOffersDetailHeroBanner = (props: ProductSpecialOffersDetailHeroBannerProps) => {
  const isEE = useEEActive();
  const context = useSitecoreContext();
  const model = new ProductSpecialOffersDetailHeroBannerModel(props, context?.sitecoreContext);
  const smallHeroRef = useRef<HTMLDivElement | null>(null);
  const isDesktop = useMediaQuery(`(min-width: ${BreakpointsInPx['tbl-p']})`);
  const apiResponse = isEmptyResponse(props);
  const isPlaceholderShown = useExperienceEditorPlaceholder(props);
  const primaryCTAHref = appendRootToInternalLink(model.primaryLink?.value?.href);
  const secondaryCTAHref = appendRootToInternalLink(model.secondaryLink?.value?.href);

  const shouldShowDefaultImage = !model?.desktopImage?.url && !model?.mobileImage?.url;

  const productImage = {
    ...model.productImageProps,
    pictureClassName: 'small-hero-banner__image-wrapper',
    imageClassName: 'small-hero-banner__image',
  };

  if ((shouldShowDefaultImage && model?.defaultDesktopImage) || model?.defaultMobileImage) {
    productImage['mobileRendition'] = { url: model?.defaultMobileImage };
    productImage['desktopRendition'] = { url: model?.defaultDesktopImage };
  }

  useEffect(() => {
    if (isEE) return;
    if (smallHeroRef) {
      const smallHeroContentEl = smallHeroRef?.current?.querySelector('.small-hero-banner__content') as HTMLDivElement;
      if (!smallHeroContentEl) return;
      smallHeroContentEl.style.minHeight = `${
        isDesktop ? model?.desktopImage?.height : (model.mobileImage?.height || model?.desktopImage?.height) ?? 0
      }px`;
    }
  }, [smallHeroRef, isEE, isDesktop]);

  // Component is unreleased
  if (model.isUnreleased) {
    return <UnreleasedComponent />;
  }
  // star  node opened directly in EE
  if (isPlaceholderShown) {
    return <ExperienceEditorComponentPlaceholder image={model.thumbnailEE} componentName={model.componentName} />;
  }
  // not opened from star node
  // data missing when navigating via from products node
  if (!apiResponse && isEditorActive()) {
    return <EmptyApiDataMessage componentName={model.componentName} />;
  }
  // No data and not in EE
  if (!apiResponse && !isPlaceholderShown && !isEditorActive()) {
    return null;
  }

  return (
    <div className="hero-banner">
      <div className="small-hero-banner" ref={smallHeroRef}>
        {model.socialMediaBarProps && (
          <SocialMediaBar {...model.socialMediaBarProps} extraClassName="small-hero-banner__social-media-bar" />
        )}
        <Picture productImageProps={productImage} />
        <div className={`small-hero-banner__content ${model.isGradientDisabled ? '' : 'small-hero-banner__content--background'}`}>
          <Text field={{ value: model?.eyebrow || '' }} className="small-hero-banner__eyebrow" tag="p" />

          <CustomText tag={model.headingTag} field={{ value: model?.title || '' }} className={'small-hero-banner__title'} />

          <RichText
            field={{ value: model?.description || '' }}
            className="rich-text color-inherit-link small-hero-banner__description"
            tag="div"
          />

          <div className="small-hero-banner__button-wrapper">
            {model?.primaryLink && (
              <CTA
                type={ctaTypes.primaryYellow}
                href={primaryCTAHref}
                target={targetLinkdsk(model.primaryLink, isDesktop)}
                extraClassName="small-hero-banner__button"
              >
                {model.primaryLink.value.title}
              </CTA>
            )}
            {model.secondaryLink && (
              <CTA
                type={ctaTypes.primaryGhost}
                href={secondaryCTAHref}
                target={targetLinkdsk(model.secondaryLink, isDesktop)}
                extraClassName="small-hero-banner__button"
              >
                {model.secondaryLink.value.title}
              </CTA>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductSpecialOffersDetailHeroBanner;
