import React, { FC } from 'react';

import { BreakpointsInPx } from '../../../../../constants/breakpoints.constants';
import Icon from '../../../icon';
import { TCarouselNavDeckCardProps } from './index.types';
import { iconSize } from '../../../../../constants/icon.constants';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import Picture from '../../../picture';

const CarouselNavDeckCard: FC<TCarouselNavDeckCardProps> = ({ slide, currentCardIndex, goToCard, index, isEE }) => {
  const isWide = useMediaQuery(`(min-width: ${BreakpointsInPx['tbl-p']})`);

  return (
    <div
      key={`overlay-carousel-popup__slide-image-wrapper-${index}`}
      id={`overlay-carousel-popup__slide-image-wrapper-${index}`}
      className="overlay-carousel-popup__slide-image-wrapper"
      onClick={(): void => goToCard(index)}
    >
      {!isEE && (
        <div
          className={`
        overlay-carousel-popup__slide-image-blur
        ${currentCardIndex === index && 'overlay-carousel-popup__slide-image-blur--deactivate'}
      `}
        />
      )}
      {!isEE && <div className={currentCardIndex === index ? 'overlay-carousel-popup__slide-highlight-bar' : ''} />}
      {slide.isVideo && (
        <div
          className={`
            overlay-carousel-popup__slide-video-play-button-slider
            overlay-carousel-popup__slide-video-play-button-slider--in-slider
            ${currentCardIndex !== index && 'overlay-carousel-popup__slide-video-play-button-slider--in-slider-play-blur'}
        `}
        >
          <Icon iconName="action-play" extraClassName="overlay-carousel-popup__icon" size={isWide ? iconSize.xs : iconSize.xxs} />
        </div>
      )}
      <Picture
        editorialImageProps={
          !slide.productImageProps
            ? {
                id: slide.id,
                mobileImage: slide.mobileImage?.value?.src ? slide.mobileImage : slide.desktopImage,
                desktopImage: slide.fullImage?.value?.src ? slide.fullImage : slide.desktopImage,
                mobileTransformation: 'Size200',
                desktopTransformation: 'Size200',
                cssClassPictureEl: 'overlay-carousel-popup__slide-image--fixed-aspect-ratio',
                cssClassImageEl: 'overlay-carousel-popup__image-style',
                alt: (isWide ? slide.desktopImage?.value?.alt : slide.mobileImage?.value?.alt) as string,
                isVideoThumbnail: slide.isVideo,
              }
            : undefined
        }
        // When renditions or more than 2 images to be sent, productImageProps should be used
        productImageProps={
          slide.productImageProps && {
            ...slide.productImageProps,
            isVideoThumbnail: slide.isVideo,
            pictureClassName: 'overlay-carousel-popup__slide-image--fixed-aspect-ratio',
            imageClassName: 'overlay-carousel-popup__image-style',
          }
        }
      />
    </div>
  );
};

export default React.memo(CarouselNavDeckCard);
