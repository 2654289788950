import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { TSeoTagsProps } from '../types/seoTags.types';

const TwitterMetaTags: FC<TSeoTagsProps> = ({ model }) => (
  <Helmet>
    {model.twitterCard && <meta name="twitter:card" content={model.twitterCard} />}
    {model.twitterSite && <meta name="twitter:site" content={model.twitterSite} />}
    {model.twitterTitle && <meta name="twitter:title" content={model.twitterTitle} />}
    {model.twitterDescription && <meta name="twitter:description" content={model.twitterDescription} />}
    {model.twitterImage && <meta name="twitter:image" content={model.twitterImage} />}
  </Helmet>
);

export default TwitterMetaTags;
