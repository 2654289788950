import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { specsExpandEvent, specsExportEvent } from '../../../../../constants/analytics.constants';
import { iconSize } from '../../../../../constants/icon.constants';
import { googleButtonClickEvent, googleExportEvent } from '../../../../../utils/analytics';
import Heading from '../../../../@shared/heading';
import Icon from '../../../../@shared/icon';
import { THeaderProps } from '../../../types/index.types';
import InputBox from './inputBox';
import { eyebrowKeyModelSpecification, titleKeyModelSpecification } from '../../../../../constants/dictionary.constants';

const TableHeader: FC<THeaderProps> = (props) => {
  const { t } = useTranslation();
  const {
    eyebrow,
    title,
    button1,
    iconsList,
    isDesktop,
    convertToExcelLink,
    categoryRows,
    expandedRows,
    searchTerm,
    setSearchTerm,
    printTable,
    hideArrowButton,
    moveGridLeft,
    moveGridRight,
    toggleAllRows,
    productLine,
    productSeries,
    clearSearch,
  } = props;

  const expandTitle = expandedRows.length === categoryRows?.length ? t('cnhi-collapseAll') : t('cnhi-expandAll');
  return (
    <div className="model-details-specification-table-header">
      <div className="model-details-specification-table-header__wrapper">
        <Heading
          eyebrow={eyebrow}
          title={title}
          link={{
            value: {
              href: button1?.url,
              text: button1?.label,
              target: button1?.target,
            },
          }}
          useDictionaryFallback
          titleDictionaryKey={titleKeyModelSpecification}
          eyebrowDictionaryKey={eyebrowKeyModelSpecification}
        />
        <div className="model-details-specification-table-header__links-wrapper model-details-specification-table-header__flex">
          {isDesktop && (
            // special case to not use RoutableNotEditableLink
            // ignored to allow download
            <a
              className="model-details-specification-table-header__link--export"
              href={convertToExcelLink}
              onClick={(): void => googleExportEvent(specsExportEvent, t('cnhi-excelExport'), productLine, productSeries)}
            >
              <span className="model-details-specification-table-header__link--export-text">{t('cnhi-excelExport')}</span>
              <Icon iconField={iconsList[0]} size={iconSize.mbl} extraClassName="model-details-specification-table-header__link-icon" />
            </a>
          )}
          {isDesktop && (
            <span
              className="model-details-specification-table-header__link--print"
              onClick={() => {
                printTable();
                googleExportEvent(specsExportEvent, t('cnhi-print'), productLine, productSeries);
              }}
            >
              <span className="model-details-specification-table-header__link--print__text">{t('cnhi-print')}</span>
              <Icon
                iconField={iconsList[1]}
                size={iconSize.xs}
                extraClassName="model-details-specification-table-header__link--print__icon"
              />
            </span>
          )}
        </div>
        <div className="model-details-specification-table-header__last-row model-details-specification-table-header__flex">
          <div>
            <button
              className="model-details-specification-table-header__link--button"
              onClick={() => {
                toggleAllRows && toggleAllRows();
                googleButtonClickEvent(specsExpandEvent, expandTitle, productLine, productSeries);
              }}
            >
              {categoryRows && (
                <>
                  {expandedRows.length === categoryRows.length ? t('cnhi-collapseAll') : t('cnhi-expandAll')}
                  <Icon
                    iconName={expandedRows.length === categoryRows.length ? 'action-minus' : 'action-plus'}
                    size={iconSize.dsk}
                    extraClassName="table-expand-icon"
                  />
                </>
              )}
            </button>
            {isDesktop && (
              <div className="model-details-specification-table-header__search-input">
                <InputBox searchTerm={searchTerm} setSearchTerm={setSearchTerm} clearSearch={clearSearch} />
              </div>
            )}
          </div>
          {!isDesktop && (
            <span className="model-details-specification-table-header__link--print">
              <span onClick={printTable}>
                <Icon
                  iconField={iconsList[1]}
                  size={iconSize.mbl}
                  extraClassName="model-details-specification-table-header__link--print__icon"
                />
              </span>
            </span>
          )}
          {!hideArrowButton && (
            <div className="model-details-specification-table-header__arrow-wrapper">
              <div
                className="model-details-specification-table-header__arrow model-details-specification-table-header__arrow--left"
                onClick={moveGridLeft}
              >
                <Icon iconName="arrow-chevron-left" size={iconSize.xlg} extraClassName="table-arrow-left" />
              </div>
              <div
                className="model-details-specification-table-header__arrow model-details-specification-table-header__arrow--right"
                onClick={moveGridRight}
              >
                <Icon iconName="arrow-chevron-right" size={iconSize.xlg} extraClassName="table-arrow-right" />
              </div>
            </div>
          )}
        </div>
        {!isDesktop && (
          <div className="model-details-specification-table-header__search-input">
            <InputBox searchTerm={searchTerm} setSearchTerm={setSearchTerm} clearSearch={clearSearch} />
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(TableHeader);
