import {TStringField, TIconField, TLinkField, TDefaultSitecoreItem} from '../../../../../types/sitecore.types';
import {TQuickLinksLinkFields} from '../types/quickLinksLink.types';

export class QuickLinksLinkModel {
  protected readonly fields?: TQuickLinksLinkFields | null;
  public readonly id: string | undefined;

  constructor(item: TDefaultSitecoreItem<TQuickLinksLinkFields>) {
    this.fields = item.fields || null;
    this.id = item.id;
  }

  get all(): TQuickLinksLinkFields {
    return this.fields as TQuickLinksLinkFields
  }

  get title(): TStringField | undefined {
    return this.fields?.title;
  }

  get link(): TLinkField | undefined {
    return this.fields?.link;
  }

  get icon(): TIconField | undefined {
    return this.fields?.icon;
  }

  get itemid(): string {
    return this.id ?? '';
  }
}
