import React, { useState, FC } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { BreakpointsInPx } from '../../../../constants/breakpoints.constants';
import ctaTypes from '../../../../enums/CTA.enums';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { targetLinkdsk } from '../../../../utils/targetDskLink';
import { adjustModalPositionBasedOnContent } from '../../../../utils/modal.utils';
import CTA from '../../CTA';
import ModalDialog from '../../modalDialog';
import { TComponentDetailsProps, InnerComponentTypes } from '../types/innerComponentsPopup.types';
import InnerSplitModules from '../innerSplitModules';
import InnerCards from '../innerCards';
import Banner from './banner';
import { googleButtonClickEvent } from '../../../../utils/analytics';
import { featureDetailOpen } from '../../../../constants/analytics.constants';
import './styles.scss';
import { appendRootToRichTextInternalLinks } from '../../../../utils/appendRootToRichTextInternalLinks.utils';
import { appendRootToInternalLink } from '../../../../utils/appendRootToInternalLink.utils';
import useScrollWithShadow from '../../../../hooks/useScrollWithShadow';
import useSitecoreContext from '../../../../hooks/useSitecoreContext';

const ContentHubSeriesDetails: FC<WithTranslation & TComponentDetailsProps> = (props) => {
  const model = props.component;
  const { t } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const hasInnerComponents = !!(model?.innerComponents && model?.innerComponents.length);
  const isDesktop = useMediaQuery(`(min-width: ${BreakpointsInPx['tbl-p']})`);
  const link1 = { value: { href: model?.button1?.url, target: model?.button1?.target } };
  const link2 = { value: { href: model?.button2?.url, target: model?.button2?.target } };

  const {sitecoreContext} = useSitecoreContext();
  const productLine = sitecoreContext?.analytics?.['productLine'];
  const productSeries = sitecoreContext?.analytics?.['productSeries'];
  const bannerDescription = appendRootToRichTextInternalLinks(model?.description);
  const { showShadowTop, showShadowBottom, scrollElementRef, onScrollHandler } = useScrollWithShadow();
  const showModal = (): void => {
    setModalIsOpen(true);
  };

  const closeModal = (): void => {
    setModalIsOpen(false);
  };

  const automaticScrollTop = (event): void => {
    event.target?.parentElement?.scrollBy({
      top: -50,
      left: 0,
      behavior: 'smooth',
    });
  };
  const automaticScrollBottom = (event): void => {
    event.target?.parentElement?.scrollBy({
      top: 50,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <article className={`component-details ${props.className}`} onScroll={onScrollHandler} ref={scrollElementRef}>
      {showShadowTop && <div className="component-details__shadow component-details__shadow-top" onClick={automaticScrollTop}></div>}
      {showShadowBottom && (
        <div className="component-details__shadow component-details__shadow-bottom" onClick={automaticScrollBottom}></div>
      )}
      {hasInnerComponents && (
        <ModalDialog isOpen={modalIsOpen} close={closeModal} onAfterOpen={adjustModalPositionBasedOnContent}>
          {model?.layoutType?.toLowerCase() === InnerComponentTypes.Split ? (
            <InnerSplitModules components={model?.innerComponents || []} />
          ) : (
            <InnerCards components={model?.innerComponents || []} />
          )}
        </ModalDialog>
      )}
      <Banner
        component={model}
        mediaHeightClassName="component-details__media-height"
        mediaCarouselClassName="component-details__media-carousel"
      />
      <div className="component-details__content-wrapper">
        <div className="component-details__content">
          {model?.title && <h2 className="component-details__title">{model?.title}</h2>}
          {bannerDescription && (
            <RichText editable={false} field={{ value: bannerDescription }} tag="div" className="component-details__text rich-text" />
          )}
          <div className="component-details__button-wrapper">
            {hasInnerComponents && (
              <CTA
                type={ctaTypes.primaryYellow}
                onClick={() => {
                  showModal();
                  props?.useAnalytics && googleButtonClickEvent(featureDetailOpen, model?.eyebrow, productLine, productSeries);
                }}
                extraClassName="component-details__button"
              >
                {model?.button?.label || t('cnhi-discoverMore')}
              </CTA>
            )}
            {model?.button1?.label && model?.button1?.url && (
              <CTA
                type={ctaTypes.primaryYellow}
                field={{ value: { href: appendRootToInternalLink(model?.button1?.url), target: model?.button1?.target } }}
                extraClassName="component-details__button"
                target={targetLinkdsk(link1, isDesktop)}
                onClick={() => props?.useAnalytics && googleButtonClickEvent(featureDetailOpen, model?.eyebrow, productLine, productSeries)}
              >
                {model?.button1?.label}
              </CTA>
            )}
            {model?.button2?.label && model?.button2?.url && (
              <CTA
                type={ctaTypes.secondaryBlue}
                field={{ value: { href: appendRootToInternalLink(model?.button2?.url), target: model?.button2?.target } }}
                extraClassName="component-details__button component-details__button--secondary-cta"
                target={targetLinkdsk(link2, isDesktop)}
                onClick={() => props?.useAnalytics && googleButtonClickEvent(featureDetailOpen, model?.eyebrow, productLine, productSeries)}
              >
                {model?.button2?.label}
              </CTA>
            )}
          </div>
        </div>
      </div>
    </article>
  );
};

export default withTranslation()(ContentHubSeriesDetails);
