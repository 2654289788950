import React, { FC } from 'react';
import { iconSize } from '../../../../../constants/icon.constants';

import Icon from '../../../icon';
import { TExpandableOverlayCarouselPopUpArrow } from '../../types/overlayCarouselPopup.types';

const OverlayCarouselArrow: FC<TExpandableOverlayCarouselPopUpArrow> = ({ direction, onClick, customClass = '' }) => {
  return (
    <button
      className={`overlay-carousel-popup__arrow-control
        overlay-carousel-popup__arrow-control--${direction}
        ${customClass}`}
      aria-label={direction + 'slide / item'}
      onClick={onClick}
    >
      <Icon
        iconName={direction === 'previous' ? 'arrow-chevron-left' : 'arrow-chevron-right'}
        size={iconSize.dsk}
        extraClassName="overlay-carousel-popup__icon"
      />
    </button>
  );
};

export default OverlayCarouselArrow;
