import React, { FC } from 'react';
import CustomExternalAnchor from '../../../customExternalAnchor';
import RoutableSitecoreLink from '../../../routableSitecoreLink';
import { TLinkChildrenAndIcon } from '../../types/CTA.types';
import IconInCTA from '../icon';

const LinkChildrenAndIcon: FC<TLinkChildrenAndIcon> = (props) => {
  const { isEditing, ctaClassName, field, hasCTAIcon, children } = props;
  if (isEditing) {
    return (
      <span className={ctaClassName}>
        <CustomExternalAnchor field={field} />
        {children}
        {<IconInCTA />}
      </span>
    );
  } else if (children) {
    return (
      <RoutableSitecoreLink field={field} className={ctaClassName}>
        {children}
        {hasCTAIcon && <IconInCTA />}
      </RoutableSitecoreLink>
    );
  } else if (hasCTAIcon) {
    return (
      <RoutableSitecoreLink field={field} className={ctaClassName}>
        {field.value.text || field.value.href}
        {<IconInCTA />}
      </RoutableSitecoreLink>
    );
  }
  return null;
};

export default LinkChildrenAndIcon;
