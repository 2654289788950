import React, { FC, useContext } from 'react';
import { iconSize } from '../../../../constants/icon.constants';
import { MIN_CARDS_FOR_SORT_OPTION } from '../../../../constants/specialOffersFilters.constants';
import DictionaryTerm from '../../../@shared/dictionaryTerm';
import Icon from '../../../@shared/icon';
import { FilterContext } from '../../fiilterContext';
import './styles.scss';

const SortCTA: FC<{ className?: string }> = ({ className = '' }) => {
  const { model, updateFilters, sortBy } = useContext(FilterContext);

  if (model.cards.length < MIN_CARDS_FOR_SORT_OPTION) return null;

  return (
    <button
      className={`special-offers-listing-sort-cta ${className}`}
      onClick={() => updateFilters({ sortBy: sortBy === 'asc' ? 'desc' : 'asc' })}
    >
      <DictionaryTerm keyword="cnhi-special-offers-listing-sort-by-expiry" />
      <Icon iconName={sortBy === 'asc' ? 'arrow-down' : 'arrow-up'} size={iconSize['dsk']} />
    </button>
  );
};

export default SortCTA;
