import React, { FC, useState, useCallback, useRef, MutableRefObject, useEffect } from 'react';
import yellowDots from '../../../../assets/Yellow-dots-1.png';
import FourColumnLayoutYellowDots from '../../../../assets/4-columnLayout-dots.png';
import { getImageField } from '../../../../utils/getImageFields';
import { getThumbnailProps } from '../../../../utils/youtubeUtils';
import { windowService } from '../../../../utils/window.utils';
import ModalDialog from '../../../@shared/modalDialog';
import { TTransformations } from '../../../@shared/picture/types/editorialImage.types';
import OverlayCarouselPopup from '../../../@shared/overlayCarouselPopup';
import SplitModule from '../../../splitModule';
import { CardModel } from '../../models/card.models';
import { ColumnLayoutEnum, TCardAnimProps, TCardProps } from '../../types/cardColumnLayout.types';
import CardButtons from './buttons';
import CardImage from './image';
import CardText from './text';
import useSitecoreContext from '../../../../hooks/useSitecoreContext';

import './styles.scss';

const Card: FC<TCardProps & TCardAnimProps> = ({
  card,
  layout,
  textcolor,
  primaryStyle,
  secondaryStyle,
  bgColor,
  shouldAnimate = false,
  animationDeleyInMs = null,
  isTouch,
}) => {
  const context = useSitecoreContext();
  const cardRef: MutableRefObject<HTMLElement | null> = useRef(null);
  const model = new CardModel(card, context?.sitecoreContext);

  const [showOverlay, setShowOverlay] = useState(false);
  const [showSplitModule, setShowSplitModule] = useState(false);

  const { thumbnailImgSrc } = getThumbnailProps(model.video?.value?.href || '');
  const closeOverlayModal = (): void => setShowOverlay(false);
  const handleSplitModuleModal = (state: boolean): void => setShowSplitModule(state);

  const showOverlayVideo = (): void => {
    if (!model.useVideo) return;
    setShowOverlay(true);
  };

  const imageOrThumbnail = getImageField(model.image, thumbnailImgSrc || '', model.useVideo);
  const mobileImageOrThumbnail = getImageField(model.mobileImage, thumbnailImgSrc || '', model.useVideo);

  const imageMobileIsPresent = !!(mobileImageOrThumbnail?.value?.src && mobileImageOrThumbnail?.value?.src.length > 0);
  const imageDesktopIsPresent = !!(imageOrThumbnail?.value?.src && imageOrThumbnail?.value?.src.length > 0);

  const imageIsPresent = imageMobileIsPresent || imageDesktopIsPresent;
  const yellowDotsImage = layout === '4ColumnLayout' ? FourColumnLayoutYellowDots : yellowDots;

  const getOverlayProps = useCallback(() => {
    return [
      {
        desktopImage: imageOrThumbnail,
        mobileImage: mobileImageOrThumbnail,
        desktopTransformation: layout === ColumnLayoutEnum.FourColumnLayout ? 'Size500' : ('Size100' as TTransformations),
        mobileTransformation: 'Size500' as TTransformations,
        fullImage: imageOrThumbnail,
        desktopAlt: imageOrThumbnail?.value?.alt as string,
        mobileAlt: imageOrThumbnail?.value?.alt as string,
        isVideo: model.useVideo,
        videoUrl: model.video?.value?.href,
      },
    ];
  }, [model]); /* eslint-disable-line */

  const setCardMinHeight = () => {
    if (!cardRef?.current) return;

    cardRef.current.style.minHeight = `${cardRef.current.clientHeight}px`;
  };

  const resetCardMinHeight = () => {
    if (!cardRef?.current) return;

    cardRef.current.style.minHeight = '';
  };

  useEffect(() => {
    if (isTouch) return;

    setCardMinHeight();

    let timeOutFunctionId;

    const _onResizeRefreshMinHeightCard = () => {
      if (timeOutFunctionId) clearTimeout(timeOutFunctionId);
      resetCardMinHeight();

      timeOutFunctionId = setTimeout(setCardMinHeight, 500);
    };

    windowService().addEventListener('resize', _onResizeRefreshMinHeightCard);

    return () => {
      windowService().removeEventListener('resize', _onResizeRefreshMinHeightCard);
    };
  }, [cardRef]);

  return (
    <article
      ref={cardRef}
      className={`card-column card-column--${layout} card-column--${bgColor} ${shouldAnimate ? 'card-column--on-load-animation' : ''} ${
        isTouch ? 'card-column--touch' : ''
      }`}
      style={{ animationDelay: `${animationDeleyInMs}ms` } as React.CSSProperties}
    >
      <CardImage
        imageOrThumbnail={imageOrThumbnail}
        useVideo={model.useVideo}
        layout={layout}
        imageIsPresent={imageIsPresent}
        yellowDotsImage={yellowDotsImage}
        mobileImageOrThumbnail={mobileImageOrThumbnail}
        showOverlayVideo={showOverlayVideo}
      />
      <div className="card-column__body-wrapper">
        <div
          className={`card-column__body card-column__body--${layout} card-column__body--${textcolor} ${
            imageIsPresent ? '' : 'card-column__body--no-image'
          }`}
        >
          <CardText title={model.title} subheading={model.subheading} textcolor={textcolor} />
          <CardButtons
            showPopupWithSplitModule={model.showPopupWithSplitModule}
            cta1={model.cta1}
            cta2={model.cta2}
            splitModuleLabel={model.splitModuleLabel}
            useImageCTA={model.useImageCTA}
            title={model.title}
            primaryStyle={primaryStyle}
            appStoreImage={model.appStoreImage}
            googlePlayImage={model.googlePlayImage}
            secondaryStyle={secondaryStyle}
            handleSplitModuleModal={handleSplitModuleModal}
          />
        </div>
      </div>

      {model?.video && model?.useVideo && (
        <ModalDialog isOpen={showOverlay} close={closeOverlayModal}>
          <OverlayCarouselPopup slides={getOverlayProps()} isOverlay={true} isEE={false} />
        </ModalDialog>
      )}
      {model?.showPopupWithSplitModule && model?.splitModules && (
        <ModalDialog isOpen={showSplitModule} close={(): void => handleSplitModuleModal(false)} className="split-module-modal">
          {model.splitModules.map((splitModule, index) => (
            <SplitModule {...splitModule} key={splitModule?.id} params={{ imagePosition: index % 2 == 0 ? 'right' : 'left' }} />
          ))}
        </ModalDialog>
      )}
    </article>
  );
};

export default Card;
