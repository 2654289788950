import { ComponentRendering } from '@sitecore-jss/sitecore-jss-react';
import { TProductCard, TSeriesListing, TSeriesListingItemData, TSeriesListingProps } from '../types/seriesListing.types';

export class SeriesListingModel {
  private readonly data?: TSeriesListing;
  private readonly itemData?: TSeriesListingItemData;
  private readonly params: IComponentParamsGeneral | undefined;
  private readonly rendering?: ComponentRendering;
  private readonly isComponentUnreleased?: boolean;

  constructor(props: TSeriesListingProps) {
    this.data = props?.fields?.apiData;
    this.itemData = props?.fields?.itemData;
    this.params = props?.params;
    this.rendering = props?.rendering;
    this.isComponentUnreleased = props?.fields?.isComponentUnreleased;
  }

  get thumbnailEE(): string {
    return this.itemData?.thumbnail || '';
  }

  get componentName(): string {
    return this.rendering?.componentName || '';
  }

  get items(): TProductCard[] | undefined {
    return this.data?.items;
  }

  get total(): number | undefined {
    return this.data?.total;
  }

  get baseUrl(): string {
    let baseUrl = this.itemData?.contextItemUrl;
    if (baseUrl) {
      baseUrl = this.params?.enableProductParameters === '1' && this.params?.lineId ?
        `${baseUrl}/${this.params?.lineId}` : baseUrl;
      return baseUrl;
    }
    return '';
  }

  get isUnreleased(): boolean {
    return !!this.isComponentUnreleased;
  }
}
