import React, { useState, FC, FocusEvent, ChangeEvent } from 'react';

import { TBaseTextField } from '../../types/baseTextField.types';
import useFirstUserAction from '../../hooks/useFirstUserActionState';
import CustomTextLabel from '../@shared/customTextLabel';

const BaseTextField: FC<TBaseTextField> = (props) => {
  const { field, type, tracker, inputAttributes = {} } = props;
  const [hasFocus, setHasFocus] = useState(false);
  const [value, setValue] = useState(props.value);
  const hasValueOrFocus = hasFocus || value !== '';
  const hasAnyError = !!props.errors?.length;
  const FieldValidationErrors = props.fieldValidationErrorsComponent;
  const label = field?.model?.title;
  const id = field?.valueField?.id;
  const name = field?.valueField?.name;
  const placeholder = field?.model?.placeholderText;
  const triggerFirstInteractionGTMEvent = useFirstUserAction();

  const onFocus = (e: FocusEvent<HTMLInputElement>): void => {
    tracker.onFocusField(field, e?.target?.value);
    setHasFocus(true);
  };

  const onBlur = (e: FocusEvent<HTMLInputElement>): void => {
    tracker.onBlurField(field, e?.target?.value);
    setHasFocus(false);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setValue(e?.target?.value);
    props.onChange(field.valueField.name, e?.target?.value, props.isValid, []);
    triggerFirstInteractionGTMEvent();
  };

  return (
    <div
      className={`sitecore-jss-form__control sitecore-jss-form__control--text-box ${
        hasAnyError ? 'sitecore-jss-form__control--has-error' : ''
      }`}
    >
      <label htmlFor={id} className={`sitecore-jss-form__label ${hasValueOrFocus ? 'sitecore-jss-form__label--has-focus' : ''}`}>
        <CustomTextLabel label={label} />
      </label>
      <input
        id={id}
        name={name}
        className="sitecore-jss-form__field"
        type={type}
        {...inputAttributes}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        value={props.value}
        placeholder={placeholder}
        form="novalidatedform"
      />
      {FieldValidationErrors && <FieldValidationErrors {...props} />}
    </div>
  );
};

export default BaseTextField;
