import { TBaseObj } from '../types/sitecore.types';

export const isInternalUrl = (url: string): boolean => url.startsWith('/');

export const getExternalLinkRelAttribute = (url = ''): TBaseObj => {
  return isInternalUrl(url) ? {} : { rel: 'nofollow' };
};

export function appendToUrlPath(url: string, appendString = ''): string {
  const appendSlash = (href: string): string => (href.endsWith('/') ? href : `${href}/`);

  if (!url) {
    return '';
  } else if (url.includes('?')) {
    const urlArray = url.split('?');

    return `${appendSlash(urlArray[0])}${appendString}?${urlArray[1]}`;
  } else if (url.includes('#')) {
    const urlArray = url.split('#');

    return `${appendSlash(urlArray[0])}${appendString}#${urlArray[1]}`;
  } else {
    return `${appendSlash(url)}${appendString}`;
  }
}
