import React, { useState, useRef, useEffect, FC } from 'react';
import { ExternalComponentModel } from './models/externalComponent.model';
import { TExternalComponentProps } from './types/externalComponent.types';
import { windowService } from '../../../utils/window.utils.js';
import LazySection from '../lazySection';
import './index.scss';

const SharedExternalComponent: FC<TExternalComponentProps> = (props: TExternalComponentProps) => {
  const model = new ExternalComponentModel(props);
  const { url, height, visibilityDelay } = model;
  const [iFrameHeight, setiFrameHeight] = useState(height.toString());
  const [isVisible, setIsVisible] = useState(false);
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    function messageListener(event: MessageEvent): void {
      if (
        event.origin === new URL(url).origin &&
        event.data &&
        typeof event.data === 'string' &&
        event.data.includes('iframeHeightChange')
      ) {
        const _height = event.data.replace('iframeHeightChange#=###=###=#', '');
        _height && setiFrameHeight(_height);
      }
    }

    windowService()?.addEventListener?.('message', messageListener);
    return (): void => {
      windowService()?.removeEventListener('message', messageListener);
      clearTimeout(timer?.current as ReturnType<typeof setTimeout>);
    };
  }, [url]);

  const onLoadHandler = (): void => {
    timer.current = setTimeout(() => setIsVisible(true), visibilityDelay);
  };

  const iframeStyle = {
    height: `${iFrameHeight || height}px`,
    opacity: isVisible ? 1 : 0,
  };

  return (
    <LazySection>
      <section className="externalComponent">
        <iframe src={url} style={iframeStyle} onLoad={onLoadHandler} allow="geolocation" title={url}></iframe>
      </section>
    </LazySection>
  );
};

export default SharedExternalComponent;
