import React, { FC, useEffect, useState } from 'react';
import { BreakpointsInPx } from '../../../../../constants/breakpoints.constants';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import Picture from '../../../picture';
import VideoPlayer from '../../../videoPlayer';
import { THeroCardProps } from '../../types/overlayCarouselPopup.types';
import OverlayTitleAndDescription from './overlayTitleAndDescription';

const HeroCard: FC<THeroCardProps> = ({
  id,
  showOverlayTitleAndDescription = false,
  customTextTitle = true,
  customTextDescription = true,
  fullImage,
  desktopImage,
  mobileImage,
  desktopTransformation,
  mobileTransformation,
  desktopAlt,
  mobileAlt,
  productImageProps,
  title,
  description,
  isVideo,
  videoUrl,
  activeCard,
  index,
  playCallback,
  pauseCallback,
  endCallback,
  moveLeft,
  moveRight,
}) => {
  const isWide = useMediaQuery(`(min-width: ${BreakpointsInPx['tbl-p']})`);
  const [swipeStart, setSwipeStart] = useState(0);
  const [videoVisible, setVideoVisible] = useState(false);

  useEffect(() => {
    setSwipeStart(0);
  }, [activeCard, index]);

  const handleTouchStartVideoPlay = (event): void => {
    if (isWide) return;
    const value = event?.changedTouches?.[0]?.pageX;
    if (!value) return;
    setSwipeStart(value);
  };

  const handleTouchEndVideoPlay = (event): void => {
    if (isWide) return;
    const value = event?.changedTouches?.[0]?.pageX;
    if (!value) {
      setSwipeStart(0);
      return;
    }

    if (value < swipeStart && swipeStart - value > 20) {
      if (moveRight) moveRight();
    } else if (value > swipeStart && value - swipeStart > 20) {
      if (moveLeft) moveLeft();
    }
    setSwipeStart(0);
  };

  const handleTouchStart = (event): void => {
    const value = event?.changedTouches?.[0]?.pageX;
    if (!value) return;
    setSwipeStart(value);
  };

  const handleTouchEnd = (event): void => {
    const value = event?.changedTouches?.[0]?.pageX;
    if (!value) {
      setSwipeStart(0);
      return;
    }

    if (value < swipeStart && swipeStart - value > 20) {
      if (moveRight) moveRight();
    } else if (value > swipeStart && value - swipeStart > 20) {
      if (moveLeft) moveLeft();
    } else {
      setSwipeStart(0);
    }
  };

  const editorialImageProps = !productImageProps
    ? {
        id: id,
        onTouchStart: handleTouchStart,
        onTouchEnd: handleTouchEnd,
        ...(isVideo && {
          onTouchStart: handleTouchStartVideoPlay,
          onTouchEnd: handleTouchEndVideoPlay,
        }),
        mobileImage: mobileImage?.value?.src ? mobileImage : desktopImage,
        desktopImage: fullImage?.value?.src ? fullImage : desktopImage,
        desktopTransformation,
        mobileTransformation,
        disableLazyLoad: true,
        alt: isWide ? desktopAlt : mobileAlt,
        cssClassPictureEl: 'overlay-carousel-popup__picture',
      }
    : undefined;

  const extendedProductImageProps = productImageProps && {
    ...productImageProps,
    onTouchStart: handleTouchStart,
    onTouchEnd: handleTouchEnd,
    ...(isVideo && {
      onTouchStart: handleTouchStartVideoPlay,
      onTouchEnd: handleTouchEndVideoPlay,
    }),
    disableLazyLoad: true,
    pictureClassName: 'overlay-carousel-popup__picture',
  };

  return (
    <>
      <OverlayTitleAndDescription
        showOverlayTitleAndDescription={!videoVisible && showOverlayTitleAndDescription}
        isWide={isWide}
        customTextTitle={customTextTitle}
        customTextDescription={customTextDescription}
        title={title}
        description={description}
      />

      {isVideo && videoUrl ? (
        <VideoPlayer
          className="series-overview-modal-video"
          iconWrapperClassName="overlay-carousel-popup__slide-video-play-button"
          link={videoUrl}
          autoPlay={true}
          showThumbnail={true}
          activeCard={activeCard}
          muted={!isWide}
          playCallback={playCallback}
          pauseCallback={pauseCallback}
          endCallback={endCallback}
          playIconClickCallback={() => {
            setVideoVisible(true);
          }}
          editorialImageProps={editorialImageProps}
          productImageProps={extendedProductImageProps}
        />
      ) : (
        <Picture
          editorialImageProps={editorialImageProps}
          // When renditions or more than 2 CH images to be sent, productImageProps should be used
          productImageProps={extendedProductImageProps}
        />
      )}
    </>
  );
};

export default React.memo(HeroCard);
