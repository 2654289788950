import React, { FC } from 'react';
import { TNavigationBarProps } from '../@shared/sharedBreadcrumbBar/types/breadcrumbBar.types';
import SharedBreadcrumbBar from '../@shared/sharedBreadcrumbBar';

const BreadcrumbBar: FC<TNavigationBarProps> = (props: TNavigationBarProps) => {
  return (
    <SharedBreadcrumbBar {...props}/>
  );
};

export default BreadcrumbBar;
