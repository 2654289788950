import { TFilter, TSearchItem, TSearchProps, TSearchPropsApiData, TSearchPropsItemData, TSearchPropsItem } from '../types/search.types';

export class SearchApiModel {
  private readonly fields?: TSearchPropsApiData;
  private readonly isComponentUnreleased: any;
  private readonly itemData?: TSearchPropsItemData;

  constructor(props: TSearchProps) {
    this.fields = props?.fields?.apiData || null;
    this.isComponentUnreleased = props?.fields?.isComponentUnreleased;
    this.itemData = props?.fields?.itemData;
  }

  get counter(): number {
    return this.fields?.totalSearchResults || 0;
  }

  get results(): TSearchItem[] {
    return (
      this.fields?.items?.map(({ title, description, link, highlight }: TSearchPropsItem) => ({
        title,
        description,
        link,
        highlight,
      })) || []
    );
  }

  get filters(): TFilter[] {
    return this.fields?.filters || [];
  }

  get isUnreleased(): boolean {
    return !!this.isComponentUnreleased;
  }

  get contextItemUrl(): string {
    return this.itemData?.contextItemUrl || '';
  }
}
