import React, { useEffect, useState } from 'react';
import { Placeholder, ComponentRendering } from '@sitecore-jss/sitecore-jss-react';
import { PlmSeriesSectionComponentProps } from '../types/productLinePlmSeriesSection.types';
import ComponentDetails from './componentDetails';
import Slide from 'react-reveal/Slide';
import useScrollWithShadow from '../../../hooks/useScrollWithShadow';
import { windowService } from '../../../utils/window.utils';

const DesktopPlmSeriesSection = (props: PlmSeriesSectionComponentProps) => {
  const model = props?.model;
  const isEEActive = props?.isEEActive;
  const [componentArr, setComponentArr] = useState([model?.items?.length && model?.items[0] ? model?.items[0] : null]);
  const { showShadowTop, showShadowBottom, scrollElementRef, onScrollHandler } = useScrollWithShadow();
  const [shadowWidth, setShadowWidth] = useState(0);

  useEffect(() => {
    function handleResize() {
      const newShadowWidth = scrollElementRef?.current?.clientWidth || 0;
      setShadowWidth(newShadowWidth);
    }

    handleResize();
    windowService().addEventListener('resize', handleResize);
    return (): void => windowService().removeEventListener('resize', handleResize);
  }, [scrollElementRef]);

  const automaticScrollTop = (event): void => {
    event.target?.parentElement?.scrollBy({
      top: -50,
      left: 0,
      behavior: 'smooth',
    });
  };
  const automaticScrollBottom = (event): void => {
    event.target?.parentElement?.scrollBy({
      top: 50,
      left: 0,
      behavior: 'smooth',
    });
  };

  if (componentArr.length === 0) {
    return null;
  }

  return (
    <section className="plm-series-details">
      <div className="plm-series-details__container">
        <div className="plm-series-details__tabs-wrapper" onScroll={onScrollHandler} ref={scrollElementRef}>
          {showShadowTop && (
            <div
              className="plm-series-details__shadow plm-series-details__shadow-light-top"
              style={{ width: shadowWidth }}
              onClick={automaticScrollTop}
            ></div>
          )}
          {showShadowBottom && (
            <div
              className="plm-series-details__shadow plm-series-details__shadow-light-bottom"
              style={{ width: shadowWidth }}
              onClick={automaticScrollBottom}
            ></div>
          )}

          <div className="plm-series-details__header">
            {model?.title && <h2 className="plm-series-details__title">{model?.title}</h2>}
            <div className={`plm-series-details__popup ${isEEActive ? 'generic-popupEE-width' : ''}`}>
              <Placeholder name={'jss-generic-popup'} rendering={props?.rendering as ComponentRendering} />
            </div>
          </div>
          <ul className="plm-series-details__tabs">
            {model?.items?.map((component) => {
              const ref = React.createRef<HTMLDivElement>();
              return (
                <li
                  key={component?.id}
                  className={`plm-series-details__tab ${
                    componentArr[componentArr.length - 1]?.id === component?.id ? 'plm-series-details__tab--selected' : ''
                  }`}
                >
                  <div
                    ref={ref}
                    className={`plm-series-details__tab-button ${
                      componentArr[componentArr.length - 1]?.id === component?.id ? 'plm-series-details__tab-button--selected' : ''
                    }`}
                    onClick={() => {
                      setComponentArr((data) => {
                        const plmDisplayitems = [...data];
                        if (component.id !== data?.[data?.length - 1]?.id) {
                          plmDisplayitems.push(component);
                        }
                        if (plmDisplayitems.length >= 3) {
                          return [...plmDisplayitems.slice(1)];
                        }
                        return [...plmDisplayitems];
                      });
                    }}
                  >
                    <h3>{component.eyebrow}</h3>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <section className="plm-series-details__component-section">
          {componentArr.length > 0 &&
            componentArr.map(
              (component) =>
                component && (
                  <Slide bottom duration={1000} key={`${component.id}}`}>
                    <div className="plm-series-details__component-wrapper">
                      <ComponentDetails baseUrl={props.baseUrl} component={component} className="plm-series-details__component" />
                    </div>
                  </Slide>
                )
            )}
        </section>
      </div>
    </section>
  );
};

export default DesktopPlmSeriesSection;
