import React, { useState, FC } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { isEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isEmptyResponse } from '../../utils/checkEmptyResponse';
import useExperienceEditorPlaceholder from '../../hooks/useExperienceEditorPlaceholder';
import { TModelsListingProps, TProductCard } from './types/modelsListing.types';
import { ModelsListingModel } from './model/modelsListing.model';
import ModelListingCard from './components/modelListingCard';
import EmptyApiDataMessage from '../@shared/emptyApiDataMessage';
import ExperienceEditorComponentPlaceholder from '../@shared/experienceEditorComponentPlaceholder';
import Heading from '../@shared/heading';
import Icon from '../@shared/icon';
import './styles.scss';
import UnreleasedComponent from '../@shared/unreleasedComponent';
import { windowService, documentService } from '../../utils/window.utils';
import useMediaQuery from '../../hooks/useMediaQuery';
import { BreakpointsInPx } from '../../constants/breakpoints.constants';
import AnalyticsVisibleEvent from '../@shared/analyticsVisibleEvent';
import { descriptionKeyModelListing, eyebrowKeyModelListing, titleKeyModelListing } from '../../constants/dictionary.constants';

const ProductModelListing: FC<WithTranslation & TModelsListingProps> = (props) => {
  const model = new ModelsListingModel(props);
  const { t } = props;
  const [showMore, setShowMore] = useState(false);
  const apiResponse = isEmptyResponse(props);
  const isPlaceholderShown = useExperienceEditorPlaceholder(props);
  const isMobile = useMediaQuery(`(max-width: ${BreakpointsInPx['tbl-l']})`);

  // Component is unreleased
  if (model.isUnreleased) {
    return <UnreleasedComponent />;
  }

  // star  node opened directly in EE
  if (isPlaceholderShown) {
    return <ExperienceEditorComponentPlaceholder image={model.thumbnailEE} componentName={model.componentName} />;
  }

  // not opened from star node
  // data missing when navigating via from products node
  if (!apiResponse && isEditorActive()) {
    return <EmptyApiDataMessage componentName={model.componentName} />;
  }

  // No data and not in EE
  if (!apiResponse && !isPlaceholderShown && !isEditorActive()) {
    return null;
  }

  const handleClickButton = () => {
    const _comparableSpecificationTable = documentService()?.querySelectorAll('.model-details-specification-table-wrapper')?.[0];
    const _offsetTop = _comparableSpecificationTable?.offsetTop;
    const _offsetCorrection = isMobile ? 40 : 70;

    if (_offsetTop) {
      windowService().scroll({
        top: _offsetTop - _offsetCorrection,
        behavior: 'smooth',
      });
    }
  };

  return (
    <section className="model-listing-wrapper">
      <AnalyticsVisibleEvent event="series_models_visible">
        <div className="model-listing">
          <Heading
            eyebrow={model.eyebrow}
            title={model.title}
            subtitle={model.description}
            link={model.button1}
            cbClickButton={handleClickButton}
            useDictionaryFallback
            titleDictionaryKey={titleKeyModelListing}
            eyebrowDictionaryKey={eyebrowKeyModelListing}
            descriptionDictionaryKey={descriptionKeyModelListing}
          />
          <div className={'model-listing__cards'}>
            {model.items &&
              showMore &&
              model.items.map((item: TProductCard, index: number) => (
                <div
                  key={item?.id}
                  className={`model-listing__card ${showMore ? 'model-listing__animate-card' : ''}`}
                  style={{ animationDelay: `${index * 0.2}s` }}
                >
                  <ModelListingCard {...item} baseUrl={model.baseUrl} disableUpperCase={model.disableUpperCase} enableBuildPriceCTA={model.enableBuildPriceCTA} />
                </div>
              ))}
            {model.items &&
              !showMore &&
              model.items
                .filter((item: TProductCard, index: number) => index < 8)
                .map((item: TProductCard) => (
                  <div key={item?.id} className={'model-listing__card'} id={`${item.id}`}>
                    <ModelListingCard {...item} baseUrl={model.baseUrl} disableUpperCase={model.disableUpperCase} enableBuildPriceCTA={model.enableBuildPriceCTA} />
                  </div>
                ))}
          </div>
          {model.items && model.items.length > 8 && (
            <div className={'model-listing__show-more'}>
              <button className={'model-listing__show-more-cta'} onClick={(): void => setShowMore(!showMore)}>
                {showMore ? t('cnhi-showLessModels') : t('cnhi-showMoreModels')}
                <Icon iconName={showMore ? 'arrow-chevron-up' : 'arrow-chevron-down'} size={24} />
              </button>
            </div>
          )}
        </div>
      </AnalyticsVisibleEvent>
    </section>
  );
};

export default withSitecoreContext()(withTranslation()(ProductModelListing));
