import React, { useContext, useEffect, useRef, useState, FC } from 'react';
import ReactDOM from 'react-dom';
import { SitecoreContextReactContext, Image as SitecoreImage } from '@sitecore-jss/sitecore-jss-react';
import { TEditorialImageProps, TImageFieldOrStringOrUndefined, TTransformations } from '../../types/editorialImage.types';
import { TImageField } from '../../../../../types/sitecore.types';
import ContentHubImage from '../contentHubImage';
import { iconSize } from '../../../../../constants/icon.constants';
import useEEActive from '../../../../../hooks/useEEActive';
import Icon from '../../../icon';
import { documentService } from '../../../../../utils/window.utils';
import { triggerCTRLCmdAndClick } from '../../../../../utils/macOs.utils';

const Image: FC<TEditorialImageProps> = (props: TEditorialImageProps) => {
  const {
    mobileImage,
    tabletImage,
    desktopImage,
    mobileTransformation,
    tabletTransformation,
    desktopTransformation,
    disableLazyLoad,
    alt,
    cssClassPictureEl = '',
    cssClassImageEl = '',
    prioritize,
    style,
    // TO DO: Check if needed
    // height,
    // width,
    onClick,
    onTouchStart,
    onTouchEnd,
    onLoad,
    isVideoThumbnail,
  } = props;

  const getUrl = (image: TImageFieldOrStringOrUndefined): string => {
    if (typeof image === 'object' && image?.value?.src) return image?.value?.src;
    if (typeof image === 'string') return image;

    return '';
  };

  const desktopFallbackImageUrl = getUrl(tabletImage) || getUrl(mobileImage);
  const tabletFallbackImageUrl = getUrl(desktopImage) || getUrl(mobileImage);
  const mobileFallbackImageUrl = getUrl(tabletImage) || getUrl(desktopImage);

  const getSrcImage = (image: TImageFieldOrStringOrUndefined, fallbackImage: string, transformation?: TTransformations): string => {
    let url = getUrl(image);

    if (!transformation) {
      return url;
    }

    if (!url) {
      url = getUrl(fallbackImage);
    }

    if (url) {
      url = url.includes('?') ? `${url}&t=${transformation}` : `${url}?t=${transformation}`;
    }

    return url;
  };

  const getImageDimensions = (image: TImageFieldOrStringOrUndefined): { width; height } => {
    if (typeof image === 'string') return { width: null, height: null };
    return {
      width: image?.value?.height || null,
      height: image?.value?.width || null,
    };
  };

  const getAlt = (image: TImageFieldOrStringOrUndefined): string => {
    if (typeof image === 'string') return '';
    return image?.value?.alt as string || '';
  };

  return (
    <ContentHubImage
      desktopRendition={{
        url: getSrcImage(desktopImage, desktopFallbackImageUrl, desktopTransformation),
        ...getImageDimensions(desktopImage),
      }}
      tabletRendition={{ url: getSrcImage(tabletImage, tabletFallbackImageUrl, tabletTransformation), ...getImageDimensions(tabletImage) }}
      mobileRendition={{ url: getSrcImage(mobileImage, mobileFallbackImageUrl, mobileTransformation), ...getImageDimensions(mobileImage) }}
      alt={alt || getAlt(desktopImage) || getAlt(mobileImage)}
      onClick={onClick}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onLoad={onLoad}
      isVideoThumbnail={isVideoThumbnail}
      pictureClassName={cssClassPictureEl}
      imageClassName={cssClassImageEl}
      disableLazyLoad={disableLazyLoad}
      imageStyle={style}
      prioritize={prioritize}
    />
  );
};

const EditImageEE: FC<any> = ({ image, mobileImage }) => {
  const getImage = (picture): TImageField => {
    let img: TImageField;

    if (typeof picture === 'string') {
      img = {
        value: {
          src: picture,
        },
      };
    } else {
      img = picture;
    }

    return img;
  };

  return (
    <>
      {image && (
        <div className="editorial-image__modal-desktopImage">
          <h3 className="editorial-image__modal-title">Edit Desktop image</h3>
          <SitecoreImage field={getImage(image)} loading="lazy" />
        </div>
      )}
      {mobileImage && (
        <div className="editorial-image__modal-mobileImage">
          <h3 className="editorial-image__modal-title ">Edit Mobile image</h3>
          <SitecoreImage field={getImage(mobileImage)} loading="lazy" />
        </div>
      )}
    </>
  );
};

const EditorialImage: FC<TEditorialImageProps> = (props: TEditorialImageProps) => {
  const sitecoreContextFactory = useContext(SitecoreContextReactContext);
  const sitecoreContext = sitecoreContextFactory.context;
  const isEEActive = useEEActive();
  const [isOpenEditImageModal, setIsOpenEditImageModal] = useState(false);
  const popUpClassName = 'generic-modal-dialog';
  const modalDialogConatinerClass = 'my-modal-dialog__overlay';
  const modalDialogContentlass = 'my-modal-dialog';
  const modalDialogCloseBtnClass = 'my-modal-dialog__close-button';
  const editorRef = useRef<HTMLDivElement | null>(null);
  const ctaCloseTriggerRef = useRef<HTMLButtonElement | null>(null);
  const ctaOpenTriggerRef = useRef<HTMLButtonElement | null>(null);
  const [modalContainer, setModalContainerEl] = useState(documentService()?.getElementById?.('modal-EE-section'));

  useEffect(() => {
    if (!isEEActive) return;

    setModalContainerEl(documentService()?.getElementById?.('modal-EE-section'));

    const startStopBodyScroll = (isScrollable: boolean): void => {
      const docElement = documentService();
      if (!docElement?.body) return;
      return isScrollable ? docElement.body.classList.remove('body--with-pop-up') : docElement.body.classList.add('body--with-pop-up');
    };

    const openCTA = (event): void => {
      triggerCTRLCmdAndClick(event, () => {
        editorRef.current?.classList.add('editor_popup--visible');
        startStopBodyScroll(false);
        setIsOpenEditImageModal(true);
      });
    };

    const closeCTA = (event): void => {
      triggerCTRLCmdAndClick(event, () => {
        editorRef.current?.classList.remove('editor_popup--visible');
        startStopBodyScroll(true);
        setIsOpenEditImageModal(false);
      });
    };

    ctaOpenTriggerRef.current?.addEventListener('click', openCTA);
    ctaCloseTriggerRef.current?.addEventListener('click', closeCTA);

    return (): void => {
      ctaOpenTriggerRef.current?.removeEventListener('click', openCTA); /* eslint-disable-line */
      ctaCloseTriggerRef.current?.removeEventListener('click', closeCTA); /* eslint-disable-line */
    };
  }, [isEEActive, isOpenEditImageModal]);

  if (isEEActive && sitecoreContext)
    return (
      <>
        <div className="editorial-image">
          <button className="editorial-image__edit-cta" ref={ctaOpenTriggerRef} title={'edit the image'}>
            <Icon iconName={'action-edit-1'} color="white" size={iconSize.dsk} />
          </button>
          <Image {...props} />
          {modalContainer &&
            ReactDOM.createPortal(
              <div ref={editorRef} className={`${modalDialogConatinerClass} ${popUpClassName} editor_popup`}>
                <button className={`${modalDialogCloseBtnClass} editor_popup__close`} ref={ctaCloseTriggerRef}>
                  <Icon iconName="action-close" size={iconSize.dsk} extraClassName="editor_popup__close-icon" />
                </button>
                <div className={`${modalDialogContentlass} ${popUpClassName} editor_popup__content editorial-image__modal`}>
                  <EditImageEE image={props.desktopImage} mobileImage={props.mobileImage} />
                </div>
              </div>,
              modalContainer
            )}
        </div>
      </>
    );

  return <Image {...props} />;
};

export default React.memo(EditorialImage);
