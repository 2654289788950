import React, { FC, useState } from 'react';
import { DateField, RichText, isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { BreakpointsInPx } from '../../constants/breakpoints.constants';
import ctaTypes from '../../enums/CTA.enums';
import useExperienceEditorPlaceholder from '../../hooks/useExperienceEditorPlaceholder';
import useFormatDate from '../../hooks/useFormatDate';
import useMediaQuery from '../../hooks/useMediaQuery';
import { appendRootToInternalLink } from '../../utils/appendRootToInternalLink.utils';
import { isEmptyResponse } from '../../utils/checkEmptyResponse';
import { targetLinkdsk } from '../../utils/targetDskLink';
import CTA from '../@shared/CTA';
import DictionaryTerm from '../@shared/dictionaryTerm';
import EmptyApiDataMessage from '../@shared/emptyApiDataMessage';
import ExperienceEditorComponentPlaceholder from '../@shared/experienceEditorComponentPlaceholder';
import ModalDialog from '../@shared/modalDialog';
import RoutableNotEditableLink from '../@shared/routableNotEditableLink';
import UnreleasedComponent from '../@shared/unreleasedComponent';
import { ProductSpecialOfferDetailModel } from './models/productSpecialOfferDetail.model';
import { TProductSpecialOfferDetailProps } from './types/productSpecialOfferDetail.types';
import useSitecoreContext from '../../hooks/useSitecoreContext';
import './styles.scss';

const ProductSpecialOfferDetail: FC<TProductSpecialOfferDetailProps> = (props) => {
  const apiResponse = isEmptyResponse(props);
  const {sitecoreContext} = useSitecoreContext();
  const specialOfferPageUrl: string | undefined = sitecoreContext?.specialOfferPageUrl as string;
  const model = new ProductSpecialOfferDetailModel(props, specialOfferPageUrl);
  const isPlaceholderShown = useExperienceEditorPlaceholder(props);
  const formattedDate = useFormatDate(model.expiration.value);
  const isDesktop = useMediaQuery(`(min-width: ${BreakpointsInPx['tbl-p']})`);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const primaryCTAHref = appendRootToInternalLink(model.primaryLink?.value?.href);
  const secondaryCTAHref = appendRootToInternalLink(model.secondaryLink?.value?.href);

  // Component is unreleased
  if (model.isUnreleased) {
    return <UnreleasedComponent />;
  }

  // star  node opened directly in EE
  if (isPlaceholderShown) {
    return <ExperienceEditorComponentPlaceholder image={model.thumbnailEE} componentName={model.componentName} />;
  }

  // not opened from star node
  // data missing when navigating via from products node
  if (!apiResponse && isEditorActive()) {
    return <EmptyApiDataMessage componentName={model.componentName} />;
  }

  // No data and not in EE
  if (!apiResponse && !isPlaceholderShown && !isEditorActive()) {
    return null;
  }

  return (
    <>
      {model.disclaimer.value && (
        <ModalDialog isOpen={isModelOpen} close={() => setIsModelOpen(false)} className="special-offer-detail-modal">
          <RichText editable={false} tag="div" field={model.disclaimer} className="rich-text special-offer-detail-modal__content" />
        </ModalDialog>
      )}
      <section className="special-offer-detail-wrapper">
        <div className="special-offer-detail">
          <RichText editable={false} field={model.title} tag="h2" className="rich-text special-offer-detail__title" />
          {model.categories.length > 0 && (
            <div className="special-offer-detail-categories">
              <span className="special-offer-detail-categories__title">
                <DictionaryTerm keyword={'cnhi-special-offers-categories'} />:
              </span>
              {model.categories.map((eachCategory, index) => {
                return (
                  <>
                    {index !== 0 && ', '}
                    <RoutableNotEditableLink
                      href={eachCategory.redirectUrl}
                      key={eachCategory.titleUrl}
                      className="special-offer-detail-categories__link"
                    >
                      {eachCategory.name}
                    </RoutableNotEditableLink>
                  </>
                );
              })}
            </div>
          )}
          <RichText editable={false} field={model.description} tag="div" className="rich-text special-offer-detail__description" />
          {model.disclaimer.value && (
            <button onClick={() => setIsModelOpen(true)} className="special-offer-detail__disclaimer">
              <DictionaryTerm keyword={'cnhi-special-offers-disclaimer'} />
            </button>
          )}
          {model.expiration.value && (
            <p className="special-offer-detail__expiry">
              <DictionaryTerm keyword={'cnhi-special-offers-expiration-label'} />
              :&nbsp;
              <DateField editable={false} field={model.expiration} tag="span" render={() => formattedDate} />
            </p>
          )}
          {(model.primaryLink || model.secondaryLink) && (
            <div className="special-offer-detail__button-wrapper">
              {model.primaryLink && (
                <CTA type={ctaTypes.primaryYellow} href={primaryCTAHref} target={targetLinkdsk(model.primaryLink, isDesktop)}>
                  {model.primaryLink.value.title}
                </CTA>
              )}
              {model.secondaryLink && (
                <CTA
                  type={ctaTypes.secondaryBlue}
                  href={secondaryCTAHref}
                  target={targetLinkdsk(model.secondaryLink, isDesktop)}
                  extraClassName="special-offer-detail__button--secondary"
                >
                  {model.secondaryLink.value.title}
                </CTA>
              )}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default ProductSpecialOfferDetail;
