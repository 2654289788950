import { Text } from '@sitecore-jss/sitecore-jss-react';
import React, { FC } from 'react';
import { TTextFieldGuard } from '../types/overlayCarouselPopup.types';

const TextFieldGuard: FC<TTextFieldGuard> = ({ field }) => {
  if (!field) return null;
  return <Text field={field} />;
};

export default TextFieldGuard;
