import React, { FC } from 'react';
import TextComponentDecider from '../../../textComponentDecider';
import { TOverlayTitleAndDescription } from '../../types/overlayCarouselPopup.types';

const OverlayTitleAndDescription: FC<TOverlayTitleAndDescription> = (props) => {
  const { showOverlayTitleAndDescription, isWide, customTextTitle, customTextDescription, title, description } = props;
  if (showOverlayTitleAndDescription && isWide) {
    return (
      <div className="overlay-carousel-popup__focus-image__text-wrapper">
        <div className="overlay-carousel-popup__focus-image__text-title">
          {title && <TextComponentDecider type={customTextTitle ? 'CustomText' : 'Text'} field={title} />}
        </div>
        <div className="overlay-carousel-popup__focus-image__text-description">
          {description && <TextComponentDecider type={customTextDescription ? 'CustomText' : 'Text'} field={description} />}
        </div>
      </div>
    );
  }
  return null;
};

export default OverlayTitleAndDescription;
