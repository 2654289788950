import React, { FC } from 'react';

import { TProductSeoTagsProps } from './types/productSeoTags.types';
import { ProductSeoTagsModel } from './models/productSeoTags.model';
import { isEmptyResponse } from '../../utils/checkEmptyResponse';
import { isEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import EmptyApiDataMessage from '../@shared/emptyApiDataMessage';
import SeoTags from '../@shared/seoTags';
import UnreleasedComponent from '../@shared/unreleasedComponent';

const ProductSeoTags: FC<TProductSeoTagsProps> = (props) => {
    const apiResponse = isEmptyResponse(props);
    const model = new ProductSeoTagsModel(props);

    // Component is unreleased
    if (model.isUnreleased) {
        return <UnreleasedComponent/>
    }

    // star  node opened directly in EE
    //  since all are meta tags in header the placeholder doesn't serve a purpose, not a presentational component

    // not opened from star node
    // data missing when navigating via from products node
    if (!apiResponse && isEditorActive()) {
        return <EmptyApiDataMessage componentName={model.componentName}/>;
    }

    // No data and not in EE
    if (!apiResponse && !isEditorActive()) {
        return null;
    }

    return <SeoTags model={model}/>;
};

export default withSitecoreContext()(ProductSeoTags);
