import { useContext } from 'react';
import { googleDataLayerPush } from '../../../../utils/analytics';
import { CustomFormContext } from '../../components/customFormContextProvider';

const useFirstUserAction = () => {
  const customFormContext = useContext(CustomFormContext);
  const [firstUserAction, setFirstUserAction] = customFormContext?.analytics?.firstUserActionState ?? [];

  const triggerFirstInteractionGTMEvent = () => {
    if (firstUserAction === false && setFirstUserAction) {
      googleDataLayerPush({
        event: 'form_start',
        form_name: customFormContext?.analytics?.formName,
      });

      setFirstUserAction(true);
    }
  };

  return triggerFirstInteractionGTMEvent;
};

export default useFirstUserAction;
