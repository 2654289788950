import React, { FunctionComponent, useState, useCallback } from 'react';
import { BreakpointsInPx } from '../../constants/breakpoints.constants';
import useMediaQuery from '../../hooks/useMediaQuery';
import { getImageField } from '../../utils/getImageFields';
import { getThumbnailProps } from '../../utils/youtubeUtils';
import ModalDialog from '../@shared/modalDialog';
import OverlayCarouselPopup from '../@shared/overlayCarouselPopup';
import { TTransformations } from '../@shared/picture/types/editorialImage.types';
import SplitModule from '../splitModule';
import Card from './components/card';
import { TCarouselSlideProps } from './types/carousel.types';

const CarouselSlide: FunctionComponent<TCarouselSlideProps> = ({
  item,
  isEE,
  isSingleCard,
  // iconimage,
}) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const isWide = useMediaQuery(`(min-width: ${BreakpointsInPx['tbl-p']})`, isEE);

  const { thumbnailImgSrc } = getThumbnailProps(item?.video?.value?.href || '');
  const closeOverlayModal = (): void => setShowOverlay(false);
  const [showSplitModule, setShowSplitModule] = useState(false);
  const handleSplitModuleModal = (state: boolean): void => setShowSplitModule(state);

  const showOverlayVideo = (): void => {
    if (!item?.useVideo) return;
    setShowOverlay(true);
  };
  const desktopImage = getImageField(item?.desktopImage, thumbnailImgSrc || '', item?.useVideo);
  const mobileImage = getImageField(item?.mobileImage, thumbnailImgSrc || '', item?.useVideo);

  const getDesktopTransformation = (cardType?: string, hasSingleCard?: boolean): TTransformations => {
    if (hasSingleCard) return 'Size1100';

    return cardType === 'offerCardWide' ? 'Size1000' : 'Size500';
  };

  const getOverlayProps = useCallback(() => {
    return [
      {
        desktopImage: desktopImage,
        mobileImage: mobileImage,
        desktopTransformation: getDesktopTransformation(item?.type?.value, isSingleCard),
        mobileTransformation: 'Size300' as TTransformations,
        fullImage: desktopImage,
        desktopAlt: desktopImage?.value?.alt as string,
        mobileAlt: mobileImage?.value?.alt as string,
        isVideo: item?.useVideo,
        videoUrl: item?.video?.value?.href,
      },
    ];
  }, [item]); // eslint-disable-line

  return (
    <>
      {item?.type && item?.desktopImage && item?.title && item?.subtitle && item?.buttonPrimary && item?.buttonSecondary && (
        <Card
          item={item}
          mobileImage={mobileImage}
          desktopImage={desktopImage}
          isWide={isWide}
          isEE={isEE}
          getDesktopTransformation={getDesktopTransformation}
          showOverlayVideo={showOverlayVideo}
          handleSplitModuleModal={handleSplitModuleModal}
          isSingleCard={isSingleCard}
        />
      )}
      {item?.video && item?.useVideo && (
        <ModalDialog isOpen={showOverlay} close={closeOverlayModal}>
          <OverlayCarouselPopup slides={getOverlayProps()} isOverlay={true} isEE={false} />
        </ModalDialog>
      )}
      {item?.showPopupWithSplitModule && item?.splitModules && (
        <ModalDialog isOpen={showSplitModule} close={() => handleSplitModuleModal(false)} className="split-module-modal">
          {item?.splitModules.map((splitModule, index) => (
            <SplitModule {...splitModule} key={splitModule?.id} params={{ imagePosition: index % 2 == 0 ? 'right' : 'left' }} />
          ))}
        </ModalDialog>
      )}
    </>
  );
};

export default React.memo(CarouselSlide);
