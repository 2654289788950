import React, {FC} from 'react';
import LazySection from '../../../@shared/lazySection';
import './styles.scss';
import grid from '../../../../assets/Grey-dots.svg';
import Fade from 'react-reveal/Fade';
import OtherQuickLinkItem from './components/otherQuickLinkItem';
import {TOtherQuickLinksMobileProps} from './types/otherQuickLinksMobile.types';

const OtherQuickLinksMobile: FC<TOtherQuickLinksMobileProps> = ({quickLinks, label}: TOtherQuickLinksMobileProps) => {
  return (
    <LazySection>
      <div className="other-quick-link-wrapper">
        <div className="other-quick-link">
          <>
            <Fade bottom duration={1000} delay={750}>
              <article className="other-quick-link__text-box">
                <h2 className="other-quick-link__text-heading">{label}</h2>
              </article>
            </Fade>
            <Fade duration={500} delay={1500}>
              { /* eslint-disable-next-line */}
              <img className="other-quick-link__grid" src={grid} loading="lazy"/>
              <ul className="other-quick-link__buttons">
                {quickLinks.map((item, index) => {
                  return <OtherQuickLinkItem key={item?.id} quickLink={item} index={index} totEl={quickLinks.length}/>;
                })}
              </ul>
            </Fade>
          </>
        </div>
      </div>
    </LazySection>
  );
};

export default React.memo(OtherQuickLinksMobile);
