import { useContext } from 'react';
import { SitecoreContextReactContext } from '@sitecore-jss/sitecore-jss-react';
import { useTranslation } from 'react-i18next';

// use this hook for checking if we have to display the dictionary fallback
export default function useShowProductComponentTitle(hasProductTitle: boolean, dictionaryTerms: string[]): boolean {
  const {t} = useTranslation();
  const sitecoreContextFactory = useContext(SitecoreContextReactContext);
  const sitecoreContext = sitecoreContextFactory.context;
  const isPageInEditState = sitecoreContext?.pageState === 'edit';
  const hasDictionaryFallback = !!dictionaryTerms.find(x => {
    const dictionaryValue = t(x, {defaultValue: ''})?.trim();
    /**
     * in content editor if dictionary key is defined and value is not defined
     * then value returned from sitecore will be the key itself
     **/ 
    return dictionaryValue && dictionaryValue !== x;
  }
  );
  return hasProductTitle || hasDictionaryFallback || isPageInEditState;
}