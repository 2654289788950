// Types
export const topMainMenu = 'Top Main Menu';
export const topIconMenu = 'Top Icon Menu';
export const sliderNavBar = 'Slider Navbar';
export const breadcrumb = 'Breadcrumbs';
export const sectionTab = 'Section Tabs';
export const productDropdown = 'Product Dropdown';
export const footerMenu = 'Footer Menu';
export const marketSelector = 'Market Selector';
export const languageSelector = 'Language Selector';
export const quickLinks = 'Quick Links';
export const FindEquipment = 'Find Equipment Toolbar';
export const primaryCTA = 'Primary CTA';
export const secondaryCTA = 'Secondary CTA';
export const callTopService = 'Call Top Service';
export const newsLetter = 'Newsletter';

// Events
export const plmClickEvent = 'series_plm_click';
export const plmDetailsOpen = 'series_plm_detail_open';
export const compatibleClickEvent = 'series_compatible_click';
export const specsExpandEvent = 'series_specs_expand';
export const specsExportEvent = 'series_specs_export';
export const downloadBrochures = 'series_brochures_download';
export const featureDetailOpen = 'series_features_detail_open';
export const dealerLocatorResultsOpen = 'dealer_locator_results_open';
export const dealerLocatorDetailView = 'dealer_locator_detail_view';
export const dealerLocatorContactClick = 'dealer_locator_contact_click';
export const dealerLocatorGetDirections = 'dealer_locator_get_directions';
