import React, { ReactElement, FunctionComponent, useContext } from 'react';
import { SitecoreContextReactContext } from '@sitecore-jss/sitecore-jss-react';
import PropTypes from 'prop-types';
import { TSuperScriptTags, ICustomTextProps } from './types/customText.types';

export function replaceSuperScriptTags(str: string, formatted: (ReactElement | string)[], isEnglish = true) {
  // u00A9 -> © , u00AE -> ®, u002A -> * , u2122 -> ™
  let regex = /(\d)(?:st|nd|rd|th)|(\u00A9)|(\u00AE)|(\u002A)|(\u2122)/g;
  if (!isEnglish) {
    regex = /(\u00A9)|(\u00AE)|(\u002A)|(\u2122)/g;
  }
  const superScriptTags: TSuperScriptTags[] = [];
  let cursor = 0;
  let matchedScriptChar = regex.exec(str);
  while (matchedScriptChar !== null) {
    let matchedChars: string;
    if (matchedScriptChar[1] && isEnglish) {
      superScriptTags.push([cursor, matchedScriptChar.index + 1]);
      matchedChars = matchedScriptChar[0].slice(matchedScriptChar[1].length);
    } else {
      superScriptTags.push([cursor, matchedScriptChar.index]);
      matchedChars = matchedScriptChar[0];
    }
    superScriptTags.push(matchedChars);
    cursor = matchedScriptChar.index + matchedScriptChar[0].length;
    matchedScriptChar = regex.exec(str);
  }
  superScriptTags.push([cursor, str.length]);
  superScriptTags.forEach((val) => {
    if (typeof val === 'string') {
      formatted.push(<sup>{val}</sup>);
    }
    if (Array.isArray(val)) {
      const [startIndex, endIndex] = val;
      formatted.push(str.slice(startIndex, endIndex));
    }
  });
}

export const CustomText: FunctionComponent<ICustomTextProps> = ({ field, tag, editable, encode, ...otherProps }) => {
  const sitecoreContextFactory = useContext(SitecoreContextReactContext);
  
  if (!field || (!field.editable && (field.value === undefined || field.value === ''))) {
    return null;
  }

  const sitecoreContext = sitecoreContextFactory.context;
  const language: string = (sitecoreContext?.language || '').toLowerCase();
  const isEnglish = language?.startsWith('en');

  // can't use editable value if we want to output unencoded
  if (!encode) {
    // eslint-disable-next-line no-param-reassign
    editable = false;
  }

  const isEditable = field.editable && editable;

  let output: string | number | (ReactElement | string)[];

  if (isEditable) {
    output = field.editable || '';
  } else {
    output = field.value === undefined ? '' : field.value;
  }

  // when string value isn't formatted, we should format line breaks
  if (!field.editable && typeof output === 'string') {
    const splitted = String(output).split('\n');

    if (splitted.length) {
      const formatted: (ReactElement | string)[] = [];

      splitted.forEach((str, i) => {
        replaceSuperScriptTags(str, formatted, isEnglish);

        const isLast = i === splitted.length - 1;

        if (!isLast) {
          formatted.push(<br key={i} />);
        }
      });

      output = formatted;
    }
  }

  const setDangerously = isEditable || !encode;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let children: any = null;
  const htmlProps: {
    [htmlAttributes: string]: unknown;
    children?: React.ReactNode;
  } = {
    ...otherProps,
  };

  if (setDangerously) {
    htmlProps.dangerouslySetInnerHTML = {
      __html: output,
    };
  } else {
    children = output;
  }

  if (tag || setDangerously) {
    return React.createElement(tag || 'span', htmlProps, children);
  } else {
    return <React.Fragment>{children}</React.Fragment>;
  }
};

CustomText.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    editable: PropTypes.string,
  }) as any,
  tag: PropTypes.string,
  editable: PropTypes.bool,
  encode: PropTypes.bool,
};

CustomText.defaultProps = {
  editable: true,
  encode: true,
};

CustomText.displayName = 'CustomText';
