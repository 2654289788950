import React from 'react';
import './styles.scss';

const LoadingCard = ({ layout }): JSX.Element => {
  return (
    <article className={`card-column card-column--${layout} loading-card`}>
      <div className="loading-simulator"></div>
      <div className={`card-column__image-wrapper card-column__image-wrapper--${layout}`} />
      <div className="loading-card__content">
        <div className="loading-card__title"></div>
        <div className="loading-card__description"></div>
        <div className="loading-card__description"></div>
        <div className="loading-card__cta"></div>
      </div>
    </article>
  );
};

export default LoadingCard;
