import React, { useEffect, useRef } from 'react';
import { TProductCard } from '../../types/compatibleProducts.types';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import RoutableNotEditableLink from '../../../@shared/routableNotEditableLink';
import { appendToUrlPath } from '../../../../utils/link.utils';
import Picture from '../../../@shared/picture';
import { googleCTAClickEvent } from '../../../../utils/analytics';
import { compatibleClickEvent } from '../../../../constants/analytics.constants';
import { appendRootToRichTextInternalLinks } from '../../../../utils/appendRootToRichTextInternalLinks.utils';
import yellowDotsImage from '../../../../assets/4-columnLayout-dots.png';
import { windowService } from '../../../../utils/window.utils';
import './styles.scss';

const CompatibleProduct = (props: TProductCard) => {
  const { title, overviewDescription, assets, url, baseUrl, productLine, productSeries } = props;
  const asset = assets && assets[0];
  const productDescription = appendRootToRichTextInternalLinks(overviewDescription);
  const imageIsPresent = !!asset?.rendition_mobile_size500?.url || !!asset?.rendition_desktop_size500?.url;

  const cardRef = useRef<HTMLDivElement | null>(null);

  const setCardMinHeight = () => {
    if (!cardRef?.current) return;
    cardRef.current.style.minHeight = `${cardRef.current.clientHeight}px`;
  };

  const resetCardMinHeight = () => {
    if (!cardRef?.current) return;
    cardRef.current.style.minHeight = '';
  };

  useEffect(() => {
    if (!cardRef?.current) return;
    setCardMinHeight();

    let timeOutFunctionId;

    const _onResizeRefreshMinHeightCard = () => {
      if (timeOutFunctionId) clearTimeout(timeOutFunctionId);
      resetCardMinHeight();
      timeOutFunctionId = setTimeout(setCardMinHeight, 500);
    };

    windowService()?.addEventListener('resize', _onResizeRefreshMinHeightCard);

    return () => {
      windowService()?.removeEventListener('resize', _onResizeRefreshMinHeightCard);
    };
  }, [cardRef]);

  return (
    <RoutableNotEditableLink
      className={`compatible-product-card ${!url ? 'compatible-product-card--none' : ''}`}
      href={appendToUrlPath(baseUrl || '', url || '')}
      onClick={(e) => {
        !url && e.preventDefault();
        googleCTAClickEvent(compatibleClickEvent, appendToUrlPath(baseUrl || '', url || ''), title, productLine, productSeries);
      }}
    >
      <div className={'compatible-product-card__picture-wrapper'} ref={cardRef}>
        <div className={'compatible-product-card__image-wrapper'}>
          {imageIsPresent && (
            <>
              <img className={'compatible-product-card__image-yellow-dots'} src={yellowDotsImage} alt={''} loading="lazy" />
              <Picture
                productImageProps={{
                  mobileRendition: asset?.rendition_mobile_size500,
                  desktopRendition: asset?.rendition_desktop_size500,
                  pictureClassName: 'compatible-product-card__image',
                  alt: asset?.alt,
                }}
              />
            </>
          )}
        </div>

        <div className={'compatible-product-card__body'}>
          {title && <Text tag="h3" field={{ value: title }} className="compatible-product-card__title" />}
          {productDescription && (
            <RichText tag="div" field={{ value: productDescription }} className={'compatible-product-card__description rich-text'} />
          )}
        </div>
      </div>
    </RoutableNotEditableLink>
  );
};

export default CompatibleProduct;
