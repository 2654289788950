import { TFunction } from 'i18next';
import { SP_QUERY_STRINGS } from '../../../constants/special-offers-page.constants';
import { TLinkField, TRendition, TStringField } from '../../../types/sitecore.types';
import { appendRootToInternalLink } from '../../../utils/appendRootToInternalLink.utils';
import { appendRootToRichTextInternalLinks } from '../../../utils/appendRootToRichTextInternalLinks.utils';
import {
  TProductSpecialOffersProps,
  TProductSpecialOffersRendererFields,
  TProductSpecialOffersRendererParams,
  TProductSpecialOffersSlideFields,
} from '../types/productSpecialOffers.types';

export class ProductSpecialOffersSlideModel {
  private readonly card: TProductSpecialOffersSlideFields;
  private readonly t?: TFunction;
  private readonly specialOfferMainPageLink: string;

  constructor(Card: TProductSpecialOffersSlideFields, t?: TFunction, specialOfferMainPageLink?: string) {
    this.card = Card || null;
    this.t = t;
    this.specialOfferMainPageLink = specialOfferMainPageLink || '';
  }

  get titleUrl(): string {
    return this.card?.titleUrl || '';
  }

  get title(): TStringField {
    return { value: this.card?.formattedTitle };
  }

  get description(): TStringField {
    return { value: this.card?.description };
  }

  get disclaimer(): string {
    if (this.card?.disclaimer) {
      return '**' + appendRootToRichTextInternalLinks(this.card?.disclaimer);
    }
    return '';
  }

  get expiration(): TStringField {
    return { value: this.card?.expiration };
  }

  get badge(): string {
    return this.card?.badge;
  }

  get expirationLabel(): TStringField {
    return { value: this.card?.expirationLabel };
  }

  get image(): TRendition {
    const asset = this.card?.assets && this.card?.assets[0];
    return asset?.rendition_desktop_size1000 as TRendition;
  }

  get mobileImage(): TRendition {
    const asset = this.card?.assets && this.card?.assets[0];
    return asset?.rendition_mobile_size300 as TRendition;
  }

  get buttonPrimary(): TLinkField {
    if (!this.titleUrl || !this.specialOfferMainPageLink || this.card?.hideSpecialOfferButtonFromCard)
      return { value: { href: '', title: '' } };
    const title = this.t?.('cnhi-special-offer-learn-more');
    return {
      value: {
        href: `${this.specialOfferMainPageLink}/${this.titleUrl}`,
        title,
        text: title,
      },
    };
  }

  get buttonSecondary(): TLinkField {
    return {
      value: {
        href: appendRootToInternalLink(this.card?.button2?.url),
        title: this.card?.button2?.label,
        target: this.card?.button2?.target,
      },
    };
  }

  get cardStyles(): TStringField {
    return { value: this.card?.cardStyles };
  }
}

export class ProductSpecialOffersModel {
  private readonly fields: null | TProductSpecialOffersRendererFields;
  private readonly params: null | TProductSpecialOffersRendererParams;
  private readonly t?: TFunction;
  private readonly specialOfferMainPageLink: string;

  constructor(props: TProductSpecialOffersProps, t?: TFunction, specialOfferMainPageLink?: string) {
    this.fields = props?.fields || null;
    this.params = props?.params || null;
    this.t = t;
    this.specialOfferMainPageLink = specialOfferMainPageLink || '';
  }

  get eyebrow(): TStringField {
    return { value: this.fields?.apiData?.eyebrow };
  }

  get title(): TStringField {
    return { value: this.fields?.apiData?.title };
  }

  get description(): TStringField {
    return { value: appendRootToRichTextInternalLinks(this.fields?.apiData?.description) };
  }

  getLink(lineId: string): TLinkField | undefined {
    if (!lineId || !this.specialOfferMainPageLink) return;
    const title = this.t?.('cnhi-special-offer-read-more') || '';
    return {
      value: {
        href: `${this.specialOfferMainPageLink}?${SP_QUERY_STRINGS.LINE}=${lineId}`,
        title,
        text: title,
      },
    };
  }

  get items() {
    const todayDate = new Date();
    const items: TProductSpecialOffersSlideFields[] = this.fields?.apiData?.items || [];
    const cardStyles = (this.params?.cardStyles && this.params?.cardStyles.split('|')) || [];
    const validSpecialOffers = items.filter((offer, index) => {
      offer['cardStyles'] = cardStyles[index];
      const expiryDate = new Date(offer.expiration || '');
      return expiryDate.setHours(0, 0, 0, 0) >= todayDate.setHours(0, 0, 0, 0);
    });
    const defaultValue =
      validSpecialOffers &&
      validSpecialOffers.map((Card: TProductSpecialOffersSlideFields) => {
        return new ProductSpecialOffersSlideModel(Card, this.t, this.specialOfferMainPageLink);
      });
    if (this.fields && this.fields['Cards']) {
      return this.fields['Cards'].map((Card: TProductSpecialOffersSlideFields) => {
        return new ProductSpecialOffersSlideModel(Card, this.t, this.specialOfferMainPageLink);
      });
    }
    return defaultValue;
  }

  get isUnreleased(): boolean {
    return !!this.fields?.isComponentUnreleased;
  }
}
