const iconSize = {
  xxs: 12,
  xs: 16,
  md: 18,
  mbl: 20,
  sm: 22,
  dsk: 24,
  xlg: 32,
  xxlg: 40
};

export { iconSize };
