import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React, { FC } from 'react';
import Fade from 'react-reveal/Fade';
import nhagDotsDsk from '../../../../assets/brand-assets-n-hag-dots.png';
import { BreakpointsInPx } from '../../../../constants/breakpoints.constants';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { appendRootToRichTextInternalLinks } from '../../../../utils/appendRootToRichTextInternalLinks.utils';
import Banner from '../componentDetails/banner';
import { TModalComponentsItemProps } from '../types/innerComponentsPopup.types';
import Buttons from './buttons';

const InnerSplitModule: FC<TModalComponentsItemProps> = (props) => {
  const model = props?.component;
  const isDesktop = useMediaQuery(`(min-width: ${BreakpointsInPx['tbl-p']})`);
  const FadeRef = isDesktop ? Fade : ({ children }): React.ReactNode => <>{children}</>;
  const firstAssset = props?.component?.assets?.[0];
  const isMediaShown =
    ((firstAssset?.role?.toLocaleLowerCase() === 'video' || firstAssset?.role?.toLocaleLowerCase() === 'extvideo') && firstAssset?.full) ||
    (firstAssset?.role?.toLocaleLowerCase() === 'main' &&
      (firstAssset?.rendition_desktop_size1000?.url || firstAssset?.rendition_mobile_size500?.url));

  return (
    <div className={`inner-split-module ${!isMediaShown ? 'inner-split-module--no-media' : ''}`}>
      <FadeRef bottom duration={1000}>
        <div className="inner-split-module__image-wrapper">
          {!!isMediaShown && (
            <Banner
              component={model}
              mediaHeightClassName="inner-split-module__media-height"
              mediaCarouselClassName="inner-split-module__media-carousel"
              desktopRenditionKey="rendition_desktop_size1000"
              mobileRenditionKey="rendition_mobile_size500"
            />
          )}
        </div>
        <div className={`inner-split-module__text-wrapper ${!isMediaShown ? 'inner-split-module__text-wrapper--no-media' : ''}`}>
          <FadeRef duration={1000} delay={1000}>
            <img
              alt={model?.title}
              loading="lazy"
              src={nhagDotsDsk}
              className={`inner-split-module__dots-image-dsk ${!isMediaShown ? 'inner-split-module__dots-image-dsk--no-media' : ''}`}
            />
          </FadeRef>
          <FadeRef bottom duration={1000} delay={1000}>
            <>
              {model?.eyebrow && <p className="inner-split-module__eyebrow">{model?.eyebrow}</p>}
              {model?.title && <h2 className="inner-split-module__title">{model?.title}</h2>}
              {model?.description && (
                <RichText
                  editable={false}
                  field={{ value: appendRootToRichTextInternalLinks(model?.description) }}
                  tag="div"
                  className="inner-split-module__description rich-text"
                />
              )}
              <Buttons button1={model.button1} button2={model.button2} isDesktop={isDesktop} />
            </>
          </FadeRef>
        </div>
      </FadeRef>
    </div>
  );
};

export default InnerSplitModule;
