import React from 'react';
import { TProductCard } from '../../types/modelsListing.types';
import './styles.scss';
import Picture from '../../../@shared/picture';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import CTA from '../../../@shared/CTA';
import ctaTypes from '../../../../enums/CTA.enums';
import DictionaryTerm from '../../../@shared/dictionaryTerm';
import { appendRootToInternalLink } from '../../../../utils/appendRootToInternalLink.utils';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { BreakpointsInPx } from '../../../../constants/breakpoints.constants';

const ModelListingCard = (props: TProductCard) => {
  const { title, summarySpecs, assets, disableUpperCase, enableBuildPriceCTA, buildAndPriceUrlForModel, tagging } = props;
  const asset = assets && assets[0];
  const isMobile = useMediaQuery(`(max-width:${BreakpointsInPx['tbl-p']})`);

  return (
    <div className={'model-listing-card'}>
      <div className={'model-listing-card__image-wrapper'}>
        {assets ? (
          <Picture
          productImageProps={{
            mobileRendition: asset?.rendition_mobile_size200,
            desktopRendition: asset?.rendition_desktop_size500,
            alt: asset?.alt,
            pictureClassName: 'model-listing-card__image',
          }}
          />
        ) : null}
        {!isMobile && tagging && <div className="model-listing-card__price-tag">{tagging}</div>}
      </div>
      <div className={'model-listing-card__body'}>
        {isMobile && tagging && <div className="model-listing-card__price-tag">{tagging}</div>}
        {title && <h3 className="model-listing-card__title">{title}</h3>}
        {summarySpecs && summarySpecs[0] && (
          <div className={'model-listing-card__spec1'}>
            <RichText tag='div' field={{ value: summarySpecs[0].key }} editable={false} className={`model-listing-card__spec-text rich-text  ${disableUpperCase ? 'model-listing-card__spec-text--no-transform' : ''}`} />
            <RichText tag='div' field={{ value: summarySpecs[0].value }} editable={false} className={`model-listing-card__spec-value rich-text  ${disableUpperCase ? 'model-listing-card__spec-text--no-transform' : ''}`} />
          </div>
        )}
        {summarySpecs && summarySpecs[1] && (
          <div className={'model-listing-card__spec2'}>
            <RichText tag='div' field={{ value: summarySpecs[1].key }} editable={false} className={`model-listing-card__spec-text rich-text  ${disableUpperCase ? 'model-listing-card__spec-text--no-transform' : ''}`} />
            <RichText tag='div' field={{ value: summarySpecs[1].value }} editable={false} className={`model-listing-card__spec-value rich-text  ${disableUpperCase ? 'model-listing-card__spec-value--no-transform' : ''}`} />
          </div>
        )}
        {enableBuildPriceCTA && buildAndPriceUrlForModel && (
          <CTA type={ctaTypes.primaryYellow} href={appendRootToInternalLink(buildAndPriceUrlForModel)} extraClassName="model-listing-card__buid-price-CTA">
            <DictionaryTerm keyword={'cnhi-build-price'} />
          </CTA>
        )}
      </div>
    </div>
  );
};

export default ModelListingCard;
