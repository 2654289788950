import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { TGridCSSVariables } from '../../../../../constants/table.constants';
import useStickyWrapper from '../../../../../hooks/useStickyWrapper';
import { TFixedColHeadingProps } from '../../../types/index.types';

const FixedColHeading = (props: TFixedColHeadingProps & { children: ReactNode }, ref) => {
  const {
    showFixedColumnHeading,
    children,
    gridColumns,
    gridFixedColHeadsMoveBy,
    gridColumnWidth,
    gridOutsideSpaceHalved,
    gridLeftCellsMoveBy,
  } = props;

  const FixedColHeadingRef = useRef<HTMLDivElement>(null);
  useImperativeHandle(ref, () => ({
    get FixedColHeadingRef() {
      return FixedColHeadingRef;
    },
  }));
  const [variableInserted, setVariablesInserted] = useState<boolean>(showFixedColumnHeading);
  const stickyWrapperEle = useStickyWrapper();

  useEffect(() => {
    if (!showFixedColumnHeading) {
      setVariablesInserted(() => false);
      return;
    }
    const fixedColHeadingEl = FixedColHeadingRef.current as HTMLElement | null;
    if (!fixedColHeadingEl) return;
    fixedColHeadingEl.style.setProperty(TGridCSSVariables.gridColumns, gridColumns);
    fixedColHeadingEl.style.setProperty(TGridCSSVariables.gridFixedColHeadsMoveBy, gridFixedColHeadsMoveBy);
    fixedColHeadingEl.style.setProperty(TGridCSSVariables.gridColumnWidth, gridColumnWidth);
    fixedColHeadingEl.style.setProperty(TGridCSSVariables.gridOutsideSpaceHalved, gridOutsideSpaceHalved);
    fixedColHeadingEl.style.setProperty(TGridCSSVariables.gridLeftCellsMoveBy, gridLeftCellsMoveBy);
    setVariablesInserted(() => true);
  }, [
    showFixedColumnHeading,
    FixedColHeadingRef,
    gridColumns,
    gridFixedColHeadsMoveBy,
    gridColumnWidth,
    gridOutsideSpaceHalved,
    gridLeftCellsMoveBy,
  ]);

  if (!stickyWrapperEle || !showFixedColumnHeading) return null;

  return createPortal(
    <div
      className={`model-detail-specification-table__fixed-col-heading-wrapper ${
        !variableInserted || !showFixedColumnHeading ? 'model-detail-specification-table__fixed-col-heading-wrapper--hide' : ''
      }`}
      ref={FixedColHeadingRef}
    >
      <div className="model-detail-specification-table__fixed-col-heading--box-shadow-el" />
      <div className="model-detail-specification-table__fixed-col-heading--left-cover" />
      <table cellSpacing="0" cellPadding="0" className="model-detail-specification-table__fixed-col-heading">
        <thead>
          <tr>
            <th />
            <th className="model-detail-specification-table__left-most-cell model-detail-specification-table__left-most-cell--omit-transition">
              <div className="model-detail-specification-table__left-most-empty-cell" />
            </th>
            {children}
            <th />
          </tr>
        </thead>
      </table>
      <div className="model-detail-specification-table__fixed-col-heading--right-cover" />
    </div>,
    stickyWrapperEle
  );
};

export default React.memo(forwardRef(FixedColHeading));
