import React, { FC } from 'react';
import Icon from '../../icon';
import { iconSize } from '../../../../constants/icon.constants';
import RoutableNotEditableLink from '../../routableNotEditableLink';
import { googleNavClickEvent } from '../../../../utils/analytics';
import { breadcrumb } from '../../../../constants/analytics.constants';
import { TNavigationBarItemProps } from '../types/navigationBarItem.types';

const NavigationItems: FC<TNavigationBarItemProps & { isNotClickable: boolean }> = (props): JSX.Element => {
  const { isLast, item, isNotClickable } = props;

  if (isLast) {
    return <>{item.text}</>;
  } else if (isNotClickable) {
    return (
      <>
        {item.text}
        <Icon iconName="arrow-chevron-right" size={iconSize.mbl} extraClassName="nav-bar__chevron-icon" />
      </>
    );
  } else {
    return (
      <>
        <RoutableNotEditableLink
          href={item.url}
          onClick={() => {
            googleNavClickEvent(breadcrumb, item.url, item.text);
          }}
        >
          {item.text}
        </RoutableNotEditableLink>
        <Icon iconName="arrow-chevron-right" size={iconSize.mbl} extraClassName="nav-bar__chevron-icon" />
      </>
    );
  }
};

export default NavigationItems;
