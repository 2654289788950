import { TContentHubSeriesOverviewProps, TGalleryItem } from '../types/contentHubSeriesOverview.type';

export class OverviewCarouselModel {
  private readonly fields?;

  constructor(props: TContentHubSeriesOverviewProps) {
    this.fields = props?.fields || null;
  }

  get slides(): Array<TGalleryItem> {
    return this.fields?.apiData?.assets;
  }
}
