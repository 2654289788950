import React, { useEffect, useRef } from 'react';
import { Text, withSitecoreContext, RichText } from '@sitecore-jss/sitecore-jss-react';
import Fade from 'react-reveal/Fade';
import { SplitModuleModel } from './models/splitModule.model';
import { SplitModuleProps } from './types/splitModule.types';
import brandImage from '../../assets/brand-assets-n-hag-dots.png';
import useMediaQuery from '../../hooks/useMediaQuery';
import Breakpoints from '../../constants/breakpoints.constants';
import { getThumbnailProps } from '../../utils/youtubeUtils';
import { getImageField } from '../../utils/getImageFields';
import { CustomText } from '../@shared/customText';
import { windowService } from '../../utils/window.utils';
import Buttons from './components/buttons';
import Media from './components/media';

import './index.scss';

const WrapFade: Fade = ({ isEE, bottom, duration, delay, children }): JSX.Element => {
  if (!isEE) {
    return (
      <Fade bottom={bottom} duration={duration} delay={delay}>
        {children}
      </Fade>
    );
  }
  return <>{children}</>;
};

const SplitModule = (props: SplitModuleProps) => {
  const isEE = props.sitecoreContext?.pageEditing;
  const model = new SplitModuleModel(props);
  const isDesktop = useMediaQuery(`(min-width: ${Breakpoints['tbl-p']}px)`);

  const mediaRef = useRef<any>();
  const splitModuleContainerRef = useRef<any>();

  const { thumbnailImgSrc } = getThumbnailProps(model?.video?.value?.href || '');
  const desktopImage = getImageField(model?.image, thumbnailImgSrc || '', model?.useVideo?.value);
  const mobileImage = getImageField(model?.mobileImage, thumbnailImgSrc || '', model?.useVideo?.value);

  const setContainerMinHeight = () => {
    if (mediaRef.current && splitModuleContainerRef.current) {
      splitModuleContainerRef.current.style.minHeight = `${mediaRef.current.clientHeight}px`;
    }
  };

  const resetCardMinHeight = () => {
    if (!splitModuleContainerRef?.current) return;

    splitModuleContainerRef.current.style.minHeight = '';
  };

  useEffect(() => {
    setContainerMinHeight();

    let timeOutFunctionId;

    const _onResizeRefreshMinHeightContainer = () => {
      if (timeOutFunctionId) clearTimeout(timeOutFunctionId);
      resetCardMinHeight();

      timeOutFunctionId = setTimeout(setContainerMinHeight, 500);
    };

    windowService().addEventListener('resize', _onResizeRefreshMinHeightContainer);

    return () => {
      windowService().removeEventListener('resize', _onResizeRefreshMinHeightContainer);
    };
  }, [mediaRef, splitModuleContainerRef]);

  return (
    <div className={`split-module split-module--${model.imagePosition}`} ref={splitModuleContainerRef}>
      <WrapFade duration={1000} delay={500} isEE={isEE}>
        <div className="split-module__dot-image">
          {/* eslint-disable-next-line */}
          <img src={brandImage} loading="lazy" />
        </div>
      </WrapFade>
      <div className="split-module__media-container" ref={mediaRef}>
        <WrapFade bottom duration={1000} delay={500} isEE={isEE}>
          <Media
            carouselSlide={model.carouselSlide}
            slides={model.slides}
            useVideo={model.useVideo}
            mobileImage={mobileImage}
            desktopImage={desktopImage}
            isDesktop={isDesktop}
            video={model.video}
          />
        </WrapFade>
      </div>
      <WrapFade bottom duration={1000} delay={500} isEE={isEE}>
        <div className="split-module__text-container container">
          <Text tag="p" field={model?.eyebrow} className={'split-module__eyebrow'} />
          <CustomText tag={model.headingTag} field={model.title} className={'split-module__title'} />
          <RichText tag="div" field={model.bodyText} className={'rich-text split-module__text'} />
          <Buttons
            isEE={isEE}
            link1={model.link1}
            link2={model.link2}
            isDesktop={isDesktop}
            appStoreImage={model.appStoreImage}
            googlePlayImage={model.googlePlayImage}
            rendering={props.rendering}
          />
        </div>
      </WrapFade>
    </div>
  );
};

export default withSitecoreContext()(SplitModule);
