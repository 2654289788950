import React, { FC, useState, useEffect, useCallback, useRef } from 'react';
import Slider from 'react-slick';
import MediaCarouselArrow from './components/carouselArrow';
import CarouselTransparentNavSection from '../carouselTransparentNavSection';
import { CarouselNavPositionEnum } from '../../../enums/carousel.enums';
import { TMediaCarouselProps } from './types/mediaCarousel.types';
import MediaCard from './components/mediaCard';
import CustomCarouselDots from '../customCarouselDots';
import useEEActive from '../../../hooks/useEEActive';
import './styles.scss';

const MediaCarousel: FC<TMediaCarouselProps> = ({
  slides,
  carouselSettings = {},
  hasFixedAspectRatio = false,
  mediaHeightClassName = '',
  extraClassName = '',
}) => {
  const isEEActive = useEEActive();
  const [currentSlide, _setCurrentSlide] = useState(0);
  const hasMultipleSlides = slides?.length > 1;
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [videoPaused, setVideoPaused] = useState(false);
  const sliderRef = useRef<Slider | null>(null);

  const cardsCount = slides.length;

  useEffect(() => {
    setVideoPlaying(false);
  }, [currentSlide]);

  const setCurrentSlide = (index: number): void => {
    if (hasMultipleSlides) {
      if (currentSlide !== index) {
        _setCurrentSlide(index);
      }
    }
  };

  const goToPreviousSlide = (): void => {
    const prevSlideNo = currentSlide === 0 ? slides.length - 1 : currentSlide - 1;
    sliderRef.current?.slickGoTo(prevSlideNo);
    setVideoPlaying(false);
    setVideoPaused(false);
  };

  const goToNextSlide = (): void => {
    const nextSlideNo = currentSlide === slides.length - 1 ? 0 : currentSlide + 1;
    sliderRef.current?.slickGoTo(nextSlideNo);
    setVideoPlaying(false);
    setVideoPaused(false);
  };

  const playCallback = useCallback(() => {
    setVideoPlaying(true);
    setVideoPaused(false);
  }, [setVideoPlaying, setVideoPaused]);

  const pauseCallback = useCallback(() => {
    setVideoPlaying(false);
    setVideoPaused(true);
  }, [setVideoPlaying, setVideoPaused]);

  const endCallback = useCallback(() => {
    setVideoPlaying(false);
    setVideoPaused(false);
  }, [setVideoPlaying, setVideoPaused]);

  const deafultSettings = {
    infinite: !isEEActive,
    dots: false,
    arrows: false,
    variableWidth: false,
    slidesToScroll: 1,
    afterChange: (index): void => {
      setCurrentSlide(index);
    },
  };

  return (
    <div className={`media-carousel ${extraClassName}`}>
      <div className="media-carousel__wrapper">
        <Slider className="media-carousel__slider" {...deafultSettings} {...carouselSettings} ref={sliderRef}>
          {slides?.map((slide, index) => {
            return (
              <MediaCard
                key={index}
                slide={slide}
                hasFixedAspectRatio={hasFixedAspectRatio}
                mediaHeightClassName={mediaHeightClassName}
                playCallback={playCallback}
                pauseCallback={pauseCallback}
                endCallback={endCallback}
                activeCard={index === currentSlide}
              />
            );
          })}
        </Slider>
        {hasMultipleSlides && (
          <>
            <MediaCarouselArrow
              direction={'previous'}
              onClick={goToPreviousSlide}
              customClass={`
                media-carousel__animate-icons
                ${videoPlaying && 'media-carousel__animate-icons--animate-fade'}
                ${videoPaused && 'media-carousel__animate-icons--show'}
              `}
            />
            <CarouselTransparentNavSection position={CarouselNavPositionEnum.LEFT} handleTouchStart={goToPreviousSlide} />
            <ul className={`media-carousel__controls ${(videoPlaying || videoPaused) && 'media-carousel__animate-icons--hide'}`}>
              <CustomCarouselDots cardsCount={cardsCount} activeCard={currentSlide + 1} isDarkMode={true} />
            </ul>
            <CarouselTransparentNavSection position={CarouselNavPositionEnum.RIGHT} handleTouchStart={goToNextSlide} />
            <MediaCarouselArrow
              direction={'next'}
              onClick={goToNextSlide}
              customClass={`
                media-carousel__animate-icons
                ${videoPlaying && 'media-carousel__animate-icons--animate-fade'}
                ${videoPaused && 'media-carousel__animate-icons--show'}
              `}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default MediaCarousel;
