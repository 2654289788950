import { SplitModuleFields, SplitModuleProps, TCarouselItemProps } from '../types/splitModule.types';
import {
  TStringField,
  TVideoSitecoreField,
  TLinkField,
  TImageField,
  TCheckboxField,
  THeadingTag,
  TComponentParamsGeneral,
} from '../../../types/sitecore.types';
import { TMediaCarouselSlide } from '../../@shared/mediaCarousel/types/mediaCarousel.types';
import { getThumbnailProps } from '../../../utils/youtubeUtils';
import { getImageField } from '../../../utils/getImageFields';

export class SplitModuleModel {
  private readonly fields: SplitModuleFields | undefined;
  private readonly params?: TComponentParamsGeneral;

  constructor(props: SplitModuleProps) {
    this.fields = props?.fields;
    this.params = props?.params;
  }

  get bodyText(): TStringField {
    return this.fields?.bodyText || {};
  }

  get eyebrow(): TStringField {
    return this.fields?.eyebrow || {};
  }

  get title(): TStringField {
    return this.fields?.title || {};
  }

  get link1(): TLinkField {
    return this.fields?.link1 || { value: {} };
  }

  get link2(): TLinkField {
    return this.fields?.link2 || { value: {} };
  }

  get image(): TImageField {
    return this.fields?.image || { value: {} };
  }

  get videoHeading(): TStringField {
    return this.fields?.videoHeading || {};
  }

  get videoSubheading(): TStringField {
    return this.fields?.videoSubheading || {};
  }

  get mobileImage(): TImageField {
    return this.fields?.mobileImage || { value: {} };
  }

  get video(): TVideoSitecoreField {
    return this.fields?.video || { value: {} };
  }

  get useVideo(): TCheckboxField {
    return this.fields?.useVideo || {};
  }

  get imagePosition(): 'left' | 'right' {
    return this.params?.imagePosition === 'right' ? 'right' : 'left';
  }

  get carouselSlide(): TCarouselItemProps[] {
    return this.fields?.items || [];
  }

  get slides(): TMediaCarouselSlide[] {
    return this.carouselSlide?.map(({ fields }) => {
      const { thumbnailImgSrc } = getThumbnailProps(fields?.video?.value?.href || '');
      const desktopImage = getImageField(fields?.image, thumbnailImgSrc || '', fields?.useVideo?.value);
      const mobileImage = getImageField(fields?.mobileImage, thumbnailImgSrc || '', fields?.useVideo?.value);
      const desktopTransformation = 'Size1000';
      const mobileTransformation = 'Size500';

      return {
        isVideo: fields?.useVideo?.value,
        videoUrl: fields?.video,
        editorialImageProps: {
          desktopImage: desktopImage,
          mobileImage: mobileImage,
          mobileTransformation: mobileTransformation,
          desktopTransformation: desktopTransformation,
        },
      };
    });
  }

  get appStoreImage(): TImageField {
    return this.fields?.appStoreImage || {};
  }

  get googlePlayImage(): TImageField {
    return this.fields?.googlePlayImage || {};
  }

  get headingTag(): THeadingTag {
    return (this.params?.headingTag?.toLowerCase() as THeadingTag) || 'h3';
  }
}
