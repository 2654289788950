import React, { FC } from 'react';

import { ValueFieldProps } from '@sitecore-jss/sitecore-jss-react-forms/types';
import Icon from '../../../@shared/icon';

const FieldValidationError: FC<ValueFieldProps> = (props) => {
  const hasAnyError = !!props.errors?.length;

  return (
    <>
      {hasAnyError && (
        <div className="sitecore-jss-form__error-list">
          {props.errors.map((error, i) => (
            <p key={`error-${props.field.valueField.id}-${i}`}>
              <Icon
                iconName="info-alert-notif-triangle-alert"
                size={20}
                color="error"
                extraClassName="sitecore-jss-form__error-list__icon"
              />
              <span className="sitecore-jss-form__error-list__error-message">{error}</span>
            </p>
          ))}
        </div>
      )}
    </>
  );
};

export default FieldValidationError;
