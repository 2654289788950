const breakpoints = {
  'xs': 0,
  'mbl': 576,
  'tbl-p': 768,
  'tbl-l': 992,
  'dsk': 1200,
  'dsk-hd': 1600,
};


export const BreakpointsInPx: { [key: string]: string } = {
  xs: `${breakpoints['xs']}px`,
  mbl: `${breakpoints['mbl']}px`,
  'tbl-p': `${breakpoints['tbl-p']}px`,
  'tbl-l': `${breakpoints['tbl-l']}px`,
  dsk: `${breakpoints['dsk']}px`,
  'dsk-hd': `${breakpoints['dsk-hd']}px`,
};


export default breakpoints;
