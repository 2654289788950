export enum DirectionEnum {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum CarouselDirectionEnum {
  PREVIOUS,
  NEXT,
}

export enum CarouselNavPositionEnum {
  LEFT = 'left',
  RIGHT = 'right',
}
