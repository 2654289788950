import {
  TCheckboxField,
  TImageField,
  TStringField,
  TVideoSitecoreField,
  TLinkField,
  TSitecoreContext,
} from '../../../types/sitecore.types';
import { TCard } from '../types/cardColumnLayout.types';

export class CardModel {
  private readonly card?: TCard;
  private readonly context?: TSitecoreContext;

  constructor(props: TCard, sitecoreContext: TSitecoreContext) {
    this.card = props || undefined;
    this.context = sitecoreContext;
  }

  get title(): TStringField | undefined {
    return this.card?.cardHeading;
  }

  get subheading(): TStringField | undefined {
    return this.card?.cardSubheading;
  }

  get image(): TImageField | undefined {
    return this.card?.image;
  }

  get mobileImage(): TImageField | undefined {
    return this.card?.mobileImage;
  }

  get video(): TVideoSitecoreField | undefined {
    return this.card?.video;
  }

  get useVideo(): boolean {
    return this.card?.useVideo?.value || false;
  }

  get cta1(): TLinkField | undefined {
    return this.card?.cardCtaButton;
  }

  get cta2(): TLinkField | undefined {
    return this.card?.cardCtaTextLink;
  }

  get appStoreImage(): TImageField | undefined {
    return this.card?.appStoreImage?.value?.src ? this.card?.appStoreImage : this.defaultAppStoreImage;
  }

  get googlePlayImage(): TImageField | undefined {
    return this.card?.googlePlayImage?.value?.src ? this.card?.googlePlayImage : this.defaultGooglePlayImage;
  }

  get useImageCTA(): TCheckboxField | undefined {
    return this.card?.useImageCta;
  }

  get defaultAppStoreImage(): TImageField | undefined {
    return (
      this.context?.defaultBackgroundImageParameters?.desktopImageData?.appStoreLogos && {
        value: { src: this.context?.defaultBackgroundImageParameters?.desktopImageData?.appStoreLogos },
      }
    );
  }

  get defaultGooglePlayImage(): TImageField | undefined {
    return (
      this.context?.defaultBackgroundImageParameters?.desktopImageData?.googlePlayStoreLogo && {
        value: { src: this.context?.defaultBackgroundImageParameters?.desktopImageData?.googlePlayStoreLogo },
      }
    );
  }

  get showPopupWithSplitModule(): boolean {
    return (
      (!this.cta1 || (!this.cta1?.value?.url && !this.cta1?.value?.href)) &&
      !!this.card?.items?.[0].fields?.items?.[0] &&
      !!this.splitModuleLabel
    );
  }

  get splitModules(): any {
    return this.card?.items?.[0].fields?.items;
  }

  get splitModuleLabel(): string | undefined {
    return this.card?.items?.[0]?.fields?.popUpLabel?.value;
  }
}
