import React, { FC } from 'react';
import { isEditorActive, Text } from '@sitecore-jss/sitecore-jss-react';
import { IComponentProps } from '../../types/sitecore.types';
import { CardsModel } from './models/cards.model';
import DashboardCard from './dashboardCard';
import { isEmptyResponse } from '../../utils/checkEmptyResponse';
import useExperienceEditorPlaceholder from '../../hooks/useExperienceEditorPlaceholder';
import EmptyApiDataMessage from '../@shared/emptyApiDataMessage';
import './styles.scss';
import UnreleasedComponent from '../@shared/unreleasedComponent';

type ProductLinesListingProps = IComponentProps;

const ProductLinesListing: FC<ProductLinesListingProps> = (props) => {
  const apiResponse = isEmptyResponse(props);
  const model = new CardsModel(props);
  const isPlaceholderShown = useExperienceEditorPlaceholder(props);

  const generateCards = model.cards?.items?.map((item) => <DashboardCard fields={item} key={item?.id} baseUrl={model.baseUrl} />);

  // Component is unreleased
  if (props?.fields?.isComponentUnreleased) {
    return <UnreleasedComponent />;
  }

  // star  node opened directly in EE
  // placeholder thumbnail is not required as the component is in products page and always loaded with component data

  // not opened from star node
  // data missing when navigating via from products node
  if (!apiResponse && isEditorActive()) {
    return <EmptyApiDataMessage componentName={props?.rendering?.componentName} />;
  }

  // No data and not in EE
  if (!apiResponse && !isPlaceholderShown && !isEditorActive()) {
    return null;
  }

  return (
    <section>
      <div className="category-dashboard category-dashboard--regular">
        <Text tag="h1" field={model?.title} className="category-dashboard--regular__title" />
        <div className="category-dashboard--regular__wrapper">{generateCards}</div>
      </div>
    </section>
  );
};

export default ProductLinesListing;
