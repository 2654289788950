import React, { FC, useState, useEffect, useCallback, useRef } from 'react';
import Slider from 'react-slick';
import CarouselTransparentNavSection from '../../../@shared/carouselTransparentNavSection';
import { CarouselNavPositionEnum } from '../../../../enums/carousel.enums';
import OveriewCarouselArrow from './overviewCarouselArrow';
import { OverviewCarouselModel } from '../../models/overviewCarousel.model';
import { TOverviewCarouselProps } from '../../types/overviewCarousel.type';
import { getYoutubeVideoId, getYoutubeThumbNail } from '../../../../utils/youtubeUtils';
import { TGalleryItem } from '../../types/contentHubSeriesPlmOverview.type';
import HeroCard from '../heroCard';
import './styles.scss';
import CustomCarouselDots from '../../../@shared/customCarouselDots';
import ModalDialog from '../../../@shared/modalDialog';
import OverlayCarouselPopup from '../../../@shared/overlayCarouselPopup';
import Icon from '../../../@shared/icon';
import { TGalleryItem as TOverlayPopUpGalleryItem } from '../../../@shared/overlayCarouselPopup/types/overlayCarouselPopup.types';

const OverviewCarousel: FC<TOverviewCarouselProps> = (props) => {  
  const model = new OverviewCarouselModel(props);
  const [currentSlide, _setCurrentSlide] = useState(0);
  const [activeCard, updateActiveCard] = useState(1);
  const [showOverlay, setShowOverlay] = useState(false);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [videoPaused, setVideoPaused] = useState(false);
  const [localSlides, setLocalSlides] = useState<TGalleryItem[]>([]);
  const sliderRef = useRef<Slider | null>(null);

    useEffect(() => {  
     const carouselSlides: TGalleryItem[] = model.slides.map((slideItem) => {
      let obj = {};
        if(slideItem.role == 'extvideo') {
        const videoId = getYoutubeVideoId(slideItem.full ?? '');
        if (videoId) {
          const thumnail = getYoutubeThumbNail(videoId);
          obj = {
            ...slideItem,
            'rendition_desktop_size1100': { url: thumnail },
            'rendition_mobile_size500': { url: thumnail },
            'role': 'video',            
            'youtubeUrl': slideItem.full,
            'mediaType': 'Video',
            'fromCarousel': true,
          }            
          }
        }  else {
          obj = {...slideItem}
        }
        return obj;
      });
        setLocalSlides([...carouselSlides]);

  }, [model.slides])

  const setCurrentSlide = (index): void => {
    if (localSlides.length > 1) {
      if (currentSlide !== index) {
        _setCurrentSlide(index);
      }
    }
  };

  const playCallback = useCallback(() => {
    setVideoPlaying(true);
    setVideoPaused(false);
  }, [setVideoPlaying, setVideoPaused]);

  const pauseCallback = useCallback(() => {
    setVideoPlaying(false);
    setVideoPaused(true);
  }, [setVideoPlaying, setVideoPaused]);

  const endCallback = useCallback(() => {
    setVideoPlaying(false);
    setVideoPaused(false);
  }, [setVideoPlaying, setVideoPaused]);

  const goToPreviousSlide = (): void => {
    const prevSlideNo = currentSlide === 0 ? localSlides.length - 1 : currentSlide - 1;
    sliderRef.current?.slickGoTo(prevSlideNo);
    setCurrentSlide(prevSlideNo);
    endCallback();
  };

  const goToNextSlide = (): void => {
    const nextSlideNo = currentSlide === localSlides.length - 1 ? 0 : currentSlide + 1;
    sliderRef.current?.slickGoTo(nextSlideNo);
    setCurrentSlide(nextSlideNo);
    endCallback();
  };

  const openOverlayModal = (): void => setShowOverlay(true);
  const closeOverlayModal = (): void => setShowOverlay(false);

  const getOverlayProps = useCallback((): TOverlayPopUpGalleryItem[] => {
    return localSlides.map((slideData) => ({
      ...slideData,
      productImageProps: {
        desktopRendition: slideData.rendition_desktop_size1100,
        mobileRendition: slideData.rendition_mobile_size500,
        alt: slideData.alt,
      },
      isVideo: slideData?.role === 'video',
      videoUrl: slideData?.full || slideData.youtubeUrl,
      title: { value: slideData?.title },
      description: { value: slideData?.description },
    }));
  }, [localSlides]);
  
  const getCarouselDots = () => <CustomCarouselDots cardsCount={localSlides?.length || 0} activeCard={activeCard} />;

  const settings = {
    infinite: true,
    dots: true,
    arrows: false,
    variableWidth: false,
    speed: 500,
    slidesToScroll: 1,
    beforeChange: (_, newIndex: number) => {
      updateActiveCard(newIndex + 1);
    },
    appendDots: getCarouselDots,
  };

  return (
    <div className="plm-series-detail-overview-carousel">
      <div className="plm-series-detail-overview-carousel__wrapper">
        <Slider
          {...settings}
          ref={sliderRef}
          className={`plm-series-detail-overview-carousel__slick-dots
           ${videoPlaying || videoPaused ? 'plm-series-detail-overview-carousel__hide-carousel-dots' : ''}
          `}
        >
          {localSlides?.map((slide, index) => (
            <HeroCard
              key={index}
              index={index}
              slide={slide}
              playCallback={playCallback}
              pauseCallback={pauseCallback}
              endCallback={endCallback}
              activeCard={index === currentSlide}
              fromCarousel={true}
            />
          ))}
        </Slider>
        {localSlides?.length > 1 && (
          <>
            <OveriewCarouselArrow
              direction={'previous'}
              onClick={goToPreviousSlide}
              customClass={`
                plm-series-detail-overview-carousel__animate-icons
                ${videoPlaying && 'plm-series-detail-overview-carousel__animate-icons--animate-fade'}
                ${videoPaused && 'plm-series-detail-overview-carousel__animate-icons--show'}
              `}
            />
            <CarouselTransparentNavSection position={CarouselNavPositionEnum.LEFT} handleTouchStart={goToPreviousSlide} />
            <CarouselTransparentNavSection position={CarouselNavPositionEnum.RIGHT} handleTouchStart={goToNextSlide} />
            <OveriewCarouselArrow
              direction={'next'}
              onClick={goToNextSlide}
              customClass={`
                plm-series-detail-overview-carousel__animate-icons
                ${videoPlaying && 'plm-series-detail-overview-carousel__animate-icons--animate-fade'}
                ${videoPaused && 'plm-series-detail-overview-carousel__animate-icons--show'}
              `}
            />
          </>
        )}
        {localSlides?.length > 0 && (
          <div
            className={`plm-series-detail-overview-carousel__expand-icon plm-series-detail-overview-carousel__animate-icons plm-series-detail-overview-carousel__animate-icons${
              videoPlaying ? '--hide' : '--show'
            }`}
            onClick={openOverlayModal}
          >
            <Icon iconName="action-expand" color="white" size={24} />
          </div>
        )}
        {(localSlides?.length || model.video) && (
          <ModalDialog isOpen={showOverlay} close={closeOverlayModal}>
            <OverlayCarouselPopup
              slides={getOverlayProps()}
              showNavDeck={true}
              showOverlayTitleAndDescription={true}
              isOverlay={true}
              isEE={false}
            />
          </ModalDialog>
        )}
      </div>
    </div>
  );
};

OverviewCarousel.displayName = 'OverviewCarousel';

export default OverviewCarousel;
