import React, { FC, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withSitecoreContext, DateField, RichText } from '@sitecore-jss/sitecore-jss-react';
import useFormatDate from '../../../../hooks/useFormatDate';
import { ProductSpecialOffersListingItemModel } from '../../models/productSpecialOffersListing.model';
import { TSpecialOfferCardProps } from '../../types/productSpecialOffersListing.types';
import { WithSitecoreContextProps } from '@sitecore-jss/sitecore-jss-react/types/enhancers/withSitecoreContext';
import ctaTypes from '../../../../enums/CTA.enums';
import CTA from '../../../@shared/CTA';
import Media from '../media';
import DictionaryTerm from '../../../@shared/dictionaryTerm';
import ModalDialog from '../../../@shared/modalDialog';
import { targetLinkdsk } from '../../../../utils/targetDskLink';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { BreakpointsInPx } from '../../../../constants/breakpoints.constants';
import useSitecoreContext from '../../../../hooks/useSitecoreContext';
import './styles.scss';

const SpecialOffersCard: FC<WithTranslation & WithSitecoreContextProps & TSpecialOfferCardProps> = (props) => {
  const { placeholderImage } = props;
  const {sitecoreContext} = useSitecoreContext();
  const specialOfferMainPageLink: string = sitecoreContext?.specialOfferPageUrl as string || '';
  const model = new ProductSpecialOffersListingItemModel(props, specialOfferMainPageLink);
  const formattedDate = useFormatDate(model.expirationDate);
  const [modalIsOpen, setIsOpen] = useState(false);
  const isDesktop = useMediaQuery(`(min-width: ${BreakpointsInPx['tbl-p']})`);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      {model.disclaimer && (
        <ModalDialog isOpen={modalIsOpen} close={closeModal} className="special-offers-listing-card-modal">
          {<RichText tag="div" field={{ value: model.disclaimer }} className="rich-text" />}
        </ModalDialog>
      )}
      <div className="special-offers-listing-card">
        {model.tag && (
          <div className="special-offers-listing-card__badge">
            <p>{model.tag}</p>
          </div>
        )}
        <Media desktopImage={model?.desktopImage} mobileImage={model?.mobileImage} defaultImage={placeholderImage} />
        <div className="special-offers-listing-card__border">
          {model.title && (
            <RichText
              editable={false}
              tag="div"
              field={{ value: model.title }}
              className="special-offers-listing-card__text special-offers-listing-card__text--title"
            />
          )}
          {model.shortDescription && (
            <RichText
              editable={false}
              tag="div"
              field={{ value: model.shortDescription }}
              className="special-offers-listing-card__text special-offers-listing-card__text--content rich-text"
            />
          )}
          {model.disclaimer && (
            <div onClick={openModal} className="special-offers-listing-card__text--disclaimer">
              <DictionaryTerm keyword={'cnhi-special-offers-disclaimer'} />
            </div>
          )}
          <div className="special-offers-listing-card__text special-offers-listing-card__text--expiration">
            <span>
              <DictionaryTerm keyword={'cnhi-special-offers-expiration-label'} />
            </span>
            :&nbsp;
            <DateField field={{ value: model.expirationDate }} tag="span" render={() => formattedDate} />
          </div>

          <div className={'special-offers-listing-card__cta-container special-offers-listing-card__cta-container--row'}>
            {model?.button1 && (
              <CTA type={ctaTypes.primaryYellow} field={model.button1} target={targetLinkdsk(model.button1, isDesktop)}>
                {model.button1.value.title}
              </CTA>
            )}
            {model?.button2 && (
              <CTA type={ctaTypes.secondaryBlue} field={model.button2} target={targetLinkdsk(model.button2, isDesktop)}>
                {model.button2.value.title}
              </CTA>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default withSitecoreContext()(withTranslation()(SpecialOffersCard));
