import { useContext } from 'react';
import { ComponentRendering, SitecoreContextReactContext } from '@sitecore-jss/sitecore-jss-react';

const isComponentsValid = (component: any) => !!component.uid;

const isComponentInTheList = (component: any, exceptComponentNames: Array<string>) =>
  exceptComponentNames.indexOf(component.componentName) >= 0;

export const useHeroBannerAsFirstComponent = (exceptComponentNames: Array<string>) => {
  const sitecoreContextFactory = useContext(SitecoreContextReactContext);
  const componentList = sitecoreContextFactory.context?.route?.placeholders['jss-main'] as any;
  return (
    (componentList || [])
      .filter((component) => isComponentsValid(component))
      .filter((component) => !isComponentInTheList(component, exceptComponentNames))
      .findIndex((rendering) => rendering.componentName === 'HeroBanner') === 0
  );
};

export const useHeroBannerBoxedTextAsFirstComponent = (exceptComponentNames: Array<string>) => {
  const sitecoreContextFactory = useContext(SitecoreContextReactContext);
  const componentList = sitecoreContextFactory.context?.route?.placeholders['jss-main'];
  return (
    (componentList || [])
      .filter((component) => isComponentsValid(component))
      .filter((component) => !isComponentInTheList(component, exceptComponentNames))
      .findIndex((rendering: ComponentRendering) => rendering.componentName === 'HeroBannerBoxedText') === 0
  );
};
