import React, { useEffect, useState } from 'react';
import './index.scss';

const Dots = (props: { class: string }) => <div className={`carousel-dots carousel-dots--${props.class}`}></div>;

const CustomCarouselDots = (props: { cardsCount: number; activeCard: number; hasAnimation?: boolean; isDarkMode?: boolean }) => {
  const dotsArray: JSX.Element[] = [];
  const [min, updateMin] = useState(0);
  const [max, updateMax] = useState(5); // min 5 cards to be shown
  const [changes, updateChanges] = useState(0);

  useEffect(() => {
    let initialMax = max;
    let initialMin = min;
    if (props.activeCard < initialMin) {
      initialMin = props.activeCard;
      initialMax = initialMin + 4;
      if (initialMax > changes + 1) {
        initialMax = changes + 1;
      }
    }
    if (props.activeCard > initialMax) {
      initialMax = props.activeCard;
      initialMin = initialMax - 4;
      if (initialMin < 0) {
        initialMin = 0;
      }
    }
    updateMax(initialMax);
    updateMin(initialMin);
    updateChanges(changes + 1);
  }, [props.activeCard, max, min]); // eslint-disable-line

  const getIndicatorClass = (ref: number) => {
    if (ref === props.activeCard) {
      return 'active';
    }
    if (ref >= min && ref <= max) {
      return 'std';
    }
    if (ref === min - 1 || ref === max + 1) {
      return 'small';
    }
    return 'hidden';
  };

  for (let i = 1; i <= props.cardsCount; i++) {
    dotsArray.push(<Dots class={getIndicatorClass(i)} key={i}/>);
  }
  return <div
    className={`carousel-dots-wrapper ${props?.hasAnimation ? ' carousel-dots-wrapper--animation' : ''}
    ${props?.isDarkMode ? ' carousel-dots--dark-mode' : ''}
    `}>{dotsArray}</div>;
}
export default CustomCarouselDots;