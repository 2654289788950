import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React, { FC } from 'react';
import { appendRootToRichTextInternalLinks } from '../../../../utils/appendRootToRichTextInternalLinks.utils';
import { CustomText } from '../../../@shared/customText';
import { TPlmSolutionCardText } from '../../types/productSeriesDetailPlmSolutions.types';

const CardText: FC<TPlmSolutionCardText> = (props) => {
  const { title, description } = props;
  return (
    <div className="product-plm-solutions-card__content-text">
      {title && <CustomText tag="div" field={{ value: title }} className={'product-plm-solutions-card__title'} />}
      {description && (
        <RichText
          tag="div"
          field={{ value: appendRootToRichTextInternalLinks(description) }}
          className={'product-plm-solutions-card__description rich-text'}
        />
      )}
    </div>
  );
};

export default CardText;
