import React, { FC, useRef } from 'react';
import { iconSize } from '../../../../constants/icon.constants';
import Icon from '../../../@shared/icon';
import { TSearchFilter } from '../../types/filter.types';
import { TQueryResults } from '../../types/result.types';
import SearchFilter from '../filter/searchFilter';
import SearchResultsList from './searchResultList';

const QueryResults: FC<TQueryResults & TSearchFilter> = (props) => {
  const { query, t, isDesktop, isCollapsed, filters, activeFilters, onFiltersChange, toggleCollapsed, results } = props;

  /**
   * searchResultsRef is created to check and retain the results view,
   * if the results view is displayed atleast once in the page
   * navigating to other pages refer bug: 415822
   */
  const searchResultsRef = useRef<HTMLDivElement>(null);

  if (query || searchResultsRef?.current) {
    return (
      <div className="search__results" ref={searchResultsRef}>
        <SearchFilter
          isDesktop={isDesktop}
          isCollapsed={isCollapsed}
          filters={filters}
          activeFilters={activeFilters}
          onFiltersChange={onFiltersChange}
          toggleCollapsed={toggleCollapsed}
          t={t}
        />
        <SearchResultsList results={results} t={t} />
      </div>
    );
  } else {
    return (
      <div className="search__content">
        <Icon iconName="action-search" size={iconSize.xlg} extraClassName="search__no-results--icon" />
        <h3 className="search__no-results__title">{t('cnhi-initialSearchTitle')}</h3>
        <p className="search__no-results__description">{t('cnhi-initialSearchDescription')}</p>
      </div>
    );
  }
};

export default QueryResults;
