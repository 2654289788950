import React from 'react';
import './index.scss';
import { TIconField, TIconFromRenderingParams } from '../../../types/sitecore.types';
// import { items as iconData } from './nhag.json';
import nhag from './nhag.json';

export type TIconProps = {
  iconName?: string;
  iconField?: TIconField | TIconFromRenderingParams;
  size?: number;
  extraClassName?: string;
  color?: 'primary' | 'complement' | 'white' | 'error' | 'grey' | 'black' | 'warning' | 'success';
  onClick?: () => void;
};

type TIconData = {
  webkitMaskUrl: string | null;
  alt: string | undefined;
};

const getIconDataFromKey: (iconName: string) => TIconData = (iconName) => {
  const _icon = nhag?.items.find((item) => iconName === item?.title);

  if (_icon) {
    return {
      webkitMaskUrl: typeof _icon?.full === 'string' ? _icon.full : null,
      alt: _icon?.alt,
    };
  }

  return { webkitMaskUrl: null, alt: undefined };
};

const getIconDataromField: (iconField: TIconField | TIconFromRenderingParams | undefined) => TIconData = (iconField) => {
  const iconReferenceField = iconField as TIconField;
  const iconFromRenderingParams = iconField as TIconFromRenderingParams;

  if (iconReferenceField?.fields?.iconUrl?.value) {
    return { webkitMaskUrl: iconReferenceField.fields.iconUrl.value, alt: iconReferenceField.fields.iconAlt?.value };
  }

  if (iconFromRenderingParams?.iconUrl) {
    return { webkitMaskUrl: iconFromRenderingParams.iconUrl, alt: iconFromRenderingParams?.iconUrl };
  }

  return { webkitMaskUrl: null, alt: undefined };
};

// eslint-disable-next-line
const Icon = React.forwardRef<HTMLSpanElement, TIconProps>(
  ({ iconName = '', color = '', iconField, size = '', extraClassName = '', onClick }, ref) => {
    const { webkitMaskUrl, alt } = iconName ? getIconDataFromKey(iconName) : getIconDataromField(iconField);

    if (!webkitMaskUrl) {
      return null;
    }

    const iconStyles: React.CSSProperties = {
      WebkitMaskImage: `url(${webkitMaskUrl})`,
      WebkitMaskSize: 'cover',
      height: `${size || 16}px`,
      width: `${size || 16}px`,
    };

    return (
      <span
        className={`icon-wrapper ${color} ${extraClassName}`}
        style={iconStyles}
        onClick={onClick}
        ref={ref}
        aria-label={alt}
        role="img"
      />
    );
  }
);

export default React.memo(Icon);
