import React, { useEffect, useRef, FC } from 'react';
import { TColumnHeadingsList } from '../../../types/index.types';
import ColumnHeadingElement from './columnHeadingElement';
import { documentService } from '../../../../../utils/window.utils';

const ColumnHeadings: FC<TColumnHeadingsList> = (props) => {
  const {
    colHeadings,
    setDropdownAtColumnIndex,
    setColOrdInIndex,
    dropdownAtColumnIndex,
    colOrdInIndex,
    fixedColumn,
    showHiddenRightCells,
  } = props;
  const DropdownPlaceholderRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (DropdownPlaceholderRef?.current) return;
    const newDiv = documentService()?.createElement('div');
    if (!newDiv) return;
    documentService()?.body?.appendChild(newDiv);
    DropdownPlaceholderRef.current = newDiv;
    return () => {
      documentService()?.body.removeChild(newDiv);
    };
  }, []);

  const handleDropdownClick = (event: React.MouseEvent, val: number | null) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    setDropdownAtColumnIndex(val);
  };

  return (
    <>
      {colHeadings
        ?.filter((_, columnIndex) => {
          if (!showHiddenRightCells) return columnIndex <= 5;
          return true;
        })
        .map((columnValue, columnIndex) => (
          <ColumnHeadingElement
            key={columnValue}
            columnValue={columnValue}
            handleDropdownClick={handleDropdownClick}
            columnIndex={columnIndex}
            dropdownAtColumnIndex={dropdownAtColumnIndex}
            colHeadings={colHeadings}
            colOrdInIndex={colOrdInIndex}
            setColOrdInIndex={setColOrdInIndex}
            setDropdownAtColumnIndex={setDropdownAtColumnIndex}
            dropdownPlaceholder={DropdownPlaceholderRef?.current}
            fixedColumn={fixedColumn}
          />
        ))}
    </>
  );
};

export default React.memo(ColumnHeadings);
