import React from 'react';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { isEmptyResponse } from '../../utils/checkEmptyResponse';
import useExperienceEditorPlaceholder from '../../hooks/useExperienceEditorPlaceholder';
import ExperienceEditorComponentPlaceholder from '../@shared/experienceEditorComponentPlaceholder';
import EmptyApiDataMessage from '../@shared/emptyApiDataMessage';
import ProductSpecialOffers from '../productSpecialOffers';
import UnreleasedComponent from '../@shared/unreleasedComponent';

const ProductLineSpecialOffers = (props) => {
    const apiResponse = isEmptyResponse(props);
    const isPlaceholderShown = useExperienceEditorPlaceholder(props);

    // Component is unreleased
    if (props?.fields?.isComponentUnreleased) {
        return <UnreleasedComponent/>
    }

    // star  node opened directly in EE
    if (isPlaceholderShown) {
        return (
            <ExperienceEditorComponentPlaceholder image={props?.fields?.itemData?.thumbnail}
                                                  componentName={props?.rendering?.componentName}/>
        );
    }

    // not opened from star node
    // data missing when navigating via from products node
    if (!apiResponse && isEditorActive()) {
        return <EmptyApiDataMessage componentName={props?.rendering?.componentName}/>;
    }

    // No data and not in EE
    if (!apiResponse && !isPlaceholderShown && !isEditorActive()) {
        return null;
    }

    return <ProductSpecialOffers {...props} />;
};

export default ProductLineSpecialOffers;
