import { windowService, documentService } from './window.utils.js';

export const isMobileDevice = (): boolean => {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(windowService()?.navigator?.userAgent) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in documentService())
  ) {
    return true;
  }
  return false;
};
