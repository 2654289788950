import React from 'react';
import { iconSize } from '../../../../constants/icon.constants';
import Icon from '../../icon';

const CloseButton = (props): JSX.Element => {
  const { closeModal, isEEActiveOrPreview, isDesktop } = props;
  return (
    <button
      className={`my-modal-dialog__close-button ${isEEActiveOrPreview ? 'my-modal-dialog__close-button--ee-active' : ''}`}
      onClick={closeModal}
    >
      <Icon iconName="action-close" size={isDesktop ? iconSize.xlg : iconSize.dsk} color="white" />
    </button>
  );
};

export default CloseButton;
