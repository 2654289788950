import React, { FC, useState, useCallback } from 'react';
import Fade from 'react-reveal/Fade';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Text, RichText, Placeholder, ComponentRendering } from '@sitecore-jss/sitecore-jss-react';
import grid from '../../../../assets/Grey-dots.svg';
import { BreakpointsInPx } from '../../../../constants/breakpoints.constants';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import ctaTypes from '../../../../enums/CTA.enums';
import { findCtaType } from '../../../../utils/popUpCtaType.utils';
import { targetLinkdsk } from '../../../../utils/targetDskLink';
import { adjustModalPositionBasedOnContent } from '../../../../utils/modal.utils';
import CTA from '../../../@shared/CTA';
import InnerSplitModules from '../../../@shared/innerComponentsPopup/innerSplitModules';
import InnerCards from '../../../@shared/innerComponentsPopup/innerCards';
import ModalDialog from '../../../@shared/modalDialog';
import { OverviewTextModel } from '../../models/overviewText.model';
import { TOverviewTextProps } from '../../types/overviewText.type';
import { TComponentDetailsProps, InnerComponentTypes } from '../../types/contentHubSeriesOverview.type';
import { googleButtonClickEvent } from '../../../../utils/analytics';
import './styles.scss';
import useSitecoreContext from '../../../../hooks/useSitecoreContext';

const OverviewText: FC<WithTranslation & TComponentDetailsProps & TOverviewTextProps & { isEE: boolean }> = (props) => {
  const model = new OverviewTextModel(props);
  const isDesktop = useMediaQuery(`(min-width: ${BreakpointsInPx['tbl-p']})`);
  const {sitecoreContext} = useSitecoreContext();
  const productLine = sitecoreContext?.analytics?.['productLine'] ? sitecoreContext?.analytics?.['productLine'] : '';
  const productSeries = sitecoreContext?.analytics?.['productSeries'] ? sitecoreContext?.analytics?.['productSeries'] : '';

  const WrapFade = useCallback(
    ({ duration, delay, children }) => {
      if (isDesktop && !props.isEE) {
        return (
          <Fade bottom duration={duration} delay={delay}>
            {children}
          </Fade>
        );
      }
      return <>{children}</>;
    },
    [isDesktop] // eslint-disable-line
  );

  const hasSecondaryCta = model?.secondaryLink?.label && model?.secondaryLink?.url;
  const popUpCtaType = findCtaType(props.rendering);
  const primaryCTAclassName = !hasSecondaryCta && popUpCtaType === 'primary-cta' ? 'series-overview-text__right-margin' : '';
  const secondaryCTAclassName = popUpCtaType === 'primary-cta' ? 'series-overview-text__right-margin' : '';
  const { t } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const hasInnerComponents = !!(model?.innerComponents && model?.innerComponents.length);

  const showModal = (): void => {
    setModalIsOpen(true);
  };

  const closeModal = (): void => {
    setModalIsOpen(false);
  };

  return (
    <>
      {hasInnerComponents && (
        <ModalDialog isOpen={modalIsOpen} close={closeModal} onAfterOpen={adjustModalPositionBasedOnContent}>
          {model?.layoutType?.toLowerCase() === InnerComponentTypes.Card ? (
            <InnerCards components={model?.innerComponents || []} />
          ) : (
            <InnerSplitModules components={model?.innerComponents || []} />
          )}
        </ModalDialog>
      )}
      <div className="series-overview-text-wrapper">
        <div className="series-overview-text-wrapper__dot-space">
          {isDesktop && (
            <WrapFade duration={1000} delay={1000}>
              {/* eslint-disable-next-line */}
              <img className="" src={grid} loading="lazy" />
            </WrapFade>
          )}
        </div>
        <WrapFade duration={1000} delay={2000}>
          <div className="series-overview-text">
            <Text field={model.eyebrow} tag="div" className="series-overview-text__eyebrow" editable={false} />
            <Text field={model.title} tag="h2" className="series-overview-text__title" editable={false} />
            <RichText field={model.description} tag="div" className="rich-text series-overview-text__description" editable={false} />
            {
              <div className="series-overview-text__link-wrapper">
                {hasInnerComponents && (
                  <CTA
                    type={ctaTypes['primaryYellow']}
                    onClick={() => {
                      showModal();
                      googleButtonClickEvent('series_overview_detail_open', model.title?.value, productLine, productSeries);
                    }}
                    extraClassName={primaryCTAclassName}
                  >
                    {t('cnhi-discoverMore')}
                  </CTA>
                )}

                {hasSecondaryCta && (
                  <CTA
                    href={model.secondaryLinkUrl}
                    target={targetLinkdsk({ value: { href: model?.secondaryLink?.url, target: model?.secondaryLink?.target } }, isDesktop)}
                    type={ctaTypes['secondaryBlue']}
                    extraClassName={`${secondaryCTAclassName}`}
                  >
                    {model.secondaryLink.label}
                  </CTA>
                )}

                <div className={'series-overview-text__pop-up'}>
                  <Placeholder name={'jss-generic-popup'} rendering={props.rendering as ComponentRendering} />
                </div>
              </div>
            }
          </div>
        </WrapFade>
      </div>
    </>
  );
};

export default withTranslation()(OverviewText);
