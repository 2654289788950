import {
  TComponentDetails,
  TContentHubSeriesDetailsProps,
  TSeriesDetailFeatureItemData,
  TSeriesDetailsData,
} from '../../@shared/innerComponentsPopup/types/innerComponentsPopup.types';

export class SeriesDetailsModel {
  public readonly data?: TSeriesDetailsData;
  public readonly itemData?: TSeriesDetailFeatureItemData;

  constructor(props: TContentHubSeriesDetailsProps) {
    this.data = props?.fields?.apiData || null;
    this.itemData = props.fields.itemData;
  }

  get contextItemUrl(): string {
    return this.itemData?.contextItemUrl || '';
  }

  get innerComponents(): TComponentDetails[] {
    return this.data?.innerComponents || [];
  }

  get eyebrow(): string {
    return this.data?.eyebrow || '';
  }

  get title(): string {
    return this.data?.title || '';
  }

  get description(): string {
    return this.data?.description || '';
  }
}
