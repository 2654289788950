import React, { FC, useCallback, useState } from 'react';
import { TFunction } from 'i18next';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { BreakpointsInPx } from '../../../../constants/breakpoints.constants';
import ctaTypes from '../../../../enums/CTA.enums';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { adjustModalPositionBasedOnContent } from '../../../../utils/modal.utils';
import CTA from '../../../@shared/CTA';
import InnerSplitModules from '../../../@shared/innerComponentsPopup/innerSplitModules';
import ModalDialog from '../../../@shared/modalDialog';
import Picture from '../../../@shared/picture';
import { TProductCard } from '../../types/modelsListing.types';

import './styles.scss';
import { appendRootToRichTextInternalLinks } from '../../../../utils/appendRootToRichTextInternalLinks.utils';

const ModelListingCard: FC<TProductCard & { t: TFunction }> = (props) => {
  const { title, description, assets, isLastCards, t } = props;
  const asset = assets && assets[0];
  const NUM_OF_LINES = 5;
  const [showAllText, setShowAllText] = useState(false);
  const isDesktop = useMediaQuery(`(min-width: ${BreakpointsInPx['tbl-p']})`);
  const [shouldShowPopUp, setShouldShowPopUp] = useState(false);
  const modelDescription = appendRootToRichTextInternalLinks(description);

  const handleMouseOver = (event): void => {
    const totalLines = event?.target?.offsetHeight / 24;
    setShowAllText(totalLines > NUM_OF_LINES);
  };
  const handleMouseLeave = (): void => {
    setShowAllText(false);
  };

  const closePopUp = useCallback(() => {
    setShouldShowPopUp(false);
  }, []);

  const openPopUp = useCallback(() => {
    setShouldShowPopUp(true);
  }, []);

  const PopUpCTA = useCallback(() => {
    return isDesktop && props.innerComponents?.length ? (
      <CTA type={ctaTypes['primaryYellow']} onClick={openPopUp} extraClassName="model-series-listing-card__cta">
        {t('cnhi-PLMModelCTA')}
      </CTA>
    ) : (
      <></>
    );
  }, [isDesktop, openPopUp, props.innerComponents, t]);

  return (
    <>
      <div className={'model-series-listing-card'} id={`${props.id}`} {...(!isDesktop && { onClick: openPopUp })}>
        <div className={'model-series-listing-card__image-wrapper '}>
          {asset && (
            <Picture
              productImageProps={{
                desktopRendition: asset?.rendition_desktop_size500,
                mobileRendition: asset?.rendition_mobile_size200,
                alt: asset?.alt,
                pictureClassName: 'model-series-listing-card__image',
              }}
            />
          )}
        </div>
        <div className={'model-series-listing-card__body'}>
          {title && <Text tag="div" field={{ value: title }} className="model-series-listing-card__title" />}
          <div className={'model-series-listing-card__description-wrapper'}>
            {(!isLastCards || (isLastCards && !showAllText)) && (
              <>
                <RichText
                  tag="div"
                  field={{ value: modelDescription }}
                  onMouseOver={handleMouseOver}
                  className={'model-series-listing-card__description model-series-listing-card__description--relative rich-text'}
                />
                <span className="model-series-listing-card__cta-wrapper">
                  <PopUpCTA />
                </span>
              </>
            )}
            {showAllText && (
              <div
                className={`model-series-listing-card__description--${isLastCards ? 'lastCard' : 'absoulte'}`}
                onMouseLeave={handleMouseLeave}
              >
                <RichText tag="div" field={{ value: modelDescription }} className={'rich-text model-series-listing-card__description'} />
                <PopUpCTA />
              </div>
            )}
          </div>
        </div>
      </div>

      {!!props.innerComponents?.length && (
        <ModalDialog isOpen={shouldShowPopUp} close={closePopUp} onAfterOpen={adjustModalPositionBasedOnContent}>
          <>
            <InnerSplitModules components={props.innerComponents} />
          </>
        </ModalDialog>
      )}
    </>
  );
};

export default ModelListingCard;
