import React, { FC, useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { windowService } from '../../utils/window.utils';
import { isEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import EmptyApiDataMessage from '../@shared/emptyApiDataMessage';
import ExperienceEditorComponentPlaceholder from '../@shared/experienceEditorComponentPlaceholder';
import { createPortal } from 'react-dom';
import { isEmptyResponse } from '../../utils/checkEmptyResponse';
import useExperienceEditorPlaceholder from '../../hooks/useExperienceEditorPlaceholder';
import useStickyWrapper from '../../hooks/useStickyWrapper';
import { ContentHubSeriesNavigationBarFields, TNavigationBarProps } from '../@shared/navigationSection/types/navigationBar.types';
import NavigationSection from '../@shared/navigationSection';
import { detectScrollDirectionForNavigationBar } from '../../utils/detectScrollDirectionForNavigationBar.utils';
import UnreleasedComponent from '../@shared/unreleasedComponent';

const ProductSeriesNavigationBar: FC<WithTranslation & TNavigationBarProps> = (props) => {
  const fields = props?.fields as ContentHubSeriesNavigationBarFields;
  const apiParams = typeof props?.params === 'object' && Object.keys(props?.params).length > 0;
  const apiResponse = isEmptyResponse(props);
  const isPlaceholderShown = useExperienceEditorPlaceholder(props);
  const stickyWrapperEle = useStickyWrapper();
  let scrollPosition = 0;
  const [dropdownIsOpen, setshowPanel] = useState(false);

  const detectScrollDirection = () => {
    scrollPosition = detectScrollDirectionForNavigationBar(stickyWrapperEle, scrollPosition);
  };

  useEffect(() => {
    windowService().addEventListener('scroll', detectScrollDirection, false);
    return () => {
      windowService().removeEventListener('scroll', detectScrollDirection);
    };
  }, [stickyWrapperEle]); // eslint-disable-line

  // Component is unreleased
  if (props?.fields?.isComponentUnreleased) {
    return <UnreleasedComponent />;
  }

  // star  node opened directly in EE
  if (isPlaceholderShown) {
    return <ExperienceEditorComponentPlaceholder image={fields?.itemData?.thumbnail} componentName={props?.rendering?.componentName} />;
  }

  // not opened from star node
  // data missing when navigating via from products node
  if (!apiResponse && isEditorActive()) {
    return <EmptyApiDataMessage componentName={props?.rendering?.componentName} />;
  }

  // No data and not in EE
  if (!apiResponse && !isPlaceholderShown && !isEditorActive() && !apiParams) {
    return null;
  }

  return (
    <>
      <NavigationSection {...props} isEditorial={false} dropdownIsOpen={dropdownIsOpen} setshowPanel={setshowPanel} isAnalytics={true} />
      {stickyWrapperEle &&
        createPortal(
          <NavigationSection
            {...props}
            sticky={true}
            isEditorial={false}
            dropdownIsOpen={dropdownIsOpen}
            setshowPanel={setshowPanel}
            isAnalytics={true}
          />,
          stickyWrapperEle
        )}
    </>
  );
};

export default withTranslation()(withSitecoreContext()(ProductSeriesNavigationBar));
