import React, { FC } from 'react';
import { iconSize } from '../../../../../constants/icon.constants';
import Icon from '../../../icon';
import { TMediaCarouselArrowProps } from './index.types';
import './styles.scss';

const MediaCarouselArrow: FC<TMediaCarouselArrowProps> = ({ direction, onClick, customClass = '' }) => {
  const icon = ('arrow-chevron-' + (direction === 'previous' ? 'left' : 'right')) as 'arrow-chevron-left' | 'arrow-chevron-right';
  return (
    <button
      className={'media-carousel-arrows media-carousel-arrows--' + direction + ' ' + customClass}
      aria-label={direction + 'slide / item'}
      onClick={onClick}
    >
      <Icon iconName={icon} size={iconSize['xlg']} color='white' />
    </button>
  );
};

export default MediaCarouselArrow;