import React, { FC } from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Icon from '../../../@shared/icon';
import { FieldEditFrameComponent } from '../../../@shared/editFrame';
import RoutableSitecoreLink from '../../../@shared/routableSitecoreLink';
import { QuickLinksLinkModel } from './models/quickLinksLink.model';
import { TQuickLinksLinkProps } from './types/quickLinksLink.types';
import breakpoints from '../../../../constants/breakpoints.constants';
import { fields } from '../../../../constants/fields.const';
import { googleCtaClickEvent } from '../../../../utils/analytics';
import './styles.scss';
import useMediaQuery from '../../../../hooks/useMediaQuery';

const QuickLinksLink: FC<TQuickLinksLinkProps> = (props: TQuickLinksLinkProps) => {
  const model = new QuickLinksLinkModel(props);
  const isWide = useMediaQuery(`(min-width: ${breakpoints['tbl-p']}px)`);
  const classLine = props?.fields?.last ? 'quick-links-link__line quick-links-link__line--last' : 'quick-links-link__line';
  const isEEactive = props.sitecoreContext?.pageEditing;
  let imgWrapper;

  if (props?.fields?.first && props?.fields?.last) {
    imgWrapper = 'quick-links-link__image-wrapper quick-links-link__image-wrapper--first quick-links-link__image-wrapper--last';
  } else if (props?.fields?.first) {
    imgWrapper = 'quick-links-link__image-wrapper quick-links-link__image-wrapper--first';
  } else if (props?.fields?.last) {
    imgWrapper = 'quick-links-link__image-wrapper quick-links-link__image-wrapper--last';
  } else {
    imgWrapper = 'quick-links-link__image-wrapper';
  }

  const desktopQuickLinks = (
    <li className="quick-links-link">
      {isEEactive ? (
        <div>
          <div className="quick-links-link__link">
            <RoutableSitecoreLink field={model.link} />
          </div>
          <div className={imgWrapper}>
            <div className="quick-links-link__image">
              <FieldEditFrameComponent
                itemId={model.itemid}
                fields={[fields.icon, fields.isMobile, fields.isOnStickyBar]}
                sitecoreContext={props?.sitecoreContext as any}
              >
                <Icon iconField={model.icon} size={20} />
              </FieldEditFrameComponent>
            </div>
            <div className={classLine}></div>
          </div>
        </div>
      ) : (
        <RoutableSitecoreLink
          field={model.link}
          onclick={() => googleCtaClickEvent('Quick Links', model?.link?.value?.href, model?.link?.value?.text, '')}
        >
          <div className="quick-links-link__link">
            <p>{model.link?.value?.text}</p>
          </div>
          <div className={imgWrapper}>
            <div className="quick-links-link__image">
              <Icon iconField={model.icon} size={20} />
            </div>
            <div className={classLine}></div>
          </div>
        </RoutableSitecoreLink>
      )}
    </li>
  );

  const mobileQuickLinks = (
    <li className="quick-links-link">
      {isEEactive ? (
        <div className="quick-links-link__link">
          <div className="quick-links-link__image-wrapper">
            <div className="quick-links-link__image">
              <FieldEditFrameComponent
                itemId={model.itemid}
                fields={[fields.icon, fields.isMobile, fields.isOnStickyBar]}
                sitecoreContext={props?.sitecoreContext as any}
              >
                <Icon iconField={model.icon} size={20} />
              </FieldEditFrameComponent>
            </div>
          </div>
          <RoutableSitecoreLink field={model.link} />
        </div>
      ) : (
        <RoutableSitecoreLink
          field={model.link}
          className="quick-links-link__link"
          onclick={() => googleCtaClickEvent('Quick Links', model?.link?.value?.href, model?.link?.value?.text, '')}
        >
          <div className="quick-links-link__image-wrapper">
            <div className="quick-links-link__image">
              <Icon iconField={model.icon} size={20} />
            </div>
          </div>
          <p>{model.link?.value?.text}</p>
        </RoutableSitecoreLink>
      )}
    </li>
  );

  return isWide ? desktopQuickLinks : mobileQuickLinks;
};

export default withSitecoreContext()(React.memo(QuickLinksLink));
