import React, { FC, useState, useEffect } from 'react';
import breakpoints from '../../../../../constants/breakpoints.constants';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import Picture from '../../../picture';
import { TContentHubImageProps } from '../../../picture/types/contentHubImage.types';
import { TEditorialImageProps } from '../../../picture/types/editorialImage.types';
import VideoPlayer from '../../../videoPlayer';
import { TMediaCardProps } from './index.types';
import './styles.scss';

const MediaCard: FC<TMediaCardProps> = ({
  slide,
  playCallback,
  pauseCallback,
  endCallback,
  activeCard,
  moveLeft,
  moveRight,
  index,
  hasFixedAspectRatio,
  mediaHeightClassName,
}) => {
  const isWide = useMediaQuery(`(min-width: ${breakpoints['tbl-p']}px)`);
  const [swipeStart, setSwipeStart] = useState(0);

  useEffect(() => {
    setSwipeStart(0);
  }, [activeCard, index]);

  const handleTouchStartVideoPlay = (event): void => {
    if (isWide) return;
    const value = event?.changedTouches?.[0]?.pageX;
    if (!value) return;
    setSwipeStart(value);
  };

  const handleTouchEndVideoPlay = (event): void => {
    if (isWide) return;
    const value = event?.changedTouches?.[0]?.pageX;
    if (!value) {
      setSwipeStart(0);
      return;
    }

    if (value < swipeStart && swipeStart - value > 20) {
      if (moveRight) moveRight();
    } else if (value > swipeStart && value - swipeStart > 20) {
      if (moveLeft) moveLeft();
    }
    setSwipeStart(0);
  };

  const productImageProps: TContentHubImageProps | undefined = slide?.productImageProps
    ? {
        onTouchStart: handleTouchStartVideoPlay,
        onTouchEnd: handleTouchEndVideoPlay,
        ...(slide.productImageProps || {}),
        isVideoThumbnail: slide?.isVideo,
        pictureClassName: `media-carousel-card__picture ${hasFixedAspectRatio ? 'media-carousel-card__picture--16-9' : ''} ${
          slide.productImageProps?.pictureClassName ?? ''
        }`,
      }
    : undefined;

  const editorialImageProps: TEditorialImageProps | undefined = slide?.editorialImageProps
    ? {
        onTouchStart: handleTouchStartVideoPlay,
        onTouchEnd: handleTouchEndVideoPlay,
        ...(slide.editorialImageProps || {}),
        isVideoThumbnail: slide?.isVideo,
        cssClassPictureEl: `media-carousel-card__picture ${hasFixedAspectRatio ? 'media-carousel-card__picture--16-9' : ''} ${
          slide.editorialImageProps?.cssClassPictureEl ?? ''
        }`,
      }
    : undefined;

  return (
    <>
      <div className={'media-carousel-card__slide-wrapper ' + (mediaHeightClassName || '')}>
        {slide.isVideo ? (
          <div className="media-carousel-card__focus-image">
            <VideoPlayer
              link={slide.videoUrl?.value?.href}
              autoPlay={true}
              muted={!isWide}
              playCallback={playCallback}
              pauseCallback={pauseCallback}
              endCallback={endCallback}
              showThumbnail={true}
              productImageProps={productImageProps}
              editorialImageProps={editorialImageProps}
            />
          </div>
        ) : (
          <Picture productImageProps={productImageProps} editorialImageProps={editorialImageProps} />
        )}
      </div>
    </>
  );
};

export default React.memo(MediaCard);
