import React, { useEffect, useState, FC } from 'react';

import { ValueFieldProps } from '@sitecore-jss/sitecore-jss-react-forms/types';

import { documentService, windowService } from '../../../../utils/window.utils';

const PageTitleField: FC<ValueFieldProps> = (props) => {
  const { field } = props;
  const [value, setValue] = useState(props.value);
  const id = field?.valueField?.id;
  const name = field?.valueField?.name;

  useEffect(() => {
    const title = windowService()?.btoa(encodeURIComponent(documentService().title));

    setValue(title);
    props.onChange(field.valueField.name, title, props.isValid, []);
  }, []);

  return (
    <div>
      <input id={id} name={name} type="hidden" value={value} form="novalidatedform" />
    </div>
  );
};

export default PageTitleField;
