import React, { FC, useState, useEffect } from 'react';
import { BreakpointsInPx } from '../../../../constants/breakpoints.constants';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import Picture from '../../../@shared/picture';
import { TContentHubImageProps } from '../../../@shared/picture/types/contentHubImage.types';
import { TEditorialImageProps } from '../../../@shared/picture/types/editorialImage.types';
import VideoPlayer from '../../../@shared/videoPlayer';
import { THeroCardProps } from '../../types/heroCard.type';
import './styles.scss';

const HeroCard: FC<THeroCardProps> = ({
  slide,
  playCallback,
  pauseCallback,
  endCallback,
  activeCard,
  fromCarousel = false,
  moveLeft,
  moveRight,
  index,
  componentName,
}) => {
  const isWide = useMediaQuery(`(min-width: ${BreakpointsInPx['tbl-p']})`);
  const [swipeStart, setSwipeStart] = useState(0);

  let pictureProps: TContentHubImageProps | TEditorialImageProps = {};

  const handleTouchStart = (event) => {
    if (fromCarousel) return;
    const value = event?.changedTouches?.[0]?.pageX;
    if (!value) return;
    setSwipeStart(value);
  };

  const handleTouchMove = () => {
    if (fromCarousel) return;
  };

  const handleTouchStartVideoPlay = (event) => {
    if (isWide) return;
    const value = event?.changedTouches?.[0]?.pageX;
    if (!value) return;
    setSwipeStart(value);
  };

  const handleTouchEndVideoPlay = (event) => {
    if (isWide) return;
    const value = event?.changedTouches?.[0]?.pageX;
    if (!value) {
      setSwipeStart(0);
      return;
    }

    if (value < swipeStart && swipeStart - value > 20) {
      if (moveRight) moveRight();
    } else if (value > swipeStart && value - swipeStart > 20) {
      if (moveLeft) moveLeft();
    }
    setSwipeStart(0);
  };

  const initializePictureProps = () => {
    // TO DO: Refactor - using Picture with editorial props within CH connected component
    if (!componentName) {
      pictureProps = {
        mobileImage: slide?.thumbnail,
        desktopImage: slide.bigthumbnail,
        alt: slide.alt,
        onTouchStart: handleTouchStartVideoPlay,
        onTouchEnd: handleTouchEndVideoPlay,
        cssClassPictureEl: 'series-overview-hero-card__picture',
      } as TEditorialImageProps;

      return;
    }

    if (componentName === 'productSeriesOverview') {
      pictureProps = {
        mobileRendition: slide.rendition_mobile_size500,
        desktopRendition: slide.rendition_desktop_size1100,
        alt: slide.alt,
        onTouchStart: handleTouchStartVideoPlay,
        onTouchEnd: handleTouchEndVideoPlay,
        cssClassPictureEl: 'series-overview-hero-card__picture',
      } as TContentHubImageProps;
    }
  };

  initializePictureProps();

  useEffect(() => {
    setSwipeStart(0);
  }, [activeCard, index]);

  const localEndCallback = () => {
    if (endCallback) {
      endCallback();
    }
  };

  const handleTouchEnd = (event) => {
    if (fromCarousel) return;
    const value = event?.changedTouches?.[0]?.pageX;
    if (!value) {
      setSwipeStart(0);
      return;
    }

    if (value < swipeStart && swipeStart - value > 20) {
      if (moveRight) moveRight();
    } else if (value > swipeStart && value - swipeStart > 20) {
      if (moveLeft) moveLeft();
    } else {
      setSwipeStart(0);
    }
  };

  const imageProps = {
    ...pictureProps,
    onTouchStart: handleTouchStartVideoPlay,
    onTouchEnd: handleTouchEndVideoPlay,
    prioritize: index === 0,
  };
  const isProductSeriesOverviewComponent = componentName === 'productSeriesOverview';
  const localProductImageProps = isProductSeriesOverviewComponent ? imageProps : undefined;
  const localEditorialImageProps = !isProductSeriesOverviewComponent ? imageProps : undefined;

  return (
    <>
      {isWide && !fromCarousel && (
        <div className="series-overview-hero-card__focus-image__text-wrapper">
          <div className="series-overview-hero-card__focus-image__text-title">{slide.title}</div>
          <div className="series-overview-hero-card__focus-image__text-description">{slide.description}</div>
        </div>
      )}
      <div
        className={fromCarousel ? 'series-overview-carousel__slide-wrapper' : 'series-overview-expandable-overlay__slider-wrapper'}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {slide.isVideo ? (
          <>
            {fromCarousel ? (
              <div className="series-overview-hero-card__focus-image">
                <VideoPlayer
                  className="series-overview-modal-video"
                  link={slide.full}
                  autoPlay={true}
                  showThumbnail={true}
                  muted={!isWide}
                  playCallback={playCallback}
                  pauseCallback={pauseCallback}
                  endCallback={localEndCallback}
                  productImageProps={localProductImageProps}
                  editorialImageProps={localEditorialImageProps}
                />
              </div>
            ) : (
              <VideoPlayer
                className="series-overview-modal-video"
                link={slide.full}
                autoPlay={true}
                showThumbnail={true}
                muted={!isWide}
                playCallback={playCallback}
                pauseCallback={pauseCallback}
                endCallback={localEndCallback}
                productImageProps={localProductImageProps}
                editorialImageProps={localEditorialImageProps}
              />
            )}
          </>
        ) : (
          <Picture productImageProps={localProductImageProps} editorialImageProps={localEditorialImageProps} />
        )}
      </div>
    </>
  );
};

export default React.memo(HeroCard);
