import { ComponentRendering } from '@sitecore-jss/sitecore-jss-react';
import { LinkField } from '@sitecore-jss/sitecore-jss-react/types/components/Link';
import {
  TModelListingParams,
  TModelsListing,
  TModelsListingItemData,
  TModelsListingProps,
  TProductCard,
} from '../types/modelsListing.types';
import { TStringField } from '../../../types/sitecore.types';
import { appendRootToRichTextInternalLinks } from '../../../utils/appendRootToRichTextInternalLinks.utils';

export class ModelsListingModel {
  private readonly data?: TModelsListing;
  private readonly itemData?: TModelsListingItemData;
  private readonly params?: TModelListingParams;
  private readonly rendering?: ComponentRendering;
  private readonly isComponentUnreleased?: boolean;

  constructor(props: TModelsListingProps) {
    this.data = props?.fields?.apiData;
    this.itemData = props?.fields?.itemData;
    this.params = props?.params;
    this.rendering = props?.rendering;
    this.isComponentUnreleased = props?.fields?.isComponentUnreleased;
  }

  get thumbnailEE(): string {
    return this.itemData?.thumbnail || '';
  }

  get componentName(): string {
    return this.rendering?.componentName || '';
  }

  get items(): TProductCard[] | undefined {
    return this.data?.items?.filter((_) => !_.hideInModelsListing);
  }

  get total(): number | undefined {
    return this.data?.total;
  }

  get button1(): LinkField {
    return {
      value: {
        href: this.data?.button1?.url,
        text: this.data?.button1?.label,
        target: this.data?.button1?.target,
      },
    };
  }

  get button1Text(): string | undefined {
    return this.data?.button1?.label;
  }

  get eyebrow(): TStringField {
    return { value: this.data?.eyebrow };
  }

  get modelsDescription(): TStringField {
    return { value: appendRootToRichTextInternalLinks(this.data?.modelsDescription) };
  }

  get modelsTitle(): TStringField {
    return { value: this.data?.modelsTitle };
  }

  get subtitle(): string | undefined {
    return this.data?.subtitle;
  }

  get baseUrl(): string {
    return this.itemData?.contextItemUrl || '';
  }

  get columnLayout(): string {
    return this.params?.columnLayoutOption || '';
  }

  get isUnreleased(): boolean {
    return !!this.isComponentUnreleased;
  }
}
