import React, { FC } from 'react';
import { TQueryTermResultCount } from '../../types/result.types';

const QueryTermResultCount: FC<TQueryTermResultCount> = (props) => {
  const { query, counter, t } = props;
  if (!query) return null;
  return (
    <>
      <p className="search__subtitle-results">
        {t('cnhi-searchTerm')}: {`"${query}"`}
      </p>
      <p className="search__subtitle-results">
        {t('cnhi-totalResults')}: {counter}
      </p>
    </>
  );
};

export default QueryTermResultCount;
