import React, { FC } from 'react';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import useExperienceEditorPlaceholder from '../../hooks/useExperienceEditorPlaceholder';
import { isEmptyResponse } from '../../utils/checkEmptyResponse';
import EmptyApiDataMessage from '../@shared/emptyApiDataMessage';
import ExperienceEditorComponentPlaceholder from '../@shared/experienceEditorComponentPlaceholder';
import ProductDownloadBrochures from '../@shared/productDownloadBrochures';
import { TProductDownloadBrochuresProps } from '../@shared/productDownloadBrochures/types/productDownloadBrochures.types';
import UnreleasedComponent from '../@shared/unreleasedComponent';
import { descriptionKeySeriesDownloadBrochures, eyebrowKeySeriesDownloadBrochures, titleKeySeriesDownloadBrochures } from '../../constants/dictionary.constants';

const ProductSeriesDetailDownloadBrochures: FC<TProductDownloadBrochuresProps> = (props) => {
  const apiResponse = isEmptyResponse(props);
  const isPlaceholderShown = useExperienceEditorPlaceholder(props);
  const canShowComponent = props.fields?.apiData?.brochures?.assets?.length > 0;
  const componentName = props?.rendering?.componentName;

  // Component is unreleased
  if (props.fields.isComponentUnreleased) {
    return <UnreleasedComponent />;
  }

  //  star node opened directly in EE
  if (isPlaceholderShown) {
    return <ExperienceEditorComponentPlaceholder image={props.fields?.itemData?.thumbnail} componentName={componentName} />;
  }

  if ((!apiResponse || !canShowComponent) && isEditorActive()) {
    return <EmptyApiDataMessage componentName={componentName} />;
  }

  if ((!apiResponse && !isPlaceholderShown && !isEditorActive()) || !canShowComponent) {
    return null;
  }

  return <ProductDownloadBrochures {...props} titleDictionaryKey={descriptionKeySeriesDownloadBrochures} eyebrowDictionaryKey={eyebrowKeySeriesDownloadBrochures} descriptionDictionaryKey={titleKeySeriesDownloadBrochures}/>;
};

export default ProductSeriesDetailDownloadBrochures;
