import React, { FC } from 'react';
import MediaCarousel from '../../../@shared/mediaCarousel';
import { TSplitModuleMedia } from '../../types/splitModule.types';
import MediaPicture from './picture';
import MediaVideo from './video';

const Media: FC<TSplitModuleMedia> = (props) => {
  const { carouselSlide, slides, useVideo, mobileImage, desktopImage, isDesktop, video } = props;
  if (carouselSlide.length > 0) {
    return (
      <MediaCarousel slides={slides} extraClassName="split-module__media-carousel" mediaHeightClassName="split-module__media-height" />
    );
  }

  if (useVideo?.value) {
    return <MediaVideo desktopImage={desktopImage} mobileImage={mobileImage} isDesktop={isDesktop} video={video} />;
  } else {
    return <MediaPicture mobileImage={mobileImage} desktopImage={desktopImage} />;
  }
};

export default Media;
