import { appendRootToInternalLink } from '../../../utils/appendRootToInternalLink.utils';
import { TPlmSolutionsApiData, TPlmSolutionsItem, TPlmSolutionsProps } from '../types/productSeriesDetailPlmSolutions.types';

const prepareItems = (item: TPlmSolutionsItem): TPlmSolutionsItem => {
  return {
    id: item?.id || '',
    titleUrl: item?.titleUrl || '',
    title: item?.title || '',
    description: item?.description || '',
    button1: {
      label: item?.button1?.label || '',
      url: item?.button1?.url || '',
      target: item?.button1?.target || '',
    },
    button2: {
      label: item?.button2?.label || '',
      url: item?.button2?.url || '',
      target: item?.button2?.target || '',
    },
    assets: item?.assets || [],
    innerComponents: item.innerComponents || [],
  };
};
export const ProductPlmSolutionsModel = (props: TPlmSolutionsProps): TPlmSolutionsApiData => {
  const apiData = props?.fields?.apiData;
  const plmSolutionItems = apiData?.items || [];
  return {
    id: apiData?.id || '',
    title: apiData?.title || '',
    eyebrow: apiData?.eyebrow || '',
    description: apiData?.description || '',
    items: plmSolutionItems.length === 0 ? [] : plmSolutionItems.map((item) => prepareItems(item)),
    button1: {
      label: apiData?.button1?.label || '',
      url: appendRootToInternalLink(apiData?.button1?.url) || '',
      target: apiData?.button1?.target || '',
    },
  };
};
