import React, { useEffect, useRef, FC } from 'react';
import { iconSize } from '../../../../../constants/icon.constants';
import { windowService } from '../../../../../utils/window.utils';
import Icon from '../../../../@shared/icon';
import { TColumnHeadingElement } from '../../../types/index.types';
import DropdownChoice from './dropdownChoice';

const ColumnHeadingElement: FC<TColumnHeadingElement> = (props) => {
  const {
    columnValue,
    handleDropdownClick,
    columnIndex,
    dropdownAtColumnIndex,
    colHeadings,
    colOrdInIndex,
    setColOrdInIndex,
    setDropdownAtColumnIndex,
    dropdownPlaceholder,
    fixedColumn,
  } = props;

  const ColSubWrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function repositionHeightOnScroll() {
      if (!ColSubWrapperRef?.current || !dropdownPlaceholder) return;
      if (dropdownAtColumnIndex === columnIndex) {
        const subWrapperDimensions = ColSubWrapperRef.current.getBoundingClientRect();
        dropdownPlaceholder.style.setProperty('--table-dropdown-left', (subWrapperDimensions.left || 0) + 'px');
        dropdownPlaceholder.style.setProperty(
          '--table-dropdown-top',
          (subWrapperDimensions.top || 0) + (subWrapperDimensions.height || 0) + 'px'
        );
        dropdownPlaceholder.style.setProperty('--table-dropdown-width', (subWrapperDimensions.width || 0) + 'px');
      }
    }
    repositionHeightOnScroll();
    windowService().addEventListener('scroll', repositionHeightOnScroll);
    return () => windowService().removeEventListener('scroll', repositionHeightOnScroll);
  }, [ColSubWrapperRef, dropdownAtColumnIndex, columnIndex, dropdownPlaceholder, colHeadings]);

  return (
    <th className="model-detail-specification-table__col-heading-dropdown__wrapper">
      <div
        ref={ColSubWrapperRef}
        key={`model-detail-specification-table__col-heading-${columnValue}`}
        className="model-detail-specification-table__col-heading model-detail-specification-table__col-heading--clickable"
        onClick={(event): void => handleDropdownClick(event, columnIndex === dropdownAtColumnIndex ? null : columnIndex)}
        data-is-dropdown-switch={true}
      >
        <span className="model-detail-specification-table__col-heading__title-spec" data-is-dropdown-switch={true}>
          {colHeadings?.[colOrdInIndex[columnIndex]]}
        </span>
        <Icon
          iconName={dropdownAtColumnIndex === columnIndex ? 'arrow-chevron-up' : 'arrow-chevron-down'}
          size={iconSize.dsk}
          extraClassName="table-dropdown-icon"
        />
      </div>
      {dropdownAtColumnIndex === columnIndex && (
        <DropdownChoice
          fixedColumn={fixedColumn}
          colHeadings={colHeadings}
          colOrdInIndex={colOrdInIndex}
          columnIndex={columnIndex}
          setColOrdInIndex={setColOrdInIndex}
          setDropdownAtColumnIndex={setDropdownAtColumnIndex}
          dropdownPlaceholder={dropdownPlaceholder}
        />
      )}
    </th>
  );
};

export default ColumnHeadingElement;
