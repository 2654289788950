import React, { FC, useCallback } from 'react';
import Fade from 'react-reveal/Fade';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { BreakpointsInPx } from '../../../../constants/breakpoints.constants';
import { TOverviewTextProps } from '../../types/overviewText.type';
import { TComponentDetailsProps } from '../../types/contentHubSeriesPlmOverview.type';
import { OverviewTextModel } from '../../models/overviewText.model';
import grid from '../../../../assets/Grey-dots.svg';
import CTA from '../../../@shared/CTA';
import ctaTypes from '../../../../enums/CTA.enums';
import { findCtaType } from '../../../../utils/popUpCtaType.utils';
import { targetLinkdsk } from '../../../../utils/targetDskLink';
import './styles.scss';

const OverviewText: FC<WithTranslation & TComponentDetailsProps & TOverviewTextProps & { isEE: boolean }> = (props) => {
  const model = new OverviewTextModel(props);
  const isDesktop = useMediaQuery(`(min-width: ${BreakpointsInPx['tbl-p']})`);

  const WrapFade = useCallback(
    ({ duration, delay, children }) => {
      if (isDesktop && !props.isEE) {
        return (
          <Fade bottom duration={duration} delay={delay}>
            {children}
          </Fade>
        );
      }
      return <>{children}</>;
    },
    [isDesktop, props.isEE]
  );

  const hasPrimaryCta = model?.primaryLink?.label && model?.primaryLink?.url;
  const hasSecondaryCta = model?.secondaryLink?.label && model?.secondaryLink?.url;
  const popUpCtaType = findCtaType(props.rendering);
  const primaryCTAclassName = !hasSecondaryCta && popUpCtaType === 'primary-cta' ? 'plm-series-detail-overview-text__right-margin' : '';
  const secondaryCTAclassName = popUpCtaType === 'primary-cta' ? 'plm-series-detail-overview-text__right-margin' : '';
  const { t } = props;

  return (
    <>
      <div className="plm-series-detail-overview-text-wrapper">
        <div className="plm-series-detail-overview-text-wrapper__dot-space">
          {isDesktop && (
            <WrapFade duration={1000} delay={1000}>
              {/* eslint-disable-next-line */}
              <img className="" src={grid} loading="lazy" />
            </WrapFade>
          )}
        </div>
        <WrapFade duration={1000} delay={2000}>
          <div className="plm-series-detail-overview-text">
            <Text field={model.title} tag="h1" className="plm-series-detail-overview-text__title" editable={false} />
            <RichText
              field={model.description}
              tag="div"
              className="rich-text plm-series-detail-overview-text__description"
              editable={false}
            />
            {
              <div className="plm-series-detail-overview-text__link-wrapper">
                {hasPrimaryCta && (
                  <CTA
                    href={model.primaryLinkUrl}
                    target={targetLinkdsk({ value: { href: model?.primaryLink?.url, target: model?.primaryLink?.target } }, isDesktop)}
                    type={ctaTypes['primaryYellow']}
                    extraClassName={primaryCTAclassName}
                  >
                    {model.primaryLink.label || t('cnhi-discoverMore')}
                  </CTA>
                )}

                {hasSecondaryCta && (
                  <CTA
                    href={model.secondaryLinkUrl}
                    target={targetLinkdsk({ value: { href: model?.secondaryLink?.url, target: model?.secondaryLink?.target } }, isDesktop)}
                    type={ctaTypes['secondaryBlue']}
                    extraClassName={`${secondaryCTAclassName}`}
                  >
                    {model.secondaryLink.label}
                  </CTA>
                )}
              </div>
            }
          </div>
        </WrapFade>
      </div>
    </>
  );
};

export default withTranslation()(OverviewText);
